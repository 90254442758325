import React, {useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_BULK_ACCEPT_FAILED,
    ROAMING_ACT_BULK_ACCEPT_REQUESTED,
    ROAMING_ACT_BULK_ACCEPT_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import {ActCustomerAcceptHashCodeAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import ResultCounter from "../../../item/forms/bulk-item/ResultCounter";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import IconButton from "../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";

const BulkAcceptActs = ({acts, activeCertificate}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);

    const bulkAcceptSendActs = async (activeCertificate, acts) => {
        try {
            setLoading(true);
            ref.current.updateLoadedCount(acts.length);
            EventBus.dispatch(ROAMING_ACT_BULK_ACCEPT_REQUESTED, acts);
            const taskSize = 10;
            const tasks = [];
            const signer = async (act) => {
                try {
                    await ActCustomerAcceptHashCodeAsync(activeCertificate, lang, act.id);
                    ref.current.incrementSucceedCount();
                } catch (error) {
                    ref.current.incrementFailedCount();
                }
            };

            for (let i = 0; i < acts.length; i++) {
                tasks.push(signer(acts[i]));
                if (tasks.length >= taskSize) {
                    await Promise.all(tasks);
                    tasks.splice(0, tasks.length);
                }
            }

            if (tasks.length > 0) {
                await Promise.all(tasks);
                tasks.splice(0, tasks.length);
            }

            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_BULK_ACCEPT_SUCCESS, acts);
        } catch (error) {
            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_BULK_ACCEPT_SUCCESS, acts);
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <ResultCounter ref={ref}/>
            <SelectCertificateWrapperComponent openDialogButtonText={t(lang, "edi.common.approve_all")}
                                               variant="falcon-success"
                                               submitButton={
                                                   <IconButton icon={faSignature}
                                                               variant="falcon-success"
                                                               id="Send"
                                                               size="sm"
                                                               disabled={loading}
                                                               onClick={() => bulkAcceptSendActs(activeCertificate, acts)}
                                                   >
                                                       {loading &&
                                                           <Spinner size="sm" className='align-middle me-2'
                                                                    animation='border' role='switch'/>}
                                                       {t(lang, "edi.common.approve_all")}
                                                   </IconButton>
                                               }
            />
        </React.Fragment>
    );
};

export default BulkAcceptActs;