import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getAllCashOutAsync,
    getCashOutCountAsync, selectCashBoxCashOutFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_CASH_OUT_DELETE_SUCCESS,
    CASHBOX_CASH_OUT_REGISTER_SUCCESS,
    CASHBOX_CASH_OUT_UPDATE_SUCCESS
} from "../../../../app/eventbus/cashbox/cashboxEvents";
import CashBoxCashOutDataTable from "../../../../components/hippo/cashbox/cash-out/CashBoxCashOutDataTable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxCashOutView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const CashBoxCashOut = () => {

    const dispatch = useDispatch()
    const filters = useSelector(selectCashBoxCashOutFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    // permissions
    const isCashBoxCashOutView = useCheckPermission(PermissionCashBoxCashOutView)

    const getCashOutAsync = () => {
        if (!isCashBoxCashOutView) return
        dispatch(getAllCashOutAsync({...filters}))
        dispatch(getCashOutCountAsync({...filters}))
    }

    useEffect(() => {
        getCashOutAsync()
    }, [filters, activeOrganization])


    useEffect(() => {
        const onSuccessRegisterCashOut = EventBus.on(CASHBOX_CASH_OUT_REGISTER_SUCCESS, () => {
            getCashOutAsync()
        })
        const onSuccessUpdateCashOut = EventBus.on(CASHBOX_CASH_OUT_UPDATE_SUCCESS, () => {
            getCashOutAsync()
        })
        const onSuccessDeleteCashOut = EventBus.on(CASHBOX_CASH_OUT_DELETE_SUCCESS, () => {
            getCashOutAsync()
        })

        return () => {
            EventBus.remove(CASHBOX_CASH_OUT_REGISTER_SUCCESS, onSuccessRegisterCashOut)
            EventBus.remove(CASHBOX_CASH_OUT_UPDATE_SUCCESS, onSuccessUpdateCashOut)
            EventBus.remove(CASHBOX_CASH_OUT_DELETE_SUCCESS, onSuccessDeleteCashOut)
        }
    }, [activeOrganization])

    if (!isCashBoxCashOutView) return <Error403 />

    return (
            <CashBoxCashOutDataTable />
    );
};

export default CashBoxCashOut;
