import {loadItemAsync} from "../../../app/store/reducers/item/itemReducer";
import {useHistory, useParams} from "react-router";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import Item from "../../../components/hippo/item/Item";
import EventBus from "../../../app/eventbus/EventBus";
import {PRICE_UPDATE_SUCCESS} from "../../../app/eventbus/priceEvents";
import {
    DELETE_ITEM_SUCCESS, EDIT_ITEM_SUCCESS,
    VALIDATE_ITEM_CATALOG_SUCCESS
} from "../../../app/eventbus/itemEvents";
import ItemProductIdentities from "../../../components/hippo/item/components/ItemProductIdentities";
import {PermissionItemView} from "../../../enum/Permission/WarehouseItemPermission";
import useCheckPermission from "../../../hooks/useCheckPermission";
import Error403 from "../../../components/errors/Error403";

const ViewItem = () => {
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory();

    const [item, setItem] = useState(null);


    // permissions
    const isItemView = useCheckPermission(PermissionItemView)

    const loadItem = () => {
        if (!isItemView) return
        setTimeout(() => {
            loadItemAsync(id)
                .then((item) => setItem(item))
                .catch(() => setItem(null));
        }, 1000)
    }

    useEffect(() => {
        loadItem();
    }, [id, activeOrganization])

    useEffect(() => {
        const onPriceUpdatedHandler = EventBus.on(PRICE_UPDATE_SUCCESS, loadItem);
        const onItemDeletedHandler = EventBus.on(DELETE_ITEM_SUCCESS, () => {
            history.replace("../../../warehouse/items");
        });
        const onItemUpdatedHandler = EventBus.on(EDIT_ITEM_SUCCESS, loadItem);
        const onCatalogValidatedHandler = EventBus.on(VALIDATE_ITEM_CATALOG_SUCCESS, loadItem);

        return () => {
            EventBus.remove(PRICE_UPDATE_SUCCESS, onPriceUpdatedHandler);
            EventBus.remove(DELETE_ITEM_SUCCESS, onItemDeletedHandler);
            EventBus.remove(VALIDATE_ITEM_CATALOG_SUCCESS, onCatalogValidatedHandler);
            EventBus.remove(EDIT_ITEM_SUCCESS, onItemUpdatedHandler);
        }
    }, [id])

    if (!isItemView) return <Error403 />

    if (!item)
        return <>loading item...</>

    return (
        <>
            <Item item={item}/>
            <ItemProductIdentities itemId={id}/>
        </>
    )
}


export default ViewItem;
