import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import ActDataTable from "./ActDataTable";
import {
    selectColumnVisibility,
    selectFilters,
    selectPagination,
    updateColumnVisibility,
    updateFilters,
    updatePagination
} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {debounce} from "underscore";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../View/TypeView";
import roamingService from "../../../../../services/roaming/roamingService";
import dayjs from "dayjs";

const RoamingActDataTable = ({viewer}) => {
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const filters = useSelector(selectFilters);
    const pagination = useSelector(selectPagination);
    const columnVisibility = useSelector(selectColumnVisibility);
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);
    const [loading, setLoading] = useState(false);
    const [acts, setActs] = useState([]);
    const [count, setCount] = useState(0);

    const onFilterChange = (filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}))
        load(viewer, filter, {...pagination, pageIndex: 0});
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}))
        load(viewer, filter, {...pagination, pageIndex: 0});
    }, 300), [pagination.pageSize])
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        dispatch(updatePagination(nextState))
        load(viewer, filters, nextState)
    };
    const onColumnVisibilityChange = (updater) => {
        const nextState = updater(columnVisibility);
        dispatch(updateColumnVisibility(nextState))
    };
    const onRowSelectionChange = (rowSelection) => {
        setRowSelection(rowSelection);
    };
    const load = async (viewer, filter, pagination) => {
        try {
            setLoading(true);
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                if (filter.registryId)
                    filterParams["registry_id"] = filter.registryId;

                if (viewer !== VIEW_DRAFT && filter.status !== null)
                    filterParams["status"] = filter.status;

                if (filter.number)
                    filterParams["number"] = filter.number;

                if (filter.dateStart)
                    filterParams["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD");

                if (filter.dateEnd)
                    filterParams["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD");

                if (filter.createdAtStart)
                    filterParams["created_at_start"] = dayjs(filter.createdAtStart).format("YYYY-MM-DD");

                if (filter.createdAtEnd)
                    filterParams["created_at_end"] = dayjs(filter.createdAtEnd).format("YYYY-MM-DD");

                if (filter.contractNumber)
                    filterParams["contract_number"] = filter.contractNumber;

                if (filter.contractDate)
                    filterParams["contract_date"] = dayjs(filter.contractDate).format("YYYY-MM-DD");

                if (filter.contractor) {
                    if (viewer === VIEW_DRAFT || viewer === VIEW_OUTBOX)
                        filterParams["customer_inn"] = filter.contractor.person_num || filter.contractor.inn;
                    else if (viewer === VIEW_INBOX)
                        filterParams["executor_inn"] = filter.contractor.person_num || filter.contractor.inn;
                }
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize
            }

            // load documents and count
            switch (viewer) {
                case VIEW_INBOX:
                    const getCustomerActsPromise = roamingService.getCustomerAct({...filterParams, ...paginationParams});
                    const getCustomerActsCountPromise = roamingService.getCustomerActCount(filterParams)
                    const {data: customerActs} = await getCustomerActsPromise;
                    const {data: customerActsCount} = await getCustomerActsCountPromise;
                    setActs(customerActs);
                    setCount(customerActsCount);
                    break;
                case VIEW_OUTBOX:
                    const getExecutorActsPromise = roamingService.getExecutorAct({...filterParams, ...paginationParams});
                    const getExecutorActsCountPromise = roamingService.getExecutorActCount(filterParams)
                    const {data: executorActs} = await getExecutorActsPromise;
                    const {data: executorActsCount} = await getExecutorActsCountPromise;
                    setActs(executorActs);
                    setCount(executorActsCount);
                    break;
                case VIEW_DRAFT:
                    const getExecutorDraftActsPromise = roamingService.getExecutorActDraft({...filterParams, ...paginationParams});
                    const getExecutorDraftActsCountPromise = roamingService.getExecutorActDraftCount(filterParams);
                    const {data: executorDraftActs} = await getExecutorDraftActsPromise;
                    const {data: executorDraftActsCount} = await getExecutorDraftActsCountPromise;
                    setActs(executorDraftActs);
                    setCount(executorDraftActsCount);
                    break;
                default:
                    break
            }
            setLoading(false);
        } catch (error) {
            setActs([]);
            setCount(0);
            setLoading(false);
        }
    };

    useEffect(() => {
        load(viewer, filters, pagination)
    }, [activeOrganization, viewer]);

    return (
        <ActDataTable activeOrganization={activeOrganization}
                      activeCertificate={activeCertificate}
                      viewer={viewer}
                      filter={filters}
                      pagination={pagination}
                      columnVisibility={columnVisibility}
                      onFilterChange={onFilterChange}
                      onDebouncedFilterChange={onDebouncedFilterChange}
                      onPaginationChange={onPaginationChange}
                      onColumnVisibilityChange={onColumnVisibilityChange}
                      onReloadData={() => load(viewer, filters, pagination)}
                      loading={loading}
                      rowSelection={rowSelection}
                      onRowSelectionChange={onRowSelectionChange}
                      lastRowSelected={lastRowSelected}
                      acts={acts}
                      count={count}
        />
    );
};

export default RoamingActDataTable;