export const DOCUMENT_SIGN_STARTED = "DOCUMENT_SIGN_STARTED"
export const DOCUMENT_SIGN_SUCCESS = "DOCUMENT_SIGN_SUCCESS"
export const DOCUMENT_SIGN_FAILED = "DOCUMENT_SIGN_FAILED"
export const DOCUMENT_SAVE_STARTED = "DOCUMENT_SAVE_STARTED"
export const DOCUMENT_SAVE_SUCCESS = "DOCUMENT_SAVE_SUCCESS"
export const DOCUMENT_SAVE_FAILED = "DOCUMENT_SAVE_FAILED"
export const DOCUMENT_CANCEL_STARTED = "DOCUMENT_CANCEL_STARTED"
export const DOCUMENT_CANCEL_SUCCESS = "DOCUMENT_CANCEL_SUCCESS"
export const DOCUMENT_CANCEL_FAILED = "DOCUMENT_CANCEL_FAILED"
export const DOCUMENT_DECLINE_STARTED = "DOCUMENT_DECLINE_STARTED"
export const DOCUMENT_DECLINE_SUCCESS = "DOCUMENT_DECLINE_SUCCESS"
export const DOCUMENT_DECLINE_FAILED = "DOCUMENT_DECLINE_FAILED"
export const COMPANY_BINDING_STARTED = "COMPANY_BINDING_STARTED"
export const COMPANY_BINDING_SUCCESS = "COMPANY_BINDING_SUCCESS"
export const COMPANY_BINDING_FAILED = "COMPANY_BINDING_FAILED"
