import React from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {roamingInvoiceDocumentTypes} from "../../../../../../enum/RoamingInvoiceWrapper";

const DocumentTypeSelector = ({defaultType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={roamingInvoiceDocumentTypes}
                     isClearable={false}
                     isSearchable={false}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => t(lang, option.label)}
                     value={roamingInvoiceDocumentTypes.find(o => o.value === defaultType) || null}
                     onChange={(option) => onChange(option ? option.value : null)}
                     placeholder={t(lang, "items.common.select")}
                     {...selectProps}
        />
    );
};

export default DocumentTypeSelector;