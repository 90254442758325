import {Row, Tab, Tabs} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from 'react';
import PricingCard from "./PricingCard";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    EDI_SERVICE_CODE,
    EIMZO_SIGNER_SERVICE_CODE,
    loadBillingPackagesAsync,
    POS_SERVICE_CODE,
    selectBillingPackages, selectOrganizationServiceDemoUses,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../../../app/store/reducers/billing/billingReducer";
import TryEdiTrialAlert from "../../../notification/TryEDITrialAlert";

const Packages = () => {
    const packages = useSelector(selectBillingPackages);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const serviceDemoUses = useSelector(selectOrganizationServiceDemoUses);

    const [key, setKey] = useState(EDI_SERVICE_CODE)

    useEffect(() => {
        dispatch(loadBillingPackagesAsync())
    }, [])

    const isAlreadyUsedWarehouseManagement = serviceDemoUses?.find(service => service?.code === WAREHOUSE_MANAGEMENT_SERVICE_CODE);
    const isAlreadyUsedPos = serviceDemoUses?.find(service => service?.code === POS_SERVICE_CODE);

    return (
        <Fragment>
            <Tabs activeKey={key} variant={'pills'} onSelect={(key) => setKey(key)} className='border-bottom pt-1 bg-light px-3 py-1'>
                <Tab eventKey={EDI_SERVICE_CODE} title={t(lang, 'edi.navbar.routes.edi')}>
                    <Row className="flex-center pt-1 pb-5 px-3 px-md-0">
                        {packages.filter(i => i.service.code === EDI_SERVICE_CODE).map(billingPackage => (
                            <PricingCard key={billingPackage.id} pricing={billingPackage}/>
                        ))}
                    </Row>
                </Tab>
                <Tab eventKey={POS_SERVICE_CODE} title={t(lang, 'POS')}>
                    {!isAlreadyUsedPos && <TryEdiTrialAlert serviceCode={POS_SERVICE_CODE}/>}

                    <Row className="flex-center pt-1 pb-5 px-3 px-md-0">
                        {packages.filter(i => i.service.code === POS_SERVICE_CODE).map(billingPackage => (
                            <PricingCard key={billingPackage.id} pricing={billingPackage}/>
                        ))}
                    </Row>
                </Tab>
                <Tab eventKey={WAREHOUSE_MANAGEMENT_SERVICE_CODE} title={t(lang, 'warehouse.navbar.routes.warehouse')}>
                    {!isAlreadyUsedWarehouseManagement && <TryEdiTrialAlert serviceCode={WAREHOUSE_MANAGEMENT_SERVICE_CODE}/>}

                    <Row className="flex-center pt-1 pb-5 px-3 px-md-0">
                        {packages.filter(i => i.service.code === WAREHOUSE_MANAGEMENT_SERVICE_CODE).map(billingPackage => (
                            <PricingCard key={billingPackage.id} pricing={billingPackage}/>
                        ))}
                    </Row>
                </Tab>
                <Tab eventKey={EIMZO_SIGNER_SERVICE_CODE} title={t(lang, 'edi.navbar.routes.edo')}>
                    <Row className="flex-center pt-1 pb-5 px-3 px-md-0">
                        {packages.filter(i => i.service.code === EIMZO_SIGNER_SERVICE_CODE).map(billingPackage => (
                            <PricingCard key={billingPackage.id} pricing={billingPackage}/>
                        ))}
                    </Row>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

export default Packages;
