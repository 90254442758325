import React, {useEffect, useState} from 'react';
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {useHistory} from "react-router-dom";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import {FormProvider, useForm} from "react-hook-form";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActCreate} from "../../../../../enum/Permission/RoamingPermission";
import {toast} from "react-toastify";
import {ActFormSendAsync, ActFormSendDraft} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import dayjs from "dayjs";
import InProgress from "../../../../common/InProgress";
import WarningAlert from "../../components/WarningAlert";
import RoamingActForm from "./RoamingActForm";
import {getNumberFixedValue} from "../../../../../helpers/utils";
import documentGeneratorService from "../../../../../services/documentGenerator/documentGeneratorService";


const apiDateDayJsFormat = 'YYYY-MM-DD';
const ROUND_SCALE_FOUR = 4;
const ROUND_SCALE_TWO = 2;
const ROUND_SCALE_FIVE = 5;

const SendRoamingActForm = ({act, isEdit, isCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const permission = useSelector(selectAccountRolePermissions);
    const form = useForm({
        defaultValues: {
            act_id: act?.act_id || '',
            description: act?.description || '',
            notes: act?.notes || '',
            info: {
                number: act?.info?.number || '',
                date: act?.info?.date ? dayjs(act.info.date).toDate() : null,
            },
            contract: {
                number: act?.contract?.number || '',
                date: act?.contract?.date ? dayjs(act.contract.date).toDate() : null,
            },
            executor: {
                identifier: act?.executor?.identifier || '',
                name: act?.executor?.name || '',
                branch: act?.executor?.branch || null,
            },
            customer: {
                identifier: act?.customer?.identifier || '',
                name: act?.customer?.name || '',
                branch: act?.customer?.branch || null,
            },
            product_list: {
                product_id: act?.product_list?.product_id || '',
                items: act?.product_list?.items?.map((item) => ({
                    catalog: item?.catalog || null,
                    package: item?.package || null,
                    measurement: item?.measurement || null,
                    name: item?.name || '',
                    quantity: item?.quantity || '',
                    price: item?.price || '',
                    total: item?.total || '',
                    vat_rate: item?.vat_rate || '',
                    vat_sum: item?.vat_sum || '',
                    total_with_vat_sum: item?.total_with_vat_sum || '',
                }))
            },
        },
    });

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActCreate))) {
            if (history.length > 1) {
                history.push('/roaming/acts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission]);

    const onSend = async (formData) => {
        try {
            setErrorMessage(null);
            setIsSending(true);

            dispatch(updateShowInProgressAsync(true));
            const roamingActSendHashCode = await getDocumentGeneratorHashcode(formData);
            const {data: act} = await ActFormSendAsync(activeCertificate, lang, roamingActSendHashCode)
            setIsSending(false);
            dispatch(updateShowInProgressAsync(false));
            toast.success(t(lang, "common.toast.success"));
            history.push(`/roaming/act/${act.id}`)
        } catch (error) {
            setIsSending(false);
            dispatch(updateShowInProgressAsync(false));
            setErrorMessage(error?.message || null);
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            toast.error(t(lang, error?.message));
        }
    };
    const onSave = async (formData) => {
        try {
            setErrorMessage(null);
            setIsSaving(true);

            dispatch(updateShowInProgressAsync(true));
            const roamingActSendHashCode = await getDocumentGeneratorHashcode(formData);
            const draftAct = await ActFormSendDraft({content: roamingActSendHashCode});
            setIsSaving(false);
            dispatch(updateShowInProgressAsync(false));
            toast.success(t(lang, "common.toast.success"));
            history.push(`/roaming/draft-act/${draftAct.id}`)
        } catch (error) {
            setIsSaving(false);
            dispatch(updateShowInProgressAsync(false));
            setErrorMessage(error?.message || null);
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            toast.error(t(lang, error?.message));
        }
    };
    const getDocumentGeneratorHashcode = async (formData) => {
        try {
            const documentGeneratorJson = {
                act_id: '',
                act_product_id: '',
                act_info: {
                    number: formData.info.number,
                    date: dayjs(formData.info.date).format(apiDateDayJsFormat)
                },
                contract_info: {
                    number: formData.contract.number,
                    date: dayjs(formData.contract.date).format(apiDateDayJsFormat)
                },
                executor: {
                    inn: formData.executor.identifier,
                    name: formData.executor.name,
                    branch_code: formData.executor.branch?.code,
                    branch_name: formData.executor.branch?.name
                },
                customer: {
                    inn: formData.customer.identifier,
                    name: formData.customer.name,
                    branch_code: formData.customer.branch?.code,
                    branch_name: formData.customer.branch?.name
                },
                notes: formData.notes || '',
                description: formData.description,
                items: formData.product_list.items.map((product) => {
                    return {
                        name: product.name,
                        catalog_code: product.catalog.class_code,
                        catalog_name: product.catalog.name,
                        measurement: product.package ? product.package.code.toString() : product.measurement.measureId.toString(),
                        quantity: parseFloat(getNumberFixedValue(product.quantity || 0, ROUND_SCALE_FIVE)),
                        price: parseFloat(getNumberFixedValue(product.price || 0, ROUND_SCALE_FOUR)),
                        vat_rate: parseInt(product.vat_rate),
                    };
                })
            };

            // set act_id
            {
                if (isEdit)
                    documentGeneratorJson.act_id = formData.act_id;
            }

            // set act_product_id
            {
                if (isEdit)
                    documentGeneratorJson.act_product_id = formData.product_list.product_id;
            }

            const {data: {hashcode}} = await documentGeneratorService.generateRoamingActSendHashCode(documentGeneratorJson);
            return hashcode;
        } catch (error) {
            throw error;
        }
    };

    return (
        <React.Fragment>
            <InProgress/>
            {errorMessage && <WarningAlert text={errorMessage} title={'Error'} variant={'danger'}/>}
            <FormProvider {...form}>
                <RoamingActForm isSaving={isSaving}
                                isSending={isSending}
                                onSend={form.handleSubmit(onSend)}
                                onSave={form.handleSubmit(onSave)}
                                isEdit={isEdit}
                                isCopy={isCopy}
                />
            </FormProvider>
        </React.Fragment>
    )
};

export default SendRoamingActForm;