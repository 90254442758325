export const ACTIVATE_DEMO_REQUESTED = 'ACTIVATE_DEMO_REQUESTED';
export const ACTIVATE_DEMO_SUCCEED = 'ACTIVATE_DEMO_SUCCEED';
export const ACTIVATE_DEMO_FAILED = 'ACTIVATE_DEMO_FAILED';

export const ACTIVATE_PACKAGE_REQUESTED = 'ACTIVATE_PACKAGE_REQUESTED';
export const ACTIVATE_PACKAGE_SUCCEED = 'ACTIVATE_PACKAGE_SUCCEED';
export const ACTIVATE_PACKAGE_FAILED = 'ACTIVATE_PACKAGE_FAILED';

export const ADD_ORGANIZATION_BALANCE_REQUESTED = 'ADD_ORGANIZATION_BALANCE_REQUESTED';
export const ADD_ORGANIZATION_BALANCE_SUCCEED = 'ADD_ORGANIZATION_BALANCE_SUCCEED';
export const ADD_ORGANIZATION_BALANCE_FAILED = 'ADD_ORGANIZATION_BALANCE_FAILED';

export const APPLY_CREDIT_INTO_ORGANIZATION_REQUESTED = 'APPLY_CREDIT_INTO_ORGANIZATION_REQUESTED';
export const APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS = 'APPLY_CREDIT_INTO_ORGANIZATION_SUCCESS';
export const APPLY_CREDIT_INTO_ORGANIZATION_FAILED = 'APPLY_CREDIT_INTO_ORGANIZATION_FAILED';

export const REGISTER_CREDENTIAL_SUCCESS = "REGISTER_CREDENTIAL_SUCCESS";
export const REGISTER_CREDENTIAL_FAIL = "REGISTER_CREDENTIAL_FAIL";

export const SEEN_BANK_PAYMENTS_ERROR_SUCCESS = "SEEN_BANK_PAYMENTS_ERROR_SUCCESS"
export const SEEN_BANK_PAYMENTS_ERROR_FAILED = "SEEN_BANK_PAYMENTS_ERROR_FAILED"

export const SERVICE_REMOVE_DEMO_USES_SUCCESS = "SERVICE_REMOVE_DEMO_USES_SUCCESS"
export const SERVICE_REMOVE_DEMO_USES_FAIL = "SERVICE_REMOVE_DEMO_USES_FAIL"

export const ADD_ORGANIZATION_BALANCE_BINDING_REQUEST = "ADD_ORGANIZATION_BALANCE_BINDING_REQUEST";
export const ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED = "ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED";
export const ADD_ORGANIZATION_BALANCE_BINDING_FAILED = "ADD_ORGANIZATION_BALANCE_BINDING_FAILED";

export const REMOVE_ORGANIZATION_BALANCE_BINDING_REQUEST = "REMOVE_ORGANIZATION_BALANCE_BINDING_REQUEST";
export const REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED = "REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED";
export const REMOVE_ORGANIZATION_BALANCE_BINDING_FAILED = "REMOVE_ORGANIZATION_BALANCE_BINDING_FAILED";