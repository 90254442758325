import React, {useEffect} from "react"
import ContractorPayoutDataTable from "../../../../components/hippo/crm/contractor/payout/ContractorPayoutDataTable"
import {
    ADD_CONTRACTOR_PAYOUT_SUCCESS,
    DELETE_CONTRACTOR_PAYOUT_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_SUCCESS
} from "../../../../app/eventbus/contractor/contractorPaymentEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    loadContractorPayoutsAsync,
    selectFilterOptionsContractorPayout,
    selectContractor
} from "../../../../app/store/reducers/contractor/contractorPayoutReducer";
import {useDispatch, useSelector} from "react-redux";
import {PermissionCRMContractorPayoutView} from "../../../../enum/Permission/CrmPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";

const ContractorPayouts = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptionsContractorPayout)
    const contractor = useSelector(selectContractor);

    // permissions
    const isPayoutView = useCheckPermission(PermissionCRMContractorPayoutView)

    useEffect(() => {
        if (!isPayoutView) return

        const onContractorPayoutAddedHandler = EventBus.on(ADD_CONTRACTOR_PAYOUT_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPayoutEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYOUT_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });

        const onContractorPayoutContractorEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPayoutNoteEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPayoutDateEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });

        const onContractorPayoutDeletedHandler = EventBus.on(DELETE_CONTRACTOR_PAYOUT_SUCCESS, () => {
            dispatch(loadContractorPayoutsAsync({...filters, contractor_id: contractor?.id}))
        });

        return () => {
            EventBus.remove(ADD_CONTRACTOR_PAYOUT_SUCCESS, onContractorPayoutAddedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYOUT_SUCCESS, onContractorPayoutEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS, onContractorPayoutContractorEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS, onContractorPayoutNoteEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS, onContractorPayoutDateEditedHandler)
            EventBus.remove(DELETE_CONTRACTOR_PAYOUT_SUCCESS, onContractorPayoutDeletedHandler)
        }
    }, []);

    if (!isPayoutView) return <Error403 />

    return (<ContractorPayoutDataTable/>)
}

export default ContractorPayouts