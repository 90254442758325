import {Route, Switch, useRouteMatch} from "react-router";
import Sales from "../sale/Sales";
import ViewSale from "../sale/ViewSale";
import AddSaleExcel from "../sale/AddSaleExcel";
import EditSaleExcel from "../sale/EditSaleExcel";
import Purchases from "../purchase/Purchases";
import AddPurchaseExcel from "../purchase/AddPurchaseExcel";
import ViewPurchase from "../purchase/ViewPurchase";
import EditPurchaseExcel from "../purchase/EditPurchaseExcel";
import Refunds from "../refund/Refunds";
import AddRefundExcel from "../refund/AddRefundExcel";
import ViewRefund from "../refund/ViewRefund";
import EditRefundExcel from "../refund/EditRefundExcel";
import ReturnPurchases from "../return-purchase/ReturnPurchases";
import AddReturnPurchaseExcel from "../return-purchase/AddReturnPurchaseExcel";
import ViewReturnPurchase from "../return-purchase/ViewReturnPurchase";
import EditReturnPurchaseExcel from "../return-purchase/EditReturnPurchaseExcel";
import Revisions from "../revision/Revisions";
import AddRevisionExcel from "../revision/AddRevisionExcel";
import ViewRevision from "../revision/ViewRevision";
import EditRevisionExcel from "../revision/EditRevisionExcel";
import WriteOffs from "../write-off/WriteOffs";
import AddWriteOffExcel from "../write-off/AddWriteOffExcel";
import ViewWriteOff from "../write-off/ViewWriteOff";
import EditWriteOffExcel from "../write-off/EditWriteOffExcel";
import Transfers from "../transfer/Transfers";
import AddTransferExcel from "../transfer/AddTransferExcel";
import ViewTransfer from "../transfer/ViewTransfer";
import EditTransferExcel from "../transfer/EditTransferExcel";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadSaleDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {loadPurchaseDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import {loadRefundDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/refundReducer";
import {
    loadReturnPurchaseDefaultsSettings
} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {loadRevisionDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";
import {loadWriteOffDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import {loadTransferDefaultsSettings} from "../../../../app/store/reducers/warehouse-operation/transferReducer";
import AddSale from "../sale/AddSale";
import EditSale from "../sale/EditSale";
import AddPurchase from "../purchase/AddPurchase";
import EditPurchase from "../purchase/EditPurchase";
import AddRefund from "../refund/AddRefund";
import EditRefund from "../refund/EditRefund";
import AddReturnPurchase from "../return-purchase/AddReturnPurchase";
import EditReturnPurchase from "../return-purchase/EditReturnPurchase";
import AddRevision from "../revision/AddRevision";
import EditRevision from "../revision/EditRevision";
import AddWriteOff from "../write-off/AddWriteOff";
import EditWriteOff from "../write-off/EditWriteOff";
import AddTransfer from "../transfer/AddTransfer";
import EditTransfer from "../transfer/EditTransfer";
import {loadEmployeesAsync} from "../../../../app/store/reducers/employee/employeeReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_EMPLOYEE_SUCCEED, EDIT_EMPLOYEE_SUCCEED} from "../../../../app/eventbus/employeeEvents";
import ViewReport from "../report/ViewReport";
import CreateTelegramOrderSale from "../../../../components/hippo/warehouse-operation/order-sale/forms/CreateTelegramOrderSale";
import DraftPurchases from "../draft-purchase/DraftPurchases";
import ViewDraftPurchase from "../draft-purchase/ViewDraftPurchase";
import DraftRefunds from "../draft-refund/DraftRefunds";
import ViewDraftRefund from "../draft-refund/ViewDraftRefund";
import OperationItemsReport from "../items-report/OperationItemsReport";
import {getAllContractorLabelsAsync, selectContractorLabelFilterOptions} from "../../../../app/store/reducers/contractor/contractorLabelReducer";
import {getBoardOrganizationAsync} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {PermissionCRMContractorLabelView} from "../../../../enum/Permission/CrmPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";


const WarehouseOperationLayout = () => {
    const filters = useSelector(selectContractorLabelFilterOptions);
    const dispatch = useDispatch()
    const {url} = useRouteMatch()
    const activeOrganization = useSelector(selectActiveOrganization)

    // permissions
    const isContractorLabelView = useCheckPermission(PermissionCRMContractorLabelView)

    useEffect(() => {
        dispatch(loadSaleDefaultsSettings())
        dispatch(loadPurchaseDefaultsSettings())
        dispatch(loadRefundDefaultsSettings())
        dispatch(loadReturnPurchaseDefaultsSettings())
        dispatch(loadRevisionDefaultsSettings())
        dispatch(loadWriteOffDefaultsSettings())
        dispatch(loadTransferDefaultsSettings())
        if (isContractorLabelView) {
            dispatch(getAllContractorLabelsAsync({...filters}))
        }
        dispatch(loadEmployeesAsync())
        const onAddEmployeeHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, () => {
            dispatch(loadEmployeesAsync())
        })

        const onEditEmployeeHandler = EventBus.on(EDIT_EMPLOYEE_SUCCEED, () => {
            dispatch(loadEmployeesAsync())
        })


        return () => {
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onAddEmployeeHandler)
            EventBus.remove(EDIT_EMPLOYEE_SUCCEED, onEditEmployeeHandler)
        }
    },[])

    useEffect(() => {
        dispatch(getBoardOrganizationAsync({}))
    }, [activeOrganization])


    return(
        <>
            <Switch>
                <Route path={`${url}/sales`} exact component={Sales} />
                {/*<Route path={`${url}/sales/register-excel`} exact component={AddSaleExcel} />
                <Route path={`${url}/sales/update-excel/:id`} component={EditSaleExcel} />*/}
                <Route path={`${url}/sales/register`} exact component={AddSale} />
                <Route path={`${url}/sales/update/:id`} component={EditSale} />
                <Route path={`${url}/sales/view/:id`} component={ViewSale} />

                <Route path={`${url}/purchases`} exact component={Purchases}/>
                {/*<Route path={`${url}/purchases/register-excel`} component={AddPurchaseExcel}/>
                <Route path={`${url}/purchases/update-excel/:id`} component={EditPurchaseExcel} />*/}
                <Route path={`${url}/purchases/register`} component={AddPurchase}/>
                <Route path={`${url}/purchases/update/:id`} component={EditPurchase} />
                <Route path={`${url}/purchases/view/:id`} component={ViewPurchase} />

                <Route path={`${url}/refunds`} exact component={Refunds}/>
                {/*<Route path={`${url}/refunds/register-excel`} component={AddRefundExcel}/>
                <Route path={`${url}/refunds/update-excel/:id`} component={EditRefundExcel} />*/}
                <Route path={`${url}/refunds/register`} component={AddRefund}/>
                <Route path={`${url}/refunds/update/:id`} component={EditRefund} />
                <Route path={`${url}/refunds/view/:id`} component={ViewRefund} />

                <Route path={`${url}/return-purchases`} exact component={ReturnPurchases}/>
                {/*<Route path={`${url}/return-purchases/register-excel`} component={AddReturnPurchaseExcel}/>
                <Route path={`${url}/return-purchases/update-excel/:id`} component={EditReturnPurchaseExcel} />*/}
                <Route path={`${url}/return-purchases/register`} component={AddReturnPurchase}/>
                <Route path={`${url}/return-purchases/update/:id`} component={EditReturnPurchase} />
                <Route path={`${url}/return-purchases/view/:id`} component={ViewReturnPurchase} />

                <Route path={`${url}/revisions`} exact component={Revisions}/>
                {/*<Route path={`${url}/revisions/register-excel`} component={AddRevisionExcel}/>
                <Route path={`${url}/revisions/update-excel/:id`} component={EditRevisionExcel} />*/}
                <Route path={`${url}/revisions/register`} component={AddRevision}/>
                <Route path={`${url}/revisions/update/:id`} component={EditRevision} />
                <Route path={`${url}/revisions/view/:id`} component={ViewRevision} />

                <Route path={`${url}/write-offs`} exact component={WriteOffs}/>
                {/*<Route path={`${url}/write-offs/register-excel`} component={AddWriteOffExcel}/>
                <Route path={`${url}/write-offs/update-excel/:id`} component={EditWriteOffExcel} />*/}
                <Route path={`${url}/write-offs/register`} component={AddWriteOff}/>
                <Route path={`${url}/write-offs/update/:id`} component={EditWriteOff} />
                <Route path={`${url}/write-offs/view/:id`} component={ViewWriteOff} />

                <Route path={`${url}/transfers`} exact component={Transfers}/>
                {/*<Route path={`${url}/transfers/register-excel`} component={AddTransferExcel}/>
                <Route path={`${url}/transfers/update-excel/:id`} component={EditTransferExcel} />*/}
                <Route path={`${url}/transfers/register`} component={AddTransfer}/>
                <Route path={`${url}/transfers/update/:id`} component={EditTransfer} />
                <Route path={`${url}/transfers/view/:id`} component={ViewTransfer} />

                <Route path={`${url}/draft-purchases`} exact component={DraftPurchases}/>
                <Route path={`${url}/draft-purchases/view/:id`} component={ViewDraftPurchase}/>

                <Route path={`${url}/draft-refunds`} exact component={DraftRefunds}/>
                <Route path={`${url}/draft-refunds/view/:id`} component={ViewDraftRefund}/>

                <Route path={`${url}/report`} component={ViewReport}/>
                <Route path={`${url}/item-report`} component={OperationItemsReport}/>

                <Route path={`${url}/order-sale/register/:id`} component={CreateTelegramOrderSale}/>
            </Switch>
        </>
    )
}


export default WarehouseOperationLayout;
