import React from 'react';
import CashBoxesDatatable from "../../../../components/hippo/cashbox/cash-boxes/CashBoxesDatatable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const CashBoxes = () => {

    // permissions
    const isCashBoxView = useCheckPermission(PermissionCashBoxView)

    if (!isCashBoxView) return <Error403 />


    return (
        <CashBoxesDatatable/>
    );
};

export default CashBoxes;
