import axios from "axios";

class DocumentGeneratorService {
    constructor() {
        this.baseURL = "/document-generator";
    }

    generateRoamingInvoiceSendHashCode(payload) {
        return axios.post(`${this.baseURL}/roaming/invoice/generate/send-hash-code`, payload);
    }
    generateRoamingConstructionInvoiceSendHashCode(payload) {
        return axios.post(`${this.baseURL}/roaming/construction-invoice/generate/send-hash-code`, payload);
    }
    generateRoamingActSendHashCode(payload) {
        return axios.post(`${this.baseURL}/roaming/act/generate/send-hash-code`, payload)
    }
    generateRoamingContractSendHashCode(payload) {
        return axios.post(`${this.baseURL}/roaming/contract/generate/send-hash-code`, payload)
    }

}

const documentGeneratorService = new DocumentGeneratorService();
export default documentGeneratorService;
