import {Card, Col, Row, Table} from "react-bootstrap";
import SoftBadge from "../../../../common/SoftBadge";
import numeral from "numeral";
import React, {useEffect, useState} from "react";
import {
    ActCancelHashCodeAsync,
    ActCustomerAcceptHashCodeAsync,
    ActCustomerDeclineHashCodeAsync,
    ActFormSendAsync,
} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {selectMeasurements} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import dayjs from "dayjs";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate,
    updateShowInProgressAsync
} from "../../../../../app/store/reducers/main/mainReducer";
import {ActWrapperClass, CustomerDeclined} from "./ActStatus";
import CustomerAccept from "../Button/CustomerAccept";
import CustomerDecline from "../Button/CustomerDecline";
import ActCancel from "../Button/ActCancel";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import IconButton from "../../../../common/IconButton";
import {Link, useHistory} from "react-router-dom";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS,
    ROAMING_ACT_SYNC_FAILED,
    ROAMING_ACT_SYNC_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import WarningAlert from "../../components/WarningAlert";
import SyncAct from "../Button/SyncAct";
import {getOrganizationBillingInfoAsync} from "../../../../../app/store/reducers/billing/billingReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActUpdate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import InProgress from "../../../../common/InProgress";


const Act = ({act, draftAct}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch()
    const measurements = useSelector(selectMeasurements)
    const dateFormat = useSelector(selectDateDayJSFormat)
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const {id} = useParams();
    const t = Translate;
    const actWrapper = new ActWrapperClass(act);
    const isDraft = actWrapper.isDraft();
    const history = useHistory()
    const [eventMessage, setEventMessage] = useState(null);

    const getMeasure = id => measurements.find(x => x.measureId === id) || null

    const total = act?.items.reduce((acc, item) => acc + item.total, 0);
    const ndsValue = act?.items.reduce((acc, item) => acc + item.nds_value, 0);
    const totalWithNds = act?.items.reduce((acc, item) => acc + item.total_with_nds, 0);

    const [showAcceptButton, setShowAcceptButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [allowSync, setAllowSync] = useState(false)
    const showDeclineNotes = act.status === CustomerDeclined
    const showCopyButton = act.executor.inn === (activeOrganization.pinfl || activeOrganization.inn)

    useEffect(() => {
        setShowAcceptButton(actWrapper.canTheBuyerAccept(activeOrganization.pinfl || activeOrganization.inn))
        setShowDeclineButton(actWrapper.canTheBuyerDecline(activeOrganization.pinfl || activeOrganization.inn))
        setShowCancelButton(actWrapper.canTheSellerCancled(activeOrganization.pinfl || activeOrganization.inn))
        setAllowSync(actWrapper.allowSyncAct())

    }, [act, activeOrganization])

    useEffect(() => {
        const onSendActRequestHandler = EventBus.on(ROAMING_ACT_REGISTER_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendActSuccessHandler = EventBus.on(ROAMING_ACT_REGISTER_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(getOrganizationBillingInfoAsync());
            history.push(`../act/${id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendActFailedHandler = EventBus.on(ROAMING_ACT_REGISTER_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.act.alert.failed_event_title'),
                text: error.message
            })
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onSyncActSuccessHandler = EventBus.on(ROAMING_ACT_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.act.alert.success_event_title'),
                text: t(lang, 'roaming.act.alert.act_sync_success')
            });
        })

        const onSyncActFailHandler = EventBus.on(ROAMING_ACT_SYNC_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.act.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_ACT_REGISTER_REQUESTED, onSendActRequestHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_SUCCESS, onSendActSuccessHandler)
            EventBus.remove(ROAMING_ACT_REGISTER_FAILED, onSendActFailedHandler)
            EventBus.remove(ROAMING_ACT_SYNC_SUCCESS, onSyncActSuccessHandler)
            EventBus.remove(ROAMING_ACT_SYNC_FAILED, onSyncActFailHandler)
        }

    }, [])

    const signAct = async () => {
        try {
            dispatch(updateShowInProgressAsync(true));
            await ActFormSendAsync(currentCertificate, lang, JSON.stringify(draftAct));
            dispatch(updateShowInProgressAsync(false));
        } catch (error) {
            dispatch(updateShowInProgressAsync(false));
            console.log(error);
        }
    }


    return (
        <>
            {eventMessage &&
                <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}
            {!eventMessage && act?.roaming_error &&
                <WarningAlert text={act?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}
            <InProgress/>
            <Card className="p-3">
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div>
                        <h3 className="text-600">{t(lang, "edi.common.created.time")}: {act.created_at}</h3>
                    </div>
                    <div>
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <SoftBadge className="text-wrap"
                                   bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                        >
                            <strong className="fs-0">
                                {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl || activeOrganization.inn))}
                            </strong>
                        </SoftBadge>
                    </div>
                </div>
                <Row className="mt-3">
                    <Col className="d-flex gap-2">
                        {showCopyButton &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faClone}
                                as={Link}
                                to={`/roaming/act/register?${!isDraft ? `${`act_id=${id}`}` : `${`draft_act_id=${id}`}`}`}
                                className="d-inline"
                            >
                                {t(lang, "roaming.common.copy")}
                            </IconButton>
                        }
                        {act.status ?
                            <>
                                {allowSync && <SyncAct id={act?.id}/>}
                                {showAcceptButton &&
                                    <CustomerAccept
                                        ActAcceptHandler={() => ActCustomerAcceptHashCodeAsync(currentCertificate, lang, act.id)}
                                    />}
                                {showDeclineButton &&
                                    <CustomerDecline
                                        ActDeclineHandler={(comment) => ActCustomerDeclineHashCodeAsync(currentCertificate, lang, act.id, comment)}
                                    />}
                                {showCancelButton &&
                                    <ActCancel
                                        ActCancelHandler={() => ActCancelHashCodeAsync(currentCertificate, lang, act.id)}
                                    />}
                            </>
                            :
                            <>
                                <IconButton
                                    variant="falcon-warning"
                                    size="sm"
                                    icon={'edit'}
                                    iconClassName="me-1"
                                    type="submit"
                                    as={dispatch(checkPermission(PermissionRoamingActUpdate)) ? Link : 'div'}
                                    onClick={() => !dispatch(checkPermission(PermissionRoamingActUpdate)) && dispatch(toastPermission())}
                                    to={`/roaming/act/register?draft_act_id=${id}&is_edit=true`}
                                >{t(lang, "edi.common.button.edit")}</IconButton>
                                <SelectCertificateWrapperComponent permission={PermissionRoamingActUpdate}
                                                                   submitButton={
                                                                       <IconButton onClick={signAct} id="sendButton"
                                                                                   size="sm"
                                                                                   transform="shrink-3"
                                                                                   variant="falcon-primary"
                                                                                   icon={faSignature}>
                                                                           {t(lang, 'roaming.common.send')}
                                                                       </IconButton>
                                                                   }
                                                                   openDialogButtonText={t(lang, 'roaming.act_form_send')}
                                />
                            </>
                        }
                    </Col>
                </Row>
            </Card>

            <Card className="my-3 p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, "roaming.act_form_seller_title")}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {act.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {act.executor.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, "roaming.act_form_buyer_name")}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {act.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {act.customer.inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card>
                <Card.Header>
                    <Row className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5} className="d-flex justify-content-center justify-content-lg-start">
                            <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{act.id}</h5>
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            {showDeclineNotes &&
                                <div className="alert-danger p-1 text-center">
                                    <span className="fw-bold me-2">{t(lang, "roaming.common.comment")}:</span>
                                    <strong>{act.notes}</strong>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <h3 className="text-center">{t(lang, "roaming.act_form.title")}</h3>
                        <Col className="d-flex flex-column flex-sm-row align-items-center justify-content-around">
                            <div>
                                <p className="mb-1">
                                    <span
                                        className="fs-0 fw-bold mb-1">{t(lang, "roaming.act_form.act_number")}: </span>
                                    <span>{act?.info?.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold mb-1">{t(lang, "roaming.act_form.act_date")}: </span>
                                    <span>{dayjs(act?.info?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                                </p>
                            </div>
                            <div>
                                <p className="mb-1">
                                    <span
                                        className="fs-0 fw-bold">{t(lang, "roaming.act_form_contract_number")}: </span>
                                    <span>{act?.contract?.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "roaming.act_form_contract_date")}: </span>
                                    <span>{dayjs(act?.contract?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={6} className="my-2 fs--1 my-lg-0">
                            <div className="row">
                                <span
                                    className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, "roaming.act_form_seller_title")}</span>
                                <span className="col-8 mb-1 ps-0">{act?.executor?.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">Инн | пинфл</span>
                                <span className="col-8 mb-1 ps-0">{act?.executor?.inn}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.act_form_seller_branch")}</span>
                                <span
                                    className="col-8 mb-1 ps-0">{act?.executor?.branch?.branch_name} {act?.executor?.branch?.branch_code}
                                    <hr className={act?.executor?.branch?.branch_name || act?.executor?.branch?.branch_code ? "m-0" : "mb-0"}
                                        style={{height: "2px"}}/></span>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="fs--1">
                            <div className="row">
                                <span
                                    className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, "roaming.act_form_buyer_name")}</span>
                                <span className="col-8 mb-1 ps-0">{act?.customer?.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">Инн | пинфл</span>
                                <span className="col-8 mb-1 ps-0">{act?.customer?.inn}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.act_form_seller_branch")}:</span>
                                <span
                                    className="col-8 mb-1 ps-0">{act?.customer?.branch?.branch_name} {act?.customer?.branch?.branch_code}
                                    <hr className={act?.customer?.branch?.branch_name || act?.customer?.branch?.branch_code ? "m-0" : "mb-0"}
                                        style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col className="d-flex flex-column">
                            <span
                                className="pe-0 my-2 fw-bold text-center">{t(lang, "roaming.act_form_act_comment")}:</span>
                            <span className="text-center">{act?.info?.text}</span>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th className="align-middle" rowSpan="2">№</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.name")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.catalog")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.barcode")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.measurement")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.quantity")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.price")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.total")}</th>
                            <th className="align-middle text-center"
                                colSpan="2">{t(lang, "roaming.contract.items_datatable.vat")}</th>
                            <th className="align-middle"
                                rowSpan="2">{t(lang, "roaming.contract.items_datatable.total_with_vat")}</th>
                        </tr>
                        <tr>
                            <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.rate")}</th>
                            <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.sum")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {act.items.map((item, index) => {
                            return (
                                <tr key={item.ordering_number}>
                                    <td className="align-middle">{index + 1}</td>
                                    <td className="align-middle">{item?.catalog?.code}</td>
                                    <td className="align-middle">{item?.catalog?.name}</td>
                                    <td className="align-middle">{item?.name}</td>
                                    <td className="align-middle">{item.measure_id ? getMeasure(item.measure_id)?.name : item.packageName}</td>
                                    <td className="align-middle text-nowra">{item?.quantity}</td>
                                    <td className="align-middle text-nowra">{numeral.formats[numberFormat].format(+item?.price)}</td>
                                    <td className="align-middle text-nowra">{numeral.formats[numberFormat].format(item?.total)}</td>
                                    <td className="align-middle text-nowrap">{item?.nds_rate}</td>
                                    <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.nds_value || 0)}</td>
                                    <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total_with_nds || 0)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(total)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(ndsValue)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(totalWithNds)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    )
}

export default Act;
