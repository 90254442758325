import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {useHistory} from "react-router-dom";

const Error403 = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const history = useHistory();

    return (
        <Card className="text-center">
            <Card.Body className="p-5">
                <div className="display-1 text-300 fs-error">403</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                    {t(lang, 'errors.common.403.title')}
                </p>
                <div className={'d-flex justify-content-center'}>
                    <Button variant={'link'} onClick={() => history.goBack()} className={'fw-bold'}>
                        <FontAwesomeIcon icon={'arrow-left'} className={'me-1'} />
                        {t(lang, 'main.common.back')}
                    </Button>
                </div>
                <hr />
                <p>
                    {t(lang, 'errors.common.403.description')}
                </p>
                <div className="d-flex flex-column flex-sm-row justify-content-center">
                    <div className="d-flex flex-column mb-2 mb-sm-0 me-sm-2">
                        <h5>{t(lang, 'edi.common.title')}</h5>
                        <div>
                            <FontAwesomeIcon icon={faPhone} />
                            <a className="mx-1" href="tel:+998712006363"> +998 71 200 63 63</a>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faTelegram} />
                            <a className="text-decoration-none ms-2" href="https://t.me/HippoEDI" target="_blank">{t(lang, 'auth.layout.link.telegram_bot.title')}</a>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <h5>{t(lang, 'warehouse.common.title')}</h5>
                        <div>
                            <FontAwesomeIcon icon={faPhone} />
                            <a className="mx-1" href="tel:+998332006363"> +998 33 200 63 63</a>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faTelegram} />
                            <a className="text-decoration-none ms-2" href="https://t.me/hippo_uz" target="_blank">{t(lang, 'auth.layout.link.telegram_bot.title')}</a>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Error403;
