export const ROAMING_ACT_REGISTER_REQUESTED = "ROAMING_ACT_REGISTER_REQUESTED";
export const ROAMING_ACT_REGISTER_SUCCESS = "ROAMING_ACT_REGISTER_SUCCESS";
export const ROAMING_ACT_REGISTER_FAILED = "ROAMING_ACT_REGISTER_FAILED";
export const ROAMING_ACT_ACCEPT_REQUESTED = "ROAMING_ACT_ACCEPT_REQUESTED";
export const ROAMING_ACT_ACCEPT_SUCCESS = "ROAMING_ACT_ACCEPT_SUCCESS";
export const ROAMING_ACT_ACCEPT_FAILED = "ROAMING_ACT_ACCEPT_FAILED";
export const ROAMING_ACT_DECLINE_REQUESTED = "ROAMING_ACT_DECLINE_REQUESTED";
export const ROAMING_ACT_DECLINE_SUCCESS = "ROAMING_ACT_DECLINE_SUCCESS";
export const ROAMING_ACT_DECLINE_FAILED = "ROAMING_ACT_DECLINE_FAILED";
export const ROAMING_ACT_CANCEL_REQUESTED = "ROAMING_ACT_CANCEL_REQUESTED";
export const ROAMING_ACT_CANCEL_SUCCESS = "ROAMING_ACT_CANCEL_SUCCESS";
export const ROAMING_ACT_CANCEL_FAILED = "ROAMING_ACT_CANCEL_FAILED";
export const ROAMING_ACT_SYNC_REQUESTED = "ROAMING_ACT_SYNC_REQUESTED";
export const ROAMING_ACT_SYNC_SUCCESS = "ROAMING_ACT_SYNC_SUCCESS";
export const ROAMING_ACT_SYNC_FAILED = "ROAMING_ACT_SYNC_FAILED";
export const ROAMING_ACT_DRAFT_REQUESTED = "ROAMING_ACT_DRAFT_REQUESTED";
export const ROAMING_ACT_DRAFT_SUCCESS = "ROAMING_ACT_DRAFT_SUCCESS";
export const ROAMING_ACT_DRAFT_FAILED = "ROAMING_ACT_DRAFT_FAILED";
export const ROAMING_ACT_DRAFT_DELETE_REQUESTED = "ROAMING_ACT_DRAFT_DELETE_REQUESTED";
export const ROAMING_ACT_DRAFT_DELETE_SUCCESS = "ROAMING_ACT_DRAFT_DELETE_SUCCESS";
export const ROAMING_ACT_DRAFT_DELETE_FAILED = "ROAMING_ACT_DRAFT_DELETE_FAILED";
export const ROAMING_ACT_DRAFT_BULK_DELETE_REQUESTED = "ROAMING_ACT_DRAFT_BULK_DELETE_REQUESTED";
export const ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS = "ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS";
export const ROAMING_ACT_DRAFT_BULK_SEND_REQUESTED = "ROAMING_ACT_DRAFT_BULK_SEND_REQUESTED";
export const ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS = "ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS";
export const ROAMING_ACT_BULK_CANCEL_REQUESTED = "ROAMING_ACT_BULK_CANCEL_REQUESTED";
export const ROAMING_ACT_BULK_CANCEL_SUCCESS = "ROAMING_ACT_BULK_CANCEL_SUCCESS";
export const ROAMING_ACT_BULK_ACCEPT_REQUESTED = "ROAMING_ACT_BULK_ACCEPT_REQUESTED";
export const ROAMING_ACT_BULK_ACCEPT_SUCCESS = "ROAMING_ACT_BULK_ACCEPT_SUCCESS";
export const ROAMING_ACT_BULK_DECLINE_REQUESTED = "ROAMING_ACT_BULK_DECLINE_REQUESTED";
export const ROAMING_ACT_BULK_DECLINE_SUCCESS = "ROAMING_ACT_BULK_DECLINE_SUCCESS";
