import React from 'react';
import AddBulkItemsForm from "../../../components/hippo/item/forms/bulk-item/AddBulkItemsForm";
import useCheckPermission from "../../../hooks/useCheckPermission";
import {PermissionItemCreate} from "../../../enum/Permission/WarehouseItemPermission";
import Error403 from "../../../components/errors/Error403";

const BulkAddItems = () => {

    // permissions
    const isItemCreate = useCheckPermission(PermissionItemCreate)

    if (!isItemCreate) return <Error403 />

    return <AddBulkItemsForm />
};

export default BulkAddItems;