import classNames from "classnames";
import {Form} from "react-bootstrap";
import Cleave from 'cleave.js/react';
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import ActionButton from "../../../../../common/ActionButton";
import SelectMeasurement from "../../../SelectMeasurement";
import SelectCompanyCatalog from "../../../../IKPU/SelectCompanyCatalog";
import {useFormContext, Controller, useFieldArray, useWatch} from "react-hook-form";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import ReactSelect from "react-select";

const ROUND_SCALE_FIVE = 5;


const ItemRow = ({index, arrayFieldName, remove, allowDelete}) => {
    const {register, formState: {errors}, watch, control, setValue} = useFormContext();
    const {update} = useFieldArray({name: arrayFieldName});
    const lang = useSelector(selectLang);
    const t = Translate;

    const rootName = `${arrayFieldName}.${index}`;
    const item = useWatch({name: rootName});
    const itemQuantity = watch(`${rootName}.quantity`);
    const itemPrice = watch(`${rootName}.price`);
    const { measureId, packageName, packageCode } = item
    const catalog = useWatch({name: `${rootName}.catalogPackage`, exact: true})
    const packages = useWatch({name: `${rootName}.packages`, exact: true})

    let itemErrors = {};
    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index])
        itemErrors = errors[arrayFieldName][index];

    useEffect(() => {
        recalculate();
    }, [itemQuantity, itemPrice]);

    const recalculate = () => {
        item.quantity = itemQuantity;
        item.price = itemPrice;
        item.total = +itemQuantity * +itemPrice;

        update(index, item);
    }

    const updateItemCatalog = (catalog) => {
        if (catalog) {
            setValue(`${rootName}.catalogPackage`, catalog)
            item.catalog_code = catalog.class_code;
            item.catalog_name = catalog.name;
            update(index, item);
        } else {
            setValue(`${rootName}.catalogPackage`, null)
            item.catalog_code = "";
            item.catalog_name = "";
            update(index, item);
        }
    }

    useEffect(() => {
        if (catalog && packages) {
            if (measureId !== "")
                setValue(`${rootName}.measureId`, "")

            const name = lang === "ru" ? packages.name_ru : packages.name_uz
            if (packageName !== name)
                setValue(`${rootName}.packageName`, name)
            if (packageCode !== packages.code)
                setValue(`${rootName}.packageCode`, packages.code);
        } else {
            if (packageName !== null)
                setValue(`${rootName}.packageName`, null)
            if (packageCode !== null)
                setValue(`${rootName}.packageCode`, null);
        }
    },[catalog, packages])

    const deleteField = () => {
        remove(index);
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td className="p-1">
                <Form.Control
                    isInvalid={itemErrors?.product}
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}
                    {...register(`${rootName}.product`, {required: t(lang, "edi.common.forms.validations.is_required")})}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.product?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    control={control}
                    name={`${rootName}.catalog_code`}
                    render={() => (
                        <SelectCompanyCatalog
                            variant="outline-primary"
                            onChange={updateItemCatalog}
                            defaultCatalogCode={item.catalog_code}
                            textButton={item.catalog_code || t(lang, "edi.common.select_button")}
                        />
                    )}
                />
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.price`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{numeral: true, delimiter: ''}}
                            className={classNames('form-control', {'is-invalid': itemErrors?.price})}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>

            {catalog?.use_package ?
                <td className="p-1">
                    <Controller name={`${rootName}.packages`}
                                rules={{
                                    required: t(lang, "roaming.validations.is_required")
                                }}
                                render={({field}) => (
                                    <ReactSelect options={catalog?.package_names}
                                                 placeholder={t(lang, "roaming.common.select.placeholder")}
                                                 getOptionLabel={option => lang === "ru" ? option.name_ru : option.name_uz}
                                                 getOptionValue={option => option.code}
                                                 defaultValue={catalog?.package_names.find(p => p.code === field.value?.code)}
                                                 menuPortalTarget={document.body}
                                                 hideSelectedOptions
                                                 className={classNames({
                                                     'is-invalid': itemErrors?.packages
                                                 })}
                                                 isClearable
                                                 onChange={field.onChange}
                                    />
                                )}
                    />

                    <Form.Control.Feedback
                        type="invalid">{itemErrors?.packages?.message}</Form.Control.Feedback>
                </td>
                :
                <td className="p-1">
                    <Controller
                        name={`${rootName}.measureId`}
                        rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                        render={({field}) => (
                            <SelectMeasurement
                                isClearable
                                classNamePrefix="react-select"
                                defaultMeasureId={field.value}
                                menuPortalTarget={document.body}
                                placeholder={t(lang, "edi.common.select_button")}
                                onChange={measurement => field.onChange(measurement?.measureId)}
                                className={classNames({"is-invalid": itemErrors?.measureId})}
                                wrapperClassName={classNames({'is-invalid': errors?.measureId})}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.measureId?.message}
                    </Form.Control.Feedback>
                </td>
            }
            <td className="p-1">
                <Controller
                    name={`${rootName}.quantity`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{numeral: true, delimiter: '', numeralDecimalScale: ROUND_SCALE_FIVE}}
                            className={classNames('form-control', {'is-invalid': itemErrors.quantity})}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    readOnly={true}
                    value={item.total}
                    {...register(`${rootName}.total`)}
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}
                />
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.road_haulage_cost`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{numeral: true, delimiter: ''}}
                            className={classNames('form-control', {'is-invalid': itemErrors?.road_haulage_cost})}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.road_haulage_cost')}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.road_haulage_cost?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control
                    type="text"
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.follow_documents')}
                    {...register(`${rootName}.follow_documents`)}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    type="text"
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_define_method')}
                    {...register(`${rootName}.weight_define_method`)}
                />
            </td>
            <td className="p-1">
                <Form.Control
                    type="text"
                    placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.load_class')}
                    {...register(`${rootName}.load_class`)}
                />
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.weight_brutto`}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            className="form-control"
                            options={{numeral: true, delimiter: ''}}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_brutto')}
                        />
                    )}
                />
            </td>
            <td className="p-1">
                <Controller
                    name={`${rootName}.weight_netto`}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            className="form-control"
                            options={{numeral: true, delimiter: ''}}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_netto')}
                        />
                    )}
                />
            </td>
            <td className="text-end p-1 align-middle">
                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1 text-danger" onClick={deleteField} disabled={!allowDelete}/>
            </td>
        </tr>
    );
};

export default ItemRow;