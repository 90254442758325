import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {toast} from "react-toastify";
import EventBus from '../../../../app/eventbus/EventBus';
import {selectActiveOrganization} from '../../../../app/store/reducers/auth/authReducer';
import {selectLang, Translate} from '../../../../app/store/reducers/main/mainReducer';
import {
    editContractAsync,
    loadContractAsync,
} from '../../../../app/store/reducers/contract/contractReducer';
import {
    EDIT_CONTRACT_FAILED,
    EDIT_CONTRACT_SUCCEED,
} from "../../../../app/eventbus/contractEvents";
import ContractFormPage from "./ContractFormPage";
import {useHistory} from "react-router-dom";

const EditContractFormPage = () => {
    const [contract, setContract] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const {id} = useParams();
    const lang = useSelector(selectLang)
    const t = Translate
    const [loading, setLoading] = useState(false);
    const routerHistory = useHistory();

    const load = () => {
        loadContractAsync(id).then(contentContract => {
            let contract = {
                contractId: contentContract.id,
                owner: {
                    tin: contentContract.customer.inn,
                    name: contentContract.customer.name,
                    address: contentContract.customer.address,
                    workPhone: contentContract.customer.work_phone,
                    mobile: contentContract.customer.mobile,
                    fax: contentContract.customer.fax,
                    oked: contentContract.customer.oked,
                    account: contentContract.customer.account,
                    bankId: contentContract.customer.mfo,
                    fizTin: contentContract.customer.director_inn,
                    fio: contentContract.customer.director_name,
                    branchCode: contentContract.customer.branch_code,
                    branchName: contentContract.customer.branch_name,
                    role: contentContract.customer.role
                },
                client: {
                    tin: contentContract.executor.inn,
                    name: contentContract.executor.name,
                    address: contentContract.executor.address,
                    workPhone: contentContract.executor.work_phone,
                    mobile: contentContract.executor.mobile,
                    fax: contentContract.executor.fax,
                    oked: contentContract.executor.oked,
                    account: contentContract.executor.account,
                    bankId: contentContract.executor.mfo,
                    fizTin: contentContract.executor.director_inn,
                    fio: contentContract.executor.director_name,
                    branchCode: contentContract.executor.branch_code,
                    branchName: contentContract.executor.branch_name,
                    role: contentContract.executor.role
                },
                contractDoc: {
                    contractName: contentContract.info.name,
                    contractNo: contentContract.info.number,
                    contractDate: contentContract.info.date ? dayjs(contentContract.info.date).toDate() : null,
                    contractExpireDate: contentContract.info.expire_date ? dayjs(contentContract.info.expire_date).toDate() : null,
                    contractPlace: contentContract.info.place
                },
                products: contentContract.items.length > 0 ? contentContract.items.map((product) => ({
                    name: product.name,
                    barCode: product.barcode,
                    measureId: product.measurement_id,
                    count: +product.quantity,
                    summa: +product.price,
                    deliverySum: +product.total,
                    vatRate: +product.nds_rate,
                    vatSum: +product.nds_value,
                    deliverySumWithVat: +product.total_with_nds,
                    catalogCode: product.catalog_code,
                    catalogName: product.catalog_name,
                    withoutVat: product.without_vat
                })) : [{
                    catalogCode: null,
                    catalogName: null,
                    barCode: "",
                    name: "",
                    measureId: null,
                    count: null,
                    summa: null,
                    deliverySum: null,
                    vatRate: 0,
                    vatSum: null,
                    deliverySumWithVat: null,
                    withoutVat: false
                }],
                parts: contentContract.parts.length > 0 ? contentContract.parts.map((part) => ({
                    title: part.title,
                    body: part.body
                })) : [{
                    title: "",
                    body: ""
                }]
            }
            setContract(contract)
        }).catch(error => {
            console.log(error);
        })
    }

    const onSubmit = (data) => {
        setLoading(true)

        let notEmptyProducts = data.products.filter(product => {
            return product.name
        })
        let notEmptyParts = data.parts.filter(part => {
            return part.title
        })
        editContractAsync(contract.contractId, {
            info: {
                name: data.contractDoc.contractName,
                number: data.contractDoc.contractNo,
                date: data.contractDoc.contractDate ? dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") : null,
                expire_date: data.contractDoc.contractExpireDate ? dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") : null,
                place: data.contractDoc.contractPlace
            },
            executor: {
                inn: data.owner.tin,
                name: data.owner.name,
                address: data.owner.address,
                work_phone: data.owner.workPhone,
                mobile: data.owner.mobile,
                fax: data.owner.fax,
                oked: data.owner.oked,
                account: data.owner.account,
                mfo: data.owner.bankId,
                director_inn: data.owner.fizTin,
                director_name: data.owner.fio,
                branch_code: data.owner.branchCode,
                branch_name: data.owner.branchName,
                role: data.owner.role
            },
            customer: {
                inn: data.client.tin,
                name: data.client.name,
                address: data.client.address,
                work_phone: data.client.workPhone,
                mobile: data.client.mobile,
                fax: data.client.fax,
                oked: data.client.oked,
                account: data.client.account,
                mfo: data.client.bankId,
                director_inn: data.client.fizTin,
                director_name: data.client.fio,
                branch_code: data.client.branchCode,
                branch_name: data.client.branchName,
                role: data.client.role
            },
            items: notEmptyProducts.map((product, index) => ({
                name: product.name,
                barcode: product.barCode,
                measurement_id: product.measureId,
                quantity: +product.count,
                price: +product.summa,
                total: +product.deliverySum,
                nds_rate: +product.vatRate,
                nds_value: +product.vatSum,
                total_with_nds: +product.deliverySumWithVat,
                catalog_code: product.catalogCode,
                catalog_name: product.catalogName,
                without_vat: product.withoutVat
            })),
            parts: notEmptyParts.map((part, index) => ({
                title: part.title,
                body: part.body
            })),
            notes: data.notes
        }).then((res) => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    useEffect(() => {
        load()
    }, [id, activeOrganization.inn])

    useEffect(() => {
        const contractRegisterSuccess = EventBus.on(EDIT_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            routerHistory.push("/crm/contracts")
        });
        const contractRegisterError = EventBus.on(EDIT_CONTRACT_FAILED, (data) => {
            toast.error(t(lang, data.message))
        });
        return () => {
            EventBus.remove(EDIT_CONTRACT_SUCCEED, contractRegisterSuccess)
            EventBus.remove(EDIT_CONTRACT_FAILED, contractRegisterError)
        }
    }, [])

    return (
        <ContractFormPage contract={contract} onSubmit={onSubmit} loading={loading}/>
    );
};

export default EditContractFormPage;