import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {loadActDraftAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {useHistory} from "react-router";
import dayjs from "dayjs";
import Act from "./Act";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActView} from "../../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../spinner/SpinnerComponent";


const DraftActView = () => {
    const [draftAct, setDraftAct] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const {params: {id}} = useRouteMatch();
    const history = useHistory()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)


    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActView))) {
            if (history.length > 1) {
                history.push('/roaming/acts/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadDraftAct()
    }, [id, activeOrganization]);


    const loadDraftAct = async () => {
        try {
            const {data} = await loadActDraftAsync(id);
            setDraftAct(data)
        } catch (errors) {
            history.push("../acts/draft")
        }
    }

    if (!draftAct) return <SpinnerComponent/>;

    const parsedDraftAct = JSON.parse(draftAct.content);
    const act = {
        id: parsedDraftAct?.ActId,
        info: {
            number: parsedDraftAct?.ActDoc?.ActNo,
            date: dayjs(parsedDraftAct?.ActDoc?.ActDate).format('YYYY-MM-DD'),
            text: parsedDraftAct?.ActDoc?.ActText,
        },
        contract: {
            number: parsedDraftAct?.ContractDoc?.ContractNo,
            date: dayjs(parsedDraftAct?.ContractDoc?.ContractDate).format('YYYY-MM-DD'),
        },
        executor: {
            inn: parsedDraftAct?.SellerTin,
            name: parsedDraftAct?.SellerName,
            branch: {
                branch_code: parsedDraftAct?.SellerBranchCode,
                branch_name: parsedDraftAct?.SellerBranchName,
            }
        },
        customer: {
            inn: parsedDraftAct?.BuyerTin,
            name: parsedDraftAct?.BuyerName,
            branch: {
                branch_code: parsedDraftAct?.BuyerBranchCode,
                branch_name: parsedDraftAct?.BuyerBranchName,
            }
        },
        items: parsedDraftAct?.ProductList?.Products?.map(item => {
            return {
                catalog: {
                    code: item?.CatalogCode,
                    name: item?.CatalogName,
                },
                measure_id: item.MeasureId,
                packageCode: item.PackageCode || null,
                packageName: item.PackageName || null,
                name: item?.Name,
                ordering_number: item?.OrdNo,
                price: item?.Summa,
                quantity: item?.Count,
                total: item?.TotalSum || 0,
                nds_value: item?.VatSum || 0,
                nds_rate: item?.VatRate || 0,
                total_with_nds: item?.DeliverySum || 0
            }
        }),
        product_id: parsedDraftAct?.ActProductId,
        roaming_error: draftAct.roaming_error,
    };

    return (
        <Act act={act}
             draftAct={parsedDraftAct}
        />
    )
}

export default DraftActView;
