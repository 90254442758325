import React, {useEffect} from 'react';
import CashBoxOperationDataTable from "../../../../components/hippo/cashbox/operation/CashBoxOperationDataTable";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    getAllOperationAsync,
    getAllOperationCount,
    selectCashBoxOperationFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {useLocation} from "react-router";
import dayjs from "dayjs";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxOperationView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const CashBoxOperation = () => {
    const {state} = useLocation()
    const dispatch = useDispatch()
    const organization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectCashBoxOperationFilter)

    // permissions

    const isCashBoxOperationView = useCheckPermission(PermissionCashBoxOperationView);


    useEffect(() => {
        if (!isCashBoxOperationView) return
        if (state) {
            const filter = {
                cashbox_id: state?.cash_box?.id || state?.to_cash_box_id || state?.from_cash_box_id || state?.id || null,
                date_start: dayjs(state?.created_at).format('YYYY-MM-DD') || null,
                date_end: state && !state?.name && dayjs(state?.created_at).format('YYYY-MM-DD') || null
            }
            dispatch(getAllOperationAsync({...filters, ...filter}))
            dispatch(getAllOperationCount({...filters, ...filter}))
        } else {
            dispatch(getAllOperationAsync({...filters}))
            dispatch(getAllOperationCount({...filters}))
        }

    }, [state,organization,filters])

    if (!isCashBoxOperationView) return <Error403 />

    return (
        <CashBoxOperationDataTable />
    );
};

export default CashBoxOperation;
