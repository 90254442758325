import React, {useEffect} from "react"
import ContractorsDataTable from "../../../../components/hippo/crm/contractor/ContractorDataTable"
import {useDispatch} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {PermissionCRMContractorView,} from "../../../../enum/Permission/CrmPermission";
import {loadAllContractorAsync} from "../../../../app/store/reducers/contractor/contractorReducer";
import {
    BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED,
    UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED
} from "../../../../app/eventbus/contractor/contractorEvents";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";

const Contractors = () => {
    const dispatch = useDispatch();
    // permissions
    const isContractorView = useCheckPermission(PermissionCRMContractorView)


    useEffect(() => {
        const onEmployeeSuccessfullyBindedToContractor = EventBus.on(BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED, () => {
            loadContractors();
        });

        const onEmployeeSuccessfullyUnbindedFromContractor = EventBus.on(UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED, () => {
            loadContractors();
        });

        return () => {
            EventBus.remove(BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED, onEmployeeSuccessfullyBindedToContractor);
            EventBus.remove(UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED, onEmployeeSuccessfullyUnbindedFromContractor);
        };
    }, []);

    const loadContractors = () => {
        if (isContractorView) {
            dispatch(loadAllContractorAsync());
        }
    };

    if (!isContractorView) return <Error403 />;

    return (<ContractorsDataTable/>)
}

export default Contractors
