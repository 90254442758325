import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import itemWrapper, {Piece, WithoutVat} from "../../item/enum/itemWrapper";
import React, {useEffect, useState} from "react";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_ITEM_FAILED, ADD_ITEM_REQUESTED, ADD_ITEM_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {addItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import {toast} from "react-toastify";
import {Form} from "react-bootstrap";
import ItemForm from "../../item/forms/ItemForm";
import {selectOrganization} from "../../../../app/store/reducers/draft-item/draftItemReducer";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import dayjs from "dayjs";
import {revisionRegisterAsync} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";

const WarehouseOperationAddItemForm = ({setShow, defaultName, onItemCreated}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const organization = useSelector(selectOrganization);
    const warehouses = useSelector(selectWarehouses);
    const form = useForm({
        defaultValues: {
            name: defaultName,
            description: '',
            isStateControlled: false,
            sku: '',
            code: '',
            barcodes: [
                {barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))}
            ],
            itemWarehouseStates: [],
            warehouseId: null,
            state: '',
            measurement: Piece,
            categoryId: null,
            descriptionAttributes: [],
            packageMeasurements: [],
            tax: {
                measurement: null,
                rate: WithoutVat,
                catalog: null,
                package: null,
                benefit: null
            },
            images: [],
            commonPrice: {
                price: '',
                currencyId: null,
            },
            bulkPrice: {
                price: '',
                currencyId: null,
            },
            purchasePrice: {
                price: '',
                currencyId: null,
            },
        },
    });

    useEffect(() => {
        if (warehouses.length)
            form.setValue('warehouseId', warehouses[0].id)
            form.setValue('itemWarehouseStates', warehouses.map(warehouse => {
            return {
                id: warehouse.id,
                name: warehouse.name,
                state: '',
                alert_on: null
            }
        }))
    }, [warehouses])

    useEffect(() => {
        form.setValue('tax.rate', organization ? organization.default_tax_rate : WithoutVat);
    }, [organization])

    const [loading, setLoading] = useState(false);
    const onSubmit = async (formData) => {
        try {
            setLoading(true);
            const itemJson = {
                name: formData.name,
                description: formData.description,
                is_state_controlled: formData.isStateControlled,
                sku: formData.sku || null,
                code: formData.code || null,
                barcodes: formData.barcodes.map(({barcode}) => barcode),
                measurement: formData.measurement,
                category_id: formData.categoryId,
                description_attributes: formData.descriptionAttributes.map((attr) => ({
                    key: attr.key,
                    value: attr.value
                })),
                package_measurements: formData.packageMeasurements.map((pck) => ({
                    name: pck.name,
                    quantity: +pck.quantity
                })),
                tax: {
                    tax_rate: formData.tax.rate === '' ? null : +formData.tax.rate,
                    catalog: formData.tax.catalog ? {
                        code: formData.tax.catalog.class_code,
                        name: formData.tax.catalog.name,
                        package: formData.tax.package ? {
                            code: formData.tax.package.code.toString(),
                            name: formData.tax.package.name_ru,
                        } : null,
                    } : null,
                    benefit: formData.tax.benefit ? {
                        id: +formData.tax.benefit.id,
                        name: formData.tax.benefit.name,
                        type: +formData.tax.benefit.type,
                    } : null,
                    measurement: formData.tax.measurement ? formData.tax.measurement.measureId : null,
                },
                images: formData.images.map((image) => ({
                    id: null,
                    name: image.name,
                    content: image.content.split(",")[1]
                })),
                common_price: {
                    currency_id: +formData.commonPrice.currencyId,
                    amount: +formData.commonPrice.price,
                },
                bulk_price: {
                    currency_id: +formData.bulkPrice.currencyId,
                    amount: +formData.bulkPrice.price
                },
                purchase_price: {
                    currency_id: +formData.purchasePrice.currencyId,
                    amount: +formData.purchasePrice.price
                },
            };

            EventBus.dispatch(ADD_ITEM_REQUESTED, itemJson);
            const createdItem = await addItemAsync(itemJson);

            if (formData.warehouseId && formData.state) {
                const revisionJson = {
                    approve: true,
                    date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    items: [
                        {
                            item_id: createdItem.id,
                            warehouse_id: formData.warehouseId,
                            quantity: +formData.state
                        }
                    ],
                };
                await revisionRegisterAsync(revisionJson);
            }

            onItemCreated(createdItem);
            setLoading(false);
            EventBus.dispatch(ADD_ITEM_SUCCESS, {itemId: createdItem.id});
            toast.success(t(lang, 'items.common.toast.success'))
            setShow(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(t(lang, 'items.common.toast.error'));

            // existing name
            if (error?.response?.data?.name_exists) {
                form.setError('name', {
                    type: 'server',
                    message: t(lang, "items.common.validations.name_exists", {name: formData.name})
                });
            }

            // existing code
            if (error?.response?.data?.code_exists) {
                form.setError('code', {
                    type: 'server',
                    message: t(lang, "items.common.validations.code_exists", {code: formData.code})
                });
            }

            // existing barcodes
            if (error?.response?.data?.existing_barcodes) {
                for (const barcode of error.existing_barcodes) {
                    const index = formData.barcodes.findIndex(c => c.code === barcode)
                    if (index >= 0) {
                        form.setError(`barcodes.${index}.barcode`, {
                            type: 'server',
                            message: t(lang, "items.common.validations.existing_barcodes", {barcode: barcode})
                        });
                    }
                }
            }

            EventBus.dispatch(ADD_ITEM_FAILED, error);
        }
    };

    return (
        <FormProvider {...form}>
            <Form>
                <ItemForm isEditing={false}
                          onSubmit={form.handleSubmit(onSubmit)}
                          loading={loading}
                />
            </Form>
        </FormProvider>
    );
};

export default WarehouseOperationAddItemForm;
