import React, {useState} from 'react';
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import DatePicker from "react-datepicker";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";

const ActDataTableFilter = ({loading, filters, onFilterChange, onDebouncedFilterChange, viewer}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const [filter, setFilter] = useState({
        registryId: filters.registryId,
        status: filters.status,
        number: filters.number,
        dateStart: filters.dateStart,
        dateEnd: filters.dateEnd,
        createdAtStart: filters.createdAtStart,
        createdAtEnd: filters.createdAtEnd,
        contractNumber: filters.contractNumber,
        contractDate: filters.contractDate,
        contractor: filters.contractor
    });

    const onSubmit = (filter) => {
        onFilterChange(filter);
    };

    return (
        <Row className="g-2">
            <Col xs={12}>
                <h4 className="fs-1 mb-0 text-nowrap">{t(lang, "roaming.invoices.table_header.filter")}</h4>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "roaming.act_form.act_number")}</Form.Label>
                    <Form.Control name="number"
                                  placeholder={t(lang, "roaming.act_form.act_number")}
                                  value={filter.number}
                                  onChange={(e) => {
                                      setFilter({...filter, number: e.target.value});
                                  }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "partners.common.sales.date_start")}</Form.Label>
                    <DatePicker className='form-control'
                                isClearable={true}
                                placeholderText={t(lang, "partners.common.sales.date_start")}
                                dateFormat={dateFormat}
                                selected={filter.dateStart}
                                defaultValue={filter.dateStart}
                                onChange={(value) => {
                                    setFilter({...filter, dateStart: value});
                                }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "partners.common.sales.date_end")}</Form.Label>
                    <DatePicker className='form-control'
                                isClearable={true}
                                placeholderText={t(lang, "partners.common.sales.date_end")}
                                dateFormat={dateFormat}
                                selected={filter.dateEnd}
                                defaultValue={filter.dateEnd}
                                onChange={(value) => {
                                    setFilter({...filter, dateEnd: value});
                                }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "roaming.invoices.table_header.created_at_start")}</Form.Label>
                    <DatePicker className='form-control'
                                isClearable={true}
                                placeholderText={t(lang, "roaming.invoices.table_header.created_at_start")}
                                dateFormat={dateFormat}
                                selected={filter.createdAtStart}
                                defaultValue={filter.createdAtStart}
                                onChange={(value) => {
                                    setFilter({...filter, createdAtStart: value});
                                }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "roaming.invoices.table_header.created_at_end")}</Form.Label>
                    <DatePicker className='form-control'
                                isClearable={true}
                                placeholderText={t(lang, "roaming.invoices.table_header.created_at_end")}
                                dateFormat={dateFormat}
                                selected={filter.createdAtEnd}
                                defaultValue={filter.createdAtEnd}
                                onChange={(value) => {
                                    setFilter({...filter, createdAtEnd: value});
                                }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "edi.common.contract.number")}</Form.Label>
                    <Form.Control name="contractNumber"
                                  placeholder={t(lang, "edi.common.contract.number")}
                                  value={filter.contractNumber}
                                  onChange={(e) => {
                                      setFilter({...filter, contractNumber: e.target.value})
                                  }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "edi.common.contract.date")}</Form.Label>
                    <DatePicker className='form-control'
                                isClearable={true}
                                placeholderText={t(lang, "edi.common.contract.date")}
                                dateFormat={dateFormat}
                                selected={filter.contractDate}
                                defaultValue={filter.contractDate}
                                onChange={(value) => {
                                    setFilter({...filter, contractDate: value});
                                }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, "edi.contract.datatable.header.row.contractor")}</Form.Label>
                    <FindCompanySearchable defaultInn={filter.contractor?.person_num || filter.contractor?.inn}
                                           onChange={(company) => {
                                               setFilter({...filter, contractor: company});
                                           }}
                                           inputProps={{
                                               placeholder: t(lang, "edi.contract.datatable.header.row.contractor")
                                           }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12}>
                <div className="d-grid d-sm-block text-sm-end">
                    <Button variant="primary"
                            disabled={loading}
                            onClick={() => onSubmit(filter)}
                    >
                        {loading && <Spinner className="align-middle me-2" animation="border"
                                             role="switch" size="sm"/>}
                        {t(lang, "crm.common.show.button")}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default ActDataTableFilter;