import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {
    revisionRegisterAsync,
} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";
import {toast} from "react-toastify";
import {Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import AddRevisionModalForm from "./AddRevisionModalForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



const AddRevisionModal = ({itemDisabled, item, ...props}) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate;
    const findItem = items?.find(x => x.item.id === item?.id)

    const form = useForm({
        defaultValues: {
            item_id: item?.id,
            quantity: 0,
            warehouse_id: findItem?.warehouse_states?.warehouse_items?.length > 1 ? null : findItem?.warehouse_states?.warehouse_items[0]?.id,
            warehouse: findItem?.warehouse_states?.warehouse_items,
            date: new Date(),
            number: null,
        }
    })


    const handlerShow = () => {
        setShow(true)
        form.setValue("item_id", item?.id)
    }

    const handlerClose = () => {
        setShow(false)
        form.reset()
    }


    const onSubmit = async (formData) => {
        setLoading(true)

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        await revisionRegisterAsync({
            date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
            note: formData?.note,
            number: formData?.number?.trim() ? formData?.number : null,
            items: [item],
            approve: true
        })
            .then((res) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                handlerClose()
            })
            .catch((err) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <span className="text-success d-block" onClick={() => handlerShow()}>
                <FontAwesomeIcon className="me-1" size="sm" icon={"plus"}/>
                {t(lang, "warehouse.operation.item.common.new_revision")}
            </span>

            <Modal show={show} onHide={handlerClose} size="lg">
                <Modal.Header>
                    <Modal.Title>Ревизия</Modal.Title>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <FormProvider {...form}>
                    <Form>
                        <Modal.Body>
                            <AddRevisionModalForm disabled={itemDisabled}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handlerClose}>
                                {t(lang, "warehouse.operation.item.common.close")}
                            </Button>
                            <Button variant="falcon-info" type="submit" onClick={form.handleSubmit(onSubmit)} disabled={loading}>
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' size="sm"
                                             role='switch'/>
                                }
                                {t(lang, "items.common.save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    )
};

export default AddRevisionModal;
