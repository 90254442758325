import React, {useEffect} from "react"
import ContractorPaymentDataTable from "../../../../components/hippo/crm/contractor/payment/ContractorPaymentDataTable"
import {
    ADD_CONTRACTOR_PAYMENT_SUCCESS, DELETE_CONTRACTOR_PAYMENT_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_SUCCESS
} from "../../../../app/eventbus/contractor/contractorPaymentEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    loadContractorPaymentAsync,
    selectFilterOptionsContractorPayments,
    selectContractor
} from "../../../../app/store/reducers/contractor/contractorPaymentReducer";
import {useDispatch, useSelector} from "react-redux";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCRMContractorPaymentView} from "../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../components/errors/Error403";

const ContractorPayments = () => {
    const filters = useSelector(selectFilterOptionsContractorPayments)
    const dispatch = useDispatch();
    const contractor = useSelector(selectContractor);

    // permissions
    const isPaymentView = useCheckPermission(PermissionCRMContractorPaymentView)

    useEffect(() => {

        if (!isPaymentView) return;

        const onContractorPaymentAddedHandler = EventBus.on(ADD_CONTRACTOR_PAYMENT_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPaymentEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYMENT_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPaymentContractorEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPaymentNoteEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPaymentDateEditedHandler = EventBus.on(EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });
        const onContractorPaymentDeletedHandler = EventBus.on(DELETE_CONTRACTOR_PAYMENT_SUCCESS, () => {
            dispatch(loadContractorPaymentAsync({...filters, contractor_id: contractor?.id}))
        });

        return () => {
            EventBus.remove(ADD_CONTRACTOR_PAYMENT_SUCCESS, onContractorPaymentAddedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYMENT_SUCCESS, onContractorPaymentEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS, onContractorPaymentContractorEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS, onContractorPaymentNoteEditedHandler)
            EventBus.remove(EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS, onContractorPaymentDateEditedHandler)
            EventBus.remove(DELETE_CONTRACTOR_PAYMENT_SUCCESS, onContractorPaymentDeletedHandler)
        }
    }, []);

    if (!isPaymentView) return <Error403 />;

    return (<ContractorPaymentDataTable/>)
}

export default ContractorPayments