import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectAllContractor} from '../../../../app/store/reducers/contractor/contractorReducer';
import Select from "react-select";
import {transliterate} from "transliteration";

const SelectContractor = ({onChange, defaultValue, ...props}) => {
    const contractors = useSelector(selectAllContractor)
    const [contractor, setContractor] = useState(contractors.find(s => s.id === defaultValue) || null)

    useEffect(() => {
        onChange(contractor ? contractor : null);
    }, [contractor])

    const onFilterContractors = (candidate, searchText) => {
        if (searchText) {
            const searchTermLower = searchText.trim().toLowerCase();
            const itemNameLower = candidate.label.trim().toLowerCase();
            const transliteratedQuery = transliterate(searchTermLower);
            const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
            const transliteratedItemName = transliterate(itemNameLower);
            return filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length;
        }
        return true;
    };

    return (
        <Select hideSelectedOptions
                {...props}
                style={{width: '100%'}}
                filterOption={onFilterContractors}
                classNamePrefix="react-select"
                onChange={option => setContractor(option)}
                options={contractors}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={contractors.find(s => s.id === defaultValue)}
                isClearable/>
    );
};

SelectContractor.propTypes = {
    onChange: PropTypes.func
}

SelectContractor.defaultProps = {
    onChange: () => {
    }
}

export default SelectContractor;
