import React, {Fragment, useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_BULK_DELETE_REQUESTED,
    ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import ResultCounter from "../../../item/forms/bulk-item/ResultCounter";
import RoamingService from "../../../../../services/roaming/roamingService";

const BulkDeleteDraftActs = ({draftActs}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteDraftActs = async (draftActs) => {
        try {
            setLoading(true);
            setShow(false);
            ref.current.updateLoadedCount(draftActs.length);
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_DELETE_REQUESTED, draftActs);
            for (const draftAct of draftActs) {
                await RoamingService.deleteActDraft(draftAct.id);
                ref.current.incrementSucceedCount();
            }
            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS, draftActs);
        } catch (error) {
            setLoading(false);
            ref.current.incrementFailedCount();
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS, draftActs);
        }
    };

    return (
        <Fragment>
            <ResultCounter ref={ref}/>
            <IconButton onClick={handleShow}
                        variant="falcon-danger"
                        icon={faTrash}
                        size="sm"
            >
                {t(lang, "roaming.invoice.delete")}
            </IconButton>
            {show &&
                <Modal show={true} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>
                            {t(lang, "roaming.invoice.delete_draft_modal_title")}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        {t(lang, "roaming.invoice.delete_draft_modal_body")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "roaming.common.cancel_button")}
                        </Button>
                        <Button variant="danger"
                                disabled={loading}
                                onClick={() => deleteDraftActs(draftActs)}
                        >
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, "roaming.invoice.delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    );
};

export default BulkDeleteDraftActs;