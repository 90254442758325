import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getRevisionItemAsync} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS,
} from "../../../../app/eventbus/warehouse-operation/revisionEvents";
import Revision from "../../../../components/hippo/warehouse-operation/revision/Revision";
import {PermissionWMSRevisionView} from "../../../../enum/Permission/WarehouseOperationPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";


const ViewRevision = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [revision, setRevision] = useState(null)
    const {id} = useParams()

    // permissions
    const isRevisionView = useCheckPermission(PermissionWMSRevisionView);

    const loadRevision = () => {
        if (isRevisionView) {
            getRevisionItemAsync({revisionId: id})
                .then(response => setRevision(response.data, response?.data?.items?.reverse()))
                .catch(error => setRevision(null))
        }

    }

    useEffect(() => {
        loadRevision()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS, () => {
            loadRevision()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
        }
    },[])

    if (!isRevisionView) return <Error403 />

    if (!revision) return <> loading... </>



    return(
        <>
            <Revision revision={revision}/>
        </>
    )
}


export default ViewRevision