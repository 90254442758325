import React, {Fragment} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import ContractInfo from "./contract-form/ContractInfo";
import OwnerInfo from "./contract-form/OwnerInfo";
import ClientInfo from "./contract-form/ClientInfo";
import Products from "./contract-form/Products";
import Parts from "./contract-form/Parts";
import IconButton from "../../../common/IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../components/SelectCertificateWrapperComponent";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useHistory} from "react-router";
import {useFieldArray, useFormContext} from "react-hook-form";

const SendContractForm = ({isSending, isSaving, onSave, onSend}) => {

    const lang = useSelector(selectLang)
    const t = Translate

    const {control} = useFormContext()

    const {
        fields: fieldsClient,
        append: appendClient,
        remove: removeClient
    } = useFieldArray({control: control, name: "clients", focusAppend: false})

    const {
        fields: fieldsProduct,
        append: appendProduct,
        remove: removeProduct,
        insert: insertProduct
    } = useFieldArray({control: control, name: "products", focusAppend: false})

    const {
        fields: fieldsPart,
        append: appendPart,
        remove: removePart
    } = useFieldArray({control: control, name: "parts", focusAppend: false})


    const addFieldClient = () => {
        appendClient({
            tin: "",
            name: "",
            address: "",
            workPhone: "",
            mobile: "",
            fax: "",
            oked: "",
            account: "",
            bankId: "",
            fizTin: "",
            fio: "",
            branchCode: "",
            branchName: ""
        })
    }
    const removeFieldClient = index => {
        removeClient(index)
    }

    const addNewItem = () => {
        appendProduct({
            name: "",
            catalog: null,
            package: null,
            barcode: "",
            measurement: "",
            quantity: null,
            price: null,
            total: null,
            vat_rate: 0,
            vat_sum: null,
            total_with_vat_sum: null,
            withoutVat: false
        })
    }

    const insertNewItem = (index) => {
        insertProduct(index, {
            name: "",
            catalog: null,
            package: null,
            barcode: "",
            measurement: "",
            quantity: null,
            price: null,
            total: null,
            vat_rate: 0,
            vat_sum: null,
            total_with_vat_sum: null,
            withoutVat: false
        });
    };

    const removeItem = index => {
        removeProduct(index)
    }
    const addFieldPart = () => {
        appendPart({
            ordNo: fieldsPart.length,
            title: "",
            body: "",
        })
    }
    const removeFieldPart = index => {
        removePart(index)
    }

    return (
        <Fragment>
            <Row>
                <Col sm="12" className="mb-4">
                    <ContractInfo/>
                </Col>

                <Col sm="6" className="mb-4">
                    <OwnerInfo/>
                </Col>

                {fieldsClient.map((client, index) => (
                    <Col sm="6" className="mb-4" key={client.id}>
                        <ClientInfo
                            index={index}
                            addFieldClient={addFieldClient}
                            removeFieldClient={removeFieldClient}
                        />
                    </Col>
                ))}

                <Col sm="12" className="mb-4">
                    <Products
                        fieldsProduct={fieldsProduct}
                        addFieldProduct={addNewItem}
                        removeFieldProduct={removeItem}
                        insertFieldProduct={insertNewItem}
                    />
                </Col>

                <Col sm="12" className="mb-4">
                    <Parts
                        fieldsPart={fieldsPart}
                        addFieldPart={addFieldPart}
                        removeFieldPart={removeFieldPart}
                    />
                </Col>

                <Col sm="12">
                    <Card className="border-primary border-top-2">
                        <Card.Body className="text-end">
                            <IconButton
                                icon={faBookmark}
                                variant="falcon-warning"
                                size="lg"
                                className="w-100 w-md-auto me-md-3"
                                disabled={isSaving}
                                onClick={onSave}
                            >
                                {t(lang, "roaming.invoice.save_button")}
                            </IconButton>

                            <SelectCertificateWrapperComponent
                                size="lg"
                                className="w-100 mt-2 mt-md-0 w-md-auto"
                                submitButton={
                                    <IconButton
                                        icon={faSignature}
                                        variant="falcon-primary"
                                        size="lg"
                                        id={"send-contract-button"}
                                        className="w-100 mt-2 mt-md-0 w-md-auto"
                                        disabled={isSending}
                                        onClick={onSend}
                                    >
                                        {t(lang, "roaming.invoice.send_button")}
                                    </IconButton>
                                }
                                openDialogButtonText={t(lang, "roaming.invoice.send_button")}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SendContractForm;