import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import CashBoxesHeaderForm from "../hippo/cashbox/cash-boxes/CashBoxesHeaderForm";
import React, {Fragment, useEffect, useState} from "react";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import CashBoxCommonRegisterModal from "../hippo/cashbox/common/CashBoxCommonRegisterModal";
import {useHistory, useLocation} from "react-router";

export const cashBoxHeaderFilter = (RegisterComponentForm, displayName) => {
    const CashBoxHeaderFilter = ({requestAsync, filters, registerRequest, pageTitle , currency, transfer, cashBoxOperation, permission}) => {
        const {state} = useLocation()
        const history = useHistory()
        const dispatch = useDispatch()
        const lang = useSelector(selectLang)
        const t = Translate
        const [show, setShow] = useState(false)

        const methods = useForm({
            defaultValues: {
                date_start: state && new Date(dayjs(state?.created_at).format('YYYY-MM-DD')) || filters.date_start && new Date(dayjs(filters.date_start).format('YYYY-MM-DD')) || null ,
                date_end: state && !state?.name && new Date(dayjs(state?.created_at).format('YYYY-MM-DD')) || filters.date_end && new Date(dayjs(filters.date_end).format('YYYY-MM-DD')) || null,
                cashbox_id: state?.cash_box?.id || state?.to_cash_box_id || state?.from_cash_box_id || state?.id || null,
                account_id: null,
                from_cashbox_id: null,
                to_cashbox_id: null,
                type: null
            }
        })

        const onSubmit = (formData) => {
            history.replace({state: null})
            dispatch(requestAsync({
                ...filters,
                date_start: formData.date_start && dayjs(formData.date_start).format("YYYY-MM-DD"),
                date_end: formData.date_end && dayjs(formData.date_end).format("YYYY-MM-DD"),
                cashbox_id: formData?.cashbox_id?.id || formData.cashbox_id,
                from_cashbox_id: formData.from_cashbox_id?.id,
                to_cashbox_id: formData.to_cashbox_id?.id,
                account_id: formData.account_id?.account_id,
                type: formData.type?.value
            }))
        }

        return (
           <Fragment>
               <FormProvider {...methods}>
                   <Form id={'cash-box-form'} onSubmit={methods.handleSubmit(onSubmit)}>
                       <CashBoxesHeaderForm currency={currency} transfer={transfer} cashBoxOperation={cashBoxOperation}/>
                   </Form>
               </FormProvider>
               <Row>
                   <Col xs={12} className="d-flex justify-content-between mt-3">
                       <h4 className={'m-0'}>{t(lang, pageTitle)}</h4>
                       <div className={'d-flex gap-2 justify-content-end'}>
                           {RegisterComponentForm && permission && <CashBoxCommonRegisterModal show={show} setShow={setShow} showBtn={true}>
                               <RegisterComponentForm registerRequest={registerRequest} setShow={setShow} transfer={transfer}/>
                           </CashBoxCommonRegisterModal>}
                           <Button size={'sm'} form={'cash-box-form'} type="submit" variant="primary" className={'px-3'}>
                               {t(lang, 'warehouse.operation.item.common.filter.search')}
                           </Button>
                       </div>

                   </Col>
               </Row>
           </Fragment>
        );
    };

    CashBoxHeaderFilter.displayName = displayName || "CashBoxExpenseHeaderFilter"

    return CashBoxHeaderFilter
}
