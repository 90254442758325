import ContractorOperationsDataTable
    from "../../../../components/hippo/crm/contractor/operation/ContractorOperationsDataTable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {
    PermissionCRMContractorOperationView,
} from "../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../components/errors/Error403";

const ContractorOperations = () => {

    // permissions
    const isContractorOperationView = useCheckPermission(PermissionCRMContractorOperationView)

    if (!isContractorOperationView) return <Error403 />

    return (<ContractorOperationsDataTable/>)
}
export default ContractorOperations;