import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_FAILED,
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_FAILED,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_FAILED,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_FAILED,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_FAILED,
    WAREHOUSE_OPERATION_REFUND_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_NOTE_FAILED,
    WAREHOUSE_OPERATION_REFUND_DATE_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DATE_FAILED,
    WAREHOUSE_OPERATION_REFUND_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_NUMBER_FAILED,
    WAREHOUSE_OPERATION_REFUND_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_EMPLOYEE_FAILED, WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_REQUESTED, WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS, WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_FAILED
} from "../../../eventbus/warehouse-operation/refundEvents";
import warehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import dayjs from "dayjs";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);


const refundReducer = createSlice({
    name: "refund",
    initialState: {
        refunds: [],
        refundCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            contractor_id: null,
            employee_id: null,
            operation_account_id: null,
            is_approved: null,
            warehouse_id: null
        },
        refundDefaultsSettings: null,
        loading: false
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateRefunds: (state, action) => {
            state.refunds = action.payload
        },
        updateRefundCount: (state, action) => {
            state.refundCount = action.payload
        },
        setRefundDefaultsSettings: (state, action) => {
            state.refundDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("RefundSettings", action.payload)
        },
        loadRefundDefaultsSettings: (state, action) => {
            const refundDefaults = JSON.parse(localStorage.getItem("RefundSettings"))
            state.refundDefaultsSettings = refundDefaults
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        updateRefundItemNote: (state, action) => {
            state.refunds = state.refunds.map((refund) => {
                if (refund.id === action.payload.id) {
                    refund.note = action.payload.note
                }
                return refund
            })
        }
    }
})

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(refundReducer.actions.updateFilterOptions({...params}))
}

export const getRefundsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(refundReducer.actions.setLoading(true))
    return new Promise((resolve, reject) => {
        warehouseOperationService.getRefunds(params)
            .then((response) => {
                dispatch(refundReducer.actions.updateRefunds(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => dispatch(refundReducer.actions.setLoading(false)))
    })
}

export const getRefundsCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getRefundsCount(filters)
            .then((response) => {
                dispatch(refundReducer.actions.updateRefundCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const refundRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, response.data.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_FAILED)
                reject(error)
            })
    })
}

export const refundAddItemAsync = ({refundId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addRefundItem({refundId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getRefundItemAsync = ({refundId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getRefund({refundId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const refundDeleteItemAsync = ({refundId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteRefundItem({refundId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const refundUpdateItemAsync = ({refundId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updateRefundItem({refundId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const refundApproveAsync = ({refundId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approveRefund({refundId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const refundDeleteAsync = (id) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundDelete(id)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const refundUpdateNoteAsync = ({refundId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundUpdateNote({refundId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NOTE_FAILED)
                reject(error)
            })
    })
}


export const refundUpdateDateAsync = ({refundId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundUpdateDate({refundId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DATE_FAILED)
                reject(error)
            })
    })
}

export const refundUpdateNumberAsync = ({refundId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundUpdateNumber({refundId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const refundChangeContractorAsync = ({refundId, contractorId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundChangeContractor({refundId, contractorId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS, refundId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_FAILED)
                reject(error)
            })
    })
}

export const refundChangeEmployeeAsync = ({refundId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundChangeEmployee({refundId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const refundPayoutRegisterAsync = ({refundId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundPayoutRegister({refundId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, refundId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_FAILED)
                reject(error)
            })
    })
}

export const refundPayoutUpdateAsync = ({refundId, payout}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundPayoutUpdate({refundId, payout})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, refundId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_FAILED)
                reject(error)
            })
    })
}

export const deleteRefundPayoutAsync = (refundId) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_REQUESTED);

    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteRefundPayout(refundId)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS, refundId);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_FAILED);
                reject(error);
            })
    })
}

export const refundDownloadPdfAsync = ({refundId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundDownloadPdf({refundId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const refundDownloadReceipt80PdfAsync = ({refundId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundDownloadReceipt80Pdf({refundId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const refundDownloadReceipt58PdfAsync = ({refundId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.refundDownloadReceipt58Pdf({refundId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const onRefundNoteChanged = ({note, id}) => (dispatch) => {
    dispatch(refundReducer.actions.updateRefundItemNote({note, id}))
}

export const selectRefundFilterOptions = state => state.refund.filterOptions
export const selectRefundCount = state => state.refund.refundCount
export const selectRefunds = state => state.refund.refunds
export const refundLoading = state => state.refund.loading
export const selectRefundDefaultsSettings = state => state.refund.refundDefaultsSettings

export const {setRefundDefaultsSettings, loadRefundDefaultsSettings} = refundReducer.actions

export default refundReducer.reducer;
