import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Collapse, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import {
    deliveryTypeOptions,
    transportTypeOptions
} from "../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";

import {
    getOrganizationInfoAsync,
    loadRoamingBranchesAsync
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../../../enum/roaming/WaybillStatus";
import dayjs from "dayjs";

const WaybillV2DatatableFilter = ({viewer, isCollapsed, predefinedFilters}) => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const activeOrganization = useSelector(selectActiveOrganization);

    const {register, setValue} = useFormContext()

    const [customer, setCustomer] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [customerBranches, setCustomerBranches] = useState([]);
    const [executorBranches, setExecutorBranches] = useState([]);
    const [customerBranch, setCustomerBranch] = useState(null);
    const [executorBranch, setExecutorBranch] = useState(null);

    const showExecutorFilter = viewer === VIEW_INBOX || viewer === RESPONSIBLE_PERSON;
    const showCustomerFilter = viewer === VIEW_OUTBOX || viewer === RESPONSIBLE_PERSON || viewer === VIEW_DRAFTS;
    const showResponsiblePersonFilter = viewer !== RESPONSIBLE_PERSON;

    useEffect(async () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            params.date_end = params?.date_end ? dayjs(params.date_end).toDate() : null;
            params.date_start = params?.date_start ? dayjs(params.date_start).toDate() : null;
            params.contract_date = params?.contract_date ? dayjs(params.contract_date).toDate() : null;
            params.created_at_start = params?.created_at_start ? dayjs(params.created_at_start).toDate() : null;
            params.created_at_end = params?.created_at_end ? dayjs(params.created_at_end).toDate() : null;

            Object.keys(params).forEach(key => {
                setValue(key, params[key]);
            })
        }
    }, [predefinedFilters])

    // set default executor || customer
    useEffect(async () => {
        switch (viewer) {
            case VIEW_INBOX:
                setCustomer(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEW_OUTBOX:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEW_DRAFTS:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            default:
                break;
        }
    }, [viewer, activeOrganization])

    // set customer_inn and load branches
    useEffect(async () => {
        if (customer) {
            setValue('customer_inn', customer.person_num || customer.inn);
            setCustomerBranches(await dispatch(loadRoamingBranchesAsync(customer.person_num || customer.inn)));
        } else {
            setValue('customer_inn', null);
            setCustomerBranches([]);
            setCustomerBranch(null);
        }
    }, [customer])

    // set executor_inn and load branches
    useEffect(async () => {
        if (executor) {
            setValue('executor_inn', executor.person_num || executor.inn);
            setExecutorBranches(await dispatch(loadRoamingBranchesAsync(executor.person_num || executor.inn)));
        } else {
            setValue('executor_inn', null);
            setExecutorBranches([]);
            setExecutorBranch(null);
        }
    }, [executor])

    // set customer branch
    useEffect(() => {
        if (customerBranch)
            setValue('customer_branch_code', customerBranch.number);
        else
            setValue('customer_branch_code', null);
    }, [customerBranch])

    // set executor branch
    useEffect(() => {
        if (executorBranch)
            setValue('executor_branch_code', executorBranch.number);
        else
            setValue('executor_branch_code', null);
    }, [executorBranch])

    return (
        <Fragment>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.return_invoice.datatable.header.filter.title")}</h4>
            </Row>

            <Row className={'mb-3 g-3'}>
                <Form.Group as={Col} md={12} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_start")}</Form.Label>
                    <Controller
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={12} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_end")}</Form.Label>
                    <Controller
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                {showCustomerFilter && <Form.Group as={Col} md={12} lg={3}>
                    <Form.Label>{t(lang, "roaming.common.customer")}</Form.Label>
                    <Controller name={'customer_inn'} render={({field}) => (
                        <FindCompanySearchable
                            onChange={setCustomer}
                            inputProps={{
                                classNamePrefix: 'react-select',
                                placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                            }}
                        />
                    )}/>
                </Form.Group>}

                {showExecutorFilter && <Form.Group as={Col} md={12} lg={3}>
                    <Form.Label>{t(lang, "roaming.common.executor")}</Form.Label>
                    <Controller name={'executor_inn'} render={({field}) => (
                        <FindCompanySearchable
                            onChange={setExecutor}
                            inputProps={{
                                classNamePrefix: 'react-select',
                                placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                            }}
                        />
                    )}/>
                </Form.Group>}

                {showResponsiblePersonFilter && <Form.Group as={Col} md={12} lg={3}>
                    <Form.Label>{t(lang, "roaming.waybill.v2.send.responsible_person")}</Form.Label>
                    <Controller name={'responsible_person_inn'} render={({field}) => (
                        <FindCompanySearchable
                            onChange={(option) => field.onChange(option?.person_num || option?.inn)}
                            inputProps={{
                                classNamePrefix: 'react-select',
                                placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                            }}
                        />
                    )}/>
                </Form.Group>}

                <Collapse in={isCollapsed} className={'mt-0'}>
                    <Row className={'mb-3 g-3'}>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.datatable.header.filter.created_at_start")}</Form.Label>
                            <Controller
                                name="created_at_start"
                                render={({field}) => (
                                    <DatePicker
                                        isClearable
                                        className='form-control'
                                        placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                        dateFormat={dateFormat}
                                        selected={field.value}
                                        onChange={date => field.onChange(date)}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.datatable.header.filter.created_at_end")}</Form.Label>
                            <Controller
                                name="created_at_end"
                                render={({field}) => (
                                    <DatePicker
                                        isClearable
                                        className='form-control'
                                        placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                        dateFormat={dateFormat}
                                        selected={field.value}
                                        onChange={date => field.onChange(date)}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.invoice.mass_add.column.contract_date")}</Form.Label>
                            <Controller
                                name="contract_date"
                                render={({field}) => (
                                    <DatePicker
                                        isClearable
                                        className='form-control'
                                        placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                        dateFormat={dateFormat}
                                        selected={field.value}
                                        onChange={date => field.onChange(date)}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.invoice.mass_add.column.contract_number")}</Form.Label>
                            <Form.Control
                                defaultValue={null}
                                {...register('contract_number')}
                                placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.waybill.send.details_form.shipment_number")}</Form.Label>
                            <Form.Control
                                defaultValue={null}
                                {...register('number')}
                                placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}</Form.Label>
                            <Controller
                                name="delivery_type"
                                render={({field}) => (
                                    <ReactSelect
                                        hideSelectedOptions={true}
                                        options={deliveryTypeOptions}
                                        classNamePrefix="react-select"
                                        getOptionValue={option => option.value}
                                        getOptionLabel={option => t(lang, option.label)}
                                        onChange={option => field.onChange(option?.value)}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type.placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_transport_type')}</Form.Label>
                            <Controller
                                name="transport_type"
                                render={({field}) => (
                                    <ReactSelect
                                        hideSelectedOptions={true}
                                        options={transportTypeOptions}
                                        classNamePrefix="react-select"
                                        getOptionValue={option => option.value}
                                        getOptionLabel={option => t(lang, option.text)}
                                        onChange={option => field.onChange(option?.value)}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_transport_type:placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.invoices.datatable.filter.executor_branch_code")}</Form.Label>
                            <Controller name="executor_branch_code"
                                        render={({field}) => (
                                            <ReactSelect options={executorBranches}
                                                         classNamePrefix="react-select"
                                                         placeholder={t(lang, "roaming.common.select.placeholder")}
                                                         noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                         hideSelectedOptions
                                                         isClearable
                                                         value={executorBranch}
                                                         onChange={setExecutorBranch}
                                                         getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                         getOptionValue={option => option.number}
                                            />
                                        )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={3}>
                            <Form.Label>{t(lang, "roaming.invoices.datatable.filter.customer_branch_code")}</Form.Label>
                            <Controller name="customer_branch_code"
                                        render={({field}) => (
                                            <ReactSelect options={customerBranches}
                                                         classNamePrefix="react-select"
                                                         placeholder={t(lang, "roaming.common.select.placeholder")}
                                                         noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                         hideSelectedOptions
                                                         value={customerBranch}
                                                         onChange={setCustomerBranch}
                                                         isClearable
                                                         getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                         getOptionValue={option => option.number}
                                            />
                                        )}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} lg={9} className="d-flex align-items-end justify-content-end mt-2">
                            <Button variant="primary" type='submit'>{t(lang, "edi.return_invoice.datatable.header.filter.show")}</Button>
                        </Form.Group>

                    </Row>
                </Collapse>
            </Row>
        </Fragment>
    );
};

export default WaybillV2DatatableFilter;
