import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterOptionsAsync,
    getRevisionsAsync,
    getRevisionsCountAsync,
    onRevisionNoteChanged,
    revisionDownloadPdfAsync,
    revisionLoading,
    revisionUpdateNoteAsync,
    selectRevisionCount,
    selectRevisionFilterOptions,
    selectRevisions
} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import React, {Fragment, useEffect, useState} from "react";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import SoftBadge from "../../../../common/SoftBadge";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {Card, Dropdown, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import RevisionDatatableHeader from "./RevisionDatatableHeader";
import {isApprovedCheckedWarehouseOperation} from "../../enum/warehouseOperationWrapper";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/revisionEvents";
import RevisionDelete from "../components/RevisionDelete";
import CardDropdown from "../../../../common/CardDropdown";
import IconButton from "../../../../common/IconButton";
import {faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import printJS from "print-js";
import UploadRevisionToExcel from "../excel/UploadRevisionToExcel";
import useEditableCell from "../../../../../hooks/useEditableCell";
import {toast} from "react-toastify";
import InProgress from "../../../../common/InProgress";
import {isNull} from "underscore";
import EditableCellConfirmationModal from "../../EditableCellConfirmationModal";
import {
    PermissionWMSRevisionDelete,
    PermissionWMSRevisionUpdate,
    PermissionWMSRevisionView
} from "../../../../../enum/Permission/WarehouseOperationPermission";
import useCheckPermission from "../../../../../hooks/useCheckPermission";


const RevisionDatatable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const revisions = useSelector(selectRevisions)
    const dispatch = useDispatch()
    const filters = useSelector(selectRevisionFilterOptions)
    const count = useSelector(selectRevisionCount)
    const lang = useSelector(selectLang)
    const t = Translate
    const loading = useSelector(revisionLoading)
    const [rowStates, setRowStates] = useState({})

    // permissions
    const isRevisionView = useCheckPermission(PermissionWMSRevisionView);
    const isRevisionEdit = useCheckPermission(PermissionWMSRevisionUpdate);
    const isRevisionDelete = useCheckPermission(PermissionWMSRevisionDelete);

    const downloadPdfRevision = ({id, number}) => {
        revisionDownloadPdfAsync({revisionId: id})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.revision_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPrint = ({id}) => {
        revisionDownloadPdfAsync({revisionId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }


    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return (filters.page - 1) * filters.limit + row.index + 1;
            }
        },
        {
            accessor: 'number',
            Header: t(lang, "Номер"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`./revisions/view/${original?.id}`} className="d-flex text-wrap">
                        {original?.number}
                    </Link>
                )
            }
        },
        {
            id: 'name',
            Header: t(lang, "warehouse.operation.item.common.sale_status"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <SoftBadge bg={original?.is_approved ? 'success' : 'warning'}>
                            {t(lang, isApprovedCheckedWarehouseOperation(original?.is_approved))}
                        </SoftBadge>
                    </>
                )
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Пользователь"),
            headerProps: {
                className: "text-center"
            },
            cellProps: {
                className: "text-center"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-center">
                        <span className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "Информация"),
            headerProps: {
                className: "text-start"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {

                const {
                    tempContent,
                    handleDoubleClick,
                    handleInputChange,
                    handleBlur,
                    handleConfirm,
                    handleCancel,
                    showModal
                } = useEditableCell(original, "Note", setRowStates, changeUpdateNote, original?.note);

                return (
                    <Fragment>
                        <div
                            onDoubleClick={handleDoubleClick}
                            className="d-flex justify-content-end"
                            style={{maxWidth: "250px", minWidth: "250px", minHeight: '20px'}}
                        >
                            {
                                rowStates[original.id]?.editNote
                                    ? (<Form.Control
                                        type="text"
                                        value={tempContent}
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        placeholder={t(lang, 'items.common.description')}
                                        autoFocus
                                    />)
                                    : <span className="d-flex text-wrap">{original?.note}</span>
                            }
                        </div>
                        <EditableCellConfirmationModal
                            show={showModal}
                            onConfirm={handleConfirm}
                            onCancel={handleCancel}
                        />
                    </Fragment>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    dayjs(original?.date).format("YYYY-MM-DD HH:mm:ss")
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <Dropdown>
                        <Dropdown.Toggle className="p-0 px-1" variant="outline-primary">
                            <FontAwesomeIcon icon={faFilePdf}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0">
                            <Dropdown.Item onClick={() => downloadPdfRevision({id: original?.id, number: original?.number})} className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faFilePdf}/>
                                <span>{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onPrint({id: original?.id})} className="text-info">
                                <FontAwesomeIcon className="me-1" icon={faPrint}/>
                                <span>{t(lang, "warehouse.operation.item.common.print")}</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Link to={`./revisions/view/${original?.id}`}>
                        <IconButton
                            variant="falcon-info"
                            icon="eye"
                            title={t(lang, "warehouse.operation.item.common.show")}
                            className="mx-2"
                            size="sm"
                        />
                    </Link>
                    <UploadRevisionToExcel className={'mx-1'} id={original?.id} number={original?.number}>
                        <FontAwesomeIcon className={'text-success'} icon={faFileExcel}/>
                    </UploadRevisionToExcel>
                    {(isRevisionDelete || isRevisionEdit) && <CardDropdown btnRevealClass="btn-reveal-sm">
                        <div className="py-2">
                            {isRevisionEdit && <Dropdown.Item as={Link} to={`./revisions/update/${original?.id}`}
                                            className="text-warning">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>}
                            {/*<Dropdown.Item as={Link} to={`./revisions/update-excel/${original?.id}`}
                                           className="text-success">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "Редактировать с Excel")}</span>
                            </Dropdown.Item>*/}
                            {isRevisionDelete && <RevisionDelete id={original?.id}/>}
                        </div>
                    </CardDropdown>}
                </div>
            )
        }
    ]

    const changeUpdateNote = ({ content, id, defaultValue }) => {
        if (content === defaultValue || isNull(content)) return;
        dispatch(updateShowInProgressAsync(true));
        revisionUpdateNoteAsync({revisionId: id, note: content})
            .then(() => {
                dispatch(onRevisionNoteChanged({id, note: content}))
                toast.success(t(lang, 'warehouse.operation.item.common.toast.success'))
            })
            .finally(() => dispatch(updateShowInProgressAsync(false)))
    };


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    const loadRevisions = () => {
        if (isRevisionView) {
            dispatch(getRevisionsAsync({...filters}))
            dispatch(getRevisionsCountAsync({...filters}))
        }
    }


    useEffect(() => {
        loadRevisions()

        const onRevisionDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS, () => {
            loadRevisions()
        })
        const onRevisionRegisterSucceedHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, () => {
            loadRevisions();
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS, onRevisionDeletedHandler);
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, onRevisionRegisterSucceedHandler);
        }
    }, [activeOrganization, filters])


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={revisions}
                pagination
                sortable
                perPage={1000}
                selection
            >
                <InProgress/>
                <Card className="mb-3">
                    <Card.Header>
                        <RevisionDatatableHeader loadRevisions={loadRevisions} table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                                style: {
                                    minHeight: '250px'
                                }
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default RevisionDatatable;
