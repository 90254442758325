import React, {useEffect, useMemo} from 'react';
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Card, Dropdown, Form, OverlayTrigger, Popover} from "react-bootstrap";
import DataTable from "../../../../common/table/DataTable";
import DataTablePagination from "../../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../../common/table/DataTableLimiter";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../View/TypeView";
import DataTableColumnSettings from "../../../../common/table/DataTableColumnSettings";
import CardDropdown from "../../../../common/CardDropdown";
import dayjs from "dayjs";
import numeral from "numeral";
import {ActWrapperClass} from "../View/ActStatus";
import SoftBadge from "../../../../common/SoftBadge";
import ActDataTableHeader from "./ActDataTableHeader";
import DeleteDraftAct from "../Button/DeleteDraftAct";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_BULK_ACCEPT_SUCCESS,
    ROAMING_ACT_BULK_CANCEL_SUCCESS,
    ROAMING_ACT_BULK_DECLINE_SUCCESS,
    ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS,
    ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS,
    ROAMING_ACT_DRAFT_DELETE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import classNames from "classnames";

const ActDataTable = ({
                          activeOrganization,
                          activeCertificate,
                          viewer,
                          acts,
                          count,
                          filter,
                          pagination,
                          rowSelection,
                          lastRowSelected,
                          columnVisibility,
                          loading,
                          onDebouncedFilterChange,
                          onFilterChange,
                          onPaginationChange,
                          onColumnVisibilityChange,
                          onRowSelectionChange,
                          onReloadData
                      }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);

    const columns = useMemo(() => {
        const cs = [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i]?.id] = true;
                        }

                        onRowSelectionChange(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'number',
                accessorKey: t(lang, "roaming.act_form.act_number"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '250px',
                                maxWidth: '250px'
                            }}
                        >
                            {t(lang, "roaming.act_form.act_number")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    const isRoamingError = !!original?.roaming_error;
                    const route = viewer === VIEW_DRAFT ? `/roaming/draft-act/${original.id}` : `/roaming/act/${original.id}`;

                    if (isRoamingError) {
                        return (
                            <OverlayTrigger placement="right" trigger={['hover', 'focus']} overlay={
                                <Popover variant="warning">
                                    <Popover.Header
                                        as="h3">Время: {dayjs(original.roaming_error.time, "YYYY-MM-DD HH:mm:ss").format(dateFormat)} | {original?.roaming_error?.status}</Popover.Header>
                                    <Popover.Body>{original?.roaming_error?.message}</Popover.Body>
                                </Popover>}
                            >
                                <td className="p-1 align-middle text-start text-dark"
                                    style={{
                                        minWidth: '250px',
                                        maxWidth: '250px'
                                    }}
                                >
                                    <Link to={route}
                                          className={classNames('cursor-pointer fw-semi-bold text-warning')}>
                                        {original.info.number}
                                    </Link>
                                </td>
                            </OverlayTrigger>
                        );
                    }

                    return (
                        <td className="p-1 align-middle text-start text-dark"
                            style={{
                                minWidth: '250px',
                                maxWidth: '250px'
                            }}
                        >
                            <Link to={route}
                                  className={classNames('cursor-pointer fw-semi-bold text-primary')}>
                                {original.info.number}
                            </Link>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '250px',
                                maxWidth: '250px'
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'date',
                accessorKey: t(lang, "roaming.act_form.act_date"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >
                            {t(lang, "roaming.act_form.act_date")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >
                            {dayjs(original.info.date, "YYYY-MM-DD").format(dateFormat)}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >

                        </td>
                    )
                }
            },
            // {
            //     id: 'total',
            //     accessorKey: t(lang, 'crm.common.datatable.total_amount'),
            //     enableSorting: false,
            //     enableHiding: true,
            //     header: ({column}) => {
            //         return (
            //             <th className="p-1 align-middle text-center text-dark fs--1"
            //                 style={{
            //                     minWidth: '150px',
            //                     maxWidth: '150px'
            //                 }}
            //             >
            //                 {t(lang, 'crm.common.datatable.total_amount')}
            //             </th>
            //         )
            //     },
            //     cell: ({row: {original}}) => {
            //         return (
            //             <td className="p-1 align-middle text-center text-dark"
            //                 style={{
            //                     minWidth: '150px',
            //                     maxWidth: '150px'
            //                 }}
            //             >
            //                 <h6 className="text-end">
            //                     <strong>
            //                         {numeral.formats[numberFormat].format((original?.total_info?.total_with_nds || 0))}
            //                     </strong>
            //                 </h6>
            //             </td>
            //         )
            //     },
            //     footer: ({table}) => {
            //         const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
            //         const totalPrice = rows.reduce((acc, {original}) => acc + original?.total_info?.total_with_nds || 0, 0);
            //         return (
            //             <td className="p-1 align-middle text-center text-dark"
            //                 style={{
            //                     minWidth: '150px',
            //                     maxWidth: '150px'
            //                 }}
            //             >
            //                 <h6 className="text-end">
            //                     <strong>
            //                         {numeral.formats[numberFormat].format((totalPrice || 0))}
            //                     </strong>
            //                 </h6>
            //             </td>
            //         )
            //     }
            // },
            {
                id: 'status',
                accessorKey: t(lang, "roaming.invoices.datatable.header.row.status"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >
                            {t(lang, "roaming.invoices.datatable.header.row.status")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    const actWrapper = new ActWrapperClass(original);
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >
                            <SoftBadge className="w-100 text-wrap"
                                       bg={actWrapper.getRoamingStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                            >
                                <strong className="fs-0">
                                    {t(lang, actWrapper.getRoamingStatus(activeOrganization.pinfl || activeOrganization.inn))}
                                </strong>
                            </SoftBadge>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'created_at',
                accessorKey: t(lang, "roaming.invoices.created_at"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >
                            {t(lang, "roaming.invoices.created_at")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >
                            {dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format(`${dateFormat} HH:mm:ss`)}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '110px',
                                maxWidth: '110px'
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'contractor',
                accessorKey: t(lang, "roaming.invoices.datatable.header.row.contractor"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-start text-dark fs--1"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >
                            {t(lang, "roaming.invoices.datatable.header.row.contractor")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    let contractor = null;

                    if (viewer === VIEW_DRAFT || viewer === VIEW_OUTBOX) {
                        contractor = {
                            inn: original.customer.inn,
                            name: original.customer.name
                        }
                    } else if (viewer === VIEW_INBOX) {
                        contractor = {
                            inn: original.executor.inn,
                            name: original.executor.name
                        }
                    }

                    return (
                        <td className="p-1 align-middle text-start text-dark"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >
                            <h5 className="text-dark fw-semi-bold fs--1 text-truncate">{contractor?.name || ''}</h5>
                            <h6>{contractor?.inn || ''}</h6>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'contract',
                accessorKey: t(lang, "edi.common.contract"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >
                            {t(lang, "edi.common.contract")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >
                            {original.contract.number} от {dayjs(original.contract.date, "YYYY-MM-DD").format(dateFormat)}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '200px',
                                maxWidth: '200px'
                            }}
                        >

                        </td>
                    )
                }
            },
        ];

        if (viewer === VIEW_DRAFT) {
            cs.push({
                id: 'action',
                accessorKey: t(lang, 'notification.table.actions'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            <DataTableColumnSettings table={table}/>
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <CardDropdown btnRevealClass="btn-reveal-sm">
                                <div className="py-2">
                                    <Dropdown.Item as={Link}
                                                   to={`/roaming/act/register?draft_act_id=${original.id}&is_edit=true`}
                                                   className="border-bottom align-center text-warning">
                                        <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                        <span>{t(lang, 'items.common.edit')}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`/roaming/act/register?draft_act_id=${original.id}`}
                                                   className="text-primary border-bottom align-center">
                                        <FontAwesomeIcon className="me-1" size="sm" icon={faClone}/>
                                        <span>{t(lang, 'roaming.invoice.copy')}</span>
                                    </Dropdown.Item>
                                    <DeleteDraftAct id={original.id}>
                                        <Dropdown.Item className="text-danger align-center">
                                            <FontAwesomeIcon className="me-1" size="sm" icon={faTrash}/>
                                            <span>
                                                {t(lang, "roaming.invoice.delete")}
                                            </span>
                                        </Dropdown.Item>
                                    </DeleteDraftAct>
                                </div>
                            </CardDropdown>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            });
        }

        return cs;
    }, [t, lastRowSelected, lang, activeOrganization, dateFormat, numberFormat, viewer, pagination]);
    const data = useMemo(() => {
        return acts;
    }, [acts]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: onRowSelectionChange,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        rowCount: count,
        meta: {
            getHeaderRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getFooterRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            }
        },
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        const onDeleteDraftActSucceedHandler = EventBus.on(ROAMING_ACT_DRAFT_DELETE_SUCCESS, () => {
            onReloadData();
        });
        const onBulkDeleteDraftActsSucceedHandler = EventBus.on(ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS, () => {
            onReloadData();
        });
        const onBulkSignDraftActsSucceedHandler = EventBus.on(ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS, () => {
            onReloadData();
        });
        const onBulkCancelSentActsSucceedHandler = EventBus.on(ROAMING_ACT_BULK_CANCEL_SUCCESS, () => {
            onReloadData();
        });
        const onBulkDeclineSentActsSucceedHandler = EventBus.on(ROAMING_ACT_BULK_DECLINE_SUCCESS, () => {
            onReloadData();
        });
        const onBulkAcceptSentActsSucceedHandler = EventBus.on(ROAMING_ACT_BULK_ACCEPT_SUCCESS, () => {
            onReloadData();
        });

        return () => {
            EventBus.remove(ROAMING_ACT_DRAFT_DELETE_SUCCESS, onDeleteDraftActSucceedHandler);
            EventBus.remove(ROAMING_ACT_DRAFT_BULK_DELETE_SUCCESS, onBulkDeleteDraftActsSucceedHandler);
            EventBus.remove(ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS, onBulkSignDraftActsSucceedHandler);
            EventBus.remove(ROAMING_ACT_BULK_CANCEL_SUCCESS, onBulkCancelSentActsSucceedHandler);
            EventBus.remove(ROAMING_ACT_BULK_DECLINE_SUCCESS, onBulkDeclineSentActsSucceedHandler);
            EventBus.remove(ROAMING_ACT_BULK_ACCEPT_SUCCESS, onBulkAcceptSentActsSucceedHandler);
        };
    }, [onReloadData]);

    return (
        <Card>
            <Card.Header>
                <ActDataTableHeader table={table}
                                    viewer={viewer}
                                    activeCertificate={activeCertificate}
                                    filter={filter}
                                    onFilterChange={onFilterChange}
                                    onDebouncedFilterChange={onDebouncedFilterChange}
                                    onReloadData={onReloadData}
                                    loading={loading}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               style: {
                                   borderWidth: "2px 0",
                                   borderColor: "lightGrey"
                               }
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={count}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default ActDataTable;