import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Badge, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {
    accountBindingDisable,
    accountBindingEnable,
    getAccount,
    selectBindContractor
} from "../../../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import {selectActiveOrganization, selectUsers} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {ACCOUNT_BINDING_SUCCESS} from "../../../../app/eventbus/bindContractorToAccountEvent";
import {toast} from "react-toastify";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCRMContractorUpdate} from "../../../../enum/Permission/CrmPermission";

const BindContractorToAccountDataTable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization);

    const connectingContractorData = useSelector(selectBindContractor)
    const users = useSelector(selectUsers)

    // permissions
    const isContractorEdit = useCheckPermission(PermissionCRMContractorUpdate)

    useEffect(() => {
        dispatch(getAccount())

        const accountBindingSuccess = EventBus.on(ACCOUNT_BINDING_SUCCESS, () => {
            dispatch(getAccount())
        })

        return () => {
            EventBus.remove(ACCOUNT_BINDING_SUCCESS, accountBindingSuccess)
        }

    }, [activeOrganization])


    const changeAccountStatus = (account) => {
        const params = {account_id: account.account_id}
        if (account.is_binding_enabled) {
            accountBindingDisable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))

        } else {
            accountBindingEnable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
        }
    }

    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            headerProps: {className: 'p-3'},
            cellProps: {
                width: '50px',
                className: 'p-3'
            }
        },
        {
            Header: t(lang, "Ф.И.О"),
            cellProps: {width: 300},
            Cell: ({row: {original}}) => {
                const name = users.find(i => i.id === original.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")
                return (
                    <strong>{name}</strong>
                );
            }
        },
        {
            Header: t(lang, "items.common.catalog.status"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center', width: 300},
            Cell: ({row: {original}}) => {
                const status = original.is_binding_enabled ? "crm.bind.contractor.account.unlock.action" : "crm.bind.contractor.account.block.action"
                return (
                    <Badge bg={original.is_binding_enabled ? "success" : "secondary"}
                           className="me-2">{t(lang, status)}</Badge>
                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {isContractorEdit && <OverlayTrigger placement={'top'} overlay={
                            <Tooltip>{t(lang, original.is_binding_enabled ? "crm.bind.contractor.account.change.status.to.active" : "crm.bind.contractor.account.change.status.to.disable")}</Tooltip>}>
                            <Form.Check
                                size={20}
                                checked={original.is_binding_enabled}
                                type='switch'
                                onChange={() => changeAccountStatus(original)}
                            />
                        </OverlayTrigger>}
                    </Fragment>
                );
            }
        }
    ]

    return (
        <Fragment>
            <AdvanceTableWrapper
                perPage={1000}
                columns={columns}
                data={connectingContractorData}
                pagination
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}>
                </AdvanceTable>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default BindContractorToAccountDataTable;
