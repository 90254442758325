import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import classNames from "classnames";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import InputGroupWithCurrency from "../../../price/components/InputGroupWithCurrency";
import numeral from "numeral";
import {ErrorMessage} from "@hookform/error-message";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import PackageTypeItem from "../../components/PackageTypeItem";
import InputGroupWithDiscount from "../../components/InputGroupWithDiscount";


const PurchaseUpdateItemModalForm = () => {
    const numberFormat = useSelector(selectNumberFormat)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate



    // state
    const [priceType, setPriceType] = useState(true)
    const [total, setTotal] = useState(null)
    const {errors} = form.formState



    // watch
    const packageMeasurements = useWatch({name: 'package_measurements', exact: true})
    const commonDiscountUpdate = form.watch('commonDiscountUpdate')
    const commonPriceUpdate = form.watch('commonPriceUpdate')
    const warehouse = form.watch('warehouse')
    const warehouseId = form.watch('warehouse_id');
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})


    const totalSum = +quantity * (+packageMeasurements?.quantity || 0) * +commonPriceUpdate?.amount
    const totalSumCurrencyName = currencies?.find(x => x.id === commonPriceUpdate?.currency_id)?.name



    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }

    const calcTotal = () => {
        if (commonDiscountUpdate?.type) {
            const percentCalc = commonPriceUpdate?.amount * +commonDiscountUpdate?.amount / 100
            setTotal(totalSum - (percentCalc * quantity))
        } else {
            setTotal(totalSum - (quantity * +commonDiscountUpdate?.amount))
        }
    }

    useEffect(() => {
        calcTotal()
    },[quantity, commonPriceUpdate?.amount, commonPriceUpdate?.currency_id, commonDiscountUpdate?.type, commonDiscountUpdate?.amount, packageMeasurements?.quantity])


    return(
        <>
            <Row>
                <Col xs={12} >
                    <Form.Group>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.product_name")}</Form.Label>
                        <Controller
                            name="item_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationItemSelect
                                        options={items}
                                        allowImage={true}
                                        warehouseId={warehouseId}
                                        defaultValue={field?.value}
                                        isClearable
                                        onChange={option => {
                                            field?.onChange(option ? option?.item?.id : null)
                                            onChangeItem(option)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.item_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.item_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                {warehouses?.length > 1 &&
                    <Col xs={12}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse_name")}</Form.Label>
                        <Controller
                            name="warehouse_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        options={warehouse}
                                        defaultValue={field?.value}
                                        onChange={option => {
                                            field?.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.warehouse_id?.message}
                        </Form.Control.Feedback>
                    </Col>
                }
                <Col xs={12} >
                    <Form.Group>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.sum")}</Form.Label>
                        <Controller
                            name='commonPriceUpdate'
                            render={({field}) => (
                                <InputGroupWithCurrency
                                    defaultCurrencyId={commonPriceUpdate?.currency_id}
                                    defaultPrice={commonPriceUpdate?.amount}
                                    onChangePrice={(price) => {
                                        form?.setValue('commonPriceUpdate.amount', price);
                                        form?.trigger('commonPriceUpdate');
                                    }}
                                    onChangeCurrency={(currency) => {
                                        form?.setValue('commonPriceUpdate.currency_id', currency ? currency?.id : null);
                                        form?.trigger('commonPriceUpdate');
                                    }}
                                    inputProps={{
                                        className: classNames({
                                            'is-invalid': errors?.commonPriceUpdate
                                        }, 'form-control'),
                                        placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                        options: {
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 4
                                        }
                                    }}
                                    className={classNames({
                                        'is-invalid': errors?.commonPriceUpdate
                                    })}
                                />
                            )}
                        />
                        {/*<span className="text-info fs--1">
                                    {t(lang, "warehouse.operation.item.common.costPrice")}
                            {numeral.formats[numberFormat].format(13000)}
                        </span>*/}
                        <ErrorMessage name='commonPriceUpdate'
                                      errors={errors}
                                      as={<Form.Control.Feedback type="invalid"/>}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{t(lang, "Скидка")}</Form.Label>
                        <Controller
                            name='commonDiscountUpdate'
                            render={({field}) => (
                                <InputGroupWithDiscount
                                    defaultAmount={commonDiscountUpdate?.amount}
                                    defaultType={commonDiscountUpdate?.type}
                                    onChangeDiscount={(discount) => {
                                        form.setValue(`commonDiscountUpdate.type`, discount)
                                    }}
                                    onChangeAmount={(amount) => {
                                        form.setValue(`commonDiscountUpdate.amount`, amount)
                                    }}
                                    inputProps={{
                                        className: classNames({
                                            'is-invalid': errors?.commonDiscountUpdate
                                        }, 'form-control'),
                                        placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                        options: {
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 4
                                        }
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name='commonDiscountUpdate'
                                      errors={errors}
                                      as={<Form.Control.Feedback type="invalid"/>}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="d-flex flex-column">
                        <Form.Label>{t(lang, "warehouse.operation.item.common.quantity")}</Form.Label>
                        <InputGroup>
                            <div className="d-flex w-100">
                                <Form.Control
                                    type="text"
                                    id="quantity"
                                    {...form.register("quantity", {
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                        pattern: {
                                            value: /^[0-9]*[\.,]?[0-9]*$/,
                                            message: "100 | 1.2 | 1,5"
                                        }
                                    })}
                                    placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                    // isInvalid={errors?.quantity}
                                    className={classNames("form-control w-100")}
                                />
                                <Controller
                                    name="package_measurements"
                                    render={({field}) => {
                                        return (
                                            <PackageTypeItem
                                                itemId={itemId}
                                                defaultValue={field?.value}
                                                onChange={option => {
                                                    field?.onChange(option)
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </div>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.quantity?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div>
                        <Table borderless size="sm" className="fs-1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900 fw-bold w-100">{t(lang, "warehouse.operation.item.common.total_sum")}:</th>
                                <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format((totalSum) || 0)}<span
                                    className="text-info ms-1">{totalSumCurrencyName}</span></td>
                            </tr>
                            {commonDiscountUpdate?.amount > 0 &&
                                <tr>
                                    <th className="text-900 fw-bold w-100">{t(lang, "Скидка на товар 1 шт")}:</th>
                                    {commonDiscountUpdate?.type ?
                                        <td className="fw-semi-bold text-nowrap">{+commonDiscountUpdate?.amount || 0}<span
                                            className="text-info ms-1">%</span></td>
                                        :
                                        <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format(+commonDiscountUpdate?.amount || 0)}<span
                                            className="text-info ms-1">{totalSumCurrencyName}</span></td>
                                    }
                                </tr>
                            }
                            <tr className="border-top-2 border-bottom-0 border-x-0 border">
                                <th className="text-900 fw-bold w-100">{t(lang, "Итого")}:</th>
                                <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format(total > 0 ? total : 0)}<span
                                    className="text-info ms-1">{totalSumCurrencyName}</span></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default PurchaseUpdateItemModalForm;