import React, {useEffect, useState} from "react";
import AuthSimpleLayout from "../../pages/authentication/AuthSimpleLayout";
import {Redirect, Route, Switch,} from "react-router-dom";
import ErrorLayout from "../../pages/error/ErrorLayout";
import SettingsToggle from "../../components/theme-settings-panel/SettingsToggle";
import SettingsPanel from "../../components/theme-settings-panel/SettingsPanel";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization, selectIsAuthorized} from "../store/reducers/auth/authReducer";
import MainLayout from "../../pages/main/MainLayout";
import {
    loadAccountPermissionAsync,
} from "../store/reducers/role/roleReducer";
import Section from "../../components/common/Section";
import {Col, Row, Spinner} from "react-bootstrap";

const AppRoutes = () => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const dispatch = useDispatch();

    const loadPermissions = () => {
        dispatch(loadAccountPermissionAsync())
            .finally(() => setPermissionsLoaded(true));
    };

    useEffect(() => {
        if (isAuthorized) {
            loadPermissions();
        }
    }, [isAuthorized]);

    if (isAuthorized && !permissionsLoaded) {
        return (
            <Section className="py-0">
                <Row className="flex-center min-vh-100 py-6">
                    <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 text-center">
                        <Spinner animation="grow" />
                    </Col>
                </Row>
            </Section>
        );
    }

    return (
        <>
            <Switch>
                <Route path="/errors" component={ErrorLayout}/>
                <Route path="/authentication" component={AuthSimpleLayout}/>

                {!isAuthorized && <Redirect to="/authentication/login"/>}

                {isAuthorized && <Route component={MainLayout}/>}

                <Redirect to="/errors/404"/>
            </Switch>

            {/*<SettingsToggle/>*/}
            {/*<SettingsPanel/>*/}
        </>
    );
}

export default AppRoutes;