import dayjs from "dayjs";
import numeral from "numeral";
import classNames from "classnames";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {Fragment, useState} from 'react';
import IconAlert from "../../../common/IconAlert";
import IconButton from "../../../common/IconButton";
import itemWrapper from "../../item/enum/itemWrapper";
import {Alert, Badge, ButtonGroup, Card, Table} from "react-bootstrap";
import WarehouseOperationAddItem from "../components/WarehouseOperationAddItem";
import ApproveDraftOperationModal from "../components/ApproveDraftOperationModal";
import BindDraftOperationItemModal from "../components/BindDraftOperationItemModal";
import {selectActiveCurrencies} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    approveDraftPurchaseAsync,
    bindDraftPurchaseItemAsync,
    deleteDraftPurchaseAsync
} from "../../../../app/store/reducers/warehouse-operation/draftPurchaseReducer";
import CommonDeleteModal from "../../../common/CommonDeleteModal";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSPurchaseCreate} from "../../../../enum/Permission/WarehouseOperationPermission";

const DraftPurchase = ({draftPurchase}) => {
    const [isApproving, setIsApproving] = useState(false);
    const [isBinding, setIsBinding] = useState(false);
    const currencies = useSelector(selectActiveCurrencies);
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    // permissions
    const isPurchaseCreate = useCheckPermission(PermissionWMSPurchaseCreate)

    const isItemBinded = (item) => {
        if (item.item_info) {
            if (!!item.item_info.item_id)
                return true
        }

        return false;
    };
    const allDraftPurchaseItemsBinded = draftPurchase.items.every(isItemBinded);

    const onItemCreated = (newItem, draftPurchaseItemId) => {
        bindDraftPurchaseItemAsync(draftPurchase.id, {
            draftPurchaseItemId: draftPurchaseItemId,
            itemId: newItem.id,
        })
            .then(() => toast.success(t(lang, 'items.common.toast.success')))
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
    };

    const approveHandler = ({warehouseId}) => {
        setIsApproving(true);

        approveDraftPurchaseAsync(draftPurchase.id, {warehouseId: warehouseId})
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setIsApproving(false))
    };

    const bindHandler = ({operationItemId, itemId}) => {
        setIsBinding(true);

        bindDraftPurchaseItemAsync(draftPurchase.id, {
            draftPurchaseItemId: operationItemId,
            itemId: itemId,
        })
            .then(() => toast.success(t(lang, 'items.common.toast.success')))
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
            .finally(() => setIsBinding(false))
    };

    const deleteHandler = () => {
        deleteDraftPurchaseAsync(draftPurchase.id)
            .then(() => toast.success(t(lang, 'items.common.toast.success')))
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
    };

    return (
        <Fragment>
            {!allDraftPurchaseItemsBinded && <IconAlert variant="danger" className={'border-danger'}>
                <Alert.Heading className="mb-0">{t(lang, 'warehouse-operation.draft_purchase.view.alert.title')}</Alert.Heading>
                <p className="mb-0 fw-medium mb-2">{t(lang, 'warehouse-operation.draft_purchase.view.alert.description')}</p>
            </IconAlert>}
            <Card>
                <Card.Header className={'bg-light d-flex flex-between-center'}>
                    <Card.Title className={'fs-2'}>{t(lang, 'warehouse-operation.draft_purchase.view.title')}</Card.Title>

                    {!draftPurchase.is_approved && (
                        <div>
                            <CommonDeleteModal className={'mx-2'} size={'lg'} onDelete={deleteHandler}/>
                            {isPurchaseCreate && <ApproveDraftOperationModal className={'mx-2'} isProcessing={isApproving}
                                                         isApproveBlocked={!allDraftPurchaseItemsBinded}
                                                         onApproved={approveHandler}/>}
                        </div>
                    )}
                </Card.Header>
                <Card.Header className={'d-flex justify-content-between'}>
                    <div>
                        <div>
                            <p className={'mb-1 d-inline-block'}>{t(lang, 'warehouse-operation.draft_purchase.view.created_at')}:</p>
                            <p className={'mb-1 ms-1 d-inline-block fw-semi-bold text-black'}>
                                {dayjs(draftPurchase.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}
                            </p>
                        </div>
                        <div>
                            <p className={'mb-1 d-inline-block'}>{t(lang, 'warehouse-operation.draft_purchase.view.status')}:</p>
                            <Badge bg={draftPurchase.is_approved ? 'success' : 'secondary'} className={'ms-1'}>
                                {draftPurchase.is_approved
                                    ? t(lang, 'warehouse-operation.draft_purchase.view.status.approved')
                                    : t(lang, 'warehouse-operation.draft_purchase.view.status.not_approved')
                                }
                            </Badge>
                        </div>
                        {draftPurchase.is_approved && <div>
                            <p className={'mb-1 d-inline-block'}>{t(lang, 'warehouse-operation.draft_purchase.view.status.approved_at')}:</p>
                            <p className={'mb-1 ms-1 d-inline-block fw-semi-bold text-black'}>
                                {dayjs(draftPurchase.approved_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}
                            </p>
                        </div>}
                        <div>
                            <p className={'mb-1 d-inline-block'}>{t(lang, 'warehouse-operation.draft_purchase.view.status.number')}:</p>
                            <p className={'mb-1 ms-1 d-inline-block fw-semi-bold text-black'}>{draftPurchase.number}</p>
                        </div>
                        <div>
                            <p className={'mb-1 d-inline-block'}>{t(lang, 'warehouse-operation.draft_purchase.view.status.contractor')}:</p>
                            <p className={'mb-1 ms-1 d-inline-block fw-semi-bold text-black'}>
                                <Link to={`/crm/contractor-card/${draftPurchase.contractor.id}`} className={'text-decoration-none'}>{draftPurchase.contractor.name}</Link>
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className={'fw-normal'}>
                            <span>{t(lang, 'warehouse-operation.draft_purchase.view.status.total_amount')}:</span>
                            {draftPurchase?.net_price?.map((netPrice, index) => (
                                <Fragment key={index}>
                                        <span className={'mx-1 float-end fw-semi-bold'}>
                                            <span>{numeral['formats'][numberFormat].format(netPrice.amount)}</span>
                                            &nbsp;
                                            <span>{itemWrapper.getCurrency(currencies, netPrice.currency.id)?.name}</span>
                                        </span>
                                    <br/>
                                </Fragment>
                            ))}
                        </h4>
                    </div>
                </Card.Header>
                <hr/>
                <Card.Body>
                    <div className={'text-center'}>
                        <h4>{t(lang, 'warehouse-operation.draft_purchase.view.status.items')}</h4>
                    </div>

                    <Table responsive={true}>
                        <thead className={'bg-soft-primary text-black'}>
                        <tr>
                            <th className={'px-1 text-center'}>#</th>
                            <th className={'px-1'}>{t(lang, "warehouse-operation.draft_purchase.view.status.item.sale_item_name")}</th>
                            <th className={'px-1'}>{t(lang, "warehouse-operation.draft_purchase.view.status.item.name")}</th>
                            <th className={'px-1'}>{t(lang, "warehouse-operation.draft_purchase.view.status.item.quantity")}</th>
                            <th className={'px-1'}>{t(lang, "warehouse-operation.draft_purchase.view.status.item.price")}</th>
                            <th className={'px-1'}>{t(lang, "warehouse-operation.draft_purchase.view.status.item.total")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {draftPurchase.items.map((draftItem, index) => (
                            <tr key={draftItem.id} className={classNames({'bg-soft-danger': !isItemBinded(draftItem)})}>
                                <td className={'px-1 text-center'}>{index + 1}</td>
                                <td className={'px-1'}>{draftItem.sale_item.name}</td>
                                <td className={'px-1'}>
                                    {isItemBinded(draftItem) ? draftItem.item_info.name : <span className={'text-danger fw-semi-bold'}>{t(lang, 'Не опознано')}</span>}
                                    {!isItemBinded(draftItem) && (
                                        <ButtonGroup className={'mx-1'}>
                                            <BindDraftOperationItemModal isProcessing={isBinding} operationItemId={draftItem.id} onItemBinded={bindHandler}>
                                                <IconButton icon={'layer-group'} size={'sm'} variant={'primary'}></IconButton>
                                            </BindDraftOperationItemModal>
                                            <WarehouseOperationAddItem onItemCreated={newItem => onItemCreated(newItem, draftItem.id)} defaultName={draftItem.sale_item.name}>
                                                <IconButton icon={'plus'} size={'sm'} variant={'warning'}></IconButton>
                                            </WarehouseOperationAddItem>
                                        </ButtonGroup>
                                    )}
                                </td>
                                <td className={'px-1'}>{draftItem.quantity}</td>
                                <td className={'px-1'}>{draftItem.price?.amount}&nbsp;{itemWrapper.getCurrency(currencies, draftItem.price?.currency?.id)?.name}</td>
                                <td className={'px-1'}>{draftItem.net_price?.amount}&nbsp;{itemWrapper.getCurrency(currencies, draftItem.net_price?.currency?.id)?.name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default DraftPurchase;