import React, {useEffect, useState} from 'react';
import EditItemForm from "../../../components/hippo/item/forms/EdititemForm";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {useParams} from "react-router";
import {loadItemAsync} from "../../../app/store/reducers/item/itemReducer";
import {useHistory} from "react-router-dom";
import EventBus from "../../../app/eventbus/EventBus";
import {EDIT_ITEM_SUCCESS} from "../../../app/eventbus/itemEvents";
import useCheckPermission from "../../../hooks/useCheckPermission";
import {PermissionItemUpdate} from "../../../enum/Permission/WarehouseItemPermission";
import Error403 from "../../../components/errors/Error403";

const EditItem = () => {
    const history = useHistory();
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);

    const [item, setItem] = useState(null);

    // permissions
    const isItemEdit = useCheckPermission(PermissionItemUpdate)

    // load item
    useEffect(() => {
        if (!isItemEdit) return
        loadItemAsync(id)
            .then((item) => {
                setItem(item);
            })
            .catch(() => {
                setItem(null);
            })

        const onItemEditedHandler = EventBus.on(EDIT_ITEM_SUCCESS, (id) => {
            history.replace('/warehouse/items');
        })

        return () => {
            EventBus.remove(EDIT_ITEM_SUCCESS, onItemEditedHandler);
        }
    }, [id, activeOrganization])

    if (!isItemEdit) return <Error403 />

    if (!item)
        return null;

    return (
        <EditItemForm item={item} />
    );
};

export default EditItem;
