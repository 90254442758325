import {configureStore} from '@reduxjs/toolkit';
import counterReducer from './reducers/counter/counterReducer';
import mainReducer from './reducers/main/mainReducer';
import authReducer from "./reducers/auth/authReducer";
import eimzoReducer from "./reducers/eimzo/eimzoReducer";
import contractReducer from "./reducers/contract/contractReducer";
import branchReducer from './reducers/branch/branchReducer';
import roamingReducer from "./reducers/roaming/roamingReducer";
import orderReducer from './reducers/order/orderReducer';
import attorneyReducer from './reducers/attorney/attorneyReducer';
import shipmentReducer from './reducers/shipment/shipmentReducer';
import invoiceReducer from './reducers/invoice/invoiceReducer';
import returnOrderReducer from './reducers/return-order/returnOrderReducer'
import returnShipmentReducer from './reducers/return-shipment/returnShipmentReducer';
import returnInvoiceReducer from './reducers/return-invoice/returnInvoiceReducer';
import productMapReducer from "./reducers/productMap/productMapReducer";
import protocolReducer from "./reducers/protocol/protocolReducer";
import roamingContractReducer from './reducers/roaming/roamingContractReducer';
import roamingEmpowermentReducer from "./reducers/roaming/roamingEmpowermentReducer";
import roamingInvoiceReducer from "./reducers/roaming/roamingInvoiceReducer";
import roamingWaybillReducer from "./reducers/roaming/roamingWaybillReducer";
import roamingVerificationActReducer from './reducers/roaming/roamingVerificationActReducer';
import roamingActReducer from "./reducers/roaming/roamingActReducer";
import roamingReference from "./reducers/roamingReference/roamingReferenceReducer";
import billingReducer from "./reducers/billing/billingReducer";
import roamingFreeFormDocumentReducer from "./reducers/roaming/roamingFreeFormDocumentReducer";
import contractFormationSlice from "./reducers/contract-formation/contractFormationReducer";
import itemReducer from "./reducers/item/itemReducer";
import contractorReducer from "./reducers/contractor/contractorReducer";
import contractorPaymentReducer from "./reducers/contractor/contractorPaymentReducer";
import contractorPayoutReducer from "./reducers/contractor/contractorPayoutReducer";
import contractorOperationReducer from "./reducers/contractor/contractorOperationReducer";
import contractorOrderReducer from "./reducers/contractor/contractorOrderReducer";
import contractorInvoiceReducer from "./reducers/contractor/contractorInvoiceReducer";
import contractorReturnInvoiceReducer from "./reducers/contractor/contractorReturnInvoiceReducer";
import contractorReturnOrderReducer from "./reducers/contractor/contractorReturnOrderReducer";
import contractorReturnShipmentReducer from "./reducers/contractor/contractorReturnShipmentReducer";
import contractorBranchReducer from "./reducers/contractor/contractorBranchReducer";
import contractorShipmentReducer from "./reducers/contractor/contractorShipmentReducer";
import contractorContractReducer from "./reducers/contractor/contractorContractReducer";
import contractorNoteReducer from "./reducers/contractor/contractorNoteReducer";
import excelReducer from "./reducers/excel/excelReducer";
import excelRegistryReducer from "./reducers/excel-registry/excelRegistryReducer";
import settingsReducer from "./reducers/settings/settingsReducer";
import contractorAttorneyReducer from "./reducers/contractor/contractorAttorneyReducer";
import priceReducer from "./reducers/price/priceReducer";
import currencyReducer from "./reducers/currency/currencyReducer";
import warehouseReducer from "./reducers/warehouse/warehouseReducer";
import draftItemReducer from "./reducers/draft-item/draftItemReducer";
import categoryReducer from "./reducers/category/categoryReducer";
import saleReducer from "./reducers/warehouse-operation/saleReducer";
import contractorAgentReducer from "./reducers/contractor/contractorAgentReducer";
import contractorAgentBotReducer from "./reducers/contractor/contractorAgentBotReducer";
import contractorOrderBotReducer from "./reducers/contractor/contractorOrderBotReducer";
import purchaseReducer from "./reducers/warehouse-operation/purchaseReducer";
import refundReducer from "./reducers/warehouse-operation/refundReducer";
import returnPurchaseReducer from "./reducers/warehouse-operation/returnPurchaseReducer";
import revisionReducer from "./reducers/warehouse-operation/revisionReducer";
import writeOffReducer from "./reducers/warehouse-operation/writeOffReducer";
import transferReducer from "./reducers/warehouse-operation/transferReducer";
import reportReducer from "./reducers/report/reportReducer";
import ediSettingsReducer from "./reducers/settings/ediSettingsReducer";
import edoSettingsReducer from "./reducers/settings/edoSettingsReducer";
import desktopAppReducer from "./reducers/desktop-app/desktopAppReducer";
import roleReducer from "./reducers/role/roleReducer";
import kanbanReducer from "./reducers/kanban/kanbanReducer"
import leadReducer from "./reducers/contractor/contractorLeadReducer";
import leadNoteReducer from "./reducers/contractor/leadNoteReducer";
import crmInternalOrderReducer from "./reducers/crm/internal/crmInternalOrderReducer";
import itemHistoryReducer from "./reducers/item-history/itemHistoryReducer";
import contractorLabelReducer from "./reducers/contractor/contractorLabelReducer";
import crmExecutorOrderReducer from "./reducers/crm/order/crmExecutorOrderReducer";
import crmCustomerOrderReducer from "./reducers/crm/order/crmCustomerOrderReducer";
import organizationBindingReducer from "./reducers/organization-binding/organizationBindingReducer";
import telegramNotificationReducer from "./reducers/telegram-notification/telegramNotificationReducer";
import pushUpdateNotificationReducer from "./reducers/push-update-notification/pushUpdateNotificationReducer";
import employeeReducer from "./reducers/employee/employeeReducer";
import contractorActReportReducer from "./reducers/contractor/contractorActReportReducer";
import bindContractorToAccountReducer from "./reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import cashboxReducer from "./reducers/cashbox/CashboxReducer";
import telegramOrderBotReducer from "./reducers/crm/bot/telegramOrderBotReducer";
import telegramOrderBotOrderReducer from "./reducers/crm/bot/telegramOrderBotOrderReducer";
import roamingWaybillV2Reducer from "./reducers/roaming/roamingWaybillV2Reducer";
import draftPurchaseReducer from "./reducers/warehouse-operation/draftPurchaseReducer";
import draftRefundReducer from "./reducers/warehouse-operation/draftRefundReducer";
import draftPaymentReducer from "./reducers/crm/draft-payment/draftPaymentReducer";
import printReducer from "./reducers/print/printReducer";
import operationItemsReportReducer from "./reducers/warehouse-operation/operationItemsReportReducer";

const store = configureStore({
    reducer: {
        main: mainReducer,
        auth: authReducer,
        counter: counterReducer,
        order: orderReducer,
        returnOrder: returnOrderReducer,
        contract: contractReducer,
        branch: branchReducer,
        attorney: attorneyReducer,
        shipment: shipmentReducer,
        returnShipment: returnShipmentReducer,
        invoice: invoiceReducer,
        returnInvoice: returnInvoiceReducer,
        eimzo: eimzoReducer,
        roamingContract: roamingContractReducer,
        roamingVerificationAct: roamingVerificationActReducer,
        roaming: roamingReducer,
        roamingReference: roamingReference,
        productMap: productMapReducer,
        protocol: protocolReducer,
        roamingEmpowerment: roamingEmpowermentReducer,
        roamingInvoice: roamingInvoiceReducer,
        roamingWaybill: roamingWaybillReducer,
        roamingAct: roamingActReducer,
        billing: billingReducer,
        roamingFreeFormDocument: roamingFreeFormDocumentReducer,
        contractFormation: contractFormationSlice,
        item: itemReducer,
        draftItem: draftItemReducer,
        category: categoryReducer,
        contractors: contractorReducer,
        contractorPayment: contractorPaymentReducer,
        contractorPayout: contractorPayoutReducer,
        contractorOperation: contractorOperationReducer,
        contractorOrder: contractorOrderReducer,
        contractorInvoice: contractorInvoiceReducer,
        contractorReturnInvoice: contractorReturnInvoiceReducer,
        contractorReturnOrder: contractorReturnOrderReducer,
        contractorReturnShipment: contractorReturnShipmentReducer,
        contractorAttorney: contractorAttorneyReducer,
        contractorBranch: contractorBranchReducer,
        contractorShipment: contractorShipmentReducer,
        contractorContract: contractorContractReducer,
        contractorNote: contractorNoteReducer,
        contractorAgent: contractorAgentReducer,
        contactorActReport: contractorActReportReducer,
        excel: excelReducer,
        excelRegistry: excelRegistryReducer,
        settings: settingsReducer,
        ediSettings: ediSettingsReducer,
        edoSettings: edoSettingsReducer,
        price: priceReducer,
        currency: currencyReducer,
        sale: saleReducer,
        warehouses: warehouseReducer,
        agentBots: contractorAgentBotReducer,
        orderBots: contractorOrderBotReducer,
        purchase: purchaseReducer,
        refund: refundReducer,
        returnPurchase: returnPurchaseReducer,
        revision: revisionReducer,
        writeOff: writeOffReducer,
        transfer: transferReducer,
        report: reportReducer,
        desktopApp: desktopAppReducer,
        kanban: kanbanReducer,
        role: roleReducer,
        lead: leadReducer,
        leadNote: leadNoteReducer,
        crmInternalOrder: crmInternalOrderReducer,
        itemHistory: itemHistoryReducer,
        contractorLabel: contractorLabelReducer,
        organizationBinding: organizationBindingReducer,
        crmExecutorOrder: crmExecutorOrderReducer,
        crmCustomerOrder: crmCustomerOrderReducer,
        telegramNotification: telegramNotificationReducer,
        pushUpdateNotification: pushUpdateNotificationReducer,
        employee: employeeReducer,
        bindContractorToAccount: bindContractorToAccountReducer,
        cashbox: cashboxReducer,
        telegramOrderBot: telegramOrderBotReducer,
        telegramOrderBotOrder: telegramOrderBotOrderReducer,
        roamingWaybillV2: roamingWaybillV2Reducer,
        draftPurchase: draftPurchaseReducer,
        draftRefund: draftRefundReducer,
        draftPayment: draftPaymentReducer,
        print: printReducer,
        operationItemsReport: operationItemsReportReducer,
    },
});

export default store;
