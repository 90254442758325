import React from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import TelegramOrderBotDataTableFilter from "./TelegramOrderBotDataTableFilter";
import Divider from "../../../../../common/Divider";
import AddTelegramOrderBotModal from "./AddTelegramOrderBotModal";
import BulkDeleteTelegramOrderBots from "./BulkDeleteTelegramOrderBots";
import {PermissionCRMTelegramBotCreate,} from "../../../../../../enum/Permission/CrmPermission";
import useCheckPermission from "../../../../../../hooks/useCheckPermission";

const TelegramOrderBotDataTableHeader = ({
                                             table,
                                             filter,
                                             onFilterChange,
                                             onDebouncedFilterChange,
                                             onReloadItems,
                                             loading
                                         }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const selectedTelegramOrderBots = table.getSelectedRowModel().rows.map(({original}) => original);

    // permissions
    const isTelegramOrderBotDelete = useCheckPermission(PermissionCRMTelegramBotCreate);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <h5 className="mb-2">{t(lang, "items.common.datatable.filter")}</h5>
                <TelegramOrderBotDataTableFilter filter={filter}
                                                 onFilterChange={onFilterChange}
                                                 onDebouncedFilterChange={onDebouncedFilterChange}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex flex-column justify-content-center flex-md-row justify-content-md-end gap-2">
                    <div className="d-grid d-md-inline-block">
                        {!!selectedTelegramOrderBots.length &&
                            <BulkDeleteTelegramOrderBots bots={selectedTelegramOrderBots}/>}
                        {!selectedTelegramOrderBots.length && isTelegramOrderBotDelete && <AddTelegramOrderBotModal/>}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default TelegramOrderBotDataTableHeader;