import React, {cloneElement, Fragment, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_DELETE_FAILED,
    ROAMING_ACT_DRAFT_DELETE_REQUESTED,
    ROAMING_ACT_DRAFT_DELETE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import RoamingService from "../../../../../services/roaming/roamingService";

const DeleteDraftAct = ({id, children}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteDraftAct = async (id) => {
        try {
            setLoading(true);
            EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_REQUESTED, id);
            await RoamingService.deleteActDraft(id);
            EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_SUCCESS, id);
            setLoading(false);
            setShow(false);
        } catch (error) {
            EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_FAILED, id);
            setLoading(false);
        }
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton onClick={handleShow} variant="falcon-danger" icon={faTrash}>
                    {t(lang, "roaming.invoice.delete")}
                </IconButton>
            )}
            {show &&
                <Modal show={true} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>
                            {t(lang, "roaming.invoice.delete_draft_modal_title")}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        {t(lang, "roaming.invoice.delete_draft_modal_body")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "roaming.common.cancel_button")}
                        </Button>
                        <Button variant="danger"
                                disabled={loading}
                                onClick={() => deleteDraftAct(id)}
                        >
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, "roaming.invoice.delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    );
};

export default DeleteDraftAct;