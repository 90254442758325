// SALE
export const PermissionWMSSaleView             = 2000
export const PermissionWMSSaleCreate           = 2001
export const PermissionWMSSaleUpdate           = 2002
export const PermissionWMSSaleDelete           = 2003

// PURCHASE
export const PermissionWMSPurchaseView         = 2100
export const PermissionWMSPurchaseCreate       = 2101
export const PermissionWMSPurchaseUpdate       = 2102
export const PermissionWMSPurchaseDelete       = 2103

// REFUND
export const PermissionWMSRefundView           = 2200
export const PermissionWMSRefundCreate         = 2201
export const PermissionWMSRefundUpdate         = 2202
export const PermissionWMSRefundDelete         = 2203

// RETURN PURCHASE
export const PermissionWMSReturnView           = 2300
export const PermissionWMSReturnCreate         = 2301
export const PermissionWMSReturnUpdate         = 2302
export const PermissionWMSReturnDelete         = 2303

// REVISION
export const PermissionWMSRevisionView         = 2400
export const PermissionWMSRevisionCreate       = 2401
export const PermissionWMSRevisionUpdate       = 2402
export const PermissionWMSRevisionDelete       = 2403

// WRITE OFF
export const PermissionWMSWriteOffView         = 2500
export const PermissionWMSWriteOffCreate       = 2501
export const PermissionWMSWriteOffUpdate       = 2502
export const PermissionWMSWriteOffDelete       = 2503

// TRANSFER
export const PermissionWMSTransferView         = 2600
export const PermissionWMSTransferCreate       = 2601
export const PermissionWMSTransferUpdate       = 2602
export const PermissionWMSTransferDelete       = 2603

// REPORT
export const PermissionWMSOperationItemsReportView= 2700
export const PermissionPeriodReportView           = 5500

export const WarehouseOperationPermissions= [
    PermissionWMSSaleView,
    PermissionWMSSaleCreate,
    PermissionWMSSaleUpdate,
    PermissionWMSSaleDelete,

    PermissionWMSPurchaseView,
    PermissionWMSPurchaseCreate,
    PermissionWMSPurchaseUpdate,
    PermissionWMSPurchaseDelete,

    PermissionWMSRefundView,
    PermissionWMSRefundCreate,
    PermissionWMSRefundUpdate,
    PermissionWMSRefundDelete,

    PermissionWMSReturnView,
    PermissionWMSReturnCreate,
    PermissionWMSReturnUpdate,
    PermissionWMSReturnDelete,

    PermissionWMSRevisionView,
    PermissionWMSRevisionCreate,
    PermissionWMSRevisionUpdate,
    PermissionWMSRevisionDelete,

    PermissionWMSWriteOffView,
    PermissionWMSWriteOffCreate,
    PermissionWMSWriteOffUpdate,
    PermissionWMSWriteOffDelete,

    PermissionWMSTransferView,
    PermissionWMSTransferCreate,
    PermissionWMSTransferUpdate,
    PermissionWMSTransferDelete,

    PermissionWMSOperationItemsReportView,
    PermissionPeriodReportView
]

export const WarehouseOperationDefaultPermission= [
    {
        text: 'warehouse.operation.item.common.sale',
        permissions: [
            {
                text: 'system.permission.sale.view',
                value: PermissionWMSSaleView
            },
            {
                text: 'system.permission.sale.create',
                value: PermissionWMSSaleCreate
            },
            {
                text: 'system.permission.sale.update',
                value: PermissionWMSSaleUpdate
            },
            {
                text: 'system.permission.sale.delete',
                value: PermissionWMSSaleDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.purchase',
        permissions: [
            {
                text: 'system.permission.purchase.view',
                value: PermissionWMSPurchaseView
            },
            {
                text: 'system.permission.purchase.create',
                value: PermissionWMSPurchaseCreate
            },
            {
                text: 'system.permission.purchase.update',
                value: PermissionWMSPurchaseUpdate
            },
            {
                text: 'system.permission.purchase.delete',
                value: PermissionWMSPurchaseDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.refund',
        permissions: [
            {
                text: 'system.permission.refund.view',
                value: PermissionWMSRefundView
            },
            {
                text: 'system.permission.refund.create',
                value: PermissionWMSRefundCreate
            },
            {
                text: 'system.permission.refund.update',
                value: PermissionWMSRefundUpdate
            },
            {
                text: 'system.permission.refund.delete',
                value: PermissionWMSRefundDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.return_purchase',
        permissions: [
            {
                text: 'system.permission.return.view',
                value: PermissionWMSReturnView
            },
            {
                text: 'system.permission.return.create',
                value: PermissionWMSReturnCreate
            },
            {
                text: 'system.permission.return.update',
                value: PermissionWMSReturnUpdate
            },
            {
                text: 'system.permission.return.delete',
                value: PermissionWMSReturnDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.revision',
        permissions: [
            {
                text: 'system.permission.revision.view',
                value: PermissionWMSRevisionView
            },
            {
                text: 'system.permission.revision.create',
                value: PermissionWMSRevisionCreate
            },
            {
                text: 'system.permission.revision.update',
                value: PermissionWMSRevisionUpdate
            },
            {
                text: 'system.permission.revision.delete',
                value: PermissionWMSRevisionDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.write_off',
        permissions: [
            {
                text: 'system.permission.write_off.view',
                value: PermissionWMSWriteOffView
            },
            {
                text: 'system.permission.write_off.create',
                value: PermissionWMSWriteOffCreate
            },
            {
                text: 'system.permission.write_off.update',
                value: PermissionWMSWriteOffUpdate
            },
            {
                text: 'system.permission.write_off.delete',
                value: PermissionWMSWriteOffDelete
            },
        ]
    },
    {
        text: 'warehouse.operation.item.common.transfer',
        permissions: [
            {
                text: 'system.permission.transfer.view',
                value: PermissionWMSTransferView
            },
            {
                text: 'system.permission.transfer.create',
                value: PermissionWMSTransferCreate
            },
            {
                text: 'system.permission.transfer.update',
                value: PermissionWMSTransferUpdate
            },
            {
                text: 'system.permission.transfer.delete',
                value: PermissionWMSTransferDelete
            },
        ]
    },
    {
        text: 'system.permission.operation.report',
        permissions: [
            {
                text: 'warehouse.navbar.routes.warehouse_operation_period_report',
                value: PermissionPeriodReportView
            },
            {
                text: 'warehouse.common.item.title.operation_histories',
                value: PermissionWMSOperationItemsReportView
            },
        ]
    },
]
