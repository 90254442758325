import React, {useCallback, useEffect, useRef, useState} from 'react';
import OrganizationBalanceBindingDatatable from "./OrganizationBalanceBindingDatatable";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    selectOrganizationBalanceBindingColumnVisibility,
    selectOrganizationBalanceBindingPagination,
    selectOrganizationBalanceBindingsFilter,
    updateOrganizationBalanceBindingColumnVisibility,
    updateOrganizationBalanceBindingFilter,
    updateOrganizationBalanceBindingPagination
} from "../../../../../app/store/reducers/billing/billingReducer";
import {debounce} from "underscore";
import BillingService from "../../../../../services/billingService";

const OrganizationBalanceBindings = ({payerOrganizationId}) => {
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectOrganizationBalanceBindingsFilter);
    const pagination = useSelector(selectOrganizationBalanceBindingPagination);
    const columnVisibility = useSelector(selectOrganizationBalanceBindingColumnVisibility);
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);
    const [loading, setLoading] = useState(false);
    const [organizationBalanceBindings, setOrganizationBalanceBindings] = useState([]);
    const [organizationBalanceBindingsCount, setOrganizationBalanceBindingsCount] = useState(0);

    const onFilterChange = (filter) => {
        dispatch(updateOrganizationBalanceBindingFilter(filter));
        dispatch(updateOrganizationBalanceBindingPagination({...pagination, pageIndex: 0}));
        load(filter, {...pagination, pageIndex: 0});
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        dispatch(updateOrganizationBalanceBindingFilter(filter));
        dispatch(updateOrganizationBalanceBindingPagination({...pagination, pageIndex: 0}));
        load(filter, {...pagination, pageIndex: 0});
    }, 300), [pagination.pageSize]);
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        dispatch(updateOrganizationBalanceBindingPagination(nextState));
        load(filters, nextState)
    };
    const onColumnVisibilityChange = (updater) => {
        const nextState = updater(columnVisibility);
        dispatch(updateOrganizationBalanceBindingColumnVisibility(nextState));
    };
    const onRowSelectionChange = (rowSelection) => {
        setRowSelection(rowSelection);
    };
    const load = async (filter, pagination) => {
        try {
            setLoading(true);
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                filterParams["payer_organization_id"] = payerOrganizationId;
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize
            }

            const getOrganizationBalanceBindingsPromise = BillingService.getOrganizationBalanceBindings({...filterParams, ...paginationParams});
            const getOrganizationBalanceBindingsCountPromise = BillingService.getOrganizationBalanceBindingsCount({...filterParams});
            const {data: bindings} = await getOrganizationBalanceBindingsPromise;
            const {data: count} = await getOrganizationBalanceBindingsCountPromise;
            setOrganizationBalanceBindings(bindings);
            setOrganizationBalanceBindingsCount(count);
            setLoading(false);
        } catch (error) {
            setOrganizationBalanceBindings([]);
            setOrganizationBalanceBindingsCount(0);
            setLoading(false);
        }
    };

    useEffect(() => {
        load(filters, pagination);
    }, [activeOrganization, payerOrganizationId]);


    return (
        <OrganizationBalanceBindingDatatable payerOrganizationId={payerOrganizationId}
                                             organizationBalanceBindings={organizationBalanceBindings}
                                             organizationBalanceBindingsCount={organizationBalanceBindingsCount}
                                             loading={loading}
                                             filter={filters}
                                             pagination={pagination}
                                             columnVisibility={columnVisibility}
                                             rowSelection={rowSelection}
                                             lastRowSelected={lastRowSelected}
                                             onReloadData={() => load(filters, pagination)}
                                             onFilterChange={onFilterChange}
                                             onPaginationChange={onPaginationChange}
                                             onDebouncedFilterChange={onDebouncedFilterChange}
                                             onRowSelectionChange={onRowSelectionChange}
                                             onColumnVisibilityChange={onColumnVisibilityChange}
        />
    );
};

export default OrganizationBalanceBindings;