// CASH_BOX
export const PermissionCashBoxView          = 4500
export const PermissionCashBoxCreate        = 4501
export const PermissionCashBoxUpdate        = 4502
export const PermissionCashBoxDelete        = 4503

// CASH_BOX_OPERATION
export const PermissionCashBoxOperationView = 4600

// CASH_BOX_EXPENSE
export const PermissionCashBoxExpenseView   = 4700
export const PermissionCashBoxExpenseCreate = 4701
export const PermissionCashBoxExpenseUpdate = 4702
export const PermissionCashBoxExpenseDelete = 4703

// CASH_BOX_CASH_IN
export const PermissionCashBoxCashInView    = 4800
export const PermissionCashBoxCashInCreate  = 4801
export const PermissionCashBoxCashInUpdate  = 4802
export const PermissionCashBoxCashInDelete  = 4803

// CASH_BOX_CASH_OUT
export const PermissionCashBoxCashOutView   = 4900
export const PermissionCashBoxCashOutCreate = 4901
export const PermissionCashBoxCashOutUpdate = 4902
export const PermissionCashBoxCashOutDelete = 4903

// CASH_BOX_TRANSFER
export const PermissionCashBoxTransferView  = 5000
export const PermissionCashBoxTransferCreate= 5001
export const PermissionCashBoxTransferUpdate= 5002
export const PermissionCashBoxTransferDelete= 5003

export const CashBoxPermissions = [
    PermissionCashBoxView,
    PermissionCashBoxCreate,
    PermissionCashBoxUpdate,
    PermissionCashBoxDelete,

    PermissionCashBoxOperationView,

    PermissionCashBoxExpenseView,
    PermissionCashBoxExpenseCreate,
    PermissionCashBoxExpenseUpdate,
    PermissionCashBoxExpenseDelete,

    PermissionCashBoxCashInView,
    PermissionCashBoxCashInCreate,
    PermissionCashBoxCashInUpdate,
    PermissionCashBoxCashInDelete,

    PermissionCashBoxCashOutView,
    PermissionCashBoxCashOutCreate,
    PermissionCashBoxCashOutUpdate,
    PermissionCashBoxCashOutDelete,

    PermissionCashBoxTransferView,
    PermissionCashBoxTransferCreate,
    PermissionCashBoxTransferUpdate,
    PermissionCashBoxTransferDelete,
]

export const CashBoxDefaultPermission = [
    {
        text: 'cashbox.bind.account.title',
        permissions: [
            {
                text: 'system.permission.cash_box.view',
                value: PermissionCashBoxView
            },
            {
                text: 'system.permission.cash_box.create',
                value: PermissionCashBoxCreate
            },
            {
                text: 'system.permission.cash_box.update',
                value: PermissionCashBoxUpdate
            },
            {
                text: 'system.permission.cash_box.delete',
                value: PermissionCashBoxDelete
            },
        ]
    },
    {
        text: 'cashboxes.cash-in.nav.label',
        permissions: [
            {
                text: 'system.permission.cash_box.cash_in.view',
                value: PermissionCashBoxCashInView
            },
            {
                text: 'system.permission.cash_box.cash_in.create',
                value: PermissionCashBoxCashInCreate
            },
            {
                text: 'system.permission.cash_box.cash_in.update',
                value: PermissionCashBoxCashInUpdate
            },
            {
                text: 'system.permission.cash_box.cash_in.delete',
                value: PermissionCashBoxCashInDelete
            },
        ]
    },
    {
        text: 'cashboxes.cash-out.nav.label',
        permissions: [
            {
                text: 'system.permission.cash_box.cash_out.view',
                value: PermissionCashBoxCashOutView
            },
            {
                text: 'system.permission.cash_box.cash_out.create',
                value: PermissionCashBoxCashOutCreate
            },
            {
                text: 'system.permission.cash_box.cash_out.update',
                value: PermissionCashBoxCashOutUpdate
            },
            {
                text: 'system.permission.cash_box.cash_out.delete',
                value: PermissionCashBoxCashOutDelete
            },
        ]
    },
    {
        text: 'cashboxes.expense.nav.label',
        permissions: [
            {
                text: 'system.permission.cash_box.expense.view',
                value: PermissionCashBoxExpenseView
            },
            {
                text: 'system.permission.cash_box.expense.create',
                value: PermissionCashBoxExpenseCreate
            },
            {
                text: 'system.permission.cash_box.expense.update',
                value: PermissionCashBoxExpenseUpdate
            },
            {
                text: 'system.permission.cash_box.expense.delete',
                value: PermissionCashBoxExpenseDelete
            },
        ]
    },
    {
        text: 'warehouse.dashboard.items.transfer_title',
        permissions: [
            {
                text: 'system.permission.cash_box.transfer.view',
                value: PermissionCashBoxTransferView
            },
            {
                text: 'system.permission.cash_box.transfer.create',
                value: PermissionCashBoxTransferCreate
            },
            {
                text: 'system.permission.cash_box.transfer.update',
                value: PermissionCashBoxTransferUpdate
            },
            {
                text: 'system.permission.cash_box.transfer.delete',
                value: PermissionCashBoxTransferDelete
            },
        ]
    },
    {
        text: 'edi.return_order.protocol_info.title',
        permissions: [
            {
                text: 'system.permission.cash_box.operation.view',
                value: PermissionCashBoxOperationView
            },
        ]
    }
]