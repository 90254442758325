import React, {useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import Charge from "./Charge";
import BillingCards from "./BillingCards";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

export const CHARGES = '1'
export const EXPENSES = '2'
const OtherTab = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [key, setKey] = useState(CHARGES);

    return (
        <Tabs activeKey={key} fill onSelect={key => setKey(key)} className='border-bottom p-3 pb-0'>
            <Tab className="bg-light" eventKey={CHARGES} title={t(lang, 'main.billing.tabs.other.expenses.title')}>
                <Charge tab={key}/>
            </Tab>
            <Tab className="bg-light" eventKey={EXPENSES}
                 title={t(lang, 'main.billing.tabs.other.payment.and.operation.title')}>
                <BillingCards tab={key}/>
            </Tab>
        </Tabs>
    );
};

export default OtherTab;
