import {Badge, Card, Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import numeral from "numeral";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {Fragment, useEffect, useState} from "react";
import SoftBadge from "../../../common/SoftBadge";
import IconButton from "../../../common/IconButton";
import {Link} from "react-router-dom";
import {
    calcDiscount,
    discountCheckReturnLabel,
    isApprovedCheckedWarehouseOperation,
    OperationTotalSum
} from "../enum/warehouseOperationWrapper";
import {
    saleApproveAsync,
    saleChangeExactDiscountsAsync,
    saleChangePercentDiscountAsync,
    salePaymentRegisterAsync,
    salePaymentUpdateAsync,
    unbindTagFromSaleAsync
} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {toast} from "react-toastify";
import WarehouseOperationWithoutPayment from "../components/WarehouseOperationWithoutPayment";
import WarehouseOperationCashBoxCard from "../components/WarehouseOperationCashBoxCard";
import WarehouseOperationDebtStateCard from "../components/WarehouseOperationDebtStateCard";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import WarehouseOperationFooterCurrencyTotalSum from "../components/WarehouseOperationFooterCurrencyTotalSum";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import WarehouseOperationImageModal from "../components/WarehouseOperationImageModal";
import dayjs from "dayjs";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import WOWIthDiscountPayment from "../components/WOWIthDiscountPayment";
import UploadSaleToExcel from "./excel/UploadSaleToExcel";
import PrintSaleDropdown from "../components/PrintSaleDropdown";
import DownloadSaleDropdown from "../components/DownloadSaleDropdown";
import {OrderTypeTelegramOrder} from "../../../../enum/order/order-types";
import {
    loadSaleOrderDifferencesAsync
} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import classNames from "classnames";
import AddSaleTagModal from "./AddSaleTagModal";
import CommonLabelBadge from "../../crm/contractor/label/CommonLabelBadge";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {
    PermissionWMSSaleCreate,
    PermissionWMSSaleUpdate
} from "../../../../enum/Permission/WarehouseOperationPermission";


const Sale = ({sale}) => {
    const [activeTab, setActiveTab] = useState("all")
    const [itemsDiffs, setItemsDiffs] = useState([]);
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const activeOrganization = useSelector(selectActiveOrganization);
    const [dataItems, setDataItems] = useState(sale?.items?.filter(f => !f.is_deleted))
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)

    // permissions
    const isSaleCreate = useCheckPermission(PermissionWMSSaleCreate);
    const isSaleEdit = useCheckPermission(PermissionWMSSaleUpdate);

    useEffect(() => {
        if (sale?.order_info?.id) {
            loadSaleOrderDifferences(sale?.id)
        } else {
            setItemsDiffs([]);
        }
    }, [sale])

    useEffect(() => {
        if (sale?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    }, [currencies])

    useEffect(() => {
        if (activeTab === "all") {
            setDataItems(sale?.items?.filter(f => !f?.is_deleted))
        } else if (activeTab === "deleted") {
            setDataItems(sale?.items?.filter(f => f?.is_deleted))
        }
    }, [activeTab])

    const isTelegramOrderCreated = sale?.order_info?.id && sale?.order_info?.type === OrderTypeTelegramOrder;

    const totalAmounts = sale?.totals?.map(saleTotal => {
        return {amount: saleTotal?.amount, currencyId: saleTotal?.currency?.id};
    });

    const exactDiscounts = sale?.exact_discounts?.map(exactDiscount => {
        return {amount: exactDiscount?.amount, currencyId: exactDiscount?.currency?.id};
    });

    const salePayment = {
        debtStates: sale?.payment?.debt_states?.map(debtState => {
            return {amount: debtState?.amount, currencyId: debtState?.currency?.id};
        }),
        cashBoxStates: sale?.payment?.cash_box_states?.map(cashBoxState => {
            return {amount: cashBoxState?.amount, currencyId: cashBoxState?.currency?.id};
        }),
        note: sale?.payment?.note,
    };

    const loadSaleOrderDifferences = (saleId) => {
        loadSaleOrderDifferencesAsync(saleId)
            .then(({data: loadedDiffs}) => setItemsDiffs(loadedDiffs))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    };

    const onSubmitPay = async (pay) => {
        const areLengthsEqual = pay?.exactDiscounts?.length !== sale?.exact_discounts?.length;

        const areEqual = pay?.exactDiscounts?.every((p, i) =>
            p?.amount !== sale?.exact_discounts[i]?.amount &&
            p?.currency_id === sale?.exact_discounts[i]?.currency?.id
        );


        if (pay?.percentDiscount !== sale?.percent_discount) {
            await saleChangePercentDiscountAsync({saleId: sale?.id, percentDiscount: pay?.percentDiscount})
                .then(() => {
                })
                .catch(() => {
                })
        }
        if (areLengthsEqual || areEqual) {
            await saleChangeExactDiscountsAsync({saleId: sale?.id, exactDiscounts: pay?.exactDiscounts})
                .then(() => {
                })
                .catch(() => {
                })
        }

        if (sale?.payment) {
            await salePaymentUpdateAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!sale?.payment && sale?.is_approved) {
            await salePaymentRegisterAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!sale?.is_approved) {
            await saleApproveAsync({saleId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }

    const unbindTag = (tagId) => {
        unbindTagFromSaleAsync({saleId: sale.id, tagId: tagId})
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_sale")} {sale?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <div>
                                <span className="me-1">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={sale?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(sale?.is_approved))}
                                </SoftBadge>
                            </div>
                            {sale?.cash_box?.name && <div>
                                <span className="me-1">{t(lang, "warehouse.operation.item.common.cash_box")}: </span>
                                <Badge bg={"primary"}>
                                    {sale?.cash_box?.name}
                                </Badge>
                            </div>}
                        </Col>
                        <Col xs={12}>
                            {isSaleEdit && <Fragment>
                                {currencies?.length > 0 && sale?.items?.filter(x => !x.is_deleted).length > 0 &&
                                    // <WarehouseOperationPayment onSubmitPay={onSubmitPay} data={sale}/>
                                    <WOWIthDiscountPayment
                                        data={sale}
                                        isEditing={true}
                                        saleId={sale?.id}
                                        payment={salePayment}
                                        onSubmitPay={onSubmitPay}
                                        totalAmounts={totalAmounts}
                                        exactDiscounts={exactDiscounts}
                                        contractorId={sale?.contractor?.id}
                                        percentDiscount={sale?.percent_discount}
                                    />
                                }
                                {!sale?.is_approved && sale?.items?.filter(x => !x.is_deleted).length > 0 &&
                                    <WarehouseOperationWithoutPayment
                                        onClick={onSubmitPay}
                                        id={sale?.id}
                                        contractorId={sale?.contractor?.id}
                                    />
                                }
                                <Link to={`/warehouse/operation/sales/update/${sale?.id}`}>
                                    <IconButton
                                        variant="falcon-warning"
                                        icon="edit"
                                        title={t(lang, "warehouse.operation.item.common.edit")}
                                        className="ms-3"
                                        size="sm"
                                    >{t(lang, "warehouse.operation.item.common.edit")}</IconButton>
                                </Link>
                            </Fragment>}

                            {/*<Link to={`/warehouse/operation/sales/update-excel/${sale?.id}`}>*/}
                            {/*    <IconButton*/}
                            {/*        variant="falcon-success"*/}
                            {/*        icon="edit"*/}
                            {/*        title={t(lang, "warehouse.operation.item.common.edit")}*/}
                            {/*        className="mx-2"*/}
                            {/*        size="sm"*/}
                            {/*    >{t(lang, "Редактировать с Excel")}</IconButton>*/}
                            {/*</Link>*/}
                            <UploadSaleToExcel className="me-2 d-inline-block" size="sm" id={sale?.id} number={sale?.number}/>
                            {isSaleCreate && <Link to={`/warehouse/operation/sales/register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang, "Новая продажа")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang, "Новая продажа")}</IconButton>
                            </Link>}

                            {activeOrganization?.inn && <Link to={`/roaming/invoice/send?sale_id=${sale?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="plus"
                                    title={t(lang, "edi.common.new_invoice_button")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang, "edi.common.new_invoice_button")}</IconButton>
                            </Link>}

                            {isTelegramOrderCreated && <Link to={`/crm/telegram-order/${sale?.order_info?.id}`}>
                                <IconButton
                                    size={'sm'}
                                    icon={'eye'}
                                    className={'mx-2'}
                                    variant={'falcon-info'}
                                    title={t(lang, "warehouse.operation.item.common.view_order")}
                                >{t(lang, "warehouse.operation.item.common.view_order")}</IconButton>
                            </Link>}

                            <PrintSaleDropdown className="d-inline-block mx-1" saleId={sale?.id}/>
                            <DownloadSaleDropdown className="d-inline-block mx-1" saleId={sale?.id} saleNumber={sale?.number}/>
                            <AddSaleTagModal saleId={sale.id}>
                                <IconButton icon={'plus'} size={'sm'} variant={'info'} className={'d-inline-block mx-1'}>{t(lang, 'Добавить тег')}</IconButton>
                            </AddSaleTagModal>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={12} className="my-3">
                    <Card>
                        <Card.Header className={'d-flex justify-content-between'}>
                            <div>
                                <h4 className="text-info mb-2">{t(lang, "warehouse.operation.item.common.sale")}</h4>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center fw-bold">
                                        <h5 className="me-2 fw-bold">{t(lang, "warehouse.operation.item.common.client_name")}:</h5> <span>{sale?.contractor?.name}</span>
                                    </div>
                                    <div className={"d-flex align-items-start flex-wrap fw-bold"}>
                                        <h5 className="d-flex justify-content-end text-nowrap fw-bold">{t(lang, "warehouse.operation.item.common.real_credit")}:</h5>
                                        <div className="fs-1 d-flex flex-wrap">
                                            {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                                if (item.amount) {
                                                    return (
                                                        <div key={index} className="d-flex text-nowrap justify-content-end mx-2">
                                                            {numeral.formats[numberFormat].format(item.amount)} <span className="text-info ms-1">{item.currency.name}</span>
                                                        </div>
                                                    )
                                                }
                                            }) : '0 сум'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!!sale.tags.length && <div>
                                <h5 className={'d-inline-block'}>{t(lang, 'Теги')}:</h5>

                                {sale.tags.map((saleTag, index) => (
                                    <CommonLabelBadge
                                        key={index}
                                        id={saleTag.id}
                                        cancellable={true}
                                        name={saleTag.name}
                                        textColor={saleTag.text_color}
                                        backgroundColor={saleTag.background_color}
                                        onCancel={(tagId) => unbindTag(tagId)}
                                    />
                                ))}
                            </div>}
                        </Card.Header>
                    </Card>
                </Col>
            </Row>

            {sale?.payment &&
                <>
                    <Row className="mb-3">
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationCashBoxCard cashBox={sale?.payment?.cash_box_states}/>
                        </Col>
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationDebtStateCard debtState={sale?.payment?.debt_states}/>
                        </Col>
                    </Row>
                    {sale?.payment?.notes &&
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-3 p-3">
                                    <Card.Title className="text-center">
                                        {t(lang, "warehouse.operation.item.common.info")}
                                    </Card.Title>
                                    <Card.Text>
                                        {sale?.payment?.notes}
                                    </Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            }

            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            {sale?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{sale?.note}</span></p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {dataItems?.length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>

                                                <Table className="border-bottom">
                                                    <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                                                    <tr>
                                                        <th className="align-middle">№</th>
                                                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                                                        <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                                                        <th className="text-end align-middle">{t(lang, "Скидка")}</th>
                                                        <th className="text-end align-middle">{t(lang, "Цена со скидка")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.total")}</th>
                                                        {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                                                            <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                                                        }
                                                        {warehouses?.length > 1 &&
                                                            <th className="text-end">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                                                        }
                                                        {dataItems?.some(d => d?.is_deleted) &&
                                                            <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                                                        }
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {groupByItemIdAndPrice(dataItems)?.map((item, rowIndex) => {
                                                        return (
                                                            <React.Fragment key={rowIndex}>
                                                                {item?.sub?.map((subItem, subRowIndex) => {
                                                                    return (
                                                                        <tr key={subItem.id}>
                                                                            <td key={rowIndex}
                                                                                className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                                rowSpan={item?.sub?.length}
                                                                                style={{width: "50px"}}
                                                                            >
                                                                                {groupByItemIdAndPrice(dataItems)?.length - rowIndex}
                                                                            </td>
                                                                            <td
                                                                                className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                                rowSpan={item?.sub?.length}>
                                                                                {subItem?.warehouse_item?.name}
                                                                            </td>
                                                                            <td
                                                                                className={subRowIndex === 0 ? "text-center border-end border-2 p-1" : "d-none"}
                                                                                style={{width: "50px"}} rowSpan={item?.sub?.length}>
                                                                                {items.find(x => x?.item?.id === subItem?.warehouse_item?.id)?.item?.images.length > 0 &&
                                                                                    <WarehouseOperationImageModal
                                                                                        image={items.find(x => x?.item?.id === item?.item?.id)?.item?.images}/>
                                                                                }
                                                                            </td>
                                                                            <th className="text-end border-end border-2">
                                                                                {Number.isInteger(subItem?.quantity) ? subItem?.quantity : numeral.formats[numberFormat].format(subItem?.quantity)}
                                                                            </th>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.discount?.value || 0)}
                                                                                <span
                                                                                    className="text-info"> {discountCheckReturnLabel(subItem?.discount?.type) || subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            <td className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(calcDiscount({
                                                                                    type: subItem?.discount?.type || 0,
                                                                                    discountAmount: subItem?.discount?.value || 0,
                                                                                    priceAmount: subItem?.price?.amount,
                                                                                    quantity: subItem?.quantity,
                                                                                }))}
                                                                                <span className="text-info ms-1">{subItem?.price?.currency?.name}</span>
                                                                            </td>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.net_price?.amount)}
                                                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            {groupByItemIdAndPrice(dataItems)[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                                                                <td className="text-center border-end border-2">
                                                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                                                    <span> => </span>
                                                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                                                </td>
                                                                            }
                                                                            {warehouses?.length > 1 &&
                                                                                <th className="text-end border-end border-2">
                                                                                    {subItem?.warehouse_item?.warehouse?.name}
                                                                                </th>
                                                                            }
                                                                            {subItem?.is_deleted &&
                                                                                <>
                                                                                    <th className="text-nowrap text-center border-end border-2 text-danger" style={{width: "150px"}}>
                                                                                        {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                                                    </th>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        }
                                    </Tab>
                                }
                                {sale?.order_info?.id && (
                                    <Tab eventKey="sale-order" tabClassName="fs-1" title={t(lang, 'warehouse-operation.sale.view.tab.order_sale_differences')}>
                                        {(activeTab === 'sale-order' && isTelegramOrderCreated) && (
                                            <div>
                                                <Row>
                                                    <Col className={'py-3'}>
                                                        <span className={'me-3'}>
                                                            <div className={'dot bg-success d-inline-block'}/>
                                                            <span className={''}>{t(lang, 'warehouse-operation.sale.view.compare_with_order.status.added')}</span>
                                                        </span>
                                                        <span className={'me-3'}>
                                                            <div className={'dot bg-warning d-inline-block'}/>
                                                            <span className={''}>{t(lang, 'warehouse-operation.sale.view.compare_with_order.status.changed')}</span>
                                                        </span>
                                                        <span className={'me-3'}>
                                                            <div className={'dot bg-danger d-inline-block'}/>
                                                            <span className={''}>{t(lang, 'warehouse-operation.sale.view.compare_with_order.status.deleted')}</span>
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Table responsive={true} size={'md'} className={'fs--1'}>
                                                    <thead className={'bg-300'}>
                                                    <tr className={'text-900 fs-0'}>
                                                        <th className={'px-2 text-center'}>#</th>
                                                        <th className={'px-1'}>{t(lang, "crm.common.product")}</th>
                                                        <th className={'px-1 text-end'}>{t(lang, "crm.common.quantity")}</th>
                                                        <th className={'px-1 text-end'}>{t(lang, "crm.common.price")}</th>
                                                        <th className={'px-2 text-end'}>{t(lang, "crm.common.total")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {itemsDiffs.map((item, index) => (
                                                        <tr key={index} className={classNames({
                                                            'bg-success text-white': item.is_position_added,
                                                            'bg-danger text-white': item.is_position_deleted,
                                                            'bg-warning text-white': item.is_position_price_changed || item.is_position_quantity_changed,
                                                        })}>
                                                            <td className={'px-2 text-center'}>{index + 1}</td>
                                                            <td className={'px-1 fw-semi-bold'}>{item.order_item.name ? item.order_item.name : item.sale_item.name}</td>
                                                            <td className={'px-1 fw-semi-bold text-end'}>
                                                                {item.is_position_quantity_changed ? (
                                                                    <Fragment>
                                                                        {numeral['formats'][numberFormat].format(item.order_item.quantity)}
                                                                        <span className={'mx-1 fs-1'}>→</span>
                                                                        {numeral['formats'][numberFormat].format(item.sale_item.quantity)}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>{numeral['formats'][numberFormat].format(item.order_item.quantity || item.sale_item.quantity)}</Fragment>
                                                                )}
                                                            </td>
                                                            <td className={'px-1 fw-semi-bold text-end'}>
                                                                {item.is_position_price_changed ? (
                                                                    <Fragment>
                                                                        {numeral['formats'][numberFormat].format(item.order_item.price.amount)}&nbsp;{item.order_item.price.currency.name}
                                                                        <span className={'mx-1 fs-1'}>→</span>
                                                                        {numeral['formats'][numberFormat].format(item.sale_item.price.amount)}&nbsp;{item.sale_item.price.currency.name}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {numeral['formats'][numberFormat].format(item.order_item.price.amount || item.sale_item.price.amount)}
                                                                        &nbsp;
                                                                        {item.order_item.price.currency.name || item.sale_item.price.currency.name}
                                                                    </Fragment>
                                                                )}
                                                            </td>
                                                            <td className={'px-2 fw-semi-bold text-end'}>
                                                                {item.is_position_price_changed || item.is_position_quantity_changed ? (
                                                                    <Fragment>
                                                                        {numeral['formats'][numberFormat].format(item.order_item.net_price.amount)}&nbsp;{item.order_item.net_price.currency.name}
                                                                        <span className={'mx-1 fs-1'}>→</span>
                                                                        {numeral['formats'][numberFormat].format(item.sale_item.net_price.amount)}&nbsp;{item.sale_item.net_price.currency.name}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {numeral['formats'][numberFormat].format(item.order_item.net_price.amount || item.sale_item.net_price.amount)}
                                                                        &nbsp;
                                                                        {item.order_item.price.currency.name || item.sale_item.net_price.currency.name}
                                                                    </Fragment>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        )}
                                    </Tab>
                                )}
                                {sale?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>

                                                <Table className="border-bottom">
                                                    <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                                                    <tr>
                                                        <th className="align-middle">№</th>
                                                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                                                        <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                                                        <th className="text-end align-middle">{t(lang, "Скидка")}</th>
                                                        <th className="text-end align-middle">{t(lang, "Цена со скидка")}</th>
                                                        <th className="text-end">{t(lang, "warehouse.operation.item.common.total")}</th>
                                                        {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                                                            <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                                                        }
                                                        {warehouses?.length > 1 &&
                                                            <th className="text-end">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                                                        }
                                                        {dataItems?.some(d => d?.is_deleted) &&
                                                            <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                                                        }
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {groupByItemIdAndPrice(dataItems)?.map((item, rowIndex) => {
                                                        return (
                                                            <React.Fragment key={rowIndex}>
                                                                {item?.sub?.map((subItem, subRowIndex) => {
                                                                    return (
                                                                        <tr key={subItem.id}>
                                                                            <td key={rowIndex}
                                                                                className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                                rowSpan={item?.sub?.length}
                                                                                style={{width: "50px"}}
                                                                            >
                                                                                {groupByItemIdAndPrice(dataItems)?.length - rowIndex}
                                                                            </td>
                                                                            <td
                                                                                className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                                rowSpan={item?.sub?.length}>
                                                                                {subItem?.warehouse_item?.name}
                                                                            </td>
                                                                            <td
                                                                                className={subRowIndex === 0 ? "text-center border-end border-2 p-1" : "d-none"}
                                                                                style={{width: "50px"}} rowSpan={item?.sub?.length}>
                                                                                {items.find(x => x?.item?.id === subItem?.warehouse_item?.id)?.item?.images.length > 0 &&
                                                                                    <WarehouseOperationImageModal
                                                                                        image={items.find(x => x?.item?.id === item?.item?.id)?.item?.images}/>
                                                                                }
                                                                            </td>
                                                                            <th className="text-end border-end border-2">
                                                                                {subItem?.quantity}
                                                                            </th>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.discount?.value || 0)}
                                                                                <span
                                                                                    className="text-info"> {discountCheckReturnLabel(subItem?.discount?.type) || subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            <td className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(calcDiscount({
                                                                                    type: subItem?.discount?.type || 0,
                                                                                    discountAmount: subItem?.discount?.value || 0,
                                                                                    priceAmount: subItem?.price?.amount,
                                                                                    quantity: subItem?.quantity,
                                                                                }))}
                                                                                <span className="text-info ms-1">{subItem?.price?.currency?.name}</span>
                                                                            </td>
                                                                            <th className="text-end border-end border-2 text-nowrap">
                                                                                {numeral.formats[numberFormat].format(subItem?.net_price?.amount)}
                                                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                            </th>
                                                                            {groupByItemIdAndPrice(dataItems)[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                                                                <td className="text-center border-end border-2">
                                                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                                                    <span> => </span>
                                                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                                                </td>
                                                                            }
                                                                            {warehouses?.length > 1 &&
                                                                                <th className="text-end border-end border-2">
                                                                                    {subItem?.warehouse_item?.warehouse?.name}
                                                                                </th>
                                                                            }
                                                                            {subItem?.is_deleted &&
                                                                                <>
                                                                                    <th className="text-nowrap text-center border-end border-2 text-danger" style={{width: "150px"}}>
                                                                                        {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                                                    </th>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        }
                                    </Tab>
                                }
                            </Tabs>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                {activeTab === "all" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPrice={sale?.net_price}
                                        pay={sale?.payment}
                                        exactDiscounts={sale?.exact_discounts}
                                        percentDiscount={sale?.percent_discount}
                                    />
                                }
                                {activeTab === "deleted" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"deleted"}
                                        netPrice={OperationTotalSum(sale?.items?.filter(f => f?.is_deleted))}
                                        pay={sale?.payment}
                                    />
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default Sale;
