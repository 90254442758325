import React, {useState} from 'react';
import {Alert, Col, Nav, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import Divider from "../../../../common/Divider";
import ActDataTableFilter from "./ActDataTableFilter";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/IconButton";
import BulkDeleteDraftActs from "../Button/BulkDeleteDraftActs";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../View/TypeView";
import {ActSend, GetStatusText, GetStatusTexts, GetStatusVariant} from "../View/ActStatus";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActCreate} from "../../../../../enum/Permission/RoamingPermission";
import {Link} from "react-router-dom";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import BulkSendDraftActs from "../Button/BulkSendDraftActs";
import SendAllDraftActs from "../Button/SendAllDraftActs";
import CancelAllActs from "../Button/CancelAllActs";
import AcceptAllActs from "../Button/AcceptAllActs";
import DeclineAllActs from "../Button/DeclineAllActs";
import BulkCancelActs from "../Button/BulkCancelActs";
import BulkAcceptActs from "../Button/BulkAcceptActs";
import BulkDeclineActs from "../Button/BulkDeclineActs";

const ActDataTableHeader = ({
                                table,
                                viewer,
                                activeCertificate,
                                filter,
                                onFilterChange,
                                onDebouncedFilterChange,
                                onReloadData,
                                loading
                            }) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = table.getRowCount();
    const statuses = GetStatusTexts(viewer);
    const [activeKey, setActiveKey] = useState(filter.status || 0);
    const rows = table.getRowModel().rows.map(({original}) => original);
    const pageSelectedItems = table.getSelectedRowModel().rows.map(({original}) => original);
    const total = !!pageSelectedItems.length ? pageSelectedItems.reduce((acc, row) => acc + row?.total_info?.total_with_nds || 0, 0) : rows.reduce((acc, row) => acc + row?.total_info?.total_with_nds || 0, 0);
    const canBulkDeleteDrafts = viewer === VIEW_DRAFT;
    const canBulkSendDrafts = viewer === VIEW_DRAFT;
    const canFilterStatus = viewer !== VIEW_DRAFT;
    const canBulkCancelSendDocuments = viewer === VIEW_OUTBOX && filter.status === ActSend;
    const canBulkDeclineSendDocuments = viewer === VIEW_INBOX && filter.status === ActSend;
    const canBulkAcceptSendDocuments = viewer === VIEW_INBOX && filter.status === ActSend;

    const onChangeStatus = (status) => {
        setActiveKey(status.id);
        onFilterChange({...filter, status: status.id === 0 ? null : status.id});
    };

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <ActDataTableFilter filters={filter}
                                    loading={loading}
                                    onFilterChange={onFilterChange}
                                    onDebouncedFilterChange={onDebouncedFilterChange}
                                    viewer={viewer}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-column">
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.verification_act.add.verification_act.contracts.title")}</h5>
                        <small className="mb-1">{t(lang, 'roaming.invoices.datatable.invoices_count')}: <span
                            className="fw-semi-bold text-black mx-1 fs--1">{count}</span></small>
                    </div>
                    {pageSelectedItems.length === 0 &&
                        <div className="d-flex gap-2">
                            <div>
                                <IconButton size="sm"
                                            icon={faRefresh}
                                            variant="falcon-warning"
                                            onClick={onReloadData}
                                            disabled={loading}
                                >
                                    {t(lang, 'crm.telegram_orders.datatable.reload_telegram_orders')}
                                    {loading && <Spinner className='align-middle ms-2' animation='border' role='switch'
                                                         size='sm'/>}
                                </IconButton>
                            </div>
                            {canBulkSendDrafts &&
                                <div>
                                    <SendAllDraftActs viewer={viewer}
                                                      count={count}
                                                      filters={filter}
                                                      activeCertificate={activeCertificate}

                                    />
                                </div>
                            }
                            {canBulkCancelSendDocuments &&
                                <div>
                                    <CancelAllActs viewer={viewer}
                                                   count={count}
                                                   filters={filter}
                                                   activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            {canBulkAcceptSendDocuments &&
                                <div>
                                    <AcceptAllActs viewer={viewer}
                                                   count={count}
                                                   filters={filter}
                                                   activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            {canBulkDeclineSendDocuments &&
                                <div>
                                    <DeclineAllActs viewer={viewer}
                                                    count={count}
                                                    filters={filter}
                                                    activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            <div>
                                <IconButton size="sm"
                                            icon="plus"
                                            variant="falcon-default"
                                            transform="shrink-3"
                                            as={dispatch(checkPermission(PermissionRoamingActCreate)) ? Link : 'div'}
                                            onClick={() => !dispatch(checkPermission(PermissionRoamingActCreate)) && dispatch(toastPermission())}
                                            to="/roaming/act/register"
                                >
                                    {t(lang, "edi.common.button.create")}
                                </IconButton>
                            </div>
                        </div>
                    }
                    {pageSelectedItems.length > 0 &&
                        <div className="d-flex gap-2">
                            {canBulkDeleteDrafts &&
                                <div>
                                    <BulkDeleteDraftActs draftActs={pageSelectedItems}/>
                                </div>
                            }
                            {canBulkSendDrafts &&
                                <div>
                                    <BulkSendDraftActs draftActs={pageSelectedItems}
                                                       activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            {canBulkCancelSendDocuments &&
                                <div>
                                    <BulkCancelActs acts={pageSelectedItems}
                                                    activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            {canBulkAcceptSendDocuments &&
                                <div>
                                    <BulkAcceptActs acts={pageSelectedItems}
                                                    activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                            {canBulkDeclineSendDocuments &&
                                <div>
                                    <BulkDeclineActs acts={pageSelectedItems}
                                                     activeCertificate={activeCertificate}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </Col>
            {/*<Col xs={12}>*/}
            {/*    <div className="d-flex align-items-center justify-content-start">*/}
            {/*        <h6 className={'mb-0'}>{t(lang, "roaming.invoices.datatable.invoices_total_sum")}: </h6>*/}
            {/*        <OverlayTrigger*/}
            {/*            overlay={<Tooltip>{t(lang, "roaming.invoices.datatable.invoices_total_sum")}</Tooltip>}*/}
            {/*            placement={'top'}>*/}
            {/*            <Alert className={'d-inline-block px-2 py-0 mb-0 ms-1 fs--1'} variant="dark">*/}
            {/*                <strong>{total}</strong>*/}
            {/*            </Alert>*/}
            {/*        </OverlayTrigger>*/}
            {/*    </div>*/}
            {/*</Col>*/}
            {canFilterStatus &&
                <Col xs={12} className="my-4">
                    <Nav justify
                         variant="tabs"
                         activeKey={activeKey}
                    >
                        {statuses.map((status) => (
                            <Nav.Item key={status.id}
                                      onClick={() => onChangeStatus(status)}
                            >
                                <Nav.Link eventKey={status.id}>
                                    <span
                                        className={`text-${GetStatusVariant(viewer, status.id)}`}>{t(lang, GetStatusText(viewer, status.id))}</span>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
            }
        </Row>
    );
};

export default ActDataTableHeader;