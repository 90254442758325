import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useForm} from "react-hook-form";
import React, {Fragment, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ADD_ORGANIZATION_BALANCE_BINDING_FAILED,
    ADD_ORGANIZATION_BALANCE_BINDING_REQUEST,
    ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED
} from "../../../../../app/eventbus/billingEvents";
import BillingService from "../../../../../services/billingService";

const AddOrganizationBalanceBindingModal = ({payerOrganizationId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const form = useForm({
        defaultValues: {
            organizationCode: null,
        },
    })

    const handleClose = () => {
        form.reset();
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const onSubmit = async (formData) => {
        try {
            const payload = {
                payer_organization_id: payerOrganizationId,
                target_organization_code: formData.organizationCode.replace(/\D/g, '')
            };
            EventBus.dispatch(ADD_ORGANIZATION_BALANCE_BINDING_REQUEST, payload);
            setIsLoading(true);
            const {data} = await BillingService.addOrganizationBalanceBinding(payload);
            EventBus.dispatch(ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED, data);
            setIsLoading(false);
            toast.success(t(lang, 'common.toast.success'));
            handleClose();
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.payer_organization_not_found) {
                form.setError('organizationCode', {
                    type: 'server',
                    message: t(lang, "main.billing.balance_bindings.add_balance_binding_error.payer_organization_not_found")
                });
                toast.error(t(lang, 'main.billing.balance_bindings.add_balance_binding_error.payer_organization_not_found'));
            } else if (error?.response?.data?.target_organization_not_found) {
                form.setError('organizationCode', {
                    type: 'server',
                    message: t(lang, "main.billing.balance_bindings.add_balance_binding_error.target_organization_not_found")
                });
                toast.error(t(lang, 'main.billing.balance_bindings.add_balance_binding_error.target_organization_not_found'));
            } else if (error?.response?.data?.binding_already_exists) {
                form.setError('organizationCode', {
                    type: 'server',
                    message: t(lang, "main.billing.balance_bindings.add_balance_binding_error.binding_already_exists")
                });
                toast.error(t(lang, 'main.billing.balance_bindings.add_balance_binding_error.binding_already_exists'));
            } else {
                toast.error(t(lang, 'common.toast.error'));
            }
            EventBus.dispatch(ADD_ORGANIZATION_BALANCE_BINDING_FAILED, error);
        }
    };

    return (
        <Fragment>
            <IconButton icon={faPlus} onClick={handleShow} size={'sm'}>{t(lang, 'items.common.add')}</IconButton>
            {show &&
                <Modal show={true}
                       onHide={handleClose}
                >
                    <FormProvider {...form}>
                        <Form onSubmit={form.handleSubmit(onSubmit)}>
                            <Modal.Header closeButton={true}>
                                <Modal.Title>{t(lang, 'Привязка баланса')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name="organizationCode"
                                            rules={{
                                                required: t(lang, 'main.validation.is_required')
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Group>
                                                    <Form.Label>{t(lang, 'partners.common.organization_code')}</Form.Label>
                                                    <Form.Control name="organizationCode"
                                                                  isInvalid={!!error}
                                                                  placeholder={t(lang, "partners.common.organization_code.placeholder")}
                                                                  value={field.value}
                                                                  onChange={field.onChange}
                                                    />
                                                    {!!error && <Form.Control.Feedback
                                                        type="invalid">{error?.message}</Form.Control.Feedback>}
                                                </Form.Group>
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                        onClick={handleClose}
                                >
                                    {t(lang, 'items.common.close')}
                                </Button>
                                <Button variant="primary"
                                        onClick={form.handleSubmit(onSubmit)}
                                        disabled={isLoading}
                                >
                                    {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                    <span>{t(lang, 'items.common.add')}</span>
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </Fragment>
    );
};

export default AddOrganizationBalanceBindingModal;