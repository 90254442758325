import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {
    loadItemsAsync,
    selectLoading,
    setLoading,
    updateCategories,
    updateCategoriesTree,
    updateItemsDictionary
} from "../../../../app/store/reducers/item/itemReducer";
import {Spinner} from "react-bootstrap";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionItemView} from "../../../../enum/Permission/WarehouseItemPermission";

const ReloadItems = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const loading = useSelector(selectLoading);

    // permissions
    const isItemView = useCheckPermission(PermissionItemView)


    const onReloadDatatable = () => {
        if (!isItemView) return
        setLoading(true);
        dispatch(setLoading(true));
        loadItemsAsync()
            .then(({categories, categoriesTree, data}) => {
                dispatch(updateItemsDictionary(data));
                dispatch(updateCategories(categories));
                dispatch(updateCategoriesTree(categoriesTree));
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(updateItemsDictionary([]));
                dispatch(updateCategories([]));
                dispatch(updateCategoriesTree([]));
                dispatch(setLoading(false));
            })
    }

    return (
        <IconButton icon={faRefresh}
                    variant={'falcon-warning'}
                    size={'sm'}
                    onClick={onReloadDatatable}
                    disabled={loading}
        >
            {t(lang, 'items.common.reload')}
            {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
        </IconButton>
    );
};

export default ReloadItems;