import React, {useEffect, useState} from 'react';
import AddItemForm from "../../../components/hippo/item/forms/AddItemForm";
import EventBus from "../../../app/eventbus/EventBus";
import {ADD_ITEM_SUCCESS} from "../../../app/eventbus/itemEvents";
import {useHistory} from "react-router-dom";
import useQuery from "../../../components/hippo/roaming/invoice/form/useQuery";
import {loadItemAsync} from "../../../app/store/reducers/item/itemReducer";
import SpinnerComponent from "../../../components/hippo/spinner/SpinnerComponent";
import {PermissionItemCreate} from "../../../enum/Permission/WarehouseItemPermission";
import useCheckPermission from "../../../hooks/useCheckPermission";
import Error403 from "../../../components/errors/Error403";

const AddItem = () => {
    const history = useHistory();

    const {item_id} = useQuery();
    const [item, setItem] = useState(null);

    // permissions
    const isItemCreate = useCheckPermission(PermissionItemCreate)

    useEffect(() => {
        const onItemAddedHandler = EventBus.on(ADD_ITEM_SUCCESS, (id) => {
            history.replace('/warehouse/items');
        })

        return () => {
            EventBus.remove(ADD_ITEM_SUCCESS, onItemAddedHandler);
        }
    }, [])

    useEffect(() => {
        item_id && loadItem();
    }, [item_id]);

    const loadItem = () => {
        if (!isItemCreate) return
        loadItemAsync(item_id)
            .then((item) => setItem(item))
            .catch(() => setItem(null));
    }

    if (!isItemCreate) return <Error403 />

    if (item_id && !item) {
        return <SpinnerComponent />
    }

    return <AddItemForm item={item} />
};

export default AddItem;
