import React from 'react';
import CashOut from "../../../../components/hippo/cashbox/cash-out/CashOut";
import {getCashOutAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxCashOutView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const ViewCashOut = ({viewId}) => {

    // permissions
    const isCashBoxCashOutView = useCheckPermission(PermissionCashBoxCashOutView)

    if (!isCashBoxCashOutView) return <Error403 />

    return (
        <CashOut
            request={getCashOutAsync}
            title={"cashboxes.cash-out.nav.label"}
            id={viewId}
        />
    );
};

export default ViewCashOut;
