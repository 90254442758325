import React from 'react';
import CashIn from "../../../../components/hippo/cashbox/cash-in/CashIn";
import {getCashInAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxCashInView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const ViewCashIn = ({viewId}) => {

    // permissions
    const isCashBoxCashInsView = useCheckPermission(PermissionCashBoxCashInView);

    if (!isCashBoxCashInsView) return <Error403 />

    return (
        <CashIn
            request={getCashInAsync}
            title={"cashboxes.cash-in.nav.label"}
            id={viewId}
        />
    );
};

export default ViewCashIn;
