import React, {useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ResultCounter from "../../../item/forms/bulk-item/ResultCounter";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import IconButton from "../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../View/TypeView";
import roamingService from "../../../../../services/roaming/roamingService";
import {ActFormSendAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_DRAFT_BULK_SEND_REQUESTED,
    ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import {VIEWER_DRAFT} from "../../../../../enum/EmpowermentStatus";

const SendAllDraftActs = ({viewer, count, filters, activeCertificate}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);

    const sendAllDraftActs = async (viewer, count, filter, activeCertificate) => {
        let drafts = [];

        try {
            if (!window.confirm(t(lang, 'roaming.invoice.send_button_all_confirmation_text')))
                return;

            setLoading(true);
            ref.current.updateLoadedCount(count);
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_SEND_REQUESTED, drafts);

            const taskSize = 10;
            const tasks = []

            // load all drafts partly
            {
                let page = 0;
                let limit = 1000;
                let filterParams = {};
                let paginationParams = {};

                // build filters
                {
                    if (viewer !== VIEWER_DRAFT && filter.status !== null)
                        filterParams["status"] = filter.status;

                    if (filter.registryId)
                        filterParams["registry_id"] = filter.registryId;
                }

                // load acts
                {
                    while (true) {
                        if (drafts.length === count)
                            break;

                        // build pagination
                        {
                            paginationParams["limit"] = limit;
                            paginationParams["skip"] = page * limit;
                        }

                        switch (viewer) {
                            case VIEW_INBOX:
                                const getCustomerActsPromise = roamingService.getCustomerAct({...filterParams, ...paginationParams});
                                const {data: customerActs} = await getCustomerActsPromise;
                                drafts = [...drafts, ...customerActs];
                                break;
                            case VIEW_OUTBOX:
                                const getExecutorActsPromise = roamingService.getExecutorAct({...filterParams, ...paginationParams});
                                const {data: executorActs} = await getExecutorActsPromise;
                                drafts = [...drafts, ...executorActs];
                                break;
                            case VIEW_DRAFT:
                                const getExecutorDraftActsPromise = roamingService.getExecutorActDraft({...filterParams, ...paginationParams});
                                const {data: executorDraftActs} = await getExecutorDraftActsPromise;
                                drafts = [...drafts, ...executorDraftActs];
                                break;
                            default:
                                break
                        }
                        page++
                    }
                }
            }

            // sign
            {
                const signer = async (draft) => {
                    try {
                        const {data: act} = await ActFormSendAsync(activeCertificate, lang, draft.content);
                        ref.current.incrementSucceedCount();
                    } catch (error) {
                        ref.current.incrementFailedCount();
                    }
                }

                for (let i = 0; i < drafts.length; i++) {
                    tasks.push(signer(drafts[i]))

                    if (tasks.length >= taskSize) {
                        await Promise.all(tasks)
                        tasks.splice(0, tasks.length)
                    }
                }

                if (tasks.length > 0) {
                    await Promise.all(tasks)
                    tasks.splice(0, tasks.length)
                }
            }

            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS, drafts);
        } catch (error) {
            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_DRAFT_BULK_SEND_SUCCESS, drafts);
        }
    };

    return (
        <React.Fragment>
            <ResultCounter ref={ref}/>
            <SelectCertificateWrapperComponent openDialogButtonText={t(lang, "roaming.invoice.send_button_all")}
                                               submitButton={
                                                   <IconButton icon={faSignature}
                                                               variant="falcon-primary"
                                                               id="Send"
                                                               size="sm"
                                                               disabled={loading}
                                                               onClick={() => sendAllDraftActs(viewer, count, filters, activeCertificate)}
                                                   >
                                                       {loading &&
                                                           <Spinner size="sm" className='align-middle me-2'
                                                                    animation='border' role='switch'/>}
                                                       {t(lang, "roaming.invoice.send_button_all")}
                                                   </IconButton>
                                               }
            />
        </React.Fragment>
    );
};

export default SendAllDraftActs;