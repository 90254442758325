import { useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { useFormContext, Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer"
import { loadBankInfo } from "../../../../../app/store/reducers/roaming/roamingReducer"
import IconButton from "../../../../common/IconButton"
import FindCompany from "../../../organization/FindCompany"
import FindPerson from "../../../person/FindPerson";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";


const ClientInfo = ({index, addFieldClient, removeFieldClient}) => {
    const { register, getValues, setValue, formState: { errors: clientError }, control } = useFormContext()
    const [companyDetails, setCompanyDetails] = useState({})
    const [bankInfo, setBankInfo] = useState({})
    const errors = clientError.clients || []
    const lang = useSelector(selectLang)
    const t = Translate


    const onCompanyChange = async company => {
        setCompanyDetails(company)
        setValue(`clients.${index}.name`, company?.name)
        setValue(`clients.${index}.fizTin`, (company?.director_pinfl || company?.director_inn) || company?.person_num)
        setValue(`clients.${index}.fio`, company?.director_pinfl ? company?.director : company?.name)
        setValue(`clients.${index}.address`, company?.address || "")
        setValue(`clients.${index}.bankId`, company?.mfo || "")
        setValue(`clients.${index}.account`, company?.account || "")



        if (company?.mfo)
            setBankInfo(await loadBankInfo(company.mfo, lang))
        else
            setBankInfo({})
    }

    const onCompanyDirectorChange = (director) => {
        if(director) {
            setValue(`clients.${index}.fizTin`, director.pinfl || director.inn)
            setValue(`clients.${index}.fio`,  director.name)
        } else {
            setValue(`clients.${index}.fizTin`, '')
            setValue(`clients.${index}.fio`, '')
        }
    }


    return (
        <Card>
            <Card.Header className={'pb-0'}>
                <Row>
                    <Col><h5 className="mb-2">{t(lang, "roaming.contracts.add.clients_info.title")}</h5></Col>
                    <Col className="text-end">
                        <IconButton
                            onClick={addFieldClient}
                            variant="falcon-primary"
                            icon="plus"
                            size="sm"
                            className="ms-2"
                        />
                        <IconButton
                            onClick={() => removeFieldClient(index)}
                            disabled={getValues('clients').length <= 1}
                            variant="falcon-danger"
                            icon="xmark"
                            size="sm"
                            className="ms-2"
                        />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className={'pt-0'}>
                <Row>
                    {companyDetails?.branch &&
                        <Col sm="12" className="mb-3">
                            <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.branch")}</Form.Label>
                            <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.branch")} />
                        </Col>
                    }
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.name")}</Form.Label>
                        <Form.Control
                            {...register(`clients.${index}.name`, {
                                // required: "edi.common.forms.validations.is_required"
                            })}
                            className={errors[index]?.name && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.name")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors[index]?.name?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name={`clients.${index}.tin`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindCompanySearchable
                                    onChange={(company) => {
                                        field.onChange(company?.person_num || company?.inn)
                                        onCompanyChange(company)
                                    }}
                                    defaultInn={field.value}
                                    inputProps={{
                                        isInvalid: errors[index]?.tin,
                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                    }}
                                    inputGroupProps={{
                                        className: classNames({
                                            'is-invalid': errors[index]?.tin,
                                        })
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors[index]?.tin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.full_name_person")}</Form.Label>
                        <Form.Control
                            {...register(`clients.${index}.fio`, {
                                required: "",
                            })}
                            className={errors[index]?.fio && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.full_name_person")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors[index]?.fio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")}</Form.Label>
                        <Controller
                            name={`clients.${index}.fizTin`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindPerson
                                    onChange={onCompanyDirectorChange}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors[index]?.fizTin && 'is-invalid',
                                        placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")
                                    }}
                                    inputGroupProps={{
                                        className: errors[index]?.fizTin && 'is-invalid'
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors[index]?.fizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.work_phone_number")}</Form.Label>
                        <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.work_phone_number")} />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mobile_number")}</Form.Label>
                        <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.mobile_number")} />
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.address")}</Form.Label>
                        <Form.Control {...register(`clients.${index}.address`)} placeholder={t(lang, "roaming.contracts.add.clients_info.address")} />
                    </Col>
                    <Col sm="4" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_account")}</Form.Label>
                        <Form.Control {...register(`clients.${index}.account`)} placeholder={t(lang, "roaming.contracts.add.clients_info.bank_account")} />
                    </Col>
                    <Col sm="5" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_address")}</Form.Label>
                        <Form.Control defaultValue={bankInfo?.name} placeholder={t(lang, "roaming.contracts.add.clients_info.bank_address")} />
                    </Col>
                    <Col sm="3" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mfo")}</Form.Label>
                        <Form.Control {...register(`clients.${index}.bankId`)} placeholder={t(lang, "roaming.contracts.add.clients_info.mfo")} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ClientInfo
