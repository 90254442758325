import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../components/common/advance-table/AdvanceTable";
import {transliterate} from "transliteration";
import AdminReportDataTableHeader from "./AdminReportDataTableHeader";
import AdvanceTableCustomPagination2 from "../../../../components/hippo/item/datatable/AdvanceTableCustomPagination2";
import {Badge} from "react-bootstrap";
import dayjs from "dayjs";

const AdminReportDataTable = ({data = []}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [filter, setFilter] = useState({
        name: '',
        inn: '',
        package_name: null,
        white_label: null
    })

    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 1
    });

    const reportData = useMemo(() => {
        let filteredData = []

        //filter
        for (let i = 0; i < data.length; i++) {
            const reportData = data[i]
            // name
            if (filter.name) {
                const name = filter.name.trim().toLowerCase();
                if (transliterate(reportData.name).toLowerCase().indexOf(name) === -1) {
                    continue
                }
            }
            // inn
            if (filter.inn) {
                if (transliterate(reportData.inn).toLowerCase().indexOf(filter.inn) === -1) {
                    continue
                }
            }
            // package
            if (filter.package_name) {
                const packageName = filter.package_name.trim().toLowerCase();
                if (transliterate(reportData.package_name || "").toLowerCase().indexOf(packageName) === -1) {
                    continue;
                }
            }
            // white_label
            if (filter.white_label) {
                const packageName = filter.white_label.trim().toLowerCase();
                if (transliterate(reportData.white_label || "").toLowerCase().indexOf(packageName) === -1) {
                    continue;
                }
            }

            filteredData.push(reportData)
        }

        return filteredData

    }, [data, filter])


    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "admin.common.payments.datatable.name"),
            Cell: ({row: {original}}) => {
                return (original?.name);
            }
        },
        {
            accessor: 'tin',
            Header: t(lang, "admin.common.payments.datatable.inn"),
            Cell: ({row: {original}}) => {
                return (original?.inn);
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "main.billing.charge.datatable.created_at"),
            Cell: ({row: {original}}) => {
                return (original?.created_at);
            }
        },
        {
            accessor: 'bought_at',
            Header: t(lang, "main.billing.bought.datatable.bought_at"),
            Cell: ({row: {original}}) => {
                return (original.bought_at);
            }
        },
        {
            accessor: 'package',
            Header: t(lang, "main.billing.charge.datatable.package"),
            Cell: ({row: {original}}) => {
                return (original?.package_name);
            }
        },
        {
            accessor: 'white-label',
            Header: t(lang, "white label"),
            Cell: ({row: {original}}) => {
                return (<Badge bg={'primary'}>{original?.white_label}</Badge>);
            }
        },
    ]

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={reportData}
                pagination
                perPage={pagination.pageSize}
                currentPage={pagination.pageIndex - 1}
            >
                <AdminReportDataTableHeader reportData={reportData} data={data} setFilter={setFilter} filter={filter}/>
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        size: 'sm',
                        striped: true,

                        className: 'fs--1 mb-0 overflow-hidden'
                    }}/>
                    <AdvanceTableCustomPagination2 table
                                                   rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                   onPageChange={(page) => setPagination({
                                                       ...pagination,
                                                       page: page
                                                   })}
                                                   onLimitChange={(limit) => setPagination({
                                                       ...pagination,
                                                       limit: limit
                                                   })}
                    />
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default AdminReportDataTable;