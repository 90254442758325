export const ExcelRegistryTypeInvoice = 1;
export const ExcelRegistryTypeWaybill = 2;

export const ExcelRegistryTypeInvoiceWithInternalAct = 100;
export const ExcelRegistryTypeInvoiceWithRoamingAct = 101;

export const ExcelRegistryTypes = [
    {label: 'roaming.excel_registry.datatable.document_type_selector_option.0', value: ExcelRegistryTypeWaybill},
    {label: 'roaming.excel_registry.datatable.document_type_selector_option.1', value: ExcelRegistryTypeInvoice},
    {label: 'roaming.excel_registry.datatable.document_type_selector_option.2', value: ExcelRegistryTypeInvoiceWithInternalAct},
    {label: 'roaming.excel_registry.datatable.document_type_selector_option.3', value: ExcelRegistryTypeInvoiceWithRoamingAct},
];