import ContractorLabelDataTable from "./ContractorLabelDataTable";
import useCheckPermission from "../../../../../hooks/useCheckPermission";
import {PermissionCRMContractorLabelView} from "../../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../errors/Error403";

const ContractorLabel = () => {

    // permissions
    const isContractorLabelView = useCheckPermission(PermissionCRMContractorLabelView)

    if (!isContractorLabelView) return <Error403 />

    return (
        <>
            <ContractorLabelDataTable/>
        </>
    )
}
export default ContractorLabel