import React, {useContext} from 'react';
import NavbarDropdown from './NavbarDropdown';
import routes, {
    dashboardRoutes,
} from "../../../../routes/routes";
import {Col, Nav, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import AppContext from "../../../../context/Context";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import NavbarNavLink from "./NavbarNavLink";
import {selectActiveOrganization, selectIsAdmin} from "../../../../app/store/reducers/auth/authReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkViewPermissions} from "../../../../enum/Permission/CheckPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";

const additionalSetting = [
    {name: 'main.top.nav.profile_dropdown.dropdown_item.settings', to: '/user/settings'},
    {name: 'main.top.nav.profile_dropdown.dropdown_item.billing', to: '/user/billing'},
    {name: 'main.top.nav.profile_dropdown.dropdown_item.currency', to: '/user/currency'},
    {name: 'main.top.nav.profile_dropdown.dropdown_item.notification', to: '/user/notification'},
    {name: 'main.top.nav.profile_dropdown.dropdown_item.employees', to: '/user/employee'},
]


export const HideNavbarTopDropdown = ({routeTitle}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{t(lang, 'common.notification.without.inn')}</Tooltip>}>
            <span className={'mx-2 d-flex align-items-center gap-2 cursor-default'}>
                <span>{routeTitle}</span>
                <FontAwesomeIcon size={'sm'} icon={'lock'}/>
            </span>
        </OverlayTrigger>
    )

}

const NavbarTopDropdown = ({item, routeTitle}) => {

    const hasPermission = useCheckPermission;

    return <Nav>
        <Row style={{minWidth: '250px'}}>
            {Array.isArray(item) && item && item.filter(route => {
                if (route.permission) {
                    return checkViewPermissions(route.permission, hasPermission);
                } else {
                    return true;
                }
            }).map(route => <Col md={12} key={route.name}>
                <NavbarNavLink routeTitle={routeTitle} route={route} hasPermission={hasPermission}/>
            </Col>)}
            {!Array.isArray(item) && item && <Col md={12} key={item.name}>
                <NavbarNavLink route={item} hasPermission={hasPermission}/>
            </Col>}
        </Row>
    </Nav>
}


const NavbarTopDropDownMenus = () => {

    const {
        config: {navbarCollapsed, showBurgerMenu},
        setConfig
    } = useContext(AppContext);

    const lang = useSelector(selectLang);
    const t = Translate;
    const isAdmin = useSelector(selectIsAdmin);
    const activeOrganization = useSelector(selectActiveOrganization)

    const EDI = 'EDI'
    const EDO = 'ЭДО'

    const handleDropdownItemClick = () => {
        if (navbarCollapsed) {
            setConfig('navbarCollapsed', !navbarCollapsed);
        }
        if (showBurgerMenu) {
            setConfig('showBurgerMenu', !showBurgerMenu);
        }
    };


    return (
        <>
            {isAdmin && dashboardRoutes.children.map((route, index) => {
                return route.onlyAdmin && <NavbarDropdown to={route.to} key={route.name}  title={t(lang, route.name)}>
                    <NavbarTopDropdown item={routes[index].children}/>
                </NavbarDropdown>
            })}

            {dashboardRoutes.children.map((route, index) => {
                if (index === 0 || route.onlyAdmin) return null;

                const isSpecialRoute = route.title === EDI || route.title === EDO;
                const hasNoInn = !activeOrganization.inn;

                if (isSpecialRoute && hasNoInn) {
                    return (
                        <HideNavbarTopDropdown key={index} routeTitle={route.title} />
                    );
                }
                if (route.permissions){
                    if (!route.permissions.some(permission => checkViewPermissions(permission, useCheckPermission))) {
                        return null;
                    }
                }
                return (
                    <NavbarDropdown
                        key={route.name}
                        to={route.to}
                        title={t(lang, route.name)}
                    >
                        <NavbarTopDropdown
                            routeTitle={route.title}
                            item={routes[index]?.children}
                        />
                    </NavbarDropdown>
                );
            })}

            <NavbarDropdown to={'/user'} title={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.miscellaneous')}>
                {additionalSetting.map(route => <NavbarTopDropdown  key={route.name} item={route}/>)}
            </NavbarDropdown>

        </>
    );
};

export default NavbarTopDropDownMenus;
