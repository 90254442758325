import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useParams} from "react-router";
import {getSaleItemAsync} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {useEffect, useState} from "react";
import Sale from "../../../../components/hippo/warehouse-operation/sale/Sale";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    BIND_TAG_TO_SALE_SUCCEED,
    UNBIND_TAG_FROM_SALE_SUCCEED,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/saleEvents";
import {loadContractorAsync, updateCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import {PermissionWMSSaleView} from "../../../../enum/Permission/WarehouseOperationPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";


const ViewSale = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [sale, setSale] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()

    // permissions
    const isSaleView = useCheckPermission(PermissionWMSSaleView);

    const loadSale = () => {
        if (isSaleView) {
            getSaleItemAsync({saleId: viewId || id})
                .then(response => {
                    setSale(response.data, response?.data?.items?.reverse())
                    loadContractorAsync(response?.data?.contractor?.id)
                        .then(cr => {
                            dispatch(updateCardContractor(cr))
                        }).catch(err => console.log(err));
                })
                .catch(error => setSale(null))
        }
    }

    useEffect(() => {
        loadSale()
    },[activeOrganization, id, viewId])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, () => {
            loadSale()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, () => {
            loadSale()
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, () => {
            loadSale()
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, () => {
            loadSale()
        })

        const onTagSuccessfullyBindedToSale = EventBus.on(BIND_TAG_TO_SALE_SUCCEED, () => {
            loadSale();
        });

        const onTagSuccessfullyUnbindedFromSale = EventBus.on(UNBIND_TAG_FROM_SALE_SUCCEED, () => {
            loadSale();
        });

        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(BIND_TAG_TO_SALE_SUCCEED, onTagSuccessfullyBindedToSale)
            EventBus.remove(UNBIND_TAG_FROM_SALE_SUCCEED, onTagSuccessfullyUnbindedFromSale)
        }
    },[])

    if (!isSaleView) return <Error403 />

    if (!sale) return <> loading... </>


    return(
        <>
            <Sale sale={sale}/>
        </>
    )
}


export default ViewSale;
