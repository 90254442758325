import React from 'react';
import OperationItemsReportDataTable
    from "../../../../components/hippo/warehouse-operation/items-report/OperationItemsReportDataTable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSOperationItemsReportView} from "../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../components/errors/Error403";

const OperationItemsReport = () => {

    // permissions
    const isItemReportView = useCheckPermission(PermissionWMSOperationItemsReportView)

    if (!isItemReportView) return <Error403 />

    return (
        <OperationItemsReportDataTable/>
    );
};

export default OperationItemsReport;