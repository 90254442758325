import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import {syncAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import PropTypes from "prop-types";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";

const RoamingInvoiceSync = ({id, ...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);

    const [loading, setLoading] = useState(false);
    const syncInvoice = async () => {
        try {
            setLoading(true);
            const params = {
                "inn": activeOrganization?.pinfl || activeOrganization?.inn
            };
            await syncAsync(id, params);
            setLoading(false);
        } catch (error) {
            setLoading(false)
        }
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-primary"
            onClick={syncInvoice}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    )
};

RoamingInvoiceSync.propTypes = {
    id: PropTypes.string
}

RoamingInvoiceSync.defaulProps = {
    id: ""
}

export default RoamingInvoiceSync;