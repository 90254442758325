import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import EventBus from "../../../../app/eventbus/EventBus";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import {getReturnPurchaseItemAsync} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/returnPurchaseEvents";
import EditReturnPurchaseForm
    from "../../../../components/hippo/warehouse-operation/return-purchase/forms/EditReturnPurchaseForm";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import {PermissionWMSReturnUpdate} from "../../../../enum/Permission/WarehouseOperationPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";
import {PermissionCRMContractorPaymentUpdate} from "../../../../enum/Permission/CrmPermission";


const EditReturnPurchase = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [returnPurchase, setReturnPurchase] = useState(null)
    const history = useHistory()
    const items = useSelector(selectItems)
    const {id} = useParams()

    // permissions
    const isReturnPurchaseEdit = useCheckPermission(PermissionWMSReturnUpdate)
    const isPaymentEdit = useCheckPermission(PermissionCRMContractorPaymentUpdate)



    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadReturnPurchase()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadReturnPurchase()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`../view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onDeletePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS, (id) => {
            loadReturnPurchase()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS, (id) => {
            loadReturnPurchase()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS, (response) => {
            setReturnPurchase(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS, (id) => {
            loadReturnPurchase()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS, onDeletePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        loadReturnPurchase()
    },[activeOrganization, id])



    const loadReturnPurchase = async () => {
        if (isReturnPurchaseEdit || isPaymentEdit) {
            await getReturnPurchaseItemAsync({returnPurchaseId: id})
                .then((response) => {
                    setReturnPurchase(response.data, response.data.items.reverse())
                })
                .catch((error) => {
                    setReturnPurchase(null)
                })
        }

    }


    if (!isReturnPurchaseEdit || !isPaymentEdit) return <Error403 />

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!returnPurchase)
        return (
            <SpinnerComponent />
        )


    return(
        <>
            <EditReturnPurchaseForm returnPurchase={returnPurchase}/>
        </>
    )
}

export default EditReturnPurchase;
