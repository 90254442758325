import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import AddRefundForm from "../../../../components/hippo/warehouse-operation/refund/forms/AddRefundForm";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSRefundCreate} from "../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../components/errors/Error403";


const AddRefund = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)

    // permissions
    const isRefundCreate = useCheckPermission(PermissionWMSRefundCreate);

    if (!isRefundCreate) return <Error403 />

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )



    return(
        <>
            <AddRefundForm/>
        </>
    )
}

export default AddRefund;
