import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {registerContractorAsync} from "../../../app/store/reducers/contractor/contractorReducer";
import IconButton from "../../common/IconButton";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {useState} from "react";
import ContractorForm from "../crm/contractor/ContractorForm";
import PropTypes from "prop-types";


const AddContractor = ({executor, buyer}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;


    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    const onSubmit = async (formData) => {
        try {
            let phones = formData.phones.filter(p => p.value.length > 4)
            phones = phones.map(phone => {
                if (phone.value)
                    return {
                        type: 1,
                        value: "998" + phone?.value?.replace(/ /g, '')
                    }
            });
            let emails = formData.emails.filter(e => e.value)
            emails = emails.map(email => {
                if (email.value)
                    return {
                        type: 3,
                        value: email.value
                    }
            });
            const contacts = phones.concat(emails)
            const startingDebts = formData.starting_debt_states.map(startingDebt => {
                return {
                    amount: Number(startingDebt.amount),
                    currency_id: startingDebt.currency_id,
                }
            });

            setLoading(true);
            await registerContractorAsync({
                name: formData.name,
                inn: formData.inn,
                is_default: formData.is_default,
                organization_code: formData.organization_code || null,
                phone: phones,
                type: formData.type.map(t => +t),
                contacts: contacts,
                starting_debts: startingDebts,
                // description: formData.description
            });
            setLoading(false)
            handlerClose()
        } catch (error) {
            setLoading(false)

        }
    }


    return (
        <>
            <IconButton
                variant="falcon-primary"
                size={"sm"}
                style={{zIndex: 0}}
                className="fs-1 position-relative"
                icon="plus"
                onClick={() => handlerShow()}
                transform="shrink-3">
            </IconButton>

            <Modal show={show} onHide={() => handlerClose()} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.contractor.add_contractor_title")}</Modal.Title>
                    <FalconCloseButton onClick={() => handlerClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <ContractorForm onClose={() => handlerClose()} executor={executor} buyer={buyer} loading={loading}
                                    onSubmit={onSubmit} isEditing={false}/>
                </Modal.Body>
            </Modal>
        </>
    )
}


AddContractor.propTypes = {
    executor: PropTypes.bool,
    buyer: PropTypes.bool,
}

AddContractor.defaultProps = {
    executor: false,
    buyer: false,
}


export default AddContractor;
