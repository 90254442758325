import {
    faBoxesStacked,
    faDownload,
    faFolderOpen,
    faHandshake,
    faHome,
    faPen,
    faPeopleCarryBox,
    faPersonWalkingArrowLoopLeft,
    faRobot,
    faTrash,
    faTruckArrowRight,
    faUser,
    faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import {
    PermissionPeriodReportView,
    PermissionWMSOperationItemsReportView,
    PermissionWMSPurchaseView,
    PermissionWMSRefundView,
    PermissionWMSReturnView,
    PermissionWMSRevisionView,
    PermissionWMSSaleView,
    PermissionWMSTransferView,
    PermissionWMSWriteOffView
} from "../enum/Permission/WarehouseOperationPermission";
import {
    PermissionCRMContractorLabelView,
    PermissionCRMContractorOperationView,
    PermissionCRMContractorPaymentView,
    PermissionCRMContractorPayoutView,
    PermissionCRMContractorView,
    PermissionCRMTelegramOrderView
} from "../enum/Permission/CrmPermission";
import {PermissionItemView} from "../enum/Permission/WarehouseItemPermission";
import {
    PermissionCashBoxCashInView,
    PermissionCashBoxCashOutView,
    PermissionCashBoxExpenseView,
    PermissionCashBoxOperationView,
    PermissionCashBoxTransferView,
    PermissionCashBoxView
} from "../enum/Permission/CashBoxPermission";
import {PermissionBranchView} from "../enum/Permission/RolePermission";
import {
    PermissionContractFormationView,
    PermissionEdiAttorneyView,
    PermissionEdiInvoiceView,
    PermissionEdiOrderView,
    PermissionEdiReturnInvoiceView,
    PermissionEdiReturnOrderView,
    PermissionEdiReturnShipmentView,
    PermissionEdiShipmentView
} from "../enum/Permission/EdiPermission";
import {
    PermissionRoamingActView,
    PermissionRoamingContractView,
    PermissionRoamingEmpowermentView,
    PermissionRoamingInvoiceView,
    PermissionRoamingShipmentView,
    PermissionRoamingVerificationActView
} from "../enum/Permission/RoamingPermission";

export const dashboardRoutes = {
    label: 'Dashboard',
    labelDisable: true,
    onlyAdmin: false,
    children: [
        {
            name: 'edi.navbar.routes.default',
            to: '/',
            exact: true,
            active: true,
            icon: faHome
        },
        {
            name: 'edi.navbar.routes.edi',
            to: '/edi',
            active: true,
            title: 'EDI',
            permissions: [
                PermissionEdiOrderView,
                PermissionEdiShipmentView,
                PermissionEdiInvoiceView,
                PermissionEdiReturnOrderView,
                PermissionEdiReturnShipmentView,
                PermissionEdiReturnInvoiceView,
                PermissionEdiAttorneyView,
                PermissionContractFormationView,
            ]
        },
        {
            name: 'edi.navbar.routes.edo',
            to: '/roaming',
            active: true,
            hidden: false,
            icon: faFolderOpen,
            title: 'ЭДО',
            permissions: [
                PermissionRoamingInvoiceView,
                PermissionRoamingContractView,
                PermissionRoamingActView,
                PermissionRoamingVerificationActView,
                PermissionRoamingEmpowermentView,
                PermissionRoamingShipmentView,
            ]
        },
        {
            name: 'main.navbar.routes.admin',
            to: '/admin',
            active: true,
            onlyAdmin: true,
            icon: faUser
        },
        {
            name: 'warehouse.navbar.routes.warehouse',
            to: '/warehouse',
            active: true,
            icon: faWarehouse,
            title: 'Warehouse'
        },
        {
            name: 'crm.navbar.routes.crm',
            to: '/crm',
            active: true,
            icon: faHandshake,
            title: 'CRM'
        },
        {
            name: 'Касса',
            to: '/cash-box',
            active: true,
            permissions: [
                PermissionCashBoxView,
                PermissionBranchView,
                PermissionCashBoxCashInView,
                PermissionCashBoxCashOutView,
                PermissionCashBoxExpenseView,
                PermissionCashBoxTransferView,
                PermissionCashBoxOperationView
            ],
        },
        {
            name: 'Доска',
            to: '/kanban',
            active: true,
            title: 'Task board'
        }
        /*{
            name: 'marketplace.navbar.routes.marketplace',
            to: '/marketplace',
            active: true,
            icon: faStore
        },*/
        // {
        //     name: 'Касса',
        //     to: '/service/pos',
        //     active: true
        // },
        // {
        //     name: 'Склад',
        //     to: '/service/warehouse',
        //     active: true
        // },
        // {
        //     name: 'Производство',
        //     to: '/service/manufacture',
        //     active: true
        // }
    ]
};
export const appRoutes = {
    label: 'app',
    children: [
        {
            name: 'Calendar',
            icon: 'calendar-alt',
            to: '/app/calendar',
            active: true
        },
        {
            name: 'Chat',
            icon: 'comments',
            to: '/app/chat',
            active: true
        },
        {
            name: 'Email',
            icon: 'envelope-open',
            active: true,
            children: [
                {
                    name: 'Inbox',
                    to: '/email/inbox',
                    active: true
                },
                {
                    name: 'Email detail',
                    to: '/email/email-detail',
                    active: true
                },
                {
                    name: 'Compose',
                    to: '/email/compose',
                    active: true
                }
            ]
        },
        {
            name: 'Events',
            icon: 'calendar-day',
            active: true,
            children: [
                {
                    name: 'Create an event',
                    to: '/events/create-an-event',
                    active: true
                },
                {
                    name: 'Event detail',
                    to: '/events/event-detail',
                    active: true
                },
                {
                    name: 'Event list',
                    to: '/events/event-list',
                    active: true
                }
            ]
        },
        {
            name: 'E Commerce',
            icon: 'shopping-cart',
            active: true,
            children: [
                {
                    name: 'Product',
                    active: true,
                    children: [
                        {
                            name: 'Product list',
                            to: '/e-commerce/product/product-list',
                            active: true
                        },
                        {
                            name: 'Product grid',
                            to: '/e-commerce/product/product-grid',
                            active: true
                        },
                        {
                            name: 'Product details',
                            to: '/e-commerce/product/product-details',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Orders',
                    active: true,
                    children: [
                        {
                            name: 'Order list',
                            to: '/e-commerce/orders/order-list',
                            active: true
                        },
                        {
                            name: 'Order details',
                            to: '/e-commerce/orders/order-details',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Customers',
                    to: '/e-commerce/customers',
                    active: true
                },
                {
                    name: 'Customer details',
                    to: '/e-commerce/customer-details',
                    active: true
                },
                {
                    name: 'Shopping cart',
                    to: '/e-commerce/shopping-cart',
                    active: true
                },
                {
                    name: 'Checkout',
                    to: '/e-commerce/checkout',
                    active: true
                },
                {
                    name: 'Billing',
                    to: '/e-commerce/billing',
                    active: true
                },
                {
                    name: 'Invoice',
                    to: '/e-commerce/invoice',
                    active: true
                }
            ]
        },
        {
            name: 'Kanban',
            icon: ['fab', 'trello'],
            to: '/app/kanban',
            active: true
        },
        {
            name: 'Social',
            icon: 'share-alt',
            active: true,
            children: [
                {
                    name: 'Feed',
                    to: '/social/feed',
                    active: true
                },
                {
                    name: 'Activity log',
                    to: '/social/activity-log',
                    active: true
                },
                {
                    name: 'Notifications',
                    to: '/social/notifications',
                    active: true
                },
                {
                    name: 'Followers',
                    to: '/social/followers',
                    active: true
                }
            ]
        }
    ]
};
export const pagesRoutes = {
    label: 'pages',
    children: [
        {
            name: 'Starter',
            icon: 'flag',
            to: '/pages/starter',
            active: true
        },
        {
            name: 'Landing',
            icon: 'globe',
            to: '/landing',
            active: true
        },
        {
            name: 'Authentication',
            icon: 'lock',
            active: true,
            children: [
                {
                    name: 'Simple',
                    active: true,
                    children: [
                        {
                            name: 'Login',
                            to: '/authentication/login',
                            active: true
                        },
                        {
                            name: 'Logout',
                            to: '/authentication/logout',
                            active: true
                        },
                        {
                            name: 'Register',
                            to: '/authentication/register',
                            active: true
                        }
                    ]
                }

                /*{
                    name: 'Simple',
                    active: true,
                    children: [
                        {
                            name: 'Login',
                            to: '/authentication/simple/login',
                            active: true
                        },
                        {
                            name: 'Logout',
                            to: '/authentication/simple/logout',
                            active: true
                        },
                        {
                            name: 'Register',
                            to: '/authentication/simple/register',
                            active: true
                        },
                        {
                            name: 'Forgot password',
                            to: '/authentication/simple/forgot-password',
                            active: true
                        },
                    ]
                },
                {
                    name: 'Card',
                    active: true,
                    children: [
                        {
                            name: 'Login',
                            to: '/authentication/card/login',
                            active: true
                        },
                        {
                            name: 'Logout',
                            to: '/authentication/card/logout',
                            active: true
                        },
                        {
                            name: 'Register',
                            to: '/authentication/card/register',
                            active: true
                        },
                        {
                            name: 'Forgot password',
                            to: '/authentication/card/forgot-password',
                            active: true
                        },
                    ]
                },
                {
                    name: 'Split',

                    active: true,
                    children: [
                        {
                            name: 'Login',
                            to: '/authentication/split/login',
                            active: true
                        },
                        {
                            name: 'Logout',
                            to: '/authentication/split/logout',
                            active: true
                        },
                        {
                            name: 'Register',
                            to: '/authentication/split/register',
                            active: true
                        },
                        {
                            name: 'Forgot password',
                            to: '/authentication/split/forgot-password',
                            active: true
                        },
                    ]
                },
                {
                    name: 'Wizard',
                    to: '/authentication/wizard',
                    active: true
                },
                {
                    name: 'Modal',
                    to: '/authentication-modal',
                    active: true
                }*/
            ]
        },
        {
            name: 'User',
            icon: 'user',
            active: true,
            children: [
                {
                    name: 'Profile',
                    to: '/user/profile',
                    active: true
                },
                {
                    name: 'Settings',
                    to: '/user/settings',
                    active: true
                },
                {
                    name: 'Currency',
                    to: '/user/currency',
                    active: true
                },
                {
                    name: 'Notification',
                    to: '/user/notification',
                    active: true
                },
                {
                    name: 'Employee',
                    to: '/user/employee',
                    active: true
                }
            ]
        },
        {
            name: 'Pricing',
            icon: 'tags',
            active: true,
            children: [
                {
                    name: 'Pricing default',
                    to: '/pricing/pricing-default',
                    active: true
                },
                {
                    name: 'Pricing alt',
                    to: '/pricing/pricing-alt',
                    active: true
                }
            ]
        },
        {
            name: 'Faq',
            icon: 'question-circle',
            active: true,
            children: [
                {
                    name: 'Faq basic',
                    to: '/faq/faq-basic',
                    active: true
                },
                {
                    name: 'Faq alt',
                    to: '/faq/faq-alt',
                    active: true
                },
                {
                    name: 'Faq accordion',
                    to: '/faq/faq-accordion',
                    active: true
                }
            ]
        },
        {
            name: 'Errors',
            active: true,
            icon: 'exclamation-triangle',
            children: [
                {
                    name: '404',
                    to: '/errors/404',
                    active: true
                },
                {
                    name: '500',
                    to: '/errors/500',
                    active: true
                }
            ]
        },
        {
            name: 'Miscellaneous',
            icon: 'thumbtack',
            active: true,
            children: [
                {
                    name: 'Associations',
                    to: '/miscellaneous/associations',
                    active: true
                },
                {
                    name: 'Invite people',
                    to: '/miscellaneous/invite-people',
                    active: true
                },
                {
                    name: 'Privacy policy',
                    to: '/miscellaneous/privacy-policy',
                    active: true
                }
            ]
        }
    ]
};
export const modulesRoutes = {
    label: 'Modules',
    children: [
        {
            name: 'Forms',
            active: true,
            icon: 'file-alt',
            children: [
                {
                    name: 'Basic',
                    active: true,
                    children: [
                        {
                            name: 'Form control',
                            to: '/forms/basic/form-control',
                            active: true
                        },
                        {
                            name: 'Input group',
                            to: '/forms/basic/input-group',
                            active: true
                        },
                        {
                            name: 'Select',
                            to: '/forms/basic/select',
                            active: true
                        },
                        {
                            name: 'Checks',
                            to: '/forms/basic/checks',
                            active: true
                        },
                        {
                            name: 'Range',
                            to: '/forms/basic/range',
                            active: true
                        },
                        {
                            name: 'Layout',
                            to: '/forms/basic/layout',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Advance',
                    active: true,
                    children: [
                        {
                            name: 'Advance select',
                            to: '/forms/advance/advance-select',
                            active: true
                        },
                        {
                            name: 'Date picker',
                            to: '/forms/advance/date-picker',
                            active: true
                        },
                        {
                            name: 'Editor',
                            to: '/forms/advance/editor',
                            active: true
                        },
                        {
                            name: 'Emoji button',
                            to: '/forms/advance/emoji-button',
                            active: true
                        },
                        {
                            name: 'File uploader',
                            to: '/forms/advance/file-uploader',
                            active: true
                        },
                        {
                            name: 'Rating',
                            to: '/forms/advance/rating',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Floating labels',
                    to: '/forms/floating-labels',
                    active: true
                },
                {
                    name: 'Wizard',
                    to: '/forms/wizard',
                    active: true
                },
                {
                    name: 'Validation',
                    to: '/forms/validation',
                    active: true
                }
            ]
        },
        {
            name: 'Tables',
            icon: 'table',
            active: true,
            children: [
                {
                    name: 'Basic tables',
                    to: '/tables/basic-tables',
                    active: true
                },
                {
                    name: 'Advance tables',
                    to: '/tables/advance-tables',
                    active: true
                }
            ]
        },
        {
            name: 'Charts',
            icon: 'chart-line',
            active: true,
            children: [
                {
                    name: 'Chartjs',
                    to: '/charts/chartjs',
                    active: true
                },
                {
                    name: 'ECharts',
                    active: true,
                    children: [
                        {
                            name: 'How to use',
                            to: '/charts/echarts/how-to-use',
                            active: true
                        },
                        {
                            name: 'Line charts',
                            to: '/charts/echarts/line-charts',
                            active: true
                        },
                        {
                            name: 'Bar charts',
                            to: '/charts/echarts/bar-charts',
                            active: true
                        },
                        {
                            name: 'Candlestick charts',
                            to: '/charts/echarts/candlestick-charts',
                            active: true
                        },
                        {
                            name: 'Geo map',
                            to: '/charts/echarts/geo-map',
                            active: true
                        },
                        {
                            name: 'Scatter charts',
                            to: '/charts/echarts/scatter-charts',
                            active: true
                        },
                        {
                            name: 'Pie charts',
                            to: '/charts/echarts/pie-charts',
                            active: true
                        },
                        {
                            name: 'Radar charts',
                            to: '/charts/echarts/radar-charts',
                            active: true
                        },
                        {
                            name: 'Heatmap charts',
                            to: '/charts/echarts/heatmap-charts',
                            active: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Icons',
            active: true,
            icon: 'shapes',
            children: [
                {
                    name: 'Font awesome',
                    to: '/icons/font-awesome',
                    active: true
                },
                {
                    name: 'React icons',
                    to: '/icons/react-icons',
                    active: true
                }
            ]
        },
        {
            name: 'Maps',
            icon: 'map',
            active: true,
            children: [
                {
                    name: 'Google map',
                    to: '/maps/google-map',
                    active: true
                },
                {
                    name: 'Leaflet map',
                    to: '/maps/leaflet-map',
                    active: true
                }
            ]
        },
        {
            name: 'Components',
            active: true,
            icon: 'puzzle-piece',
            children: [
                {
                    name: 'Alerts',
                    to: '/components/alerts',
                    active: true
                },
                {
                    name: 'Accordion',
                    to: '/components/accordion',
                    active: true
                },
                {
                    name: 'Animated icons',
                    to: '/components/animated-icons',
                    active: true
                },
                {
                    name: 'Background',
                    to: '/components/background',
                    active: true
                },
                {
                    name: 'Badges',
                    to: '/components/badges',
                    active: true
                },
                {
                    name: 'Breadcrumbs',
                    to: '/components/breadcrumb',
                    active: true
                },
                {
                    name: 'Buttons',
                    to: '/components/buttons',
                    active: true
                },
                {
                    name: 'Calendar',
                    to: '/components/calendar',
                    active: true
                },
                {
                    name: 'Cards',
                    to: '/components/cards',
                    active: true
                },
                {
                    name: 'Carousel',
                    active: true,
                    children: [
                        {
                            name: 'Bootstrap',
                            to: '/components/carousel/bootstrap',
                            label: 'bootstrap-carousel',
                            active: true
                        },
                        {
                            name: 'Slick',
                            to: '/components/carousel/slick',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Collapse',
                    to: '/components/collapse',
                    active: true
                },
                {
                    name: 'Cookie notice',
                    to: '/components/cookie-notice',
                    active: true
                },
                {
                    name: 'Countup',
                    to: '/components/countup',
                    active: true
                },
                {
                    name: 'Draggable',
                    to: '/components/draggable',
                    active: true
                },
                {
                    name: 'Dropdowns',
                    to: '/components/dropdowns',
                    active: true
                },
                {
                    name: 'List group',
                    to: '/components/list-group',
                    active: true
                },
                {
                    name: 'Modals',
                    to: '/components/modals',
                    active: true
                },
                {
                    name: 'Offcanvas',
                    to: '/components/offcanvas',
                    active: true
                },
                {
                    name: 'Navs & Tabs',
                    active: true,
                    children: [
                        {
                            name: 'Navs',
                            to: '/components/navs-and-tabs/navs',
                            active: true
                        },
                        {
                            name: 'Navbar',
                            to: '/components/navs-and-tabs/navbar',
                            active: true
                        },
                        {
                            name: 'Vertical navbar',
                            to: '/components/navs-and-tabs/vertical-navbar',
                            active: true
                        },
                        {
                            name: 'Top navbar',
                            to: '/components/navs-and-tabs/top-navbar',
                            active: true
                        },
                        {
                            name: 'Combo navbar',
                            to: '/components/navs-and-tabs/combo-navbar',
                            active: true
                        },
                        {
                            name: 'Tabs',
                            to: '/components/navs-and-tabs/tabs',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Pictures',
                    active: true,
                    children: [
                        {
                            name: 'Avatar',
                            to: '/components/pictures/avatar',
                            active: true
                        },
                        {
                            name: 'Images',
                            to: '/components/pictures/images',
                            active: true
                        },
                        {
                            name: 'Figures',
                            to: '/components/pictures/figures',
                            active: true
                        },
                        {
                            name: 'Hoverbox',
                            to: '/components/pictures/hoverbox',
                            active: true
                        },
                        {
                            name: 'Lightbox',
                            to: '/components/pictures/lightbox',
                            active: true
                        }
                    ]
                },
                {
                    name: 'Progress Bar',
                    to: '/components/progress-bar',
                    active: true
                },
                {
                    name: 'Pagination',
                    to: '/components/pagination',
                    active: true
                },
                {
                    name: 'Placeholder',
                    to: '/components/placeholder',
                    active: true
                },
                {
                    name: 'Popovers',
                    to: '/components/popovers',
                    active: true
                },
                {
                    name: 'Scrollspy',
                    to: '/components/scrollspy',
                    active: true
                },
                {
                    name: 'Search',
                    to: '/components/search',
                    active: true
                },
                {
                    name: 'Spinners',
                    to: '/components/spinners',
                    active: true
                },
                {
                    name: 'Toasts',
                    to: '/components/toasts',
                    active: true
                },
                {
                    name: 'Tooltips',
                    to: '/components/tooltips',
                    active: true
                },
                {
                    name: 'Treeview',
                    to: '/components/treeview',
                    active: true
                },
                {
                    name: 'Typed text',
                    to: '/components/typed-text',
                    active: true
                },
                {
                    name: 'Videos',
                    active: true,
                    children: [
                        {
                            name: 'Embed',
                            to: '/components/videos/embed',
                            active: true
                        },
                        {
                            name: 'React Player',
                            to: '/components/videos/react-player',
                            active: true
                        }
                    ]
                }
            ]
        },
        {
            name: 'Utilities',
            active: true,
            icon: 'fire',
            children: [
                {
                    name: 'Borders',
                    to: '/utilities/borders',
                    active: true
                },
                {
                    name: 'Colors',
                    to: '/utilities/colors',
                    active: true
                },
                {
                    name: 'Colored links',
                    to: '/utilities/colored-links',
                    active: true
                },
                {
                    name: 'Display',
                    to: '/utilities/display',
                    active: true
                },
                {
                    name: 'Flex',
                    to: '/utilities/flex',
                    active: true
                },
                {
                    name: 'Float',
                    to: '/utilities/float',
                    active: true
                },
                {
                    name: 'Grid',
                    to: '/utilities/grid',
                    active: true
                },
                {
                    name: 'Scroll Bar',
                    to: '/utilities/scroll-bar',
                    active: true
                },
                {
                    name: 'Position',
                    to: '/utilities/position',
                    active: true
                },
                {
                    name: 'Spacing',
                    to: '/utilities/spacing',
                    active: true
                },
                {
                    name: 'Sizing',
                    to: '/utilities/sizing',
                    active: true
                },
                {
                    name: 'Stretched link',
                    to: '/utilities/stretched-link',
                    active: true
                },
                {
                    name: 'Text truncation',
                    to: '/utilities/text-truncation',
                    active: true
                },
                {
                    name: 'Typography',
                    to: '/utilities/typography',
                    active: true
                },
                {
                    name: 'Vertical align',
                    to: '/utilities/vertical-align',
                    active: true
                },
                {
                    name: 'Visibility',
                    to: '/utilities/visibility',
                    active: true
                }
            ]
        },
        {
            name: 'Widgets',
            icon: 'poll',
            to: '/widgets',
            active: true
        },
        {
            name: 'Multi level',
            active: true,
            icon: 'layer-group',
            children: [
                {
                    name: 'Level two',
                    active: true,
                    children: [
                        {
                            name: 'ViewItem 1',
                            active: true,
                            to: '#!'
                        },
                        {
                            name: 'ViewItem 2',
                            active: true,
                            to: '#!'
                        }
                    ]
                },
                {
                    name: 'Level three',
                    active: true,
                    children: [
                        {
                            name: 'ViewItem 3',
                            active: true,
                            to: '#!'
                        },
                        {
                            name: 'ViewItem 4',
                            active: true,
                            children: [
                                {
                                    name: 'ViewItem 5',
                                    active: true,
                                    to: '#!'
                                },
                                {
                                    name: 'ViewItem 6',
                                    active: true,
                                    to: '#!'
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'Level four',
                    active: true,
                    children: [
                        {
                            name: 'ViewItem 6',
                            active: true,
                            to: '#!'
                        },
                        {
                            name: 'ViewItem 7',
                            active: true,
                            children: [
                                {
                                    name: 'ViewItem 8',
                                    active: true,
                                    to: '#!'
                                },
                                {
                                    name: 'ViewItem 9',
                                    active: true,
                                    children: [
                                        {
                                            name: 'ViewItem 10',
                                            active: true,
                                            to: '#!'
                                        },
                                        {
                                            name: 'ViewItem 11',
                                            active: true,
                                            to: '#!'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
export const documentationRoutes = {
    label: 'documentation',
    children: [
        {
            name: 'Getting started',
            icon: 'rocket',
            to: '/documentation/getting-started',
            active: true
        },
        {
            name: 'Customization',
            active: true,
            icon: 'wrench',
            children: [
                {
                    name: 'Configuration',
                    to: '/documentation/configuration',
                    active: true
                },
                {
                    name: 'Styling',
                    to: '/documentation/styling',
                    active: true
                },
                {
                    name: 'Dark mode',
                    to: '/documentation/dark-mode',
                    active: true,
                    badge: {
                        type: 'success',
                        text: 'New'
                    }
                },
                {
                    name: 'Plugin',
                    to: '/documentation/plugin',
                    active: true
                }
            ]
        },
        {
            name: 'Design file',
            icon: 'palette',
            to: '/documentation/design-file',
            active: true
        },
        {
            name: 'Changelog',
            icon: 'code-branch',
            to: '/changelog',
            active: true
        }
    ]
};
export const ediRoutes = {
    label: 'EDI',
    to: '/edi',
    onlyAdmin: false,
    children: [
        {
            name: 'edi.navbar.routes.orders',
            icon: 'shopping-cart',
            to: '/edi/orders',
            active: true
        },
        {
            name: 'edi.navbar.routes.shipments',
            icon: 'fa-clipboard',
            to: '/edi/shipments',
            active: true
        },
        {
            name: 'edi.navbar.routes.invoices',
            icon: 'fa-paste',
            to: '/edi/invoices',
            active: true
        },
        {
            name: 'edi.navbar.routes.return_orders',
            icon: 'shopping-cart',
            to: '/edi/return-orders',
            active: true
        },
        {
            name: 'edi.navbar.routes.return_shipments',
            icon: 'fa-clipboard',
            to: '/edi/return-shipments',
            active: true
        },
        {
            name: 'edi.navbar.routes.return_invoices',
            icon: 'fa-paste',
            to: '/edi/return-invoices',
            active: true
        },
        {
            name: 'edi.navbar.routes.attorneys',
            icon: 'fa-file-lines',
            to: '/edi/attorneys',
            active: true
        },
        {
            name: 'edi.navbar.routes.branches',
            icon: 'fa-shop',
            to: '/edi/branches',
            active: true
        },
        {
            name: 'edi.navbar.routes.contract_formation',
            icon: 'fa-shop',
            to: '/edi/contracts-formation',
            active: true,
        }
    ]
}
export const edoRoutes = {
    label: 'ЭДО',
    to: '/roaming',
    onlyAdmin: false,
    children: [
        {
            name: 'edi.navbar.routes.edo.invoice',
            icon: 'fa-file-lines',
            to: '/roaming/invoices/inbox',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.waybill.v2',
            icon: 'fa-file-lines',
            to: '/roaming/v2-waybills',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.contract',
            icon: 'fa-file-lines',
            to: '/roaming/contracts',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.empowerment',
            icon: 'fa-file-lines',
            to: '/roaming/empowerments',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.verification_act',
            icon: 'fa-file-lines',
            to: '/roaming/verification-acts',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.act',
            icon: 'fa-file-lines',
            to: '/roaming/acts',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.free_form_document',
            icon: 'fa-file-lines',
            to: '/roaming/free-forms',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.excel_registry',
            icon: 'fa-file-lines',
            to: '/roaming/excel-registries',
            active: true
        },
        {
            name: 'edi.navbar.routes.edo.waybill',
            icon: 'fa-file-lines',
            to: '/roaming/waybills',
            active: true
        },
        /*{
            name: 'edi.navbar.routes.edo.company_address',
            icon: 'fa-file-lines',
            to: '/roaming/organization-addresses',
            active: false
        },*/
        {
            name: 'edi.navbar.routes.edo.system.company_address',
            icon: 'fa-file-lines',
            to: '/roaming/system-organization-addresses',
            active: true
        },
        {
            name: 'roaming.invoices.datatable.register_construction_invoice',
            icon: 'fa-file-lines',
            to: '/roaming/construction-invoice/send',
            active: true
        },
    ]
}
export const adminRoutes = {
    label: 'Admin',
    to: '/admin',
    onlyAdmin: true,
    children: [
        {
            name: 'admin.navbar.routes.organizations',
            icon: 'users',
            to: '/admin/organizations',
            active: true
        },
        {
            name: 'admin.navbar.routes.payments.title',
            icon: 'users',
            to: '/admin/payments',
            active: true
        },
        {
            name: 'admin.navbar.routes.bank_payment_error',
            icon: 'users',
            to: '/admin/bank-payment-error',
            active: true
        },
        {
            name: 'admin.navbar.routes.desktop_app_versions',
            icon: 'users',
            to: '/admin/desktop-app/versions',
            active: true
        },
        {
            name: 'admin.navbar.routes.control_company_roaming',
            icon: 'users',
            to: '/admin/control-company-roaming',
            active: true
        },
        {
            name: 'admin.navbar.routes.e-imzo-signer',
            icon: 'users',
            to: '/admin/e-imzo-signer',
            active: true
        },
    ]
}

export const warehouseRoutes = {
    label: 'Склад',
    to: '/warehouse',
    onlyAdmin: false,
    children: [
        {
            name: 'warehouse.dashboard.items.title',
            to: '/warehouse/items',
            active: true,
            permission: PermissionItemView
        },
        {
            name: "warehouse.operation.item.common.list_sales",
            // icon: 'tags',
            icon: faPeopleCarryBox,
            to: '/warehouse/operation/sales',
            active: true,
            permission: PermissionWMSSaleView
        },
        {
            name: "warehouse.operation.item.common.list_coming",
            icon: faDownload,
            to: '/warehouse/operation/purchases',
            active: true,
            permission: PermissionWMSPurchaseView
        },
        {
            name: "warehouse.operation.item.common.list_refund",
            icon: faPersonWalkingArrowLoopLeft,
            to: '/warehouse/operation/refunds',
            active: true,
            permission: PermissionWMSRefundView
        },
        {
            name: "warehouse.operation.item.common.list_return_purchase",
            icon: faTruckArrowRight,
            to: '/warehouse/operation/return-purchases',
            active: true,
            permission: PermissionWMSReturnView
        },
        {
            name: "warehouse.operation.item.common.list_revision",
            icon: faPen,
            to: '/warehouse/operation/revisions',
            active: true,
            permission: PermissionWMSRevisionView
        },
        {
            name: "warehouse.operation.item.common.list_write_off",
            icon: faTrash,
            to: '/warehouse/operation/write-offs',
            active: true,
            permission: PermissionWMSWriteOffView
        },
        {
            name: "warehouse.operation.item.common.list_transfer",
            icon: 'fa-right-left',
            to: '/warehouse/operation/transfers',
            active: true,
            permission: PermissionWMSTransferView
        },
        {
            name: "warehouse.operation.item.common.list_draft_purchase",
            icon: 'fa-right-left',
            to: '/warehouse/operation/draft-purchases',
            active: true
        },
        {
            name: "warehouse.operation.item.common.list_draft_refund",
            icon: 'fa-right-left',
            to: '/warehouse/operation/draft-refunds',
            active: true
        },
        {
            name: 'warehouse.navbar.routes.warehouse_operation_period_report',
            to: '/warehouse/operation/report',
            active: true,
            permission: PermissionPeriodReportView
        },
        {
            name: 'items.common.draft',
            to: '/warehouse/items/drafts',
            active: true
        },
        {
            name: 'items.common.categories',
            to: '/warehouse/items/categories',
            active: true
        },
        {
            name: 'items.common.settings',
            to: '/warehouse/items/settings',
            active: true
        },
        {
            name: 'warehouse.common.item.title.warehouses',
            icon: faBoxesStacked,
            to: '/warehouse/warehouses',
            active: true
        },
        {
            name: 'warehouse.common.item.title.histories',
            to: '/warehouse/items/history',
            active: true
        },
        {
            name: 'warehouse.common.item.title.operation_histories',
            to: '/warehouse/operation/item-report',
            active: true,
            permission: PermissionWMSOperationItemsReportView
        },
        {
            name: 'warehouse.navbar.routes.partners',
            to: '/warehouse/partners',
            active: true
        }
    ]
}

export const crmRoutes = {
    label: 'CRM',
    to: '/crm',
    onlyAdmin: false,
    children: [
        {
            name: 'edi.contractor.datatable.header.row.contractor',
            icon: 'fa-user',
            to: '/crm/contractor',
            active: true,
            permission: PermissionCRMContractorView
        },
        {
            name: 'edi.contractor.datatable.header.row.contractor_payments',
            icon: 'fa-usd',
            to: '/crm/contractor-payments',
            active: true,
            permission: PermissionCRMContractorPaymentView
        },
        {
            name: 'edi.contractor.datatable.header.row.contractor_payouts',
            icon: 'fa-usd',
            to: '/crm/contractor-payouts',
            active: true,
            permission: PermissionCRMContractorPayoutView
        },
        {
            name: "edi.contractor.datatable.header.row.contractor_draft_payments",
            icon: 'fa-right-left',
            to: '/crm/contractor-draft-payments',
            active: true
        },
        {
            name: 'edi.contractor.datatable.header.row.contractor_operations',
            icon: 'fa-clipboard',
            to: '/crm/contractor-operation',
            active: true,
            permission: PermissionCRMContractorOperationView
        },
        {
            name: 'edi.dashboard.contracts.title',
            icon: 'fa-file-lines',
            to: '/crm/contracts',
            active: true,
        },
        {
            name: 'crm.common.order',
            icon: 'shopping-cart',
            to: '/crm/order',
            active: true,
        },
        {
            name: "crm.contractor.agent",
            icon: "fa-user",
            to: '/crm/contractor-agents',
            active: true,
        },
        {
            name: "crm.telegram-bots",
            icon: faRobot,
            to: '/crm/telegram-bots',
            active: true,
        },
        {
            name: "crm.telegram-orders",
            icon: faRobot,
            to: '/crm/telegram-orders',
            active: true,
            permission: PermissionCRMTelegramOrderView
        },
        {
            name: "crm.lead",
            icon: faRobot,
            to: '/crm/lead',
            active: true,
        },

        {
            name: "crm.internal.order",
            icon: faRobot,
            to: '/crm/internal/order/all',
            active: true,
        },
        {
            name: "crm.contractor.label",
            icon: faRobot,
            to: '/crm/contractor-labels',
            active: true,
            permission: PermissionCRMContractorLabelView
        },
    ]
}

export const kanbanRoutes = {
    label: 'Доска',
    to: '/kanban',
    onlyAdmin: false,
    children: [
        {
            name: 'task.board.route.name',
            to: '/kanban',
            active: true
        }
    ]
}

export const cashBox = {
    label: "CashBox",
    to: "/cash-box",
    onlyAdmin: false,
    children: [
        {
            name: 'cashbox.bind.account.title',
            to: '/cash-box/cash-boxes',
            active: true,
            permission: PermissionCashBoxView
        },
        {
            name: 'edi.navbar.routes.branches',
            icon: 'fa-shop',
            to: '/cash-box/branches',
            active: true,
            permission: PermissionBranchView
        },
        {
            name: 'cashboxes.cash-in.nav.label',
            to: '/cash-box/cash-in',
            active: true,
            permission: PermissionCashBoxCashInView
        },
        {
            name: 'cashboxes.cash-out.nav.label',
            to: '/cash-box/cash-out',
            active: true,
            permission: PermissionCashBoxCashOutView
        },
        {
            name: 'cashboxes.expense.nav.label',
            to: '/cash-box/expense',
            active: true,
            permission: PermissionCashBoxExpenseView
        },
        {
            name: 'warehouse.dashboard.items.transfer_title',
            icon: 'fa-shop',
            to: '/cash-box/transfer',
            active: true,
            permission: PermissionCashBoxTransferView
        },
        {
            name: 'edi.return_order.protocol_info.title',
            icon: 'fa-shop',
            to: '/cash-box/operation',
            active: true,
            permission: PermissionCashBoxOperationView
        },
    ]
}

const routes = [
    dashboardRoutes,
    ediRoutes,
    // appRoutes,
    // pagesRoutes,
    // modulesRoutes,
    // documentationRoutes
];

routes.push(edoRoutes);

routes.push(adminRoutes);

routes.push(warehouseRoutes);

routes.push(crmRoutes);

routes.push(cashBox)

routes.push(kanbanRoutes)

// Todo: if a marketplaceRoutes is added, it must have "to" in its property => to: "/marketplace"

export default routes;
