import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "./TypeView";

export const ActSend = 15
export const ActCanceled = 17
export const CustomerDeclined = 20
export const CustomerApproved = 30

export const GetStatusTexts = (viewer) => {
    let arr = [];
    switch (viewer) {
        case VIEW_INBOX:
            arr = [
                {id: 0, name: "roaming.invoices.invoice.status.all"},
                {id: ActSend, name: "roaming.invoices.invoice.status.customer.15"},
                {id: ActCanceled, name: "roaming.invoices.invoice.status.customer.17"},
                {id: CustomerDeclined, name: "roaming.invoices.invoice.status.customer.20"},
                {id: CustomerApproved, name: "roaming.invoices.invoice.status.customer.30"},
            ];
            break;
        case VIEW_OUTBOX:
            arr = [
                {id: 0, name: "roaming.invoices.invoice.status.all"},
                {id: ActSend, name: "roaming.invoices.invoice.status.executor.15"},
                {id: ActCanceled, name: "roaming.invoices.invoice.status.executor.17"},
                {id: CustomerDeclined, name: "roaming.invoices.invoice.status.executor.20"},
                {id: CustomerApproved, name: "roaming.invoices.invoice.status.executor.30"},
            ];
            break;
        default:
            arr = [
                {id: 0, name: "roaming.invoices.invoice.status.all"},
                {id: ActSend, name: "roaming.act.status.act_send"},
                {id: ActCanceled, name: "roaming.act.status.act_canceled"},
                {id: CustomerDeclined, name: "roaming.act.status.customer_declined"},
                {id: CustomerApproved, name: "roaming.act.status.customer_approved"},
            ];
            break;
    }
    return arr;
};
export const GetStatusVariants = (viewer) => {
    const WARNING = "warning";
    const DANGER = "danger";
    const SUCCESS = "success";
    const INFO = "info";
    const PRIMARY = "primary";
    const SECONDARY = "secondary";
    const DARK = "dark";

    let arr = [];
    switch (viewer) {
        case VIEW_INBOX:
            arr = [
                {id: 0, name: PRIMARY},
                {id: ActSend, name: WARNING},
                {id: ActCanceled, name: DANGER},
                {id: CustomerDeclined, name: DANGER},
                {id: CustomerApproved, name: SUCCESS},
            ];
            break;
        case VIEW_OUTBOX:
            arr = [
                {id: 0, name: PRIMARY},
                {id: ActSend, name: INFO},
                {id: ActCanceled, name: DANGER},
                {id: CustomerDeclined, name: DANGER},
                {id: CustomerApproved, name: SUCCESS},
            ];
            break;
        default:
            arr = [
                {id: 0, name: PRIMARY},
                {id: ActSend, name: PRIMARY},
                {id: ActCanceled, name: DANGER},
                {id: CustomerDeclined, name: DANGER},
                {id: CustomerApproved, name: SUCCESS},
            ];
            break;
    }
    return arr;
};
export const GetStatusText = (viewer, status) => {
    let arr = GetStatusTexts(viewer);
    return arr.find(v => v.id === status)?.name;
};
export const GetStatusVariant = (viewer, status) => {
    let arr = GetStatusVariants(viewer);
    return arr.find(v => v.id === status)?.name;
};

export const ActStatuses = [
    {id: ActSend, name: "roaming.act.status.act_send"},
    {id: ActCanceled, name: "roaming.act.status.act_canceled"},
    {id: CustomerDeclined, name: "roaming.act.status.customer_declined"},
    {id: CustomerApproved, name: "roaming.act.status.customer_approved"}
]


export class ActWrapperClass {
    _act;

    constructor(act) {
        this._act = act
    }

    enumStatuses = {
        sendToBuyer: 15,
        sellerIsCanceled: 17,
        buyerIsDeclined: 20,
        signedByBothParties: 30,
    };

    allowSyncAct() {
        switch (this._act?.status) {
            case ActSend:
                return true;
            default:
                return false;
        }
    }

    isDraft() {
        return !this._act.status;
    }

    getRoamingStatus = (identifier) => {
        let status;
        if (this._act?.executor?.inn === identifier) {
            // Outbox
            if (!this._act?.status) {
                status = "roaming.act.datatable.viewer.draft";
            } else {
                switch (this._act?.status) {
                    case this.enumStatuses.sendToBuyer:
                        status = "roaming.invoices.invoice.status.executor.15"; // ActSend
                        break;
                    case this.enumStatuses.sellerIsCanceled:
                        status = "roaming.invoices.invoice.status.executor.17"; // ActCanceled
                        break;
                    case this.enumStatuses.buyerIsDeclined:
                        status = "roaming.invoices.invoice.status.executor.20"; // CustomerDeclined
                        break;
                    case this.enumStatuses.signedByBothParties:
                        status = "roaming.invoices.invoice.status.executor.30"; // CustomerApproved
                        break;
                    default:
                        status = "roaming.act.roaming_statuses.not_exist";
                        break;
                }
            }
        } else {
            // Inbox
            if (!this._act?.status) {
                status = "roaming.act.datatable.viewer.draft";
            } else {
                switch (this._act?.status) {
                    case this.enumStatuses.sendToBuyer:
                        status = "roaming.invoices.invoice.status.customer.15"; // ActSend
                        break;
                    case this.enumStatuses.sellerIsCanceled:
                        status = "roaming.invoices.invoice.status.customer.17"; // ActCanceled
                        break;
                    case this.enumStatuses.buyerIsDeclined:
                        status = "roaming.invoices.invoice.status.customer.20"; // CustomerDeclined
                        break;
                    case this.enumStatuses.signedByBothParties:
                        status = "roaming.invoices.invoice.status.customer.30"; // CustomerApproved
                        break;
                    default:
                        status = "roaming.act.roaming_statuses.not_exist";
                        break;
                }
            }
        }
        return status;
    }
    getRoamingStatusVariant = (identifier) => {
        let statusVariant;
        if (this._act?.executor?.inn === identifier) {
            // Outbox
            if (!this._act?.status) {
                statusVariant = "dark"; // Default for no status
            } else {
                switch (this._act?.status) {
                    case this.enumStatuses.sendToBuyer:
                        statusVariant = "info"; // ActSend
                        break;
                    case this.enumStatuses.sellerIsCanceled:
                        statusVariant = "danger"; // ActCanceled
                        break;
                    case this.enumStatuses.buyerIsDeclined:
                        statusVariant = "danger"; // CustomerDeclined
                        break;
                    case this.enumStatuses.signedByBothParties:
                        statusVariant = "success"; // CustomerApproved
                        break;
                    default:
                        statusVariant = "dark"; // Not exist
                        break;
                }
            }
        } else {
            // Inbox
            if (!this._act?.status) {
                statusVariant = "dark"; // Default for no status
            } else {
                switch (this._act?.status) {
                    case this.enumStatuses.sendToBuyer:
                        statusVariant = "warning"; // ActSend
                        break;
                    case this.enumStatuses.sellerIsCanceled:
                        statusVariant = "danger"; // ActCanceled
                        break;
                    case this.enumStatuses.buyerIsDeclined:
                        statusVariant = "danger"; // CustomerDeclined
                        break;
                    case this.enumStatuses.signedByBothParties:
                        statusVariant = "success"; // CustomerApproved
                        break;
                    default:
                        statusVariant = "dark"; // Not exist
                        break;
                }
            }
        }
        return statusVariant;
    }

    canTheSellerCancled(identifier) {
        return this._act?.status === this.enumStatuses.sendToBuyer && identifier === this._act?.executor?.inn
    }

    canTheBuyerAccept(identifier) {
        return this._act?.status === this.enumStatuses.sendToBuyer && identifier === this._act?.customer?.inn
    }

    canTheBuyerDecline(identifier) {
        return this._act?.status === this.enumStatuses.sendToBuyer && identifier === this._act?.customer?.inn
    }
}
