import axios from "axios";

class RoamingContractService {
    get(id) {
        return axios.get(`/roaming/contract/get/${id}`)
    }

    getDraft(id) {
        return axios.get(`/roaming/contract/draft/get/${id}`)
    }

    getDraftContracts(params) {
        return axios.get('/roaming/contract/drafts/get', {
            params: params
        })
    }

    getExecutorContracts(params) {
        return axios.get('/roaming/contract/executor/get', {
            params: params
        })
    }

    getCustomerContracts(params) {
        return axios.get('/roaming/contract/customer/get', {
            params: params
        })
    }

    getDraftContractsCount(params) {
        return axios.get('/roaming/contract/drafts/count', {
            params: params
        })
    }

    getExecutorContractsCount(params) {
        return axios.get('/roaming/contract/executor/count', {
            params: params
        })
    }

    getCustomerContractsCount(params) {
        return axios.get('/roaming/contract/customer/count', {
            params: params
        })
    }

    send({id, signature, executorInn, lang}) {
        return axios.post('/roaming/contract/send', {
            id: id,
            signature: signature,
            executor_inn: executorInn,
            lang: lang,
        })
    }

    save(data) {
        return axios.post('/roaming/contract/draft/save', data)
    }

    sync(id) {
        return axios.get(`roaming/contract/sync/${id}`)
    }

    create({id, signature, executorInn, lang}) {
        return axios.post('/roaming/contract/send', {
            id: id,
            signature: signature,
            executor_inn: executorInn,
            lang: lang,
        })
    }

    cancel(id, signature) {
        return axios.post(`/roaming/contract/cancel/${id}`, {
            signature: signature
        })
    }

    customerAccept(id, signature) {
        return axios.post(`/roaming/contract/customer/accept/${id}`, {
            signature: signature
        })
    }

    customerDecline(id, {signature, comment}) {
        return axios.post(`/roaming/contract/customer/decline/${id}`, {
            signature: signature,
            comment: comment
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/roaming/contract/hashcode/accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/contract/hashcode/cancel/${id}`, {
            transformResponse: [data => data]
        })
    }

    getDeclineHashCode(id, comment) {
        return axios.get(`/roaming/contract/hashcode/decline/${id}`, {
            transformResponse: [data => data],
            params: {comment: comment}
        })
    }

    deleteContract(id) {
        return axios.post(`/roaming/contract/draft/delete/${id}`)
    }

}

const roamingContractService = new RoamingContractService();
export default roamingContractService;