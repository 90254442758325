import React from 'react';
import Expense from "../../../../components/hippo/cashbox/expense/Expense";
import {getExpenseAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {PermissionCashBoxExpenseView} from "../../../../enum/Permission/CashBoxPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import Error403 from "../../../../components/errors/Error403";

const ViewExpense = ({viewId}) => {

    // permissions
    const isCashBoxExpenseView = useCheckPermission(PermissionCashBoxExpenseView)

    if (!isCashBoxExpenseView) return <Error403 />

    return (
        <Expense
            request={getExpenseAsync}
            title={"cashboxes.expense.nav.label"}
            id={viewId}
        />
    );
};

export default ViewExpense;
