import React, {cloneElement, Fragment, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import EventBus from "../../../../app/eventbus/EventBus";
import {deleteContractAsync} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import {
    ROAMING_CONTRACT_DRAFT_DELETE_FAIL,
    ROAMING_CONTRACT_DRAFT_DELETE_REQUESTED,
    ROAMING_CONTRACT_DRAFT_DELETE_SUCCESS
} from "../../../../app/eventbus/roaming/roamingContractEvents";
import PropTypes from "prop-types";

const ContractDraftDelete = ({id, contracts = [], handleShowProgressBar, updateActionResult, children, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide confirmation modal
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const deleteDraft = () => {
        setLoading(true)
        if (contracts.length > 0)
            handleBulkDelete();
        else
            handleSingleDelete();
    }

    const handleBulkDelete = async () => {
        handleShowProgressBar();
        for (let i = 0; i < contracts.length; i++) {
            const contract = contracts[i];
            try {
                await deleteContractAsync(contract.id)
                updateActionResult({
                    number: contract?.info?.number,
                    date: contract?.info.date,
                    contractor: contract?.contractors.map(contractor => ({
                        name: contractor.name,
                        inn: contractor.inn,
                    }))
                })
            } catch (error) {
                updateActionResult({
                    number: contract?.info?.number,
                    date: contract?.info.date,
                    contractor: contract?.contractors.map(contractor => ({
                        name: contractor.name,
                        inn: contractor.inn,
                    })),
                    error: error?.message
                })
            }
        }
        handleClose();
    }

    const handleSingleDelete = () => {
        EventBus.dispatch(ROAMING_CONTRACT_DRAFT_DELETE_REQUESTED, id);
        deleteContractAsync(id)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(ROAMING_CONTRACT_DRAFT_DELETE_SUCCESS, id);
                handleClose();
            })
            .catch(() => {
                EventBus.dispatch(ROAMING_CONTRACT_DRAFT_DELETE_FAIL, id);
                setLoading(false);
            })
            .finally(() => {
                setLoading(true);
            })
    }

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton onClick={handleShow} variant="falcon-danger" icon={faTrash}{...props}>
                    {t(lang, "roaming.invoice.delete")}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.delete_draft_modal_title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.invoice.delete_draft_modal_body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.common.cancel_button")}
                    </Button>
                    <Button variant="danger"
                            disabled={loading}
                            onClick={deleteDraft}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "roaming.invoice.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

ContractDraftDelete.propTypes = {
    id: PropTypes.string,
}

ContractDraftDelete.defaultProps = {
    id: "",
}

export default ContractDraftDelete;