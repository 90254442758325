import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import SaleDatatable from "../../../../components/hippo/warehouse-operation/sale/datatable/SaleDatatable";
import {
    getSaleItemsAsync,
    getSaleItemsCountAsync,
    selectSaleFilterOptions
} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {WAREHOUSE_OPERATION_SALE_DELETE_SUCCESS} from "../../../../app/eventbus/warehouse-operation/saleEvents";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSSaleView} from "../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../components/errors/Error403";

const Sales = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectSaleFilterOptions);
    const dispatch = useDispatch();

    const isSaleView = useCheckPermission(PermissionWMSSaleView);

    useEffect(() => {
        loadSales();
    }, [activeOrganization, filters])

    useEffect(() => {
        const onSaleDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_SUCCESS, () => {
            loadSales();
        });

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_SUCCESS, onSaleDeletedHandler)
        };
    }, [filters])

    const loadSales = () => {
        if (isSaleView) {
            dispatch(getSaleItemsAsync({...filters}));
            dispatch(getSaleItemsCountAsync({...filters}));
        }
    }

    if (!isSaleView) {
        return <Error403 />;
    }

    return (
        <SaleDatatable loadSales={loadSales}/>
    )
}

export default Sales;
