import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getAllCashInAsync,
    getCashInCountAsync, selectCashBoxCashInFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {getAccount} from "../../../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_CASH_IN_DELETE_SUCCESS,
    CASHBOX_CASH_IN_REGISTER_SUCCESS, CASHBOX_CASH_IN_UPDATE_SUCCESS,
} from "../../../../app/eventbus/cashbox/cashboxEvents";
import CashBoxCashInDataTable from "../../../../components/hippo/cashbox/cash-in/CashBoxCashInDataTable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxCashInView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const CashBoxCashIn = () => {

    const dispatch = useDispatch()
    const filters = useSelector(selectCashBoxCashInFilter);
    const activeOrganization = useSelector(selectActiveOrganization)

    // permissions
    const isCashBoxCashInView = useCheckPermission(PermissionCashBoxCashInView)

    const getCashInAsync = () => {
        if (!isCashBoxCashInView) return
        dispatch(getAllCashInAsync({...filters}))
        dispatch(getCashInCountAsync({...filters}))
    }

    useEffect(() => {
        getCashInAsync()
    }, [filters, activeOrganization])

    useEffect(() => {
        const onSuccessRegisterCashIn = EventBus.on(CASHBOX_CASH_IN_REGISTER_SUCCESS, () => {
            getCashInAsync()
        })
        const onSuccessUpdateCashIn = EventBus.on(CASHBOX_CASH_IN_UPDATE_SUCCESS, () => {
            getCashInAsync()
        })
        const onSuccessDeleteCashIn = EventBus.on(CASHBOX_CASH_IN_DELETE_SUCCESS, () => {
            getCashInAsync()
        })

        return () => {
            EventBus.remove(CASHBOX_CASH_IN_REGISTER_SUCCESS, onSuccessRegisterCashIn)
            EventBus.remove(CASHBOX_CASH_IN_UPDATE_SUCCESS, onSuccessUpdateCashIn)
            EventBus.remove(CASHBOX_CASH_IN_DELETE_SUCCESS, onSuccessDeleteCashIn)
        }
    }, [activeOrganization])

    if (!isCashBoxCashInView) return <Error403 />

    return (
        <CashBoxCashInDataTable />
    );
};

export default CashBoxCashIn;
