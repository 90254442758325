import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getWriteOffItemAsync} from "../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import {
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/writeOffEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import WriteOff from "../../../../components/hippo/warehouse-operation/write-off/WriteOff";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSWriteOffView} from "../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../components/errors/Error403";


const ViewWriteOff = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [writeOff, setWriteOff] = useState(null)
    const {id} = useParams()

    const isWriteOffView = useCheckPermission(PermissionWMSWriteOffView)

    const loadWriteOff = () => {
        if (isWriteOffView) {
            getWriteOffItemAsync({writeOffId: id})
                .then(response => setWriteOff(response.data, response?.data?.items?.reverse()))
                .catch(error => setWriteOff(null))
        }
    }

    useEffect(() => {
        loadWriteOff()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, () => {
            loadWriteOff()
        })

        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
        }
    },[])

    if (!writeOff) return <Error403 />

    if (!writeOff) return <> loading... </>



    return(
        <>
            <WriteOff writeOff={writeOff}/>
        </>
    )
}


export default ViewWriteOff