import {Card, Col, Row, Table} from "react-bootstrap"
import {useSelector} from "react-redux"
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer"
import Cleave from 'cleave.js/react';
import {useFormContext} from "react-hook-form"
import IconButton from "../../../../common/IconButton"
import Product from "./Product"
import React, {useState} from "react"
import classNames from "classnames";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import VatRateLinkSelector from "../../../../common/VatRateLinkSelector";

const Products = ({fieldsProduct, addFieldProduct, removeFieldProduct, insertFieldProduct}) => {
    const [totalAmounts, setTotalAmounts] = useState({
        deliverySum: null,
        vatSum: null,
        deliverySumWithVat: null
    })
    const {watch, setValue} = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const watchProducts = watch('products')

    const calcAmounts = () => {
        let totalDeliverySum = watchProducts.reduce((total, product) => total + +product.total, 0)
        let totalVatSum = watchProducts.reduce((total, product) => total + +product.vat_sum, 0)
        let totalDeliverySumWithVat = watchProducts.reduce((total, product) => total + +product.total_with_delivery_sum, 0)
        totalDeliverySum = totalDeliverySum.toFixed(2)
        totalVatSum = totalVatSum.toFixed(2)
        totalDeliverySumWithVat = totalDeliverySumWithVat.toFixed(2)
        setTotalAmounts({
            deliverySum: totalDeliverySum,
            vatSum: totalVatSum,
            deliverySumWithVat: totalDeliverySumWithVat
        })
    }

    const onBulkSetCatalog = (value) => {
        fieldsProduct.map((field, index) => {
            setValue(`products.${index}.catalog`, value);
            setValue(`products.${index}.package`, null);
            setValue(`products.${index}.measurement`, null);
        });
    };

    const onBulkSetVatRate = (value) => {
        fieldsProduct.map((field, index) => {
            setValue(`products.${index}.vat_rate`, value);
        });
    };

    return (
        <Card>
            <Card.Header className={'pb-0'}>
                <Row>
                    <Col><h5 className="mb-0">{t(lang, "roaming.contracts.add.products.title")}</h5></Col>
                    <Col className="text-end">
                        <IconButton
                            onClick={addFieldProduct}
                            variant="falcon-primary"
                            icon="plus"
                            size="sm"
                            transform="shrink-3"
                        >{t(lang, "roaming.contracts.add.products.add_product")}
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table responsive striped bordered className="border-300">
                    <thead className="bg-200 text-dark">
                    <tr className="position-relative">
                        <th style={{minWidth: '30px', width: '30px'}} className="p-0 align-middle text-center">
                            #
                        </th>
                        <th className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.name")}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                            <div className="d-flex flex-column">
                                <span>
                                    {t(lang, 'roaming.common.items.catalog_class_code')}
                                    <span className="text-danger">*</span>
                                </span>
                                <CatalogLinkSelector
                                    defaultCatalog={null}
                                    onChange={onBulkSetCatalog}
                                    linkProps={{
                                        className: classNames('text-primary cursor-pointer fs--1')
                                    }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.barcode")}
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.measure")}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '120px', width: '120px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.quantity")}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '140px', width: '140px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.price")}
                            <span className="text-danger">*</span>
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.sum")}
                        </th>
                        <th style={{minWidth: '130px', width: '130px'}} className="p-0 align-middle text-center">
                            <div className="d-flex flex-column">
                                <span>{t(lang, "roaming.common.items.nds")}</span>
                                <VatRateLinkSelector
                                    defaultVatRate={''}
                                    onChange={onBulkSetVatRate}
                                    linkProps={{
                                        className: classNames('text-primary cursor-pointer fs--1')
                                    }}
                                />
                            </div>
                        </th>
                        <th style={{minWidth: '160px', width: '160px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.vat_sum")}
                        </th>
                        <th style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                            {t(lang, "roaming.contracts.add.products.sum_with_vat_sum")}
                        </th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {fieldsProduct.map((product, index) => (
                        <Product
                            key={product.id}
                            product={product}
                            fieldName={`products.${index}`}
                            index={index}
                            removeFieldProduct={removeFieldProduct}
                            insertFieldProduct={insertFieldProduct}
                            onChangeAmounts={calcAmounts}
                            allowDeleteItem={fieldsProduct.length > 1}
                        />
                    ))}
                    <tr>
                        <td colSpan={7} className="px-1 py-0 fs--1 fw-bold text-end">
                            {t(lang, "roaming.contracts.add.products.total_info")}
                        </td>
                        <td className="p-0">
                            <Cleave
                                disabled={true}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2,
                                }}
                                value={totalAmounts.deliverySum}
                                style={{height: '40px', border: 'none'}}
                                className={`form-control px-1 text-end rounded-0`}
                            />
                        </td>
                        <td></td>
                        <td className="p-0">
                            <Cleave
                                disabled={true}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2
                                }}
                                value={totalAmounts.vatSum}
                                style={{height: '40px', border: 'none'}}
                                className={`form-control px-1 text-end rounded-0`}
                            />
                        </td>
                        <td className="p-0">
                            <Cleave
                                disabled={true}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2
                                }}
                                value={totalAmounts.deliverySumWithVat}
                                style={{height: '40px', border: 'none'}}
                                className={`form-control px-1 text-end rounded-0`}
                            />
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default Products