import React from "react";
import {Form} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentCertificate } from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import SignActionWithSelectCertificatWrapper from "../../components/SignActionWithSelectCertificatWrapper";
import {faSignature} from "@fortawesome/free-solid-svg-icons";


const ActCancel = ({ActCancelHandler, disabled, ...props}) => {
    const methods = useForm();
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;


    const formSubmit = () => {
        if(currentCertificate?.keyId) {
            ActCancelHandler()
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(formSubmit)} className="d-inline-flex" id="ExecutorCancelForm">
                    <SignActionWithSelectCertificatWrapper isForm>
                        <IconButton
                            variant="falcon-danger"
                            size="sm"
                            icon={faSignature}
                            disabled={disabled}
                            iconClassName="me-1"
                            type="submit"
                            form="ExecutorCancelForm"
                            {...props}
                        >{t(lang,"edi.common.cancel_decline")}</IconButton>
                    </SignActionWithSelectCertificatWrapper>
                </Form>
            </FormProvider>
        </>
    )
}

export default ActCancel;