import {useDispatch, useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useMemo, useRef, useState} from "react";
import SoftBadge from "../../../common/SoftBadge";
import numeral from "numeral";
import PropTypes from "prop-types";
import EventBus from "../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {InputGroup, OverlayTrigger, Popover} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {debounce} from "underscore";
import {selectFilteredItems, updateFilteredItems} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import WarehouseOperationAddItem from "./WarehouseOperationAddItem";
import WarehouseOperationEditItem from "./WarehouseOperationEditItem";
import WarehouseOperationImageModal from "./WarehouseOperationImageModal";
import {
    CheckScanBarcodeOrMarking, showOperationItemSelectOptionCodeInfo, showOperationItemSelectOptionSkuInfo,
    statusProductShowRetailPrice,
    statusProductShowWholeSalePrice
} from "../enum/warehouseOperationWrapper";
import {transliterate} from "transliteration";
import {loadItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {
    PermissionItemCreate,
    PermissionItemUpdate
} from "../../../../enum/Permission/WarehouseItemPermission";


const WarehouseOperationItemSelect = ({options, defaultValue, defaultsSettings, warehouseId, onChange, allowAdd, allowEdit, allowImage, onFocus, ...props}) => {
    const filterOptions = {page: 0, limit: +defaultsSettings?.showItemSearchOptions || 20}
    const currencies = useSelector(selectCurrency)
    const numberFormat = useSelector(selectNumberFormat);
    const selectFilteredItem = useSelector(selectFilteredItems)
    const dispatch = useDispatch()
    const [value, setValue] = useState(null)
    const lang = useSelector(selectLang);
    const t = Translate;
    const ref = useRef(null)
    const allowRetailPrice = statusProductShowRetailPrice(defaultsSettings?.priceTypeOfInputOption || 0)
    const allowWholeSalePrice = statusProductShowWholeSalePrice(defaultsSettings?.priceTypeOfInputOption || 0)

    const showOperationItemSkuOnSearch = showOperationItemSelectOptionSkuInfo(defaultsSettings?.operationItemExtraInfo || 0);
    const showOperationItemCodeOnSearch = showOperationItemSelectOptionCodeInfo(defaultsSettings?.operationItemExtraInfo || 0);
    const showOperationItemExtraInfoOnSearch = showOperationItemSkuOnSearch || showOperationItemCodeOnSearch;
    const showPurchasePrice = defaultsSettings?.showOperationItemPurchasePrice && warehouseId;

    // permissions
    const isItemCreate = useCheckPermission(PermissionItemCreate)
    const isItemEdit = useCheckPermission(PermissionItemUpdate)

    useEffect(() => {
        if (defaultValue !== null) {
            const val = options?.find(item => item?.item?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    }, [options, defaultValue])


    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [])

    const itemWarehousePurchasePrice = useMemo(() => {
        if (value) {
            for (let i = 0; i < value?.warehouse_states?.warehouse_items?.length; i++) {
                const itemWarehouse = value?.warehouse_states?.warehouse_items[i];
                if (itemWarehouse.id === warehouseId && itemWarehouse.purchase_price) {
                    return itemWarehouse.purchase_price.price;
                }
            }
        }

        return null;
    }, [value, warehouseId])


    const onScan = async (value) => {
        try {
            const val = options?.find(item => item?.item?.barcodes.includes(CheckScanBarcodeOrMarking(value)))
            setValue(val)
            onChange(val)
        } catch (e) {
            console.log(e);
        }
    }


    const onChangeItem = option => {
        setValue(option)
        onChange(option)
    }

    const onNewItemCreated = async createdItem => {
        try {
            const rdaItem = await loadItemAsync(createdItem.id)
            onChangeItem(rdaItem);
        } catch (error) {
            console.log(error);
            onChangeItem(null);
        }
    }

    const onItemEdited = async editedItem => {
        try {
            const rdaItem = await loadItemAsync(editedItem.id)
            onChangeItem(rdaItem);
        } catch (error) {
            console.log(error);
            onChangeItem(null);
        }
    }

    const filteredItems = [];
    const loadOptions = (query, callback) => {
        for (let i = 0; i < options?.length; i++) {
            const item = options[i];

            let found = false;
            const searchTermLower = query?.trim().toLowerCase();
            const transliteratedQuery = transliterate(searchTermLower)

            // search by name
            const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
            const itemNameLower = item.item.name.trim().toLowerCase();
            const transliteratedItemName = transliterate(itemNameLower);
            if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                found = true;

            const findBarcode = item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(transliteratedQuery))
            if (findBarcode) {
                onChangeItem(item)
                found = true
                return
            }

            const itemSkuLower = item?.item?.sku?.trim().toLowerCase();
            const transliteratedItemSku = transliterate(itemSkuLower);
            const findSku = filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemSku.indexOf(fnp) > -1).length
            if (findSku) {
                found = true
            }

            const itemCodeLower = item?.item?.code?.trim().toLowerCase();
            const transliteratedItemCode = transliterate(itemCodeLower);
            const findCode = filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemCode.indexOf(fnp) > -1).length
            if (findCode) {
                found = true
            }

            if (!found)
                continue;

            if (!filteredItems?.find(f => f?.item?.id === item?.item?.id))
                filteredItems.push(item);
        }

        filteredItems.sort((a, b) => {
            const key = query?.toLowerCase();
            const isGoodMatchA = a.item.name.toLowerCase().startsWith(key);
            const isGoodMatchB = b.item.name.toLowerCase().startsWith(key);

            if (isGoodMatchA ^ isGoodMatchB) {
                return isGoodMatchA ? -1 : 1;
            }

            return a.item.name.localeCompare(b.item.name);
        })

        dispatch(updateFilteredItems(filteredItems?.slice(filterOptions?.page, filterOptions?.limit)))
        callback(filteredItems?.length ? filteredItems?.slice(filterOptions?.page, filterOptions?.limit) : selectFilteredItem);
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const getOptionValue = option => option?.item?.id
    const getOptionLabel = option => option?.item?.name
    const formatOptionLabel = option => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                {showOperationItemExtraInfoOnSearch ?
                    <div className={'lh-1'}>
                        <p className={'m-0 fs-0'}>{option?.item?.name}</p>

                        {showOperationItemCodeOnSearch && <OverlayTrigger trigger={['hover', 'focus']} placement={'bottom'}
                                                                          overlay={
                                                                              <Popover id="popover-basic" className='mt-0'>
                                                                                  <Popover.Header>
                                                                                      <SoftBadge bg={'warning'}>{t(lang, 'Код')}</SoftBadge>
                                                                                  </Popover.Header>
                                                                              </Popover>
                                                                          }
                        >
                            <SoftBadge bg={'warning'} className={'m-0 fs--2 px-2 py-0 me-1'}>{option?.item?.code}</SoftBadge>
                        </OverlayTrigger>}

                        {showOperationItemSkuOnSearch && <OverlayTrigger trigger={['hover', 'focus']} placement={'bottom'}
                                                                         overlay={
                                                                             <Popover id="popover-basic" className='mt-0'>
                                                                                 <Popover.Header>
                                                                                     <SoftBadge className={'text-black'} bg={'secondary'}>{t(lang, 'Артикул')}</SoftBadge>
                                                                                 </Popover.Header>
                                                                             </Popover>
                                                                         }
                        >
                            <SoftBadge bg={'secondary'} className={'m-0 fs--2 px-2 py-0 me-1 text-700'}>{option?.item?.sku}</SoftBadge>
                        </OverlayTrigger>}
                    </div> : <h6 className="fs-0 mb-0">{option?.item?.name}</h6>
                }
                <div className="d-flex flex-nowrap ms-1">
                    {(allowRetailPrice || allowWholeSalePrice) &&
                        <>
                            {allowRetailPrice &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge bg={'primary'}>{t(lang, 'Цена продажи')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg="primary">
                                        {numeral.formats[numberFormat].format(option?.price.common_price?.amount)}
                                        &nbsp;
                                        <span className="fw-bold">{currencies?.find(cur => cur?.id === option?.price.common_price?.currency?.global_currency_id)?.name}</span>
                                    </SoftBadge>
                                </OverlayTrigger>
                            }
                            {allowWholeSalePrice &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge bg={'secondary'}>{t(lang, 'Оптовая цена')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg="secondary" className="ms-1">
                                        {numeral.formats[numberFormat].format(option?.price.bulk_price?.amount)}
                                        &nbsp;
                                        <span className="fw-bold">{currencies?.find(cur => cur?.id === option?.price.bulk_price?.currency?.global_currency_id)?.name}</span>
                                    </SoftBadge>
                                </OverlayTrigger>
                            }
                        </>
                    }
                    {option?.warehouse_states?.warehouse_items?.length === 1 &&
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge bg={'info'}>{option?.warehouse_states?.warehouse_items[0]?.name}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg={option?.warehouse_states?.warehouse_items[0]?.state < 0 ? 'danger' : 'success'} className="ms-1">
                                {option?.warehouse_states?.warehouse_items[0]?.state}
                            </SoftBadge>
                        </OverlayTrigger>
                    }
                    {option?.item?.package_measurements?.length >= 1 &&
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge bg={'info'}>{option?.warehouse_states?.warehouse_items[0]?.name}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg={option?.warehouse_states?.warehouse_items[0]?.state < 0 ? 'danger' : 'success'} className="ms-1">
                                {option?.warehouse_states?.warehouse_items[0]?.state}
                                <span className="mx-2">=></span>
                                {Math.round(option?.warehouse_states?.warehouse_items[0]?.state / option?.item?.package_measurements[0]?.quantity)}
                                <span className="ms-1">{option?.item?.package_measurements[0]?.name}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    }
                </div>
            </div>
        )
    }

    useEffect(() => {
        onFocus && ref?.current?.focus()
    }, [onFocus])

    const defaultOptions = selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)?.length ?
        selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)
        :
        options?.slice(filterOptions?.page, filterOptions?.limit)


    return (
        <>
            <InputGroup>
                <div className="react-select form-control p-0">
                    <AsyncSelect
                        loadOptions={debouncedLoadOptions}
                        ref={ref}
                        formatOptionLabel={formatOptionLabel}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        placeholder={t(lang, 'items.common.select')}
                        value={value}
                        // defaultOptions={selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)}
                        defaultOptions={defaultOptions}
                        onChange={onChangeItem}
                        noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                        hideSelectedOptions
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                margin: '-1px'
                            })
                        }}
                        {...props}
                    />
                </div>
                {isItemCreate && allowAdd && !value &&
                    <WarehouseOperationAddItem onItemCreated={onNewItemCreated}/>
                }
                {value?.item?.images.length > 0 && allowImage &&
                    <WarehouseOperationImageModal
                        image={value?.item?.images}/>
                }
                {isItemEdit && allowEdit && value &&
                    <WarehouseOperationEditItem id={value?.item?.id} onItemEdited={onItemEdited}/>
                }

            </InputGroup>

            {value && showPurchasePrice && (
                <small>
                    <span className={'text-black fw-semi-bold'}>{t(lang, 'warehouse.common.operation_item.purchase_price')}: </span>
                    {itemWarehousePurchasePrice
                        ? <span className={'text-success fw-semi-bold'}>{numeral['formats'][numberFormat].format(itemWarehousePurchasePrice?.amount)}&nbsp;{itemWarehousePurchasePrice?.currency?.name}</span>
                        : <span className={'text-warning fw-semi-bold'}>не найдено</span>
                    }
                </small>
            )}
        </>
    );
};


WarehouseOperationItemSelect.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    defaultsSettings: PropTypes.object,
    allowEdit: PropTypes.bool,
    allowAdd: PropTypes.bool,
    allowImage: PropTypes.bool,
    props: PropTypes.any,
}

WarehouseOperationItemSelect.defaultProps = {
    onChange: () => {
    },
    defaultValue: "",
    allowEdit: false,
    allowAdd: false,
    allowImage: false,
}

export default WarehouseOperationItemSelect;
