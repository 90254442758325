import React, {useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import SignActionWithSelectCertificatWrapper from "../../components/SignActionWithSelectCertificatWrapper";
import { selectCurrentCertificate } from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActReject} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import {faSignature} from "@fortawesome/free-solid-svg-icons";


const CustomerDecline = ({ActDeclineHandler, disabled, ...props}) => {
    const methods = useForm();
    const {register, formState: {errors}, handleSubmit, reset} = methods;
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const [show, setShow] = useState(false);

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingActReject))) setShow(true)
        else dispatch(toastPermission())
    }
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            ActDeclineHandler(comment)
            reset();
            setShow(false);
        }
    };

    return (
        <>
        <IconButton
            variant="falcon-danger"
            size="sm"
            icon={faSignature}
            disabled={disabled}
            iconClassName="me-1"
            onClick={handleShow}
            {...props}
        >{t(lang,"edi.common.button.decline")}</IconButton>

        <Modal size="md" show={show} onHide={handleClose}>
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(formSubmit)} id="CustomerDeclineForm">
                    <Modal.Header>
                        <Modal.Title>{t(lang, "edi.common.button.decline")}</Modal.Title>
                        <FalconCloseButton onClick={() => {handleClose()}}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                {t(lang,"edi.common.modal.reason")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                name="reason"
                                as="textarea" rows={3}
                                placeholder={t(lang,"edi.common.modal.reason.placeholder")}
                                isInvalid={errors?.comment?.message}
                                {...register("comment", {
                                    required: {
                                        value: true,
                                        message: t(lang,"edi.common.forms.validations.is_required")
                                    }
                                })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.comment?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang,"edi.common.button.close")}</Button>
                        <SignActionWithSelectCertificatWrapper isForm>
                            <Button
                                variant="danger"
                                className="d-flex align-items-center"
                                disabled={disabled}
                                type="submit"
                                form="CustomerDeclineForm"
                            >{t(lang,"edi.common.button.decline")}
                            </Button>
                        </SignActionWithSelectCertificatWrapper>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
</>
)
}

export default CustomerDecline;
