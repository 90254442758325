import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_SALE_DATE_FAILED,
    WAREHOUSE_OPERATION_SALE_DATE_REQUESTED,
    WAREHOUSE_OPERATION_SALE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_NOTE_FAILED,
    WAREHOUSE_OPERATION_SALE_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_REQUESTED,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_FAILED,
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_REQUESTED,
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_FAILED,
    WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_REQUESTED,
    WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_FAILED,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_SALE_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_REQUESTED,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_REQUESTED,
    WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_FAILED,
    WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_REQUESTED,
    WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_FAILED,
    WAREHOUSE_OPERATION_SALE_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_SALE_NUMBER_FAILED,
    WAREHOUSE_OPERATION_SALE_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_EMPLOYEE_FAILED,
    WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_REQUESTED,
    BIND_TAG_TO_SALE_REQUESTED,
    BIND_TAG_TO_SALE_SUCCEED,
    BIND_TAG_TO_SALE_FAILED,
    UNBIND_TAG_FROM_SALE_REQUESTED,
    UNBIND_TAG_FROM_SALE_SUCCEED,
    UNBIND_TAG_FROM_SALE_FAILED
} from "../../../eventbus/warehouse-operation/saleEvents";
import warehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import dayjs from "dayjs";

const currentDate = new Date()
export const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
export const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

export const saleSlice = createSlice({
    name: 'sale',
    initialState: {
        saleItems: [],
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            contractor_id: null,
            employee_id: null,
            operation_account_id: null,
            is_approved: null,
            warehouse_id: null
        },
        saleCount: 0,
        saleDefaultsSettings: null,
        filteredItems: [],
        loading: false
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateSaleItems: (state, action) => {
            state.saleItems = action.payload
        },
        updateSaleItemsCount: (state, action) => {
            state.saleCount = action.payload
        },
        setSaleDefaultsSettings: (state, action) => {
            console.log(action.payload, 'payload')
            state.saleDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("SaleSettings", action.payload)
        },
        loadSaleDefaultsSettings: (state, action) => {
            const saleDefaults = JSON.parse(localStorage.getItem("SaleSettings"))
            state.saleDefaultsSettings = saleDefaults
        },
        updateFilteredItems: (state, action) => {
            state.filteredItems = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        updateSaleItemNote: (state, action) => {
            state.saleItems = state.saleItems.map(item => {
                if (item.id === action.payload.id) {
                    item.note = action.payload.note
                }
                return item
            })
        }
    }
})



export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(saleSlice.actions.updateFilterOptions({...params}))
}


export const getSaleItemsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(saleSlice.actions.setLoading(true))
    return new Promise((resolve, reject) => {
        warehouseOperationService.getSaleItems(params)
            .then((response) => {
                dispatch(saleSlice.actions.updateSaleItems(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => dispatch(saleSlice.actions.setLoading(false)))
    })
}


export const getSaleItemsCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getSaleItemsCount(filters)
            .then((response) => {
                dispatch(saleSlice.actions.updateSaleItemsCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getSaleItemAsync = ({saleId}) => {

    return new Promise((resolve, reject) => {
        warehouseOperationService.getSaleItem({saleId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const saleRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, response?.data?.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_FAILED)
                reject(error)
            })
    })
}

export const saleAddItemAsync = ({saleId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleAddItem({saleId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const saleUpdateItemAsync = ({saleId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleUpdateItem({saleId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const saleDeleteItemAsync = ({saleId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDeleteItem({saleId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const saleApproveAsync = ({saleId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleApprove({saleId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, saleId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const saleDeleteAsync = (id) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDelete(id)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const saleUpdateNoteAsync = ({saleId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleUpdateNote({saleId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NOTE_FAILED)
                reject(error)
            })
    })
}

export const saleUpdateDateAsync = ({saleId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleUpdateDate({saleId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DATE_FAILED)
                reject(error)
            })
    })
}

export const saleUpdateNumberAsync = ({saleId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleUpdateNumber({saleId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const saleChangeContractorAsync = ({saleId, contractorId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleChangeContractor({saleId, contractorId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_FAILED)
                reject(error)
            })
    })
}

export const saleChangeEmployeeAsync = ({saleId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleChangeEmployee({saleId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const salePaymentRegisterAsync = ({saleId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.salePaymentRegister({saleId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, saleId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_FAILED)
                reject(error)
            })
    })
}

export const salePaymentUpdateAsync = ({saleId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.salePaymentUpdate({saleId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, saleId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_FAILED)
                reject(error)
            })
    })
}

export const deleteSalePaymentAsync = (saleId) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteSalePayment(saleId)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, saleId);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_FAILED);
                reject(error);
            })
    })
}

export const saleChangeExactDiscountsAsync = ({saleId, exactDiscounts}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_REQUESTED, saleId)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleChangeExactDiscounts({saleId, exactDiscounts})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_FAILED, saleId)
                reject(error)
            })
    })
}

export const saleChangePercentDiscountAsync = ({saleId, percentDiscount}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_REQUESTED, saleId)
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleChangePercentDiscount({saleId, percentDiscount})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS, saleId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_FAILED, saleId)
                reject(error)
            })
    })
}

export const saleDownloadPdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadPdf({saleId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const saleDownloadSkuPdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadSkuPdf({saleId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const saleDownloadContractorLastPaymentPdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadContractorLastPaymentPdf({saleId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const saleDownloadItemWithImagePdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadItemWithImagePdf({saleId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const saleDownloadMultiWarehousePdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadMultiWarehousePdf({saleId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const saleDownloadReceipt80PdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadReceipt80Pdf({saleId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const saleDownloadReceipt58PdfAsync = ({saleId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.saleDownloadReceipt58Pdf({saleId})
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const bindTagToSaleAsync = ({saleId, tagId}) => {
    EventBus.dispatch(BIND_TAG_TO_SALE_REQUESTED, saleId);

    return new Promise((resolve, reject) => {
        warehouseOperationService.bindTagToSale(saleId, tagId)
            .then(response => {
                EventBus.dispatch(BIND_TAG_TO_SALE_SUCCEED, saleId);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(BIND_TAG_TO_SALE_FAILED, saleId);
                reject(error);
            })
    })
};

export const unbindTagFromSaleAsync = ({saleId, tagId}) => {
    EventBus.dispatch(UNBIND_TAG_FROM_SALE_REQUESTED, saleId);

    return new Promise((resolve, reject) => {
        warehouseOperationService.unbindTagFromSale(saleId, tagId)
            .then(response => {
                EventBus.dispatch(UNBIND_TAG_FROM_SALE_SUCCEED, saleId);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(UNBIND_TAG_FROM_SALE_FAILED, saleId);
                reject(error);
            })
    })
};

export const exportSalesAsync = (uri,{page, limit, ...filters}) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    return new Promise((resolve, reject) => {
        warehouseOperationService.exportSales(uri, params)
            .then((response) => {
                resolve({content: response.data, contentType: response.headers['content-type']})
            })
            .catch((error) => reject(error))
    })
}

export const onContractorChanged = () => {
    return saleChangeContractorAsync()
}

export const onNoteChanged = ({note, id}) => (dispatch) => {
    dispatch(saleSlice.actions.updateSaleItemNote({note, id}))
}

export const selectSaleFilterOptions = state => state.sale.filterOptions;

export const selectSaleItems = state => state.sale.saleItems

export const selectSaleCount = state => state.sale.saleCount

export const selectSaleDefaultsSettings = state => state.sale.saleDefaultsSettings

export const selectFilteredItems = state => state.sale.filteredItems
export const saleLoading = state => state.sale.loading;

export const {setSaleDefaultsSettings, loadSaleDefaultsSettings, updateFilteredItems} = saleSlice.actions


export default saleSlice.reducer
