import React, {Fragment, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterCashIn,
    deleteCashIn,
    getAllCashInAsync,
    registerCashIn,
    selectCashBoxCashIn,
    selectCashBoxCashInCount,
    selectCashBoxCashInFilter, selectedCurrencyForCashBox,
    updateCashIn,
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {
    totalAmountCard,
    totalAmountCash,
    totalAmountInCashBox, totalGroupAmounts, totalGroupCashAmount,
} from "../../../../enum/cash_box/cashBoxWrapper";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import {Alert, Badge, Card, Dropdown} from "react-bootstrap";
import numeral from "numeral";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import CashBoxCashInDataTableHeader from "./CashBoxCashInDataTableHeader";
import CashBoxCommonRegisterModal from "../common/CashBoxCommonRegisterModal";
import CashBoxCashInEdit from "./CashBoxCashInEdit";
import CashBoxCashInDeleteModal from "./CashBoxCashInDeleteModal";
import CardDropdown from "../../../common/CardDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {
    PermissionCashBoxCashInCreate, PermissionCashBoxCashInDelete,
    PermissionCashBoxCashInUpdate
} from "../../../../enum/Permission/CashBoxPermission";

const CashBoxCashInDataTable = () => {

    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const cashIn = useSelector(selectCashBoxCashIn)
    const count = useSelector(selectCashBoxCashInCount)
    const filters = useSelector(selectCashBoxCashInFilter)
    const currency = useSelector(selectCurrency)
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    const numberFormat = useSelector(selectNumberFormat);

    const [showEditModal, setShowEditModal] = useState(false)
    const [defaultValue, setDefaultValue] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    // permissions
    const isCashBoxCashInCreate = useCheckPermission(PermissionCashBoxCashInCreate)
    const isCashBoxCashInEdit = useCheckPermission(PermissionCashBoxCashInUpdate)
    const isCashBoxCashInDelete = useCheckPermission(PermissionCashBoxCashInDelete)

    const totalAmountDataTable = useMemo(() => {
        return selectedCurrency ? totalAmountInCashBox(cashIn, currency) / selectedCurrency?.rate : 0
    }, [cashIn, currency, selectedCurrency])

    const totalAmountDataTableCash = useMemo(() => {
        return  totalAmountCash(cashIn, currency)
    }, [cashIn, currency])

    const totalAmountDataTableCard = useMemo(() => {
        return  totalAmountCard(cashIn, currency)
    }, [cashIn, currency])


    const columns = [
        {
            Header: "#",
            id: "index",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "cashbox.bind.account.title"),
            cellProps: {width: 250, className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={{
                        pathname: `/cash-box/operation`,
                        state: original
                    }}>
                        <strong>{original.cash_box.name}</strong>
                    </Link>
                );
            }
        },
        {
            accessor: 'account',
            Header: t(lang, "crm.contractor.view.tab.act_report.account"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.account.name}</strong>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "edi.contract_formation.datatable.header.row.created_at"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.created_at}</span>
                );
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "edi.contractor_payment.datatable.note"),
            headerProps: {
                className: "text-center"
            },
            cellProps: {
                className: "text-center"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-center" title={original?.notes} style={{maxWidth: "250px", minWidth: "250px"}}>
                        <span className="text-truncate">{original?.notes}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'total_amount',
            Header: t(lang, "crm.data.table.total.sum"),
            headerProps: {className: "text-end"},
            cellProps: {className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                const total = totalAmountInCashBox(original.amounts, currency, true)
                return (
                    <span className={'fw-bold fs-0'}>
                        <span className={'text-700'}>
                            {total ? numeral['formats'][numberFormat].format(total / selectedCurrency?.rate) : 0}
                        </span>
                        <strong className={'text-info ms-2 fw-bold'}>{selectedCurrency?.name}</strong>
                    </span>
                );
            }
        },
        {
            accessor: 'cash',
            Header: t(lang, "crm.payment_type.cash"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                const cash = totalGroupCashAmount(original.amounts.filter(i => i.money_type === PaymentTypeCashCode)) || []
                return (
                    <Fragment>
                        {cash.map((i, index) => <Badge key={index} bg='success' className='me-2 py-2'
                                                       style={{fontSize: 12}}>
                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                            <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                        </Badge>)}
                    </Fragment>
                );
            }
        },
        {
            accessor: 'plastic_card',
            Header: t(lang, "Карта"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {width: 250, className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                let plasticCard = totalGroupAmounts(original.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type))) || []
                return (
                    <Fragment>
                        {plasticCard.map((item, index) => <Badge key={index} bg='info' className='me-2'
                                                                 style={{fontSize: 12}}>
                                <span>{numeral.formats[numberFormat].format(item?.total)}</span>
                                <strong className={'ms-2 fw-bold'}>{item?.name}</strong>
                                <i className={'d-block fs--2 fw-bold text-center m-0 mt-1 text-dark'}>{t(lang, ContractorPaymentTypes.find(b => b.value === item?.money_type)?.label)}</i>
                            </Badge>
                        )}
                    </Fragment>
                );
            }
        },
        {
            Header: "",
            accessor: 'none',
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {(isCashBoxCashInEdit || isCashBoxCashInDelete) && <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
                            {isCashBoxCashInEdit && <Dropdown.Item
                                onClick={() => {
                                    setDefaultValue(original)
                                    setShowEditModal(true)
                                }}
                                className="text-warning">
                                <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                                <span>{t(lang, "roaming.common.edit")}</span>
                            </Dropdown.Item>}

                            {isCashBoxCashInDelete && <Dropdown.Item
                                onClick={() => {
                                    setShowDeleteModal(true)
                                    setDefaultValue(original)
                                }}
                                className="text-danger">
                                <FontAwesomeIcon className="me-1" icon={"trash"}/>
                                <span>{t(lang, "roaming.invoice.delete")}</span>
                            </Dropdown.Item>}
                        </CardDropdown>}
                    </Fragment>

                );
            }
        },
    ]

    const onHideModal = () => {
        setDefaultValue(null)
        setShowEditModal(false)
        setShowDeleteModal(false)
    }

    const onPageChange = (page) => dispatch(changeFilterCashIn({...filters, page: page}))
    const onLimitChange = (limit) => dispatch(changeFilterCashIn({...filters, limit: limit, page: 1}))

    return (
        <Fragment>
            <AdvanceTableWrapper
                data={cashIn}
                columns={columns}
                pagination
                perPage={1000}
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                        <CashBoxCashInDataTableHeader
                            requestAsync={changeFilterCashIn}
                            registerRequest={registerCashIn}
                            filters={filters}
                            pageTitle={"cashboxes.cash-in.nav.label"}
                            currency={true}
                            permission={isCashBoxCashInCreate}
                        />
                        <div>
                            <Alert className={'d-inline-block px-2 py-0 mb-0 ms-1 fs--1'} variant={'primary'}>
                                <div className={'d-flex gap-1'}>
                                    <strong
                                        className={'fw-bold'}>{numeral.formats[numberFormat].format(totalAmountDataTable) || 0}</strong>
                                    <strong>{selectedCurrency?.name}</strong>
                                </div>
                            </Alert>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <CashBoxCommonRegisterModal show={showEditModal} setShow={setShowEditModal} showBtn={false}
                                        defaultValue={true}>
                <CashBoxCashInEdit registerRequest={updateCashIn} setShow={onHideModal} defaultValue={defaultValue}/>
            </CashBoxCommonRegisterModal>

            <CashBoxCashInDeleteModal show={showDeleteModal} setShow={onHideModal} id={defaultValue?.id}
                                      deleteRequest={deleteCashIn}/>

        </Fragment>
    );
};

export default CashBoxCashInDataTable;
