import { useSelector } from 'react-redux';
import {selectAccountRolePermissions} from "../app/store/reducers/role/roleReducer";

const useCheckPermission = (permission) => {
    const accountPermissions = useSelector(selectAccountRolePermissions);

    return accountPermissions?.includes(permission);
};

export default useCheckPermission;
