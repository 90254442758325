import React, {useEffect} from 'react';
import CashBoxExpenseDataTable from "../../../../components/hippo/cashbox/expense/CashBoxExpenseDataTable";
import {useDispatch, useSelector} from "react-redux";
import {
    getAllExpenseAsync,
    getExpenseCountAsync,
    selectCashBoxExpenseFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_EXPENSE_DELETE_SUCCESS,
    CASHBOX_EXPENSE_REGISTER_SUCCESS,
    CASHBOX_EXPENSE_UPDATE_SUCCESS
} from "../../../../app/eventbus/cashbox/cashboxEvents";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxExpenseView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const CashBoxExpense = () => {

    const dispatch = useDispatch()
    const filters = useSelector(selectCashBoxExpenseFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    // permissions
    const isCashBoxExpenseView = useCheckPermission(PermissionCashBoxExpenseView)

    const getExpenseAsync = () => {
        if (!isCashBoxExpenseView) return
        dispatch(getAllExpenseAsync({...filters}))
        dispatch(getExpenseCountAsync({...filters}))
    }

    useEffect(() => {
        getExpenseAsync()
    }, [filters, activeOrganization])

    useEffect(() => {
        const onSuccessRegisterExpense = EventBus.on(CASHBOX_EXPENSE_REGISTER_SUCCESS, () => {
            getExpenseAsync()
        })
        const onSuccessUpdateExpense = EventBus.on(CASHBOX_EXPENSE_UPDATE_SUCCESS, () => {
            getExpenseAsync()
        })
        const onSuccessDeleteExpense = EventBus.on(CASHBOX_EXPENSE_DELETE_SUCCESS, () => {
            getExpenseAsync()
        })

        return () => {
            EventBus.remove(CASHBOX_EXPENSE_REGISTER_SUCCESS, onSuccessRegisterExpense)
            EventBus.remove(CASHBOX_EXPENSE_UPDATE_SUCCESS, onSuccessUpdateExpense)
            EventBus.remove(CASHBOX_EXPENSE_DELETE_SUCCESS, onSuccessDeleteExpense)
        }
    }, [activeOrganization])

    if (!isCashBoxExpenseView) return <Error403 />

    return (
        <CashBoxExpenseDataTable/>
    );
};

export default CashBoxExpense;
