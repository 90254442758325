import React from 'react';
import classNames from "classnames";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useParams, useRouteMatch} from "react-router";
import {Card, Col, Row} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import {VIEWER_TELEGRAM_ORDER_BOT} from "../../../../enum/crm/TelegramBotWrapper";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import TelegramOrderBotDatatable
    from "../../../../components/hippo/crm/contractor/telegram-bot/order/TelegramOrderBotDatatable";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCRMTelegramOrderView} from "../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../components/errors/Error403";

const TelegramBots = () => {
    const {viewer} = useParams();
    const t = Translate;
    const lang = useSelector(selectLang);


    return (
        <div>
            <Row className="mb-3">
                <Col>
                    <Link to={`../telegram-bots/${VIEWER_TELEGRAM_ORDER_BOT}`} className="text-decoration-none">
                        <Card
                            className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_TELEGRAM_ORDER_BOT})}>
                            <Flex justifyContent="center" alignItems="center">
                                <Card.Title
                                    className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === VIEWER_TELEGRAM_ORDER_BOT})}>
                                    {t(lang, "crm.telegram_bot.order.type")}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                {/*<Col>*/}
                {/*    <Link to={`../telegram-bots/${VIEWER_TELEGRAM_AGENT_BOT}`} className="text-decoration-none">*/}
                {/*        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_TELEGRAM_AGENT_BOT})}>*/}
                {/*            <Flex justifyContent="center" alignItems="center">*/}
                {/*                <Card.Title className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === VIEWER_TELEGRAM_AGENT_BOT})}>*/}
                {/*                    {t(lang, "crm.telegram_bot.agent.type")}*/}
                {/*                </Card.Title>*/}
                {/*            </Flex>*/}
                {/*        </Card>*/}
                {/*    </Link>*/}
                {/*</Col>*/}
            </Row>
            {viewer === VIEWER_TELEGRAM_ORDER_BOT && <TelegramOrderBotDatatable/>}
        </div>
    );
};

export default TelegramBots;