import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Card, Col, Form, Row} from "react-bootstrap";
import RoamingActFormCardInfo from "./RoamingActFormCardInfo";
import RoamingActFormCardExecutor from "./RoamingActFormCardExecutor";
import RoamingActFormCardCustomer from "./RoamingActFormCardCustomer";
import RoamingActFormCardDescription from "./RoamingActFormCardDescription";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import RoamingActFormCardItems from "./RoamingActFormCardItems";
import IconButton from "../../../../common/IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";

const RoamingActForm = ({isSaving, isSending, onSend, onSave, isEdit, isCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Form>
            <Row className="g-3">
                <Col xs={12}>
                    <RoamingActFormCardInfo/>
                </Col>
                <Col xs={12} md={6}>
                    <RoamingActFormCardExecutor isEditOrCopy={isEdit || isCopy}/>
                </Col>
                <Col xs={12} md={6}>
                    <RoamingActFormCardCustomer isEditOrCopy={isEdit || isCopy}/>
                </Col>
                <Col xs={12}>
                    <RoamingActFormCardDescription/>
                </Col>
                <Col xs={12}>
                    <RoamingActFormCardItems/>
                </Col>
                <Col xs={12}>
                    <Card>
                        <Card.Body className="text-end">
                            <IconButton icon={faBookmark}
                                        variant="falcon-warning"
                                        size="lg"
                                        className="w-100 w-md-auto me-md-3"
                                        disabled={isSaving || isSending}
                                        onClick={onSave}
                            >
                                {t(lang, "roaming.invoice.save_button")}
                            </IconButton>
                            <SelectCertificateWrapperComponent size="lg" className="w-100 mt-2 mt-md-0 w-md-auto"
                                                               submitButton={
                                                                   <IconButton icon={faSignature}
                                                                               variant="falcon-primary"
                                                                               size="lg"
                                                                               className="w-100 mt-2 mt-md-0 w-md-auto"
                                                                               disabled={isSending || isSaving}
                                                                               id="Send"
                                                                               onClick={onSend}
                                                                   >
                                                                       {t(lang, "roaming.invoice.send_button")}
                                                                   </IconButton>
                                                               }
                                                               openDialogButtonText={t(lang, "roaming.invoice.send_button")}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

export default RoamingActForm;