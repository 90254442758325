import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import IconButton from "../../../common/IconButton";
import {Link} from "react-router-dom";
import {selectLang, Translate} from '../../../../app/store/reducers/main/mainReducer';
import {useDispatch, useSelector} from 'react-redux';
import {selectCount} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import SoftBadge from "../../../common/SoftBadge";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingContractCreate} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import ActionProgressBar from "../../../common/ActionProgressBar";
import ContractDraftDelete from "./ContractDraftDelete";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const ContractDataTableHeader = ({selectedRowIds, page, reloadRoamingContracts}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate
    const contractCount = useSelector(selectCount)


    const [showProgressBar, setShowProgressBar] = useState(false);
    const [bulkActionResult, setBulkActionResult] = useState([]);

    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedContract =  selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    // show progress modal
    const handleShowProgressBar = () => setShowProgressBar(true);
    // bulk action result
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);
    const handleCloseProgressBar = () => {
        reloadRoamingContracts();
        setBulkActionResult([]);
        setShowProgressBar(false);
    };

    return (
        <Fragment>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={selectedContract.length}
                               onClose={handleCloseProgressBar}
            />
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.contracts.datatable.title")}</h5>
                    <div className="mt-1">
                        {t(lang, "roaming.common.count")}:
                        <SoftBadge bg='success' className='ms-2'>
                            {contractCount}
                        </SoftBadge>
                    </div>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <div id="invoice-actions">
                        {!!selectedRowIndexArray.length && (
                           <ContractDraftDelete
                               contracts={selectedContract}
                               handleShowProgressBar={handleShowProgressBar}
                               updateActionResult={updateActionResult}
                           >
                               <IconButton
                                   variant="falcon-danger"
                                   size="sm"
                                   icon={faTrash}
                                   iconClassName="me-1"
                                   className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                               >
                                   {t(lang, 'common.button.delete')}
                               </IconButton>
                           </ContractDraftDelete>
                        )}
                        <IconButton
                            as={dispatch(checkPermission(PermissionRoamingContractCreate)) ? Link : 'div'}
                            onClick={()=>!dispatch(checkPermission(PermissionRoamingContractCreate)) && dispatch(toastPermission())}
                            to="/roaming/contract/send"
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            className="mx-2"
                            transform="shrink-3"
                        >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.contracts.datatable.register_contract")}</span>
                        </IconButton>
                    </div>
                </Col>
            </Row>
        </Fragment>

    );
};

ContractDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractDataTableHeader;
