import WarehouseOperationImageModal from "./WarehouseOperationImageModal";
import numeral from "numeral";
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React from "react";
import dayjs from "dayjs";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {calcDiscount, discountCheckReturnLabel} from "../enum/warehouseOperationWrapper";

const WarehouseOperationDatatableTab = ({dataItems}) => {
    const numberFormat = useSelector(selectNumberFormat)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate





    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(dataItems)



    return (
        <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>

            <Table className="border-bottom">
                <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                <tr>
                    <th className="align-middle">№</th>
                    <th className="align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                    <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                    <th className="text-end">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                    <th className="text-end">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                    <th className="text-end align-middle">{t(lang, "Скидка")}</th>
                    <th className="text-end align-middle">{t(lang, "Цена со скидка")}</th>
                    <th className="text-end">{t(lang, "warehouse.operation.item.common.total")}</th>
                    {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                        <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                    }
                    {warehouses?.length > 1 &&
                        <th className="text-end">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                    }
                    {dataItems?.some(d => d?.is_deleted) &&
                        <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                    }
                </tr>
                </thead>
                <tbody>

                {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                    return (
                        <React.Fragment key={rowIndex}>
                            {item?.sub?.map((subItem, subRowIndex) => {
                                return (
                                    <tr key={subItem.id}>
                                        <th key={rowIndex}
                                            className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                            rowSpan={item?.sub?.length}
                                            style={{width: "50px"}}
                                        >
                                            {groupByItemPriceCurrencyId?.length - rowIndex}
                                        </th>
                                        <th
                                            className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                            rowSpan={item?.sub?.length}>
                                            {subItem?.warehouse_item?.name}
                                        </th>
                                        <th
                                            className={subRowIndex === 0 ? "text-center border-end border-2 p-1" : "d-none"}
                                            style={{width: "50px"}} rowSpan={item?.sub?.length}>
                                            {items.find(x => x?.item?.id === subItem?.warehouse_item?.id)?.item?.images.length > 0 &&
                                                <WarehouseOperationImageModal
                                                    image={items.find(x => x?.item?.id === item?.item?.id)?.item?.images}/>
                                            }
                                        </th>
                                        <th className="text-end border-end border-2">
                                            {Number.isInteger(subItem?.quantity) ? subItem?.quantity : numeral.formats[numberFormat].format(subItem?.quantity)}
                                        </th>
                                        <th className="text-end border-end border-2 text-nowrap">
                                            {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                            <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                        </th>
                                        <th className="text-end border-end border-2 text-nowrap">
                                            {numeral.formats[numberFormat].format(subItem?.discount?.value || 0)}
                                            <span
                                                className="text-info"> {discountCheckReturnLabel(subItem?.discount?.type) || subItem?.price?.currency?.name}</span>
                                        </th>
                                        <th className="text-end border-end border-2 text-nowrap">
                                            {numeral.formats[numberFormat].format(calcDiscount({
                                                type: subItem?.discount?.type || 0,
                                                discountAmount: subItem?.discount?.value || 0,
                                                priceAmount: subItem?.price?.amount,
                                                quantity: subItem?.quantity,
                                            }))}
                                            <span className="text-info ms-1">{subItem?.price?.currency?.name}</span>
                                        </th>
                                        <th className="text-end border-end border-2 text-nowrap">
                                            {numeral.formats[numberFormat].format(subItem?.net_price?.amount || subItem?.quantity * subItem?.price?.amount)}
                                            <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                        </th>
                                        {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                            <th className="text-center border-end border-2">
                                                {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                <span> => </span>
                                                {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                            </th>
                                        }
                                        {warehouses?.length > 1 &&
                                            <th className="text-end border-end border-2">
                                                {subItem?.warehouse_item?.warehouse?.name}
                                            </th>
                                        }
                                        {subItem?.is_deleted &&
                                            <>
                                                <th className="text-nowrap text-center border-end border-2 text-danger"
                                                    style={{width: "150px"}}>
                                                    {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                </th>
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </React.Fragment>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
};

export default WarehouseOperationDatatableTab;