import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Form} from "react-bootstrap";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import SelectMeasurement from "../../../SelectMeasurement";
import ActionButton from "../../../../../common/ActionButton";
import CatalogLinkSelector from "../../../../../common/CatalogLinkSelector";
import CatalogPackageLinkSelector from "../../../../../common/CatalogPackageLinkSelector";
import {selectCompanyCatalogs} from "../../../../../../app/store/reducers/roaming/roamingReducer";
import {loadCatalogByCode} from "../../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {debounce} from "underscore";
import {getNumberFixedValue, getNumberValue} from "../../../../../../helpers/utils";
import {ROUND_SCALE_FOUR} from "../../../invoice/form/RoamingInvoiceFormItemRow";

const itemFieldName = 'items'
const ROUND_SCALE_FIVE = 5;

const ItemRowV2 = React.memo(({
                                  index,
                                  remove,
                                  allowDelete,
                                  isEditing,
                                  content,
                                  reversedCalculation,
                                  refs,
                                  handleKeyDown
                              }) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const companyCatalogs = useSelector(selectCompanyCatalogs);

    const [catalogPackages, setCatalogPackages] = useState(null)
    const {register, formState: {errors}, watch, setValue, control} = useFormContext();
    const {update} = useFieldArray({name: itemFieldName});
    const rootName = `${itemFieldName}.${index}`;
    const item = useWatch({name: rootName});
    const catalogCode = useWatch({name: `${rootName}.catalog_code`, exact: true})
    const packageCode = useWatch({name: `${rootName}.package_code`, exact: true})

    let itemErrors = {};
    if (errors && errors[itemFieldName] && errors[itemFieldName][index])
        itemErrors = errors[itemFieldName][index];



    useEffect( () => {
        const fetchCatalog = async () => {
            if ((isEditing || content) && companyCatalogs.length > 0) {
                if (catalogCode) {
                    const catalog = await loadCatalogByCode(catalogCode);
                    setCatalogPackages(catalog);
                }
            }
        };
        fetchCatalog();
    }, [isEditing, content, companyCatalogs.length, catalogCode]);

    useEffect( () => {
        const fetchCatalog = async () => {
            if (companyCatalogs.length > 0) {
                if (catalogCode) {
                    const code = onCatalogChanged(companyCatalogs.find(i => i.class_code === catalogCode));
                    if (!code) {
                        const catalog = await loadCatalogByCode(catalogCode);
                        setCatalogPackages(catalog);
                    }
                }
            }
        };
        fetchCatalog();
    }, [companyCatalogs, catalogCode])

    const onCatalogChanged = useCallback((option) => {
        if (option) {
            setCatalogPackages(option)
        } else {
            setCatalogPackages(null)
        }
        setValue(`${rootName}.measurement`, null);
        return option
    }, [setValue, rootName]);

    const deleteField = useCallback(() => {
        remove(index);
    }, [remove, index])


    const onChangeQuantity = useCallback(
        debounce((value, onChange) => {
            onChange(value)
            if (!reversedCalculation) {
                const total = getNumberValue(value) * getNumberValue(item.price);
                setValue(`${rootName}.total`, getNumberFixedValue(total, ROUND_SCALE_FOUR) || 0);
            } else {
                const summa = getNumberValue(item.total) / getNumberValue(item.price);
                setValue(`${rootName}.price`, getNumberFixedValue(summa, ROUND_SCALE_FOUR) || 0);
            }
        }, 300),
        [item]
    );

    const onChangePrice = useCallback(
        debounce((value, onChange) => {
            onChange(value)
            if (!reversedCalculation) {
                const total = getNumberValue(value) * getNumberValue(item.quantity);
                setValue(`${rootName}.total`, getNumberFixedValue(total, ROUND_SCALE_FOUR) || 0);
            } else {
                const summa = getNumberValue(item.total) / getNumberValue(item.quantity);
                setValue(`${rootName}.price`, getNumberFixedValue(summa, ROUND_SCALE_FOUR) || 0);
            }
        }, 300), [item]
    );


    const onChangeTotal = useCallback(
        debounce((value, onChange) => {
            onChange(value)
            if (reversedCalculation) {
                const summa = getNumberValue(item.total) / getNumberValue(item.quantity);
                setValue(`${rootName}.price`, getNumberFixedValue(summa, ROUND_SCALE_FOUR) || 0);
            }
        }, 300),
        [item]
    );


    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td className="p-1" ref={refs?.name} style={{width: 450}}>
                <Form.Control onKeyDown={(e) => handleKeyDown(e, index, 'name')}
                              placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}
                              {...register(`${rootName}.name`, {required: t(lang, "edi.common.forms.validations.is_required")})}
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.name?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1" ref={refs?.catalog_code}>
                <Controller
                    name={`${rootName}.catalog_code`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={
                        ({field}) => (
                            <CatalogLinkSelector
                                defaultCatalog={field.value}
                                textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                onChange={(catalog) => {
                                    field.onChange(catalog?.class_code || null)
                                    setValue(`${rootName}.package_code`, null);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, index, 'catalog_code')}
                                onFocus={(e) => e.target.classList.add('btn-primary')}
                                onBlur={(e) => e.target.classList.remove('btn-primary')}
                            />
                        )
                    }
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.catalog_code?.message}
                </Form.Control.Feedback>
            </td>
            {catalogPackages && <td className="p-1" ref={refs?.package_code}>
                <Controller name={`${rootName}.package_code`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field}) => (
                                <CatalogPackageLinkSelector
                                    textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                    defaultCatalogPackage={packageCode?.code ? packageCode.code : packageCode}
                                    catalogPackages={catalogPackages?.package_names}
                                    onChange={field.onChange}
                                    onKeyDown={(e) => handleKeyDown(e, index, 'package_code')}
                                    onFocus={(e) => e.target.classList.add('btn-primary')}
                                    onBlur={(e) => e.target.classList.remove('btn-primary')}
                                    /*linkProps={{
                                        className: classNames({
                                            'text-primary cursor-pointer fs--1': !error?.package,
                                            'text-danger cursor-pointer fs--1': error?.package
                                        })
                                    }}*/
                                />
                            )}

                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.package_code?.message}
                </Form.Control.Feedback>
            </td>}
            {!catalogPackages && <td className="p-1">
                <Controller
                    control={control}
                    name={`${rootName}.measurement`}
                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                    render={({field}) => (
                        <SelectMeasurement
                            ref={refs?.package_code}
                            isClearable
                            classNamePrefix="react-select"
                            defaultMeasureId={field.value}
                            menuPortalTarget={document.body}
                            placeholder={t(lang, "edi.common.select_button")}
                            onChange={measurement => field.onChange(measurement?.measureId)}
                            className={classNames({"is-invalid": itemErrors?.measureId})}
                            wrapperClassName={classNames({'is-invalid': errors?.measureId})}
                            onKeyDown={(e) => handleKeyDown(e, index, 'package_code', true)}
                        />
                    )}
                />
                <Form.Control.Feedback className={'d-block'} type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>}


            <td className="p-1" ref={refs?.quantity}>
                <Controller
                    control={control}
                    name={`${rootName}.quantity`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            htmlRef={field.ref}
                            options={{numeral: true, delimiter: ' ', numeralDecimalScale: ROUND_SCALE_FIVE}}
                            className={'form-control'}
                            value={field.value}
                            onKeyDown={(e) => {
                                handleKeyDown(e, index, 'quantity')
                                if (e.keyCode === 188 || e.keyCode === 188)
                                    e.target.value += "."
                            }}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\s/g, '');
                                onChangeQuantity(value, field.onChange);
                            }}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1" ref={refs?.price}>
                <Controller
                    control={control}
                    name={`${rootName}.price`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            disabled={reversedCalculation}
                            htmlRef={field.ref}
                            options={{numeral: true, delimiter: ' ', numeralDecimalScale: 4}}
                            className={'form-control'}
                            value={field.value}
                            onKeyDown={(e) => {
                                handleKeyDown(e, index, 'price')
                                if (e.keyCode === 188 || e.keyCode === 188)
                                    e.target.value += "."
                            }}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\s/g, '');
                                onChangePrice(value, field.onChange);
                            }}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1" ref={refs?.total}>
                <Controller
                    control={control}
                    name={`${rootName}.total`}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            disabled={!reversedCalculation}
                            htmlRef={field.ref}
                            options={{numeral: true, delimiter: ' ', numeralDecimalScale: 4}}
                            className={'form-control'}
                            value={field.value}
                            onKeyDown={(e) => {
                                handleKeyDown(e, index, 'total')
                                if (e.keyCode === 188 || e.keyCode === 188)
                                    e.target.value += "."
                            }}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\s/g, '');
                                onChangeTotal(value, field.onChange);
                            }}
                            placeholder={t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}
                        />
                    )}
                />
            </td>
            <td className="text-end p-1 align-middle">
                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1 text-danger"
                              onClick={deleteField} disabled={!allowDelete}/>
            </td>
        </tr>
    );
});

export default ItemRowV2;
