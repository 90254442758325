import {Card, Form} from "react-bootstrap";
import {toast} from "react-toastify";
import React, {useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import CommonDeleteModal from "../../../../common/CommonDeleteModal";
import AddOrganizationBalanceBindingModal from "./AddOrganizationBalanceBindingModal";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED,
    REMOVE_ORGANIZATION_BALANCE_BINDING_FAILED,
    REMOVE_ORGANIZATION_BALANCE_BINDING_REQUEST,
    REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED
} from "../../../../../app/eventbus/billingEvents";
import BillingService from "../../../../../services/billingService";
import DataTable from "../../../../common/table/DataTable";
import DataTablePagination from "../../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../../common/table/DataTableLimiter";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import DataTableColumnSettings from "../../../../common/table/DataTableColumnSettings";

const OrganizationBalanceBindingDatatable = ({
                                                 payerOrganizationId,
                                                 organizationBalanceBindings,
                                                 organizationBalanceBindingsCount,
                                                 filter,
                                                 pagination,
                                                 rowSelection,
                                                 lastRowSelected,
                                                 columnVisibility,
                                                 loading,
                                                 onDebouncedFilterChange,
                                                 onFilterChange,
                                                 onPaginationChange,
                                                 onColumnVisibilityChange,
                                                 onRowSelectionChange,
                                                 onReloadData
                                             }) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const columns = useMemo(() => {
        return [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i]?.id] = true;
                        }

                        onRowSelectionChange(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'organizationName',
                accessorKey: t(lang, "roaming.common.organization"),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-start text-dark fs--1"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >
                            {t(lang, "roaming.common.organization")}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-start text-dark"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >
                            <h5 className="text-dark fw-semi-bold fs--1 text-truncate">{original.target_organization.name}</h5>
                            <h6>{original.target_organization.identifier || ''}</h6>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'organizationCode',
                accessorKey: t(lang, 'partners.common.organization_code'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: '150px',
                                maxWidth: '150px'
                            }}
                        >
                            {t(lang, 'partners.common.organization_code')}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '150px',
                                maxWidth: '150px'
                            }}
                        >
                            <h6 className="text-center">
                                <strong>
                                    {original.target_organization.code}
                                </strong>
                            </h6>
                        </td>
                    )
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: '150px',
                                maxWidth: '150px'
                            }}
                        >
                        </td>
                    )
                }
            },
            {
                id: 'action',
                accessorKey: t(lang, 'notification.table.actions'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            <DataTableColumnSettings table={table}/>
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    const removeOrganizationBalanceBinding = (id) => {
                        EventBus.dispatch(REMOVE_ORGANIZATION_BALANCE_BINDING_REQUEST, id);
                        BillingService.removeOrganizationBalanceBinding({
                            id: id,
                            payer_organization_id: payerOrganizationId
                        })
                            .then(response => {
                                EventBus.dispatch(REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED, response.data);
                                toast.success(t(lang, 'common.toast.success'));
                            })
                            .catch(error => {
                                EventBus.dispatch(REMOVE_ORGANIZATION_BALANCE_BINDING_FAILED, error);
                                toast.error(t(lang, 'common.toast.error'));
                            })
                    };

                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <CommonDeleteModal onDelete={() => removeOrganizationBalanceBinding(original.id)}>
                                <IconButton icon={'trash'} variant={'danger'} size={'sm'}/>
                            </CommonDeleteModal>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            }
        ];
    }, [t, lang, pagination]);
    const data = useMemo(() => {
        return organizationBalanceBindings;
    }, [organizationBalanceBindings]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: onRowSelectionChange,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        rowCount: organizationBalanceBindingsCount,
        meta: {
            getHeaderRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getFooterRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            }
        },
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        const onAddOrganizationBindingSucceed = EventBus.on(ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED, () => {
            onReloadData();
        });

        const onRemoveOrganizationBindingSucceed = EventBus.on(REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED, () => {
            onReloadData();
        });

        return () => {
            EventBus.remove(ADD_ORGANIZATION_BALANCE_BINDING_SUCCEED, onAddOrganizationBindingSucceed);
            EventBus.remove(REMOVE_ORGANIZATION_BALANCE_BINDING_SUCCEED, onRemoveOrganizationBindingSucceed);
        };
    }, [onReloadData]);

    return (
        <Card>
            <Card.Header className={'bg-light d-flex flex-between-center'}>
                <div className="d-flex flex-column">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, 'main.billing.tabs.other.balance_bindings')}</h5>
                    <small className="mb-1">{t(lang, 'roaming.invoices.datatable.invoices_count')}: <span
                        className="fw-semi-bold text-black mx-1 fs--1">{organizationBalanceBindingsCount}</span></small>
                </div>
                <AddOrganizationBalanceBindingModal payerOrganizationId={payerOrganizationId}/>
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               style: {
                                   borderWidth: "2px 0",
                                   borderColor: "lightGrey"
                               }
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={organizationBalanceBindingsCount}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    )
};

export default OrganizationBalanceBindingDatatable;