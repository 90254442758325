import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Dashboard from "./Dashboard"
import ViewVerificationAct from './verification-act/ViewVerificationAct';
import SendVerificationAct from './verification-act/SendVerificationAct';
import RoamingInvoices from "./invoice/RoamingInvoices";
import ViewRoamingInvoice from "./invoice/ViewRoamingInvoice";
import {loadMeasurementsAsync} from "../../../app/store/reducers/roaming/roamingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../app/store/reducers/main/mainReducer";
import ViewEmpowerment from "./empowerment/ViewEmpowerment";
import SendEmpowerment from "./empowerment/SendEmpowerment";
import Empowerments from "./empowerment/Empowerments";
import {VIEWER_CUSTOMER} from "../../../enum/EmpowermentStatus";
import SendWaybill from "./waybill/SendWaybill";
import {VIEWER_CUSTOMER as ROAMING_INVOICE_VIEWER_CUSTOMER} from "../../../enum/RoamingInvoiceWrapper";
import Waybills from "./waybill/Waybills";
import {VIEW_INBOX} from "../../../enum/roaming/WaybillStatus";
import ViewWaybill from "./waybill/ViewWaybill";
import SendContract from "./contract/SendContract";
import ViewContract from './contract/ViewContract';
import Contracts from './contract/Contracts';
import VerificationActs from './verification-act/VerificationActs';
import SendAct from "./act/SendAct";
import ViewAct from "./act/ViewAct";
import Acts from "./act/Acts";
import {getOrganizationBillingInfoAsync} from "../../../app/store/reducers/billing/billingReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {ACTIVATE_DEMO_SUCCEED} from "../../../app/eventbus/billingEvents";
import ViewDraftVerificationAct from './verification-act/ViewDraftVerificationAct';
import EditVerificationAct from './verification-act/EditVerificationAct';
import DraftActView from "../../../components/hippo/roaming/act/View/DraftActView";
import ViewDraftContract from './contract/ViewDraftContract';
import DraftEmpowerment from "../../../components/hippo/roaming/empowerement/draftEmpowerment/DraftEmpowerment";
import DraftEmpowermentEdit from "../../../components/hippo/roaming/empowerement/draftEmpowerment/DraftEmpowermentEdit";
import ViewDraftWaybill from "./waybill/ViewDraftWaybill";
import EditWaybill from "./waybill/EditWaybill";
import SendFreeFormDocument from "./freeform/SendFreeFormDocument";
import ViewFreeFormDocument from "./freeform/ViewFreeFormDocument";
import FreeFormDocuments from "./freeform/FreeFormDocuments";
import ViewRoamingInvoiceDraft from "./invoice/ViewRoamingInvoiceDraft";
import MassAddRoamingInvoice from "./invoice/MassAddRoamingInvoice";
import ExcelRegistries from "./excel-registry/ExcelRegistries";
import ViewExcelRegistry from "./excel-registry/ViewExcelRegistry";
import FillEmpowerment from "./empowerment/FillEmpowerment";
import FillVerificationAct from "./verification-act/FillVerificationAct";
import FillWaybill from "./waybill/FillWaybill";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import SendRoamingInvoice from "./invoice/SendRoamingInvoice";
import {loadCompanyCurrentTurnOver} from "../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import dayjs from "dayjs";
import RoamingProviderBinding from "../../../components/hippo/roaming/RoamingProviderBinding";
import TasnifAlert from "../../../components/hippo/roaming/common/TasnifAlert";
import SendWaybillV2 from "./waybill/SendWaybillV2";
import WaybillsV2 from "./waybill/WaybillsV2";
import EditWaybillV2 from "./waybill/EditWaybillV2";
import ViewDraftWaybillV2 from "./waybill/ViewDraftWaybillV2";
import ViewWaybillV2 from "./waybill/ViewWaybillV2";
import OrganizationAddresses from "./organization-address/OrganizationAddresses";
import MassRegisterRoamingWaybill from "./waybill/mass-register/MassRegisterRoamingWaybill";
import SystemCompanyAddress from "./system-company-address/SystemCompanyAddress";
import SendRoamingConstructionInvoice
    from "../../../components/hippo/roaming/invoice/form/SendRoamingConstructionInvoice";

const RoamingLayout = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const {url} = useRouteMatch();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang))
    }, [lang]);

    useEffect(() => {
        const activateDemoPeriodHandler = EventBus.on(ACTIVATE_DEMO_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_DEMO_SUCCEED, activateDemoPeriodHandler);
        }
    }, [])

    useEffect(() => {
        const date = dayjs(new Date())
        dispatch(loadCompanyCurrentTurnOver(date.format('YYYY')))
    }, [activeOrganization])

    if (!activeOrganization.inn) {
        return <Redirect to={'/'}/>
    }

    return (
        <>
            <RoamingProviderBinding/>
            <TasnifAlert/>

            <Switch>
                <Route path={`${url}`} exact component={Dashboard}/>

                <Route path={`${url}/contract/send`} component={SendContract}/>
                <Route path={`${url}/contract/draft/:id`} component={ViewDraftContract}/>
                <Route path={`${url}/contract/:id`} component={ViewContract}/>
                <Route path={`${url}/contracts/:type_viewer`} component={Contracts}/>
                <Redirect from={`${url}/contracts`} to={`./contracts/${VIEW_INBOX}`}/>

                <Route path={`${url}/invoice/send`} component={SendRoamingInvoice}/>
                <Route path={`${url}/construction-invoice/send`} component={SendRoamingConstructionInvoice}/>
                <Route path={`${url}/invoice/mass-add`} component={MassAddRoamingInvoice}/>
                <Route path={`${url}/invoice/draft/:id`} component={ViewRoamingInvoiceDraft}/>
                <Route path={`${url}/invoice/:id`} component={ViewRoamingInvoice}/>
                <Route path={`${url}/invoices/:viewer`} component={RoamingInvoices}/>
                <Redirect from={`${url}/invoices`} to={`./invoices/${ROAMING_INVOICE_VIEWER_CUSTOMER}`}/>

                <Route path={`${url}/empowerment/send`} component={SendEmpowerment}/>
                <Route path={`${url}/empowerment/draft-edit/:id`} component={DraftEmpowermentEdit}/>
                <Route path={`${url}/empowerment/actions/:id`} component={FillEmpowerment}/>
                <Route path={`${url}/empowerment/:id`} component={ViewEmpowerment}/>
                <Route path={`${url}/draft-empowerment/:id`} component={DraftEmpowerment}/>
                <Route path={`${url}/empowerments/:viewer`} component={Empowerments}/>
                <Redirect from={`${url}/empowerments`} to={`${url}/empowerments/${VIEWER_CUSTOMER}`}/>

                <Route path={`${url}/verification-act/send`} component={SendVerificationAct}/>
                <Route path={`${url}/verification-act/draft/edit/:id`} component={EditVerificationAct}/>
                <Route path={`${url}/verification-act/draft/:id`} component={ViewDraftVerificationAct}/>
                <Route path={`${url}/verification-act/actions/:id`} component={FillVerificationAct}/>
                <Route path={`${url}/verification-act/:id`} component={ViewVerificationAct}/>
                <Route path={`${url}/verification-acts/:type_viewer`} component={VerificationActs}/>
                <Redirect from={`${url}/verification-acts`} to={`./verification-acts/${VIEW_INBOX}`}/>

                <Route path={`${url}/waybill-v2/draft/:id`} exact component={ViewDraftWaybillV2}/>
                <Route path={`${url}/waybill-v2/edit/:id`} exact component={EditWaybillV2}/>
                <Route path={`${url}/waybill-v2/send`} exact component={SendWaybillV2}/>
                <Route path={`${url}/waybill-v2/:id`} exact component={ViewWaybillV2}/>
                <Route path={`${url}/v2-waybills/:viewer`} exact component={WaybillsV2}/>
                <Redirect from={`${url}/v2-waybills`} exact to={`${url}/v2-waybills/${VIEW_INBOX}`}/>

                <Route path={`${url}/waybill/send`} component={SendWaybill}/>
                <Route path={`${url}/waybill/mass-register`} component={MassRegisterRoamingWaybill}/>
                <Route path={`${url}/waybill/edit/:id`} component={EditWaybill}/>
                <Route path={`${url}/waybill/draft/:id`} component={ViewDraftWaybill}/>
                <Route path={`${url}/waybill/actions/:id`} component={FillWaybill}/>
                <Route path={`${url}/waybill/:id`} component={ViewWaybill}/>
                <Route path={`${url}/waybills/:viewer`} component={Waybills}/>
                <Redirect from={`${url}/waybills`} to={`./waybills/${VIEW_INBOX}`}/>

                <Route path={`${url}/acts/:viewer`} component={Acts}/>
                <Route path={`${url}/act/register`} component={SendAct}/>
                <Route path={`${url}/act/:id`} component={ViewAct}/>
                <Route path={`${url}/draft-act/:id`} component={DraftActView}/>
                <Redirect path={`${url}/acts`} to={`./acts/${VIEW_INBOX}`}/>

                <Route path={`${url}/free-forms/send`} component={SendFreeFormDocument}/>
                <Route path={`${url}/free-forms/:viewType`} component={FreeFormDocuments}/>
                <Route path={`${url}/free-form/:id`} component={ViewFreeFormDocument}/>
                <Redirect from={`${url}/free-forms`} to={`./free-forms/${FreeFormDocuments.ViewTypeInbox}`}/>

                <Route path={`${url}/excel-registry/:id`} component={ViewExcelRegistry}/>
                <Route path={`${url}/excel-registries`} component={ExcelRegistries}/>

                <Route path={`${url}/organization-addresses`} component={OrganizationAddresses}/>
                <Route path={`${url}/system-organization-addresses`} component={SystemCompanyAddress}/>
            </Switch>
        </>
    );
}

export default RoamingLayout;
