import {Card, Col, OverlayTrigger, Popover, Row, Table} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import SoftBadge from "../../common/SoftBadge";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import ImageSlider from "./components/ImageSlider";
import itemWrapper from "./enum/itemWrapper";
import IconButton from "../../common/IconButton";
import {Link} from "react-router-dom";
import {selectCurrency} from "../../../app/store/reducers/currency/currencyReducer";
import CatalogStatusBadge from "./components/CatalogStatusBadge";
import DeleteItemButton from "./components/DeleteItemButton";
import ValidateCatalogButton from "./components/ValidateCatalogButton";
import AddToFavoriteButton from "./components/AddToFavoriteButton";
import RemoveFromFavoriteButton from "./components/RemoveFromFavoriteButton";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import useCheckPermission from "../../../hooks/useCheckPermission";
import {
    PermissionItemCreate,
    PermissionItemDelete,
    PermissionItemUpdate
} from "../../../enum/Permission/WarehouseItemPermission";


const Item = ({item: {item, price, warehouse_states: {warehouse_items}}}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);

    // permissions
    const isItemCreate = useCheckPermission(PermissionItemCreate)
    const isItemEdit = useCheckPermission(PermissionItemUpdate)
    const isItemDelete = useCheckPermission(PermissionItemDelete)

    const getCurrencyName = (id) => {
        const currency = currencies.find(c => c.id === id);
        if (currency)
            return currency.name;
        return "";
    };


    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <h3 className="text-600 col-md-8">
                                    {item.name}
                                </h3>
                                <h5>
                                    {t(lang, "items.common.created_at", {created_at: item.created_at})}
                                </h5>
                            </Col>
                            <Col xs={12} className="mt-3 d-lg-flex flex-wrap">
                                <div className="d-grid d-md-block">
                                    {isItemDelete && <RemoveFromFavoriteButton item={item}/>}
                                    <AddToFavoriteButton item={item}/>
                                    {isItemEdit && <IconButton as={Link}
                                                 variant="falcon-warning"
                                                 size="sm"
                                                 icon={'fa-pencil'}
                                                 to={`edit/${item.id}`}
                                                 className="mt-2 mt-md-0 ms-md-2"
                                    >
                                        {t(lang, "items.common.edit")}
                                    </IconButton>}
                                    {isItemCreate && <Link to={`/warehouse/items/add?item_id=${item.id}`}>
                                        <IconButton size='sm'
                                                    variant='falcon-primary'
                                                    icon={faClone}
                                                    className='mt-2 mt-lg-0 ms-md-2'
                                        >
                                            {t(lang, 'roaming.invoice.copy')}
                                        </IconButton>
                                    </Link>}
                                </div>
                                <div className="d-grid d-md-block">
                                    <ValidateCatalogButton id={item.id} />
                                    {isItemDelete && <DeleteItemButton item={item}/>}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} className="mt-3">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col lg={6} className="mb-4 mb-lg-0 h-sm-100">
                                <ImageSlider product={{
                                    images: item.images
                                }}/>
                            </Col>
                            <Col lg={6}>
                                <Table className="fs--1">
                                    <tbody>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.price")}</td>
                                        <td>
                                            <span className="fw-bold">{price.common_price.amount} {getCurrencyName(price.common_price.currency.global_currency_id)}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.bulk_price")}</td>
                                        <td>
                                            <span className="fw-bold">{price.bulk_price.amount} {getCurrencyName(price.bulk_price.currency.global_currency_id)}</span>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.purchase_price")}</td>
                                        <td>
                                            {warehouse_items.length && warehouse_items?.map((warehouseItem) => {
                                                if (warehouseItem?.purchase_price) {
                                                    return <span className="fw-bold" key={warehouseItem.id}>
                                                        {warehouseItem?.name} {t(lang, "items.common.purchase_price.warehouse")}: {warehouseItem?.purchase_price?.price?.amount} {warehouseItem?.purchase_price?.price?.currency?.name}<br />
                                                    </span>
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.is_state_controlled")}</td>
                                        <td>{item.is_state_controlled ? t(lang, "items.common.yes") : t(lang, "items.common.no")}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.measurement")}</td>
                                        <td>{t(lang, itemWrapper.getMeasurement(item.measurement))}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.package_measurement")}</td>
                                        <td>{item.package_measurements.map((packageMeasurement, index) => (
                                            <OverlayTrigger
                                                key={index}
                                                trigger={['hover', 'focus']}
                                                placement="bottom"
                                                overlay={
                                                    <Popover id="popover-basic" className='ms-0'>
                                                        <Popover.Body className={'py-2 px-3'}>
                                                            <h6 className={'mb-0'}>
                                                                <span className='me-2'>{t(lang, 'items.common.package_measurement.quantity')}:</span>
                                                                <SoftBadge bg={'primary'}>{packageMeasurement.quantity}</SoftBadge>
                                                            </h6>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <SoftBadge bg={'primary'} className={'me-1'}>{packageMeasurement.name}</SoftBadge>
                                            </OverlayTrigger>
                                        ))}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.sku")}</td>
                                        <td>{item.sku}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.code")}</td>
                                        <td>{item.code}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.barcode")}</td>
                                        <td>[{item.barcodes.map((barcode, index) => {
                                            if (index !== item.barcodes.length - 1)
                                                return barcode + ", "
                                            return barcode
                                        })}]
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.roaming_measurement")}</td>
                                        <td>{item.tax?.measurement || item.tax?.catalog?.package?.name  || t(lang, "items.common.not_selected")}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.catalog")}</td>
                                        <td>{item.tax?.catalog?.code ? <CatalogStatusBadge catalogCode={item.tax.catalog.code} tasnif_info={item.tasnif_info} /> : t(lang, "items.common.not_selected")}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.tax_rate")}</td>
                                        <td>{(item.tax && item.tax?.tax_rate !== null) ? item.tax.tax_rate : t(lang, "items.common.tax_rate_options.0")}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-100 w-25">{t(lang, "items.common.description")}</td>
                                        <td>{item.description ? item.description : t(lang, "items.common.not_selected")}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} className="mt-6">
                                {item.tax?.benefit &&
                                    <i className="text-dark">
                                        <span className="text-danger">*</span><strong
                                        className="mx-1">{t(lang, "items.common.grouped_privilege_info_id")} </strong>{item.tax.benefit?.id}
                                        <strong
                                            className="mx-1">{t(lang, "items.common.grouped_privilege_info_name")} </strong><span
                                        className="text-primary">{item.tax.benefit?.name}</span>
                                        {/*<strong className="mx-1">{t(lang,"edi.invoice.grouped_privilege_info_total")} </strong>{key.benefit_vat_total_sum} сўм*/}
                                        <strong
                                            className="mx-1">{t(lang, "items.common.grouped_privilege_info_benefit_vat_total_sum")} </strong>{item.tax.benefit?.tax_price} сўм
                                    </i>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}


export default Item;
