import React, {useEffect} from "react"
import {Form, InputGroup} from "react-bootstrap"
import {Controller, useFormContext, useWatch} from "react-hook-form"
import Cleave from 'cleave.js/react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer"
import {useSelector} from "react-redux"
import classNames from "classnames";
import {getNumberFixedValue, getNumberValue} from "../../../../../helpers/utils";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import CatalogPackageLinkSelector from "../../../../common/CatalogPackageLinkSelector";
import MeasurementLinkSelector from "../../../../common/MeasurementLinkSelector";
import VatRateLinkSelector from "../../../../common/VatRateLinkSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ROUND_SCALE_FOUR = 4;
const ROUND_SCALE_TWO = 2;
const ROUND_SCALE_FIVE = 5;

const Products = ({index, removeFieldProduct, onChangeAmounts, fieldName, allowDeleteItem, insertFieldProduct}) => {

    const lang = useSelector(selectLang)
    const t = Translate

    const {
        register,
        setValue,
        formState: {errors: productError},
    } = useFormContext()

    const errors = productError.products || []

    const item = useWatch({name: `${fieldName}`});

    useEffect(() => {
        const deliverySum = getNumberValue(item.quantity) * getNumberValue(item.price);
        const vatSum = item.vat_rate ? getNumberValue(deliverySum) * getNumberValue(item.vat_rate) / 100 : '';
        const deliverySumWithVat = vatSum ? getNumberValue(deliverySum) + getNumberValue(vatSum) : '';

        setValue(`${fieldName}.total`, getNumberFixedValue(deliverySum, ROUND_SCALE_TWO));
        setValue(`${fieldName}.vat_sum`, getNumberFixedValue(vatSum, ROUND_SCALE_TWO));
        setValue(`${fieldName}.total_with_delivery_sum`, getNumberFixedValue(deliverySumWithVat, ROUND_SCALE_TWO));

        onChangeAmounts();
    }, [item.quantity, item.price, item.vat_rate]);

    const onChangeCatalog = () => {
        setValue(`${fieldName}.package`, null);
        setValue(`${fieldName}.measurement`, null);
    };

    return (
        <tr className="position-relative">
            <td className="p-0 align-middle text-center" style={{minWidth: '30px'}}>
                <span style={{height: '40px'}}>{index + 1}</span>
            </td>
            <td style={{minWidth: '350px'}} className="p-0">
                <Controller
                    name={`${fieldName}.name`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field, fieldState: {error}}) => (
                        <InputGroup>
                            <Form.Control
                                name={`${fieldName}.name`}
                                className="px-1"
                                style={{height: '40px', border: !error && 'none'}}
                                isInvalid={!!error}
                                value={item.name}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        </InputGroup>
                    )}
                />
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                <Controller
                    name={`${fieldName}.catalog`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field, fieldState: {error}}) => (
                        <CatalogLinkSelector defaultCatalog={item.catalog}
                                             onChange={(option) => {
                                                 field.onChange(option);
                                                 onChangeCatalog(option);
                                             }}
                                             linkProps={{
                                                 className: classNames({
                                                     'text-primary cursor-pointer fs--1': !error,
                                                     'text-danger cursor-pointer fs--1': !!error
                                                 })
                                             }}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.catalogCode?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-0">
                <Form.Control style={{height: '40px', border: 'none'}}
                    {...register(`products.${index}.barcode`)}
                    className="px-1"
                />
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                {item?.catalog?.use_package &&
                    <Controller name={`${fieldName}.package`}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required')
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <CatalogPackageLinkSelector catalogPackages={item?.catalog?.package_names}
                                                                defaultCatalogPackage={item.package}
                                                                onChange={field.onChange}
                                                                linkProps={{
                                                                    className: classNames({
                                                                        'text-primary cursor-pointer fs--1': !error,
                                                                        'text-danger cursor-pointer fs--1': !!error
                                                                    })
                                                                }}
                                    />
                                )}
                    />
                }
                {!item?.catalog?.use_package &&
                    <Controller name={`${fieldName}.measurement`}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required')
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <MeasurementLinkSelector onChange={field.onChange}
                                                             defaultMeasurement={item.measurement}
                                                             linkProps={{
                                                                 className: classNames({
                                                                     'text-primary cursor-pointer fs--1': !error,
                                                                     'text-danger cursor-pointer fs--1': !!error
                                                                 })
                                                             }}
                                    />
                                )}
                    />
                }
            </td>
            <td className="p-0">
                <Controller
                    name={`${fieldName}.quantity`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required'),
                    }}
                    render={({field, fieldState: {error}}) => (
                        <Cleave htmlRef={field.ref}
                                className={classNames('form-control px-1 text-end', {
                                    'is-invalid': !!error
                                })}
                                style={{height: '40px', border: !error && 'none'}}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 188)
                                        e.target.value += "."
                                }}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: ROUND_SCALE_FIVE,
                                }}
                                value={item.quantity}
                                onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '140px', width: '140px'}} className="p-0">
                <Controller
                    name={`${fieldName}.price`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required'),
                    }}
                    render={({field, fieldState: {error}}) => (
                        <Cleave htmlRef={field.ref}
                                className={classNames('form-control px-1 text-end', {
                                    'is-invalid': !!error
                                })}
                                style={{height: '40px', border: !error && 'none'}}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 188)
                                        e.target.value += "."
                                }}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: ROUND_SCALE_FOUR,
                                }}
                                value={item.price}
                                onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0">
                <Controller
                    name={`${fieldName}.total`}
                    render={({field}) => (
                        <Cleave className="form-control px-1 text-end"
                                disabled={true}
                                style={{height: '40px', border: 'none'}}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 188)
                                        e.target.value += "."
                                }}
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: ROUND_SCALE_TWO,
                                }}
                                value={item.total}
                                onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '60px', width: '60px'}} className="p-0 align-middle text-center">
                <Controller name={`${fieldName}.vat_rate`}
                            render={({field, fieldState: {error}}) => (
                                <VatRateLinkSelector defaultVatRate={item.vat_rate ? item.vat_rate?.toString() : item.vat_rate}
                                                     onChange={field.onChange}
                                                     linkProps={{
                                                         className: classNames({
                                                             'text-primary cursor-pointer fs--1': !error,
                                                         })
                                                     }}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '130px', width: '130px'}} className="p-0">
                <Controller name={`${fieldName}.vat_sum`}
                            render={({field}) => (
                                <Cleave className="form-control px-1 text-end"
                                        style={{height: '40px', border: 'none'}}
                                        disabled={true}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_TWO,
                                        }}
                                        value={item.vat_sum}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '160px', width: '160px'}} className="p-0">
                <Controller name={`${fieldName}.total_with_delivery_sum`}
                            render={({field}) => (
                                <Cleave className="form-control px-1 text-end"
                                        style={{height: '40px', border: 'none'}}
                                        disabled={true}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 188)
                                                e.target.value += "."
                                        }}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: ROUND_SCALE_TWO,
                                        }}
                                        value={item.total_with_delivery_sum}
                                        onChange={e => field.onChange(e.target.rawValue)}
                                />
                            )}
                />
            </td>
            <td style={{minWidth: '30px', width: '30px'}} className="p-0 flex-wrap">
                <div className="d-flex flex-column justify-content-center align-items-center"
                     style={{height: '40px', minHeight: '40px'}}
                >
                    <FontAwesomeIcon icon="plus"
                                     className="text-success cursor-pointer"
                                     title={t(lang, 'roaming.common.add')}
                                     onClick={() => insertFieldProduct(index + 1)}
                    />
                    {allowDeleteItem &&
                        <FontAwesomeIcon icon="trash-alt"
                                         className="text-danger cursor-pointer mt-2"
                                         title={t(lang, 'roaming.invoice.delete')}
                                         onClick={() => removeFieldProduct(index)}
                        />
                    }
                </div>
            </td>
        </tr>
    )
}

export default Products