import React from 'react';
import {getTransferAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import CashBoxTransfer from "../../../../components/hippo/cashbox/transfer/CashBoxTransfer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxTransferView} from "../../../../enum/Permission/CashBoxPermission";
import Error403 from "../../../../components/errors/Error403";

const ViewTransfer = ({viewId}) => {

    // permissions
    const isCashBoxTransferView = useCheckPermission(PermissionCashBoxTransferView)

    if (!isCashBoxTransferView) return <Error403 />

    return (
      <CashBoxTransfer
          request={getTransferAsync}
          title={"warehouse.dashboard.items.transfer_title"}
          id={viewId}
      />
    );
};

export default ViewTransfer;
