import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ContractorPaymentView from "../../../../components/hippo/crm/contractor/payment-view/ContractorPaymentView";
import {loadOneContractorPaymentAsync} from "../../../../app/store/reducers/contractor/contractorPaymentReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCRMContractorPaymentView} from "../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../components/errors/Error403";


const ContractorPayment = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const {id} = useParams()
    const [payment, setPayment] = useState(null)

    // permissions
    const isPaymentView = useCheckPermission(PermissionCRMContractorPaymentView)

    const loadContractorPayment = async () => {
        if (isPaymentView) {
            await loadOneContractorPaymentAsync(viewId || id)
                .then((res) => {
                    setPayment(res)
                })
                .catch(() => {
                    setPayment(null)
                })
        }
    }

    useEffect(() => {
        loadContractorPayment()
    },[id, activeOrganization, viewId])

    if (!isPaymentView) {
        return  <Error403 />
    }

    if (!payment)
        return (
            <>loading...</>
        )

    return(
        <>
            <ContractorPaymentView payment={payment}/>
        </>
    )
}


export default ContractorPayment;
