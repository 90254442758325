// CONTRACTOR
export const PermissionCRMContractorView                = 3000
export const PermissionCRMContractorCreate              = 3001
export const PermissionCRMContractorUpdate              = 3002
export const PermissionCRMContractorDelete              = 3003

// CONTRACTOR PAYMENT
export const PermissionCRMContractorPaymentView         = 3100
export const PermissionCRMContractorPaymentCreate       = 3101
export const PermissionCRMContractorPaymentUpdate       = 3102
export const PermissionCRMContractorPaymentDelete       = 3103

// CONTRACTOR PAYOUT
export const PermissionCRMContractorPayoutView          = 3200
export const PermissionCRMContractorPayoutCreate        = 3201
export const PermissionCRMContractorPayoutUpdate        = 3202
export const PermissionCRMContractorPayoutDelete        = 3203

// CONTRACTOR LABEL
export const PermissionCRMContractorLabelView           = 3300
export const PermissionCRMContractorLabelCreate         = 3301
export const PermissionCRMContractorLabelUpdate         = 3302
export const PermissionCRMContractorLabelDelete         = 3303

// CONTRACTOR OPERATION
export const PermissionCRMContractorOperationView       = 3400

// TELEGRAM BOT
export const PermissionCRMTelegramBotView     = 3500
export const PermissionCRMTelegramBotCreate   = 3501
export const PermissionCRMTelegramBotDelete   = 3502

// TELEGRAM ORDER
export const PermissionCRMTelegramOrderView   = 3600
export const PermissionCRMTelegramOrderDelete= 3601

export const CrmPermissions = [
    PermissionCRMContractorCreate,
    PermissionCRMContractorUpdate,
    PermissionCRMContractorDelete,
    PermissionCRMContractorView,

    PermissionCRMContractorPaymentCreate,
    PermissionCRMContractorPaymentUpdate,
    PermissionCRMContractorPaymentDelete,
    PermissionCRMContractorPaymentView,

    PermissionCRMContractorPayoutCreate,
    PermissionCRMContractorPayoutUpdate,
    PermissionCRMContractorPayoutDelete,
    PermissionCRMContractorPayoutView,

    PermissionCRMContractorOperationView,

    PermissionCRMTelegramOrderView,
    PermissionCRMTelegramBotCreate,
    PermissionCRMTelegramOrderDelete,

    PermissionCRMContractorLabelView,
    PermissionCRMContractorLabelCreate,
    PermissionCRMContractorLabelUpdate,
    PermissionCRMContractorLabelDelete,
]

export const CrmDefaultPermission = [
    {
        text: 'edi.contractor.datatable.header.row.contractor',
        permissions: [
            {
                text: 'system.permission.contractor.view',
                value: PermissionCRMContractorView
            },
            {
                text: 'system.permission.contractor.create',
                value: PermissionCRMContractorCreate
            },
            {
                text: 'system.permission.contractor.update',
                value: PermissionCRMContractorUpdate
            },
            {
                text: 'system.permission.contractor.delete',
                value: PermissionCRMContractorDelete
            },
        ]
    },
    {
        text: 'edi.contractor.datatable.header.row.contractor_payments',
        permissions: [
            {
                text: 'system.permission.contractor.payment.view',
                value: PermissionCRMContractorPaymentView
            },
            {
                text: 'system.permission.contractor.payment.create',
                value: PermissionCRMContractorPaymentCreate
            },
            {
                text: 'system.permission.contractor.payment.update',
                value: PermissionCRMContractorPaymentUpdate
            },
            {
                text: 'system.permission.contractor.payment.delete',
                value: PermissionCRMContractorPaymentDelete
            },
        ]
    },
    {
        text: 'edi.contractor.datatable.header.row.contractor_payouts',
        permissions: [
            {
                text: 'system.permission.contractor.payout.view',
                value: PermissionCRMContractorPayoutView
            },
            {
                text: 'system.permission.contractor.payout.create',
                value: PermissionCRMContractorPayoutCreate
            },
            {
                text: 'system.permission.contractor.payout.update',
                value: PermissionCRMContractorPayoutUpdate
            },
            {
                text: 'system.permission.contractor.payout.delete',
                value: PermissionCRMContractorPayoutDelete
            },
        ]
    },
    {
        text: 'edi.contractor.datatable.header.row.contractor_operations',
        permissions: [
            {
                text: 'system.permission.contractor.operations.view',
                value: PermissionCRMContractorOperationView
            }
        ]
    },
    /*{
        text: 'edi.contractor.datatable.header.row.contractor_telegram_bots',
        permissions: [
            {
                text: 'system.permission.contractor.telegram_bot.view',
                value: PermissionCRMTelegramBotView
            },
            {
                text: 'system.permission.contractor.telegram_bot.delete',
                value: PermissionCRMTelegramBotDelete
            },
        ]
    },*/
    {
        text: 'crm.telegram-orders',
        permissions: [
            {
                text: 'system.permission.contractor.telegram_order.view',
                value: PermissionCRMTelegramOrderView
            },
            {
                text: 'system.permission.contractor.telegram_order.create',
                value: PermissionCRMTelegramOrderDelete
            },
            {
                text: 'system.permission.contractor.telegram_order.delete',
                value: PermissionCRMTelegramBotCreate
            },
        ]
    },
    {
        text: 'crm.contractor.label',
        permissions: [
            {
                text: 'system.permission.contractor.label.view',
                value: PermissionCRMContractorLabelView
            },
            {
                text: 'system.permission.contractor.label.create',
                value: PermissionCRMContractorLabelCreate
            },
            {
                text: 'system.permission.contractor.label.update',
                value: PermissionCRMContractorLabelUpdate
            },
            {
                text: 'system.permission.contractor.label.delete',
                value: PermissionCRMContractorLabelDelete
            },
        ]
    }
]
