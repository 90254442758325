import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SaleDatatable from "../../../../warehouse-operation/sale/datatable/SaleDatatable";
import {
    getSaleItemsAsync,
    getSaleItemsCountAsync,
    selectSaleFilterOptions
} from "../../../../../../app/store/reducers/warehouse-operation/saleReducer";
import useCheckPermission from "../../../../../../hooks/useCheckPermission";
import {PermissionWMSSaleView} from "../../../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../../errors/Error403";

const ContractorSales = ({contractorId}) => {
    const filters = useSelector(selectSaleFilterOptions);
    const dispatch = useDispatch();

    const isSaleView = useCheckPermission(PermissionWMSSaleView)



    useEffect(() => {
        loadContractorSales();
    }, [contractorId, filters])

    const predefinedFilters = useMemo(() => {
        return {
            contractor_id: contractorId,
            is_approved: null,
        };
    }, [contractorId])

    const loadContractorSales = () => {
        if (isSaleView) {
            dispatch(getSaleItemsAsync({...filters, ...predefinedFilters}));
            dispatch(getSaleItemsCountAsync({...filters, ...predefinedFilters}));
        }
    }

    if (!isSaleView) {
        return <Error403 />
    }

    return <SaleDatatable predefinedFilters={predefinedFilters}/>;
};

export default ContractorSales;