import React, {useEffect, useState} from 'react';
import {HashRouter as Router,} from 'react-router-dom';
import {Provider, useDispatch, useSelector} from "react-redux";
import store from "./store";
import AppRoutes from "./routes/AppRoutes";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {CloseButton} from "../components/common/Toast";
import axios from 'axios';
import {
    INITIALIZATION_STATE_COMPLETE,
    initializeAsync as authInitializeAsync,
    selectActiveOrganization,
    selectInitializationState
} from "./store/reducers/auth/authReducer";
import {Col, Row, Spinner} from "react-bootstrap";
import Section from "../components/common/Section";
import {initializeAsync as eimzoInitializeAsync} from "./store/reducers/eimzo/eimzoReducer";
import InProgress from '../components/common/InProgress';
import {registerCustomNumberFormats} from "../helpers/customNumberFormat"
import {loadCompany} from "./store/reducers/roamingReference/roamingReferenceReducer";

axios.defaults.baseURL = process.env.REACT_APP_HOST;
axios.defaults.withCredentials = true;

registerCustomNumberFormats();


const Loader = ({children}) => {
    const initializationState = useSelector(selectInitializationState);
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        dispatch(authInitializeAsync())
        dispatch(eimzoInitializeAsync())
    }, []);

    useEffect( () => {
        if (activeOrganization) {
           dispatch(loadCompany(activeOrganization.person_num || activeOrganization.inn));
        }
    }, [activeOrganization])


    if (initializationState === INITIALIZATION_STATE_COMPLETE)
        return children;

    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 text-center">
                    <Spinner animation="grow"/>
                </Col>
            </Row>
        </Section>
    );
};

const App = () => {
    return (
        <Provider store={store}>
            <Loader>
                <Router basename="/">
                    <AppRoutes/>
                </Router>
                <InProgress/>
                <ToastContainer
                    closeButton={CloseButton}
                    closeOnClick
                    position={toast.POSITION.BOTTOM_LEFT}
                />
            </Loader>
        </Provider>
    );
};

export default App;
