import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import RoamingInvoice from "../../../../components/hippo/roaming/invoice/RoamingInvoice"
import {loadRoamingInvoiceAsync} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS,
    ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS,
    ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS,
    ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS,
    ROAMING_INVOICE_PERSON_DECLINE_SUCCESS,
    ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS,
    ROAMING_INVOICE_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingInvoiceEvents";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useHistory} from "react-router-dom";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceView} from "../../../../enum/Permission/RoamingPermission";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewRoamingInvoice = ({modalId}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)
    const {id} = useParams();
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingInvoiceView))) {
            if (history.length) {
                history.push('/roaming/invoices/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const loadRoamingInvoice = () => {
        loadRoamingInvoiceAsync(modalId ? modalId : id)
            .then((response) => {
                const viewKeys = {
                    id: response.id,
                    created_at: response.created_at,
                    updated_at: response.updated_at,
                    customer: {
                        inn: response.customer.inn || '',
                        name: response.customer.name || '',
                        account: response.customer.account || '',
                        mfo: response.customer.mfo || '',
                        director: response.customer.director || '',
                        accountant: response.customer.accountant || '',
                        oked: response.customer.oked || '',
                        address: response.customer.address || '',
                        nds_registration_code: response.customer.nds_registration_code || '',
                        nds_status: response.customer.nds_status || '',
                        tax_gap: response.customer.tax_gap || '',
                        branch: {
                            code: response.customer.branch_code || '',
                            name: response.customer.branch_name || ''
                        }
                    },
                    executor: {
                        inn: response.executor.inn,
                        name: response.executor.name,
                        account: response.executor.account,
                        mfo: response.executor.mfo,
                        director: response.executor.director,
                        accountant: response.executor.accountant,
                        oked: response.executor.oked,
                        address: response.executor.address,
                        nds_registration_code: response.executor.nds_registration_code,
                        nds_status: response.executor.nds_status,
                        tax_gap: response.executor.tax_gap,
                        branch: {
                            code: response.executor.branch_code,
                            name: response.executor.branch_name
                        },
                    },
                    info: {
                        number: response.info.number,
                        date: response.info.date
                    },
                    contract: {
                        number: response.contract.number,
                        date: response.contract.date
                    },
                    old_invoice: {
                        id: response.old_invoice?.id || '',
                        number: response.old_invoice?.number || '',
                        date: response.old_invoice?.date || ''
                    },
                    notes: response.notes,
                    status: response.status,
                    foreign_company: {
                        country_id: response.foreign_company?.country_id || '',
                        name: response.foreign_company?.name || '',
                        address: response.foreign_company?.address || '',
                        account: response.foreign_company?.account || ''
                    },
                    releaser: {
                        name: response.releaser?.name || '',
                        pinfl: response.releaser?.inn || '',
                        inn: response.releaser?.pinfl || ''
                    },
                    empowerment: {
                        name: response.empowerment?.name || '',
                        inn: response.empowerment?.inn,
                        pinfl: response.empowerment?.pinfl || '',
                        number: response.empowerment?.number || '',
                        date_of_issue: response.empowerment?.date_of_issue || ''
                    },
                    total_info: {
                        total: response.total_info.total,
                        nds_value: response.total_info.nds_value,
                        total_with_nds: response.total_info.total_with_nds
                    },
                    histories: response.histories.map((history) => {
                        return {
                            action: history.action,
                            action_date: history.action_date,
                            certificate: {
                                full_name: history.certificate.full_name,
                                serial_number: history.certificate.serial_number,
                                signing_time: history.certificate.signing_time
                            }
                        }
                    }),
                    total_with_nds_after_adds: response.total_with_nds_after_adds,
                    lot_id: response.lot_id,
                    waybill_id: response.waybill_id,
                    contract_id: response.contract_id,
                    factura_type: response.factura_type,
                    single_sided_type: response.single_sided_type,
                    income_type: response.income_type,
                    has_marking: response.has_marking,
                    has_committent: response.has_committent,
                    has_lgota: response.has_lgota,
                    hide_report_committent: response.hide_report_committent,
                    has_medical: response.has_medical,
                    has_excise: response.has_excise,
                    has_vat: response.has_vat,
                    is_empowered: response.is_empowered,
                    items: response.items.map((item) => {
                        return {
                            ordering_number: item.ordering_number,
                            committent_name: item.committent_name,
                            committent_inn: item.committent_inn,
                            committent_nds_registration_code: item.committent_nds_registration_code,
                            committent_nds_status: item.committent_nds_status,
                            name: item.name,
                            catalog: {
                                code: item.catalog?.code,
                                name: item.catalog?.name
                            },
                            package: {
                                code: item.package?.code,
                                name: item.package?.name
                            },
                            origin: item.origin,
                            barcode: item.barcode,
                            measure_id: item.measure_id,
                            base_price: +item.base_price,
                            profit_rate: +item.profit_rate,
                            price: +item.price,
                            nds_rate: item.nds_rate,
                            nds_value: +item.nds_value,
                            excise_rate: +item.excise_rate,
                            excise_value: +item.excise_value,
                            quantity: +item.quantity,
                            without_vat: item.without_vat,
                            total: +item.total,
                            total_with_nds: +item.total_with_nds,
                            lgota_id: item.lgota_id,
                            lgota_name: item.lgota_name,
                            lgota_type: item.lgota_type,
                            lgota_vat_sum: +item.lgota_vat_sum,
                            serial: item.serial,
                            marking: item.marking ? {
                                product_type: item.marking.product_type,
                                kiz: item.marking.kiz,
                                nom_upak: item.marking.nom_upak,
                                ident_trans_upak: item.marking.ident_trans_upak
                            } : null
                        }
                    }),
                    roaming_error: response.roaming_error,
                    internal_act: response?.internal_act ? {
                        description: response?.internal_act?.description
                    } : null
                }
                setInvoice(viewKeys);
            })
            .catch(e => setInvoice(null))
    }

    useEffect(() => {
        loadRoamingInvoice();
    }, [id])

    useEffect(() => {
        const onExecutorCancelSuccessHandler = EventBus.on(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, () => {
            loadRoamingInvoice()
        })

        const onCustomerDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, () => {
            loadRoamingInvoice();
        })

        const onCustomerAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, () => {
            loadRoamingInvoice();
        })

        const onPersonDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, () => {
            loadRoamingInvoice();
        })

        const onPersonAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, () => {
            loadRoamingInvoice()
        })

        const onSyncInvoiceSuccessHandler = EventBus.on(ROAMING_INVOICE_SYNC_SUCCESS, () => {
            loadRoamingInvoice();
        })

        const onSyncInvoiceHistoriesSuccessHandler = EventBus.on(ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS, () => {
            loadRoamingInvoice();
        })


        return () => {
            EventBus.remove(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, onExecutorCancelSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, onCustomerDeclineSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, onCustomerAcceptSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, onPersonDeclineSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, onPersonAcceptSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_SYNC_SUCCESS, onSyncInvoiceSuccessHandler);
            EventBus.remove(ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS, onSyncInvoiceHistoriesSuccessHandler);
        }
    }, [])

    return (
        <>
            {!invoice && <SpinnerComponent/>}
            {invoice && <RoamingInvoice invoice={invoice}/>}
        </>
    )
};

export default ViewRoamingInvoice;
