import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {
    cashboxAccountBindingDisable, cashboxAccountBindingEnable,
    selectAccountCashbox
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {selectUsers} from "../../../../app/store/reducers/auth/authReducer";
import AdvanceTable from "../../../../components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../components/common/advance-table/AdvanceTableWrapper";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Badge, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxUpdate} from "../../../../enum/Permission/CashBoxPermission";

const CashboxAccountDataTable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const accountCashbox = useSelector(selectAccountCashbox)
    const users = useSelector(selectUsers)

    // permissions
    const isCashBoxUpdate = useCheckPermission(PermissionCashBoxUpdate)


    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            headerProps: {className: 'p-3'},
            cellProps: {
                width: '50px',
                className: 'p-3'
            }
        },
        {
            Header: t(lang, "Ф.И.О"),
            cellProps: {width: 300},
            Cell: ({row: {original}}) => {
                const name = users.find(i => i.id === original.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")
                return (
                    <strong>{name}</strong>
                );
            }
        },
        {
            Header: t(lang, "cashboxes.bind.account.label"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center'},
            Cell: ({row: {original}}) => {
                const status = original.cash_boxes
                return (
                    original.cash_boxes.map((item,index) => (
                        <Badge key={index} bg={'primary'}
                               className="me-2">{item.name}</Badge>
                    ))

                );
            }
        },
        {
            Header: t(lang, "items.common.catalog.status"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center', width: 300},
            Cell: ({row: {original}}) => {
                const status = original.is_binding_enabled ? "crm.bind.contractor.account.unlock.action" : "crm.bind.contractor.account.block.action"
                return (
                    <Badge bg={original.is_binding_enabled ? "success" : "secondary"}
                           className="me-2">{t(lang, status)}</Badge>
                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {isCashBoxUpdate && <OverlayTrigger placement={'top'} overlay={
                            <Tooltip>{t(lang, original.is_binding_enabled ? "crm.bind.contractor.account.change.status.to.active" : "crm.bind.contractor.account.change.status.to.disable")}</Tooltip>}>
                            <Form.Check
                                size={20}
                                checked={original.is_binding_enabled}
                                type='switch'
                                onChange={() => changeCashboxAccountStatus(original)}
                            />
                        </OverlayTrigger>}
                    </Fragment>
                );
            }
        }
    ]

    const changeCashboxAccountStatus = (account) => {
        const params = {account_id: account.account_id}
        if (account.is_binding_enabled) {
            cashboxAccountBindingDisable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))

        } else {
            cashboxAccountBindingEnable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
        }
    }

    return (
        <Fragment>
            <AdvanceTableWrapper
                perPage={1000}
                columns={columns}
                data={accountCashbox}
                pagination
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}>
                </AdvanceTable>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default CashboxAccountDataTable;
