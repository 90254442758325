import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Badge, Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import numeral from "numeral";
import {isApprovedCheckedWarehouseOperation, OperationTotalSum,} from "../enum/warehouseOperationWrapper";
import {
    purchaseApproveAsync,
    purchaseChangeExactDiscountsAsync,
    purchaseChangePercentDiscountAsync,
    purchasePayoutRegisterAsync,
    purchasePayoutUpdateAsync,
} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import {toast} from "react-toastify";
import React, {Fragment, useEffect, useState} from "react";
import WarehouseOperationWithoutPayment from "../components/WarehouseOperationWithoutPayment";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import WarehouseOperationCashBoxCard from "../components/WarehouseOperationCashBoxCard";
import WarehouseOperationDebtStateCard from "../components/WarehouseOperationDebtStateCard";
import {selectCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import WarehouseOperationDatatableTab from "../components/WarehouseOperationDatatableTab";
import WarehouseOperationFooterCurrencyTotalSum from "../components/WarehouseOperationFooterCurrencyTotalSum";
import UploadPurchaseToExcel from "./excel/UploadPurchaseToExcel";
import PrintPurchaseDropdown from "./components/PrintPurchaseDropdown";
import DownloadPurchaseDropdown from "./components/DownloadPurchaseDropdown";
import WOWIthDiscountPayment from "../components/WOWIthDiscountPayment";
import {
    PermissionWMSPurchaseCreate,
    PermissionWMSPurchaseUpdate
} from "../../../../enum/Permission/WarehouseOperationPermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";


const Purchase = ({purchase}) => {
    const [activeTab, setActiveTab] = useState("all")
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate

    // permissions
    const isPurchaseCreate = useCheckPermission(PermissionWMSPurchaseCreate);
    const isPurchaseEdit = useCheckPermission(PermissionWMSPurchaseUpdate);

    useEffect(() => {
        if (purchase?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])


    const purchasePayout = {
        debtStates: purchase?.payout?.debt_states?.map(debtState => {
            return {amount: debtState?.amount, currencyId: debtState?.currency?.id};
        }),
        cashBoxStates:purchase?.payout?.cash_box_states?.map(cashBoxState => {
            return {amount: cashBoxState?.amount, currencyId: cashBoxState?.currency?.id};
        }),
        note: purchase?.payout?.note,
    };

    const totalAmounts = purchase?.totals?.map(saleTotal => {
        return {amount: saleTotal?.amount, currencyId: saleTotal?.currency?.id};
    });

    const exactDiscounts = purchase?.exact_discounts?.map(exactDiscount => {
        return {amount: exactDiscount?.amount, currencyId: exactDiscount?.currency?.id};
    });

    const onSubmitPay = async (pay) => {

        if (pay?.percentDiscount !== purchase?.percent_discount) {
            await purchaseChangePercentDiscountAsync({purchaseId: purchase?.id, percentDiscount: pay?.percentDiscount})
                .then(() => {})
                .catch(() => {})
        }

        const isExactDiscountsLengthEqual = pay?.exactDiscounts?.length === purchase?.exact_discounts?.length;
        const isExactDiscountsValuesIdential = pay?.exactDiscounts?.every((discount, index) => {
            const isAmountEqual = Number(discount?.amount) === Number(purchase?.exact_discounts[index]?.amount);
            const isCurrencyEqual = Number(discount?.currency_id) === Number(purchase?.exact_discounts[index]?.currency?.id);

            return isAmountEqual && isCurrencyEqual;
        });

        if (!isExactDiscountsLengthEqual || !isExactDiscountsValuesIdential) {
            await purchaseChangeExactDiscountsAsync({purchaseId: purchase?.id, exactDiscounts: pay?.exactDiscounts})
                .then(() => {})
                .catch(() => {})
        }

        if (purchase?.payout) {
            await purchasePayoutUpdateAsync({purchaseId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!purchase?.payout && purchase?.is_approved) {
            await purchasePayoutRegisterAsync({purchaseId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!purchase?.is_approved) {
            await purchaseApproveAsync({purchaseId: pay?.id, payout: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    return(
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_purchase")} {purchase?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <div>
                                <span className="me-1">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={purchase?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(purchase?.is_approved))}
                                </SoftBadge>
                            </div>
                            {purchase?.cash_box?.name && <div>
                                <span className="me-1">{t(lang, "warehouse.operation.item.common.cash_box")}: </span>
                                <Badge bg={"primary"}>
                                    {purchase?.cash_box?.name}
                                </Badge>
                            </div>}
                        </Col>
                        <Col xs={12}>
                            {isPurchaseEdit && <Fragment>
                                {currencies?.length > 0 && purchase?.items?.filter(x => !x.is_deleted).length > 0 &&
                                    // <WarehouseOperationPayment onSubmitPay={onSubmitPay} data={purchase}/>
                                    <WOWIthDiscountPayment
                                        data={purchase}
                                        isEditing={true}
                                        saleId={purchase?.id}
                                        payment={purchasePayout}
                                        onSubmitPay={onSubmitPay}
                                        totalAmounts={totalAmounts}
                                        exactDiscounts={exactDiscounts}
                                        contractorId={purchase?.contractor?.id}
                                        percentDiscount={purchase?.percent_discount}
                                    />
                                }
                                {!purchase?.is_approved && purchase?.items?.filter(x => !x.is_deleted).length > 0 &&
                                    <WarehouseOperationWithoutPayment
                                        onClick={onSubmitPay}
                                        id={purchase?.id}
                                    />
                                }
                                <Link to={`/warehouse/operation/purchases/update/${purchase?.id}`}>
                                    <IconButton
                                        variant="falcon-warning"
                                        icon="edit"
                                        title={t(lang, "warehouse.operation.item.common.edit")}
                                        className="ms-3"
                                        size="sm"
                                    >{t(lang, "warehouse.operation.item.common.edit")}</IconButton>
                                </Link>
                            </Fragment>}

                            {/*<Link to={`/warehouse/operation/purchases/update-excel/${purchase?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="edit"
                                    title={t(lang, "warehouse.operation.item.common.edit")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang, "Редактировать с Excel")}</IconButton>
                            </Link>*/}
                            <UploadPurchaseToExcel className="me-2 d-inline-block" size="sm" id={purchase?.id} number={purchase?.number}/>
                            {isPurchaseCreate && <Link to={`/warehouse/operation/purchases/register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang, "Новый приход")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang, "Новый приход")}</IconButton>
                            </Link>}

                            <PrintPurchaseDropdown className={'d-inline-block mx-1'} purchaseId={purchase?.id}/>
                            <DownloadPurchaseDropdown className={'d-inline-block mx-1'} purchaseId={purchase?.id} purchaseNumber={purchase?.number}/>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={12} className="my-3">
                    <Card>
                        <Card.Header>
                            <h4 className="text-info mb-2">{t(lang, "warehouse.operation.item.common.purchase")}</h4>
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center fw-bold">
                                    <h5 className="me-2 fw-bold">{t(lang, "Имя поставщика")}:</h5> <span>{purchase?.contractor?.name}</span>
                                </div>
                                <div className={"d-flex align-items-start flex-wrap fw-bold"}>
                                    <h5 className="d-flex justify-content-end text-nowrap fw-bold">{t(lang, "warehouse.operation.item.common.real_credit")}:</h5>
                                    <div className="fs-1 d-flex flex-wrap">
                                        {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                            if (item.amount){
                                                return (
                                                    <div key={index} className="d-flex text-nowrap justify-content-end mx-2">
                                                        {numeral.formats[numberFormat].format(item.amount)} <span className="text-info ms-1">{item.currency.name}</span>
                                                    </div>
                                                )
                                            }
                                        }) : '0 сум'}
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>

            {purchase?.payout &&
                <>
                    <Row className="mb-3">
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationCashBoxCard cashBox={purchase?.payout?.cash_box_states} />
                        </Col>
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationDebtStateCard debtState={purchase?.payout?.debt_states} />
                        </Col>
                    </Row>
                    {purchase?.payout?.notes &&
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-3 p-3">
                                    <Card.Title className="text-center">
                                        {t(lang, "warehouse.operation.item.common.info")}
                                    </Card.Title>
                                    <Card.Text>
                                        {purchase?.payout?.notes}
                                    </Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            }

            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            {purchase?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{purchase?.note}</span> </p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {purchase?.items?.filter(f => !f?.is_deleted).length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={purchase?.items?.filter(f => !f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                                {purchase?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={purchase?.items?.filter(f => f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                {activeTab === "all" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPrice={purchase?.net_price}
                                        totals={purchase?.totals}
                                        pay={purchase?.payout}
                                        exactDiscounts={purchase?.exact_discounts}
                                        percentDiscount={purchase?.percent_discount}
                                    />
                                }
                                {activeTab === "deleted" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"deleted"}
                                        netPrice={OperationTotalSum(purchase?.items?.filter(f => f?.is_deleted))}
                                        pay={purchase?.payout}
                                    />
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default Purchase;
