export const PermissionSystemRoleView = 1
export const PermissionSystemRoleCreate = 2
export const PermissionSystemRoleUpdate = 3
export const PermissionSystemRoleDelete = 4
export const PermissionSystemRoleBindAccount = 5

// BRANCH
export const PermissionBranchView = 100
export const PermissionBranchCreate = 101
export const PermissionBranchUpdate = 102
export const PermissionBranchDelete = 103

export const AnotherPermissions = [
    PermissionSystemRoleView,
    PermissionSystemRoleCreate,
    PermissionSystemRoleUpdate,
    PermissionSystemRoleDelete,
    PermissionSystemRoleBindAccount,

    PermissionBranchView,
    PermissionBranchCreate,
    PermissionBranchUpdate,
    PermissionBranchDelete,
]

export const AnotherDefaultPermission = [
    {
        text: 'hippo.user.settings.roles',
        permissions: [
            {
                text: 'system.permission.role.view',
                value: PermissionSystemRoleView
            },
            {
                text: 'system.permission.role.create',
                value: PermissionSystemRoleCreate
            },
            {
                text: 'system.permission.role.update',
                value: PermissionSystemRoleUpdate
            },
            {
                text: 'system.permission.role.delete',
                value: PermissionSystemRoleDelete
            },
            {
                text: 'system.permission.role.bind_to_account',
                value: PermissionSystemRoleBindAccount
            },

        ]
    },
    {
        text: 'edi.permission.branches',
        permissions: [
            {
                text: 'system.permission.branch.view',
                value: PermissionBranchView
            },
            {
                text: 'system.permission.branch.create',
                value: PermissionBranchCreate
            },
            {
                text: 'system.permission.branch.update',
                value: PermissionBranchUpdate
            },
            {
                text: 'system.permission.branch.delete',
                value: PermissionBranchDelete
            },
        ]
    },
]
