const NOTCONFIRMED = false
const CONFIRMED = true

export const withoutDiscount = 0
export const withDiscount = 1

export const operationUnmark                  = 0
export const operationRetailPrice             = 1
export const operationWholeSalePrice          = 2
export const operationRetailAndWholeSalePrice = 3

export const operationItemOptionExtraInfoUnmark = 0;
export const operationItemOptionExtraInfoWithSku = 1;
export const operationItemOptionExtraInfoWithCode = 2;
export const operationItemOptionExtraInfoWithSkuAndCode = 3;

export const productMarksType = '010'

export const dropDownDiscountOptions = [
    {
        value: withoutDiscount,
        label: "Без процентов"
    },
    {
        value: withDiscount,
        label: "С процентами"
    }
]

const formatNumberMajorPart = (value) => {
    let result = ''
    value = value.split('').reverse().join('')
    for (let i = 0; i < value.length; i++) {
        if (i > 0 && i % 3 === 0)
            result = `${result} `
        result = `${result}${value[i]}`
    }

    if (result.length && result[result.length - 1] === ' ')
        result = result.substr(0, result.length - 1)

    return result.split('').reverse().join('')
}

export const DecimalToString = (value, mathRound) => {

    value = Math.round10(value, -(+mathRound))

    let major = value.toString();
    let commaIndex = major.replace(",", ".").indexOf(".");

    // minor part
    let minor = "";
    if (commaIndex > -1) {
        minor = major.substr(commaIndex + 1);
        major = major.substr(0, commaIndex);
    }

    major = formatNumberMajorPart(major)
    while (minor.length > 0) {
        let lastChar = minor[minor.length - 1];
        if (lastChar === '.') {
            minor = ''
        } else if (lastChar === '0')
            minor = minor.substr(0, minor.length - 1)
        else {
            break;
        }
    }

    if (minor.length === 0)
        return major

    return `${major}.${minor}`
}

export const CheckScanBarcodeOrMarking = (value) => {
    let barcode = value
    if (value?.length > 14){
        // product barcode check 010
        if (value?.startsWith(productMarksType)) {
            barcode = value?.slice(3, 16)
        }

        // cigarette barcode check 000000
        if (value?.indexOf(productMarksType) === -1) {
            barcode = value?.slice(0, 14).replace(/^0+|$/g, '');
        }
    }

    return barcode
}

export const BulkPriceCommon = [
    {
        label: "warehouse.operation.item.common.retail_price",
        value: true
    },
    {
        label: "warehouse.operation.item.common.wholeSale_price",
        value: false
    },
]


export const UnmarkedPriceWholesalePrice = [
    {
        label: "warehouse.operation.item.common.unmarked",
        value: operationUnmark
    },
    {
        label: "warehouse.operation.item.common.retail_price",
        value: operationRetailPrice
    },
    {
        label: "warehouse.operation.item.common.wholeSale_price",
        value: operationWholeSalePrice
    },
    {
        label: "warehouse.operation.item.common.price_and_bulk_price",
        value: operationRetailAndWholeSalePrice
    },
]

export const OperationItemSelectOptionExtraInfos = [
    {label: "warehouse.operation.item.common.unmarked", value: operationItemOptionExtraInfoUnmark},
    {label: "warehouse.operation.item.common.sku", value: operationItemOptionExtraInfoWithSku},
    {label: "warehouse.operation.item.common.code", value: operationItemOptionExtraInfoWithCode},
    {label: "warehouse.operation.item.common.sku_and_code_price", value: operationItemOptionExtraInfoWithSkuAndCode},
];

export const discountCheckReturnLabel = (type) => {
    switch (type) {
        case withoutDiscount:
            return ''
        case withDiscount:
            return '%'
        default:
            return ''
    }
}

export const calcDiscount = ({type, discountAmount, priceAmount, quantity}) => {
    switch (type) {
        case withoutDiscount:
            // return Math.abs(+priceAmount - +discountAmount / quantity)
            return Math.abs(+priceAmount - +discountAmount)
        case withDiscount:
            return Math.abs(+priceAmount - (+priceAmount * +discountAmount / 100))
        default:
            return 0
    }
}

export const statusProductShowRetailPrice = (value) => {
    switch (value) {
        case operationRetailPrice:
            return true;
        case operationRetailAndWholeSalePrice:
            return true;
        default:
            return false
    }
}

export const statusProductShowWholeSalePrice = (value) => {
    switch (value) {
        case operationWholeSalePrice:
            return true;
        case operationRetailAndWholeSalePrice:
            return true;
        default:
            return false
    }
}

export const showOperationItemSelectOptionCodeInfo = (value) => {
    switch (value) {
        case operationItemOptionExtraInfoWithCode:
            return true;
        case operationItemOptionExtraInfoWithSkuAndCode:
            return true;
        default:
            return false
    }
}

export const showOperationItemSelectOptionSkuInfo = (value) => {
    switch (value) {
        case operationItemOptionExtraInfoWithSku:
            return true;
        case operationItemOptionExtraInfoWithSkuAndCode:
            return true;
        default:
            return false
    }
}

export const isApprovedCheckedWarehouseOperation = (bool) => {
    switch (bool) {
        case NOTCONFIRMED:
            return "warehouse.operation.item.common.status.not_confirmed"
        case CONFIRMED:
            return "warehouse.operation.item.common.status.confirmed"
        default:
            return "not found"
    }
}

export const findCurrencyReturnRate = (currencies, id) => {
    return currencies.find(currency => currency?.id === id)?.rate
}

export const checkTypeOfReturnNumber = (amount) => {
    return typeof (amount) === 'string' ? +amount.replace(/ /g, '') : +amount
}


// remove this operationTotalSumInfo
export const OperationTotalSumInfo = (items) => {
    var groupByCurrencyId = {}

    items?.forEach(item => {
        if (item?.price?.currency?.id in groupByCurrencyId) {
            groupByCurrencyId[item?.price?.currency?.id].totalSum += item?.price?.amount * item?.quantity
        } else {
            if (item?.price?.currency?.id) {
                groupByCurrencyId[item?.price?.currency?.id] = {
                    id: item?.price?.currency?.id,
                    name: item?.price?.currency?.name,
                    quantity: item?.quantity,
                    totalSum: item?.price?.amount * item?.quantity,
                }
            }
        }
    })
    return Object.values(groupByCurrencyId)
}


export const OperationTotalSum = (items) => {
    var groupByCurrencyId = {}

    items?.forEach(item => {
        if (item?.price?.currency?.id in groupByCurrencyId) {
            groupByCurrencyId[item?.price?.currency?.id].amount += item?.price?.amount * item?.quantity
        } else {
            if (item?.price?.currency?.id) {
                groupByCurrencyId[item?.price?.currency?.id] = {
                    amount: item?.price?.amount * item?.quantity,
                    currency: {
                        id: item?.price?.currency?.id,
                        name: item?.price?.currency?.name
                    }
                }
            }
        }
    })

    return Object.values(groupByCurrencyId)
}

export const OperationTotalSumInfoPayment = (states) => {
    var groupByCurrencyId = {}

    if (states) {
        states.forEach(state => {
            const key = `${state.currency.id}_${state.payment_type}`

            if (key in groupByCurrencyId) {
                groupByCurrencyId[key].totalSum += state.amount
            } else {
                if (state.currency.id) {
                    groupByCurrencyId[key] = {
                        id: state.currency.id,
                        name: state.currency.name,
                        totalSum: state.amount,
                        payment_type: state.payment_type
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    } else {
        return 0
    }
}

export const OperationTotalSumInfoWithoutPaymentType = (states) => {
    var groupByCurrencyId = {}

    if (states) {
        states.forEach(state => {
            const key = state.currency.id

            if (key in groupByCurrencyId) {
                groupByCurrencyId[key].totalSum += state.amount
            } else {
                if (state.currency.id) {
                    groupByCurrencyId[key] = {
                        id: state.currency.id,
                        name: state.currency.name,
                        totalSum: state.amount,
                        payment_type: state.payment_type
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    } else {
        return 0
    }
}

export const OperationInfoPaymentType = (states) => {
    const resultObject = states?.reduce((result, currentItem) => {
        const paymentType = currentItem.payment_type;

        const key = `${paymentType}`;

        if (!result[key]) {
            result[key] = [
                    {
                        id: currentItem.currency.id,
                        name: currentItem.currency.name,
                        totalSum: 0,
                        payment_type: currentItem.payment_type
                    }
                    ]
        }

        result[key].push({
            id: currentItem.currency.id,
            name: currentItem.currency.name,
            totalSum: currentItem.amount,
            payment_type: currentItem.payment_type
        });

        return result;
    }, {});
    return resultObject ? resultObject : ""
}


export const ItemsTotalSumGroupCurrencyId = (ArraysIntoArrays) => {
    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.net_price?.forEach(t => {
            if (!groupedSums[t.currency?.id]) {
                groupedSums[t.currency?.id] = {
                    totalSum: 0,
                    currency_id: t.currency?.id,
                    name: t.currency?.name
                };
            }
            groupedSums[t.currency?.id].totalSum += t.amount;
        });
    });
    return Object.values(groupedSums)
}

export const ItemDiscountsTotalSumGroupCurrencyId = (ArraysIntoArrays) => {
    const groupedSums = {};

    ArraysIntoArrays?.forEach((array) => {
        if (array?.exact_discounts.length > 0) {
            array?.exact_discounts?.forEach(t => {
                if (!groupedSums[t.currency?.id]) {
                    groupedSums[t.currency?.id] = {
                        totalSum: 0,
                        currency_id: t.currency?.id,
                        name: t.currency?.name
                    };
                }
                groupedSums[t.currency?.id].totalSum += t.amount;
            });
        } else if (array?.percent_discount) {
            array?.overall_discounts?.forEach(t => {
                if (!groupedSums[t.currency?.id]) {
                    groupedSums[t.currency?.id] = {
                        totalSum: 0,
                        currency_id: t.currency?.id,
                        name: t.currency?.name
                    };
                }
                groupedSums[t.currency?.id].totalSum += t.amount;
            });
        }
    });

    return Object.values(groupedSums);
};

export const ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payment?.cash_box_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const paymentType = item.payment_type;
            const currenyId = item.currency?.id;

            const key = `${paymentType}${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                    payment_type: item?.payment_type,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}


export const ItemsTotalSumDebtsGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.debts?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const currenyId = item.currency?.id;

            const key = `${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}

export const ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payout?.cash_box_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const paymentType = item.payment_type;
            const currenyId = item.currency?.id;

            const key = `${paymentType}${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                    payment_type: item?.payment_type,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}

export const ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payout?.debt_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const currenyId = item.currency?.id;

            const key = `${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}








export const CalculatePaymentDebtAndCashBack = ({cash_box_states, net_price, currency}) => {
    if (!cash_box_states || cash_box_states.length === 0)
        return {debts: [], cash_back: net_price}

    let debts = []
    let cash_back = []
    let toPay = cash_box_states;

    let isExistAnonymPayment = false
    toPay?.forEach(toPay => {
        let existNetPrice = net_price?.find(netPrice => netPrice.currency.id === toPay.currency.id)
        if (!existNetPrice) {
            isExistAnonymPayment = true;
        }
    })

    if (isExistAnonymPayment) {
        //agarda tolovlarni hammasi sotuv summalari bilan mos kelmasa, yani birortasi nomalum valyuta kiritlgan bolsa
        let nationalAmountNetPrice = 0;
        let nationalAmountToPay = 0;

        net_price.forEach(netPrice => {
            nationalAmountNetPrice = nationalAmountNetPrice + (netPrice.amount * netPrice.currency.rate);
        });
        toPay.forEach(toPay => {
            nationalAmountToPay = nationalAmountToPay + (toPay.amount * toPay.currency.rate);
        });

        if (nationalAmountNetPrice > nationalAmountToPay) {
            debts.push({
                currency: net_price[0].currency,
                amount: (nationalAmountNetPrice - nationalAmountToPay) / net_price[0].currency.rate
            })
        } else {
            cash_back.push({
                currency: currency?.find(x => x.is_national),
                amount: nationalAmountToPay - nationalAmountNetPrice
            })
        }

    } else {
        //agarda tolovlarni hammasi sotuv summalari bilan mos kelsa
        net_price.forEach(net_pr => {
            let pay = toPay.find(cash => cash.currency.id === net_pr.currency?.id);
            if (pay) {
                debts.push({
                    currency: pay.currency,
                    amount: +net_pr.amount - pay.amount
                })
            } else {
                debts.push({
                    currency: net_pr.currency,
                    amount: net_pr.amount
                })
            }
        });

        //minusga kirgan qarz valyutasini plusdagi valyuta bolsa convert qilib ayrvoramiz, yoq bolsa qaytimga qoshamiz
        debts.forEach(minusDebt => {
            if (minusDebt.amount < 0) {
                //minus bor bolsa, plus dagi qarzni topamiz
                let findPlusDebt = debts.find(debt => debt.amount > 0);

                //agar plusdagi valyuta bor bolsa
                if (findPlusDebt) {
                    //agar minusdagi qarz national valyuta bolsa
                    if (minusDebt.currency.code === 860) {
                        findPlusDebt.amount = (findPlusDebt.amount - (Math.abs(minusDebt.amount) / findPlusDebt.currency.rate));
                        minusDebt.amount = 0
                        if (findPlusDebt.amount < 0) {
                            cash_back.push({
                                currency: findPlusDebt.currency,
                                amount: Math.abs(findPlusDebt.amount)
                            })
                            findPlusDebt.amount = 0
                        }
                    } else {
                        //agar minusdagi qarz national valyuta bolmasa
                        findPlusDebt.amount = findPlusDebt.amount - (Math.abs(minusDebt.amount) * minusDebt.currency.rate)
                        minusDebt.amount = 0
                        if (findPlusDebt.amount < 0) {
                            cash_back.push({
                                currency: findPlusDebt.currency,
                                amount: Math.abs(findPlusDebt.amount)
                            })
                            findPlusDebt.amount = 0
                        }
                    }
                } else {
                    //yoq bolsa qarzni qaytimga qoshib yuboramiz
                    cash_back.push({
                        currency: minusDebt.currency,
                        amount: Math.abs(minusDebt.amount)
                    })
                    minusDebt.amount = 0
                }
            }
        })
    }

    return {debts, cash_back}
}
