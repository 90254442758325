import {Switch, useRouteMatch} from "react-router-dom";
import {Route} from "react-router";
import Dashboard from "./Dashboard";
import Warehouses from "../../../components/hippo/warehouses/Warehouses";
import React, {useEffect} from "react";
import EventBus from "../../../app/eventbus/EventBus";
import WarehouseOperationLayout from "../warehouse-operation/layout/WarehouseOperationLayout";
import {getAllWarehousesAsync} from "../../../app/store/reducers/warehouse/warehouseReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {currencyGetAsync} from "../../../app/store/reducers/currency/currencyReducer";
import BarcodeReader from "../../../components/hippo/item/components/BarcodeReader";
import ItemLayout from "../item/layout/ItemLayout";
import TryEdiTrialAlert from "../../../components/notification/TryEDITrialAlert";
import {
    getOrganizationBillingInfoAsync,
    POS_SERVICE_CODE,
    selectIsPosServiceActive,
    selectIsWarehouseServiceActive,
    selectOrganizationServiceDemoUses,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../../app/store/reducers/billing/billingReducer";
import {ACTIVATE_DEMO_SUCCEED} from "../../../app/eventbus/billingEvents";
import ActivateServicePackageAlert from "../../../components/notification/ActivateServicePackageAlert";
import OrganizationBindingsLayout from "../organization-bindings/OrganizationBindingsLayout";
import EndBillingPackageAlert from "../../../components/common/EndBillingPackageAlert";

const WarehouseLayout = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const isWarehouseServiceActive = useSelector(selectIsWarehouseServiceActive)
    const isPosServiceActive = useSelector(selectIsPosServiceActive)
    const {url} = useRouteMatch();


    useEffect(() => {
        dispatch(getAllWarehousesAsync({}))

        const activateDemoPeriodHandler = EventBus.on(ACTIVATE_DEMO_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_DEMO_SUCCEED, activateDemoPeriodHandler);
        }
    }, [activeOrganization])

    return (
        <>
            {!isWarehouseServiceActive && <ActivateServicePackageAlert serviceCode={WAREHOUSE_MANAGEMENT_SERVICE_CODE} />}
            {!isPosServiceActive && <ActivateServicePackageAlert serviceCode={POS_SERVICE_CODE}/>}

            <BarcodeReader/>
            <Switch>
                <Route path={`${url}`} exact component={Dashboard}/>
                <Route path={`${url}/items`} component={ItemLayout}/>
                <Route path={`${url}/warehouses`} component={Warehouses}/>
                <Route path={`${url}/partners`} component={OrganizationBindingsLayout}/>
                <Route path={`${url}/operation`} component={WarehouseOperationLayout}/>
            </Switch>
        </>
    );
}

export default WarehouseLayout;
