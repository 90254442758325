import React, {Fragment, useEffect, useRef} from 'react';
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Button, Card, OverlayTrigger, Popover} from "react-bootstrap";
import RoamingInvoiceDataTableHeader from "./RoamingInvoiceDataTableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {
    roamingHideModal,
    selectIsLoading,
    setId,
    setRoamingCommonHideModal,
    setShowModal,
    viewPage
} from '../../../../../app/store/reducers/roaming/roamingInvoiceReducer';
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import dayjs from 'dayjs';
import SoftBadge from '../../../../common/SoftBadge';
import AdvanceTableCustomPagination from '../../../../common/advance-table/AdvanceTableCustomPagination';
import numeral from 'numeral';
import {
    DRAFT,
    RoamingInvoiceWrapper,
    StatusDeclinedByCustomerOrPerson,
    StatusSentToCustomer,
    VIEWER_CUSTOMER,
    VIEWER_EXECUTOR,
    VIEWER_PERSON
} from "../../../../../enum/RoamingInvoiceWrapper";
import PropTypes from "prop-types";
import RoamingInvoiceDraftDelete from "../actions/RoamingInvoiceDraftDelete";
import ShowNoteModal from "../ShowNoteModal";
import EventBus from "../../../../../app/eventbus/EventBus";
import {ROAMING_INVOICE_DRAFT_DELETE_SUCCESS} from "../../../../../app/eventbus/roaming/roamingInvoiceEvents";
import classNames from "classnames";
import ViewModalPage from "../../components/ViewModalPage";
import ViewRoamingInvoice from "../../../../../pages/main/roaming/invoice/ViewRoamingInvoice";
import ViewRoamingInvoiceDraft from "../../../../../pages/main/roaming/invoice/ViewRoamingInvoiceDraft";
import {faQrcode,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RoamingInvoiceDataTable = ({
                                     viewer,
                                     isSelectable,
                                     predefinedFilters,
                                     onSelect,
                                     activeColumns,
                                     changeFilterOptionsAsync,
                                     roamingInvoices,
                                     loadInvoices,
                                     filters,
                                     count,
                                     onPageChange,
                                     onLimitChange,
                                     returnInvoice,
                                     excelRegistry
                                 }) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const invoicePositive = roamingInvoices?.filter((sumPositive) => sumPositive?.total_info?.total_with_nds > 0)
    const invoiceNegative = roamingInvoices?.filter((sumNegative) => sumNegative?.total_info?.total_with_nds < 0)
    const positiveSumPage = invoicePositive?.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)
    const negativeSumPage = invoiceNegative?.reduce((acc, cur) => acc + cur?.total_info?.total_with_nds, 0)
    const getActiveColumns = (activeColumns, columns = []) => {
        if (activeColumns?.length) {
            return columns?.filter((col, index) => activeColumns[index]?.active)
        } else {
            return columns
        }
    }

    const openViewModalPage = (e, id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === DRAFT) {
            if (ctrlKey) {
                history('/#/roaming/invoice/draft/' + id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        } else {
            if (ctrlKey) {
                history('/#/roaming/invoice/' + id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if (hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    // table columns
    const cols = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "roaming.invoices.number"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                const isRoamingError = !!original.roaming_error

                const link = <span
                    className={`cursor-pointer text-primary ${classNames({'text-warning': isRoamingError})}`}
                    onClick={(e) => openViewModalPage(e, original.id)}>
                    <strong>{original.info.number}</strong>
                </span>

                return <div className={'d-flex align-items-center gap-2'}>
                    {(viewer !== DRAFT && original?.has_marking) &&
                        <OverlayTrigger placement="right" trigger={['hover', 'focus']} overlay={
                            <Popover variant="warning">
                                <Popover.Body> {t(lang, 'roaming.common.has_marking.description')
                                    .split('{icon_exclamation}')
                                    .map((part, index) =>
                                        index === 0 ? (
                                            <>
                                                {part}
                                                <FontAwesomeIcon size={'lg'} className="text-warning"
                                                                 icon={"exclamation-circle"}/>
                                            </>
                                        ) : (
                                            part
                                        )
                                    )}</Popover.Body>
                            </Popover>
                        }>
                            <div>
                                <FontAwesomeIcon className={'text-warning'} size={'lg'} icon={faQrcode}/>
                            </div>
                        </OverlayTrigger>
                    }
                    {!isRoamingError && link}
                    {isRoamingError &&
                        <OverlayTrigger placement="right" trigger={['hover', 'focus']} overlay={
                            <Popover variant="warning">
                                <Popover.Header
                                    as="h3">Время: {dayjs(original.roaming_error.time, "YYYY-MM-DD HH:mm:ss").format(currentDateDayJSFormat)} | {original.roaming_error.status}</Popover.Header>
                                <Popover.Body>{original.roaming_error.message}</Popover.Body>
                            </Popover>}
                        >
                            {link}
                        </OverlayTrigger>}
                </div>
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.invoices.datatable.header.row.total_sum"),
            headerProps: {className: 'text-end'},
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <strong>
                        {numeral.formats[numberFormat].format((original.total_info.total_with_nds))}
                    </strong>
                )
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "roaming.invoices.date"),
            headerProps: {className: 'pe-1 text-center'},
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'status',
            Header: t(lang, "roaming.invoices.datatable.header.row.status"),
            headerProps: {className: 'text-center'},
            cellProps: {
                className: 'text-center',
            },
            Cell: ({row: {original}}) => {
                const invoiceWrapper = new RoamingInvoiceWrapper(original)
                const showDeclineModal = StatusDeclinedByCustomerOrPerson === original.status
                if (showDeclineModal) {
                    return (
                        <ShowNoteModal
                            notes={original.notes}
                            bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                            title={t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                        />
                    )
                } else {
                    return (
                        <h5>
                            <SoftBadge
                                bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                                className='me-2'>
                                {t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                            </SoftBadge>
                        </h5>
                    )
                }

            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.invoices.created_at"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.invoices.datatable.header.row.contractor"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                if (original.single_sided_type === 0) {
                    if (activeOrganization.inn === original.executor.inn || activeOrganization.pinfl === original.executor.inn)
                        return <>
                            <b>{original.customer.name}</b>
                            <br/>
                            <small><i>{original.customer.inn}</i></small>
                        </>
                    else
                        return <>
                            <b>{original.executor.name}</b>
                            <br/>
                            <small><i>{original.executor.inn}</i></small>
                        </>
                }
                if (original?.content) {
                    if (activeOrganization?.inn === original?.executor?.inn || activeOrganization?.pinfl === original?.executor?.inn)
                        return <>
                            <b>{original?.customer?.name}</b>
                            <br/>
                            <small><i>{original?.customer?.inn}</i></small>
                        </>
                    else
                        return <>
                            <b>{original?.executor?.name}</b>
                            <br/>
                            <small><i>{original?.executor?.inn}</i></small>
                        </>
                }
                return null;
            }
        },
        {
            accessor: 'contract_num',
            Header: t(lang, "roaming.invoices.datatable.header.row.contract_num"),
            headerProps: {className: 'text-start'},
            cellProps: {
                className: 'text-start',
            },
            Cell: ({row: {original}}) => {
                return <b>{original?.contract?.number}</b>
            }
        },
    ];
    const columns = getActiveColumns(activeColumns, cols);

    // push a new column if is draft (delete action)
    if (viewer === DRAFT)
        columns.push({
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RoamingInvoiceDraftDelete id={original.id}
                                               size="sm"
                    />
                )
            }
        })

    // push a new column if is selectable (choose action)
    if (isSelectable)
        columns.push({
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Button variant="primary" size="sm" className="mb-1" onClick={() => onSelect(original)}>
                        {t(lang, "roaming.common.select.placeholder")}
                    </Button>
                )
            }
        })

    // load invoices
    useEffect(() => {
        const onDraftDeletedHandler = EventBus.on(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, () => {
            loadInvoices();
        })

        loadInvoices();

        return () => {
            EventBus.remove(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, onDraftDeletedHandler);
        }
    }, [activeOrganization, filters])


    // save previous viewer for match in reducer
    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value])

        return ref.current;
    };
    const previousViewer = usePrevious(viewer);

    // change filters on viewer change
    useEffect(() => {
        if ((viewer && previousViewer) && viewer !== previousViewer) {
            let params = {...filters};

            // initialize status
            params.status = StatusSentToCustomer;

            if (previousViewer === VIEWER_CUSTOMER && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
                params.customer_inn = null;
                params.executor_inn = null;
                params.customer_branch_code = null;
                params.executor_branch_code = null;
            }

            if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_CUSTOMER) {
                params.customer_inn = null;
                params.executor_inn = null;
                params.customer_branch_code = null;
                params.executor_branch_code = null;
            }

            if (previousViewer === VIEWER_CUSTOMER && viewer === VIEWER_PERSON) {
                params.customer_inn = null;
                params.customer_branch_code = null;
            }

            if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_PERSON) {
                params.executor_inn = null;
                params.executor_branch_code = null;
            }

            if (previousViewer === VIEWER_PERSON && viewer === VIEWER_CUSTOMER) {
                params.customer_inn = null;
                params.customer_branch_code = null;
            }

            if (previousViewer === VIEWER_PERSON && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
                params.executor_inn = null;
                params.executor_branch_code = null;
            }
            dispatch(changeFilterOptionsAsync(params));
        }
    }, [viewer])

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={roamingInvoices}
                pagination
                perPage={1000}
                selection={true}
                selectionColumnWidth={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <RoamingInvoiceDataTableHeader
                            returnInvoice={returnInvoice}
                            excelRegistry={excelRegistry}
                            viewer={viewer}
                            predefinedFilters={predefinedFilters}
                            loadInvoices={loadInvoices}
                            positiveSumPage={positiveSumPage}
                            negativeSumPage={negativeSumPage}
                            table
                            changeFilterOptionsAsync={changeFilterOptionsAsync}
                            filters={filters}
                            count={count}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            isLoading={isLoading}
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage onHide={onHideModalPage} show={showModal}>
                {id && <ViewRoamingInvoice modalId={id}/>}
                {draftId && <ViewRoamingInvoiceDraft modalId={draftId}/>}
            </ViewModalPage>

        </Fragment>
    );
}

RoamingInvoiceDataTable.propTypes = {
    predefinedFilters: PropTypes.object,
}

RoamingInvoiceDataTable.defaultProps = {
    predefinedFilters: {},
}

export default RoamingInvoiceDataTable;
