import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {ExcelRegistryTypes} from "../../../../enum/ExcelRegistry";

const DocumentTypeSelector = ({defaultType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={ExcelRegistryTypes}
                     isClearable={true}
                     isSearchable={!defaultType}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => t(lang, option.label)}
                     value={ExcelRegistryTypes.find(o => o.value === defaultType) || null}
                     onChange={(option) => onChange(option ? option.value : null)}
                     placeholder={t(lang, "items.common.select")}
                     {...selectProps}
        />
    );
};

export default DocumentTypeSelector;