import dayjs from "dayjs";
import {Card, Form} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED,
    DELETE_TELEGRAM_ORDER_BOT_SUCCEED,
    REGISTER_TELEGRAM_ORDER_BOT_SUCCEED
} from "../../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import {
    selectTelegramOrderBotFilters,
    selectTelegramOrderBotPagination,
    selectTelegramOrderBots,
    updateBots,
    updateFilters,
    updatePagination
} from "../../../../../../app/store/reducers/crm/bot/telegramOrderBotReducer";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {Link} from "react-router-dom";
import {debounce} from "underscore";
import classNames from "classnames";
import {transliterate} from "transliteration";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import DataTable from "../../../../../common/table/DataTable";
import DataTablePagination from "../../../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../../../common/table/DataTableLimiter";
import TelegramOrderBotService from "../../../../../../services/telegram-order-bot/telegramOrderBotService";
import TelegramOrderBotDataTableHeader from "./TelegramOrderBotDataTableHeader";
import DeleteTelegramOrderBotDropdownItem from "./DeleteTelegramOrderBotDropdownItem";
import CardDropdown from "../../../../../common/CardDropdown";
import useCheckPermission from "../../../../../../hooks/useCheckPermission";
import {
    PermissionCRMTelegramOrderDelete,
    PermissionCRMTelegramOrderView
} from "../../../../../../enum/Permission/CrmPermission";
import Error403 from "../../../../../errors/Error403";


const TelegramOrderBotDatatable = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const bots = useSelector(selectTelegramOrderBots);
    const filters = useSelector(selectTelegramOrderBotFilters);
    const pagination = useSelector(selectTelegramOrderBotPagination);
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);
    const [loading, setLoading] = useState(false);

    // permissions
    const isTelegramOrderBotDelete = useCheckPermission(PermissionCRMTelegramOrderDelete);
    const isTelegramOrderBotView = useCheckPermission(PermissionCRMTelegramOrderView);


    const onFilterChange = (filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
    }, 500), [pagination.pageSize]);
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        dispatch(updatePagination(nextState))
    };
    const load = async () => {
        if (!isTelegramOrderBotView) return
        try {
            setLoading(true);
            const {data: bots} = await TelegramOrderBotService.getTelegramOrderBots();
            dispatch(updateBots(bots));
            setLoading(false);
        } catch (error) {
            dispatch(updateBots([]));
            setLoading(false);
        }
    };

    const columns = useMemo(() => {
        return [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i].id] = true;
                        }

                        setRowSelection(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {row.index + 1}
                        </td>
                    );
                },
            },
            {
                id: 'name',
                accessorKey: t(lang, "crm.common.datatable.name"),
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1"
                            style={{
                                minWidth: "400px",
                            }}
                            onClick={column.getToggleSortingHandler()}
                        >
                            {t(lang, "crm.common.datatable.name")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                minWidth: "400px",
                            }}
                        >
                            <Link to={`/crm/telegram-order-bot/${original.id}`}
                                  className={classNames('fs--1 fw-semi-bold font-sans-serif text-dark')}>
                                {original.name}
                            </Link>
                        </td>
                    )
                },
            },
            {
                id: 'createdAt',
                accessorKey: t(lang, "crm.common.datatable.created_at"),
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "180px",
                                width: "180px"
                            }}
                        >
                            {t(lang, 'crm.common.datatable.created_at')}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center fs--1"
                            style={{
                                minWidth: "180px",
                                width: "180px"
                            }}
                        >
                            {dayjs(original.created_at).format(`${dateFormat} HH:mm:ss`)}
                        </td>
                    )
                },
            },
            {
                id: 'actions',
                accessorKey: t(lang, "notification.table.actions"),
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"></th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center fs--1">
                            {isTelegramOrderBotDelete && <CardDropdown btnRevealClass="btn-reveal-sm">
                                <div className="py-2">
                                    <DeleteTelegramOrderBotDropdownItem bot={original}/>
                                </div>
                            </CardDropdown>}
                        </td>
                    )
                },
            }
        ];
    }, [lang]);
    const data = useMemo(() => {
        const filteredItems = bots.filter((bot) => {
            // search by filters.name
            {
                if (filters.name) {
                    const searchTermLower = filters.name.trim().toLowerCase();
                    const itemNameLower = bot.name.trim().toLowerCase();
                    const transliteratedQuery = transliterate(searchTermLower);
                    const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                    const transliteratedItemName = transliterate(itemNameLower);
                    if (filterNameParts.length !== filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                        return false
                }
            }

            return true;
        })

        return filteredItems;
    }, [bots, filters])
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: onPaginationChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        meta: {
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
        },
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
        }
    });

    useEffect(() => {
        load();

        const onRegisterTelegramOrderBotSucceedHandler = EventBus.on(REGISTER_TELEGRAM_ORDER_BOT_SUCCEED, load);
        const onDeleteTelegramOrderBotSucceedHandler = EventBus.on(DELETE_TELEGRAM_ORDER_BOT_SUCCEED, load);
        const onBulkDeleteTelegramOrderBotsSucceedHandler = EventBus.on(BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED, load);

        return () => {
            EventBus.remove(REGISTER_TELEGRAM_ORDER_BOT_SUCCEED, onRegisterTelegramOrderBotSucceedHandler);
            EventBus.remove(DELETE_TELEGRAM_ORDER_BOT_SUCCEED, onDeleteTelegramOrderBotSucceedHandler);
            EventBus.remove(BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED, onBulkDeleteTelegramOrderBotsSucceedHandler);
        };
    }, [activeOrganization])

    if (!isTelegramOrderBotView) return <Error403 />;

    return (
        <Card>
            <Card.Header>
                <TelegramOrderBotDataTableHeader table={table}
                                                 filter={filters}
                                                 onFilterChange={onFilterChange}
                                                 onDebouncedFilterChange={onDebouncedFilterChange}
                                                 onReloadItems={load}
                                                 loading={loading}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={data.length}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default TelegramOrderBotDatatable;