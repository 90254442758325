import {useState} from "react";

const useEditableCell = (item, key, setRowStates, changeUpdate, defaultValue) => {
    const rowId = item.id;

    const [tempContent, setTempContent] = useState(defaultValue || "");
    const [showModal, setShowModal] = useState(false);

    const handleDoubleClick = () => {
        setRowStates(prevState => ({
            ...prevState,
            [rowId]: {
                ...prevState[rowId],
                [`edit${key}`]: true,
            }
        }));
    };

    const handleInputChange = (e) => {
        setTempContent(e.target.value);
    };

    const handleBlur = () => {
        if (tempContent === defaultValue) {
            setRowStates(prevState => ({
                ...prevState,
                [rowId]: {
                    ...prevState[rowId],
                    [`edit${key}`]: false,
                }
            }));
            return
        }
        setShowModal(true);
    };

    const handleConfirm = () => {
        changeUpdate({ content: tempContent, id: rowId, defaultValue });
        setRowStates(prevState => ({
            ...prevState,
            [rowId]: {
                ...prevState[rowId],
                [`edit${key}`]: false,
            }
        }));
        setShowModal(false);
    };

    const handleCancel = () => {
        setTempContent(item[key] || "");
        setRowStates(prevState => ({
            ...prevState,
            [rowId]: {
                ...prevState[rowId],
                [`edit${key}`]: false,
            }
        }));
        setShowModal(false);
    };

    return {
        tempContent,
        handleDoubleClick,
        handleInputChange,
        handleBlur,
        handleConfirm,
        handleCancel,
        showModal
    };

}

export default useEditableCell;