
export const Piece = "0";
export const Kilogram = "1";
export const Meter = "2";
export const Litre = "3";
export const SquareMeter = "4";
export const Gram = "5";

export const measurements = [
    {
        label: 'items.common.measurement_options.0',
        value: Piece
    },
    {
        label: 'items.common.measurement_options.1',
        value: Kilogram
    },
    {
        label: 'items.common.measurement_options.5',
        value: Gram
    },
    {
        label: 'items.common.measurement_options.2',
        value: Meter
    },
    {
        label: 'items.common.measurement_options.3',
        value: Litre
    },
    {
        label: 'items.common.measurement_options.4',
        value: SquareMeter
    },
];

export const barcodeTypes = [
    {
        value: 'CODE39',
        label: 'CODE39',
    },
    {
        value: 'CODE128',
        label: 'CODE128 auto',
    },
    {
        value: 'CODE128A',
        label: 'CODE128 A',
    },
    {
        value: 'CODE128B',
        label: 'CODE128 B',
    },
    {
        value: 'CODE128C',
        label: 'CODE128 C',
    },
    {
        value: 'EAN13',
        label: 'EAN13'
    },
    {
        value: 'EAN8',
        label: 'EAN 8'
    },
    {
        value: 'EAN5',
        label: 'EAN 5'
    },
    {
        value: 'EAN2',
        label: 'EAN 2'
    },
    {
        value: 'EAN2',
        label: 'EAN 2'
    },
    {
        value: 'UPC',
        label: 'UPC',
    },
    {
        value: 'ITF14',
        label: 'ITF14',
    },
    {
        value: 'ITF',
        label: 'ITF',
    },
    {
        value: 'MSI',
        label: 'MSI',
    },
    {
        value: 'MSI10',
        label: 'MSI 10',
    },
    {
        value: 'MSI11',
        label: 'MSI 11',
    },
    {
        value: 'msi1110',
        label: 'MSI 1110',
    },
    {
        value: 'MSI1010',
        label: 'MSI 1010',
    },
    {
        value: 'MSI1110',
        label: 'MSI 1110',
    },
    {
        value: 'pharmacode',
        label: 'Pharma code'
    },
    {
        value: 'codabar',
        label: 'Coda bar'
    }
];

export const ItemLegalTypeWhite = 0;
export const ItemLegalTypeBlack = 1;

export const itemLegalTypes = [
    {
        label: 'items.common.item_legal_type_selector.type_white',
        value: ItemLegalTypeWhite
    },
    {
        label: 'items.common.item_legal_type_selector.type_black',
        value: ItemLegalTypeBlack
    },
];

export const KZT = 398;
export const RUB = 200;
export const GBP = 826;
export const USD = 840;
export const UZS = 860;
export const EUR = 978;

export const WithoutVat = "";
export const ZeroVat = "0";
export const TwelveVat = "12";
export const FifteenVat = "15";

export const ALERT_ON_STATE_0 = "0"
export const ALERT_ON_STATE_1 = "1"
export const ALERT_ON_STATE_2 = "2"
export const ALERT_ON_STATE_3 = "3"
export const ALERT_ON_STATE_4 = "4"


export const taxRateOptions = [
    {value: WithoutVat, label: 'items.common.tax_rate_options.0'},
    {value: ZeroVat, label: '0'},
    {value: TwelveVat, label: '12'},
    {value: FifteenVat, label: '15'}
]

export const Unknown = null;
export const CatalogCodeAndPackageOk = 2;
export const CatalogCodeMoved = 3;
export const CatalogCodeInvalidPackage = 4;
export const CatalogCodeDeleted = 5;
export const ServerError = 6;

export const checkState = (item, warehouse) => {
    if (warehouse) {
        if (warehouse.state < 0) {
            return 'rgb(227 81 97 / 23%)';
        } else if (warehouse.alert_on !== null && warehouse.alert_on > warehouse.state) {
            return 'rgb(252 219 113 / 37%)';
        }
    } else {
        let count = 0;

        for (let warehouseItem of item.warehouse_states.warehouse_items) {
            count += warehouseItem.state;
        }

        if (count < 0) {
            return 'rgb(227 81 97 / 23%)';
        } else if (count > 0) {
            for (let warehouseItem of item.warehouse_states.warehouse_items) {
                if (warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state) {
                    return 'rgb(252 219 113 / 37%)';
                }
            }
        }
    }
    return false;
}

const filterItemByWarehouse = (filter, warehouse) => {
    switch (filter) {
        case ALERT_ON_STATE_1:
            return warehouse.state > 0;
        case ALERT_ON_STATE_3:
            return warehouse.state === 0;
        case ALERT_ON_STATE_4:
            return warehouse.state < 0;
        case ALERT_ON_STATE_2:
            return (warehouse.alert_on !== null && warehouse.alert_on > warehouse.state) || warehouse.state < 0;
        case ALERT_ON_STATE_0:
            return true;
        default:
            return false;
    }
}

const filterByItem = (filter, item) => {
    let count = 0
    switch (filter) {
        case ALERT_ON_STATE_1:
        case ALERT_ON_STATE_3:
        case ALERT_ON_STATE_4:
            count = item.reduce((sum, warehouseItem) => sum + warehouseItem.state, 0);
            if (filter === ALERT_ON_STATE_1 && count > 0) return true;
            if (filter === ALERT_ON_STATE_3 && count === 0) return true;
            if (filter === ALERT_ON_STATE_4 && count < 0) return true;
            break;
        case ALERT_ON_STATE_2:
            for (let warehouseItem of item) {
                if (warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state) return true;
                count += warehouseItem.state;
            }
            if (count < 0) return true;
            break;
        case ALERT_ON_STATE_0:
            return true;
        default:
            return false
    }
}

export const filterState = (item, filter, warehouse) => {
    if (warehouse) {
        return filterItemByWarehouse(filter, warehouse)
    } else {
        return filterByItem(filter, item)
    }
}

class ItemWrapper {
    calculateItemsCommonPriceTotal(items) {
        const commonPrices = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemWarehouseTotalState = item.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);

            if (itemWarehouseTotalState <= 0)
                continue;

            let found = false;
            for (let j = 0; j < commonPrices.length; j++) {
                if (commonPrices[j].currencyId === item.price.common_price.currency.id) {
                    commonPrices[j].amount += item.price.common_price.amount * itemWarehouseTotalState;
                    found = true;
                    break;
                }
            }

            if (!found) {
                commonPrices.push({
                    amount: item.price.common_price.amount * itemWarehouseTotalState,
                    currencyId: item.price.common_price.currency.id,
                    currencyName: item.price.common_price.currency.name,
                });
            }
        }

        return commonPrices;
    }

    calculateItemsBulkPriceTotal(items) {
        const bulkPrices = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemWarehouseTotalState = item.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);

            if (itemWarehouseTotalState <= 0)
                continue;

            let found = false;
            for (let j = 0; j < bulkPrices.length; j++) {
                if (bulkPrices[j].currencyId === item.price.bulk_price.currency.id) {
                    bulkPrices[j].amount += item.price.bulk_price.amount * itemWarehouseTotalState;
                    found = true;
                    break;
                }
            }

            if (!found) {
                bulkPrices.push({
                    amount: item.price.bulk_price.amount * itemWarehouseTotalState,
                    currencyId: item.price.bulk_price.currency.id,
                    currencyName: item.price.bulk_price.currency.name,
                });
            }
        }

        return bulkPrices;
    }

    calculateItemsPurchasePricesTotal(items) {
        let warehousesPurchasePrices = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            for (let j = 0; j < item.warehouse_states.warehouse_items.length; j++) {
                const itemWarehouse = item.warehouse_states.warehouse_items[j];

                if (itemWarehouse.state <= 0)
                    continue;

                let warehouseFound = false;
                for (let k = 0; k < warehousesPurchasePrices.length; k++) {
                    if (warehousesPurchasePrices[k].warehouseId === itemWarehouse.id) {
                        if (!!itemWarehouse.purchase_price) {
                            let purchaseCurrencyFound = false;
                            for (let l = 0; l < warehousesPurchasePrices[k].purchasePrices.length; l++) {
                                if (warehousesPurchasePrices[k].purchasePrices[l].currencyId === itemWarehouse.purchase_price.price.currency.id) {
                                    warehousesPurchasePrices[k].purchasePrices[l].amount += itemWarehouse.purchase_price.price.amount * itemWarehouse.state;
                                    purchaseCurrencyFound = true;
                                    break;
                                }
                            }

                            if (!purchaseCurrencyFound) {
                                warehousesPurchasePrices[k].purchasePrices.push({
                                    amount: itemWarehouse.purchase_price.price.amount * itemWarehouse.state,
                                    currencyId: itemWarehouse.purchase_price.price.currency.id,
                                    currencyName: itemWarehouse.purchase_price.price.currency.name,
                                });
                            }
                        }
                        warehouseFound = true;
                        break;
                    }
                }

                if (!warehouseFound) {
                    const warehousePurchasePrices = {
                        warehouseId: itemWarehouse.id,
                        warehouseName: itemWarehouse.name,
                        purchasePrices: [],
                    }

                    if (!!itemWarehouse.purchase_price) {
                        warehousePurchasePrices.purchasePrices.push({
                            amount: itemWarehouse.purchase_price.price.amount * itemWarehouse.state,
                            currencyId: itemWarehouse.purchase_price.price.currency.id,
                            currencyName: itemWarehouse.purchase_price.price.currency.name,
                        });
                    }

                    warehousesPurchasePrices.push(warehousePurchasePrices);
                }
            }
        }

        return warehousesPurchasePrices;
    }

    calculateItemsWarehouseStates(items) {
        const itemsWarehouseStates = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            for (let j = 0; j < item.warehouse_states.warehouse_items.length; j++) {
                const itemWarehouse = item.warehouse_states.warehouse_items[j];

                let warehouseFound = false;
                for (let k = 0; k < itemsWarehouseStates.length; k++) {
                    if (itemsWarehouseStates[k].itemWarehouseId === itemWarehouse.id) {

                        if (itemWarehouse.state >= 0)
                            itemsWarehouseStates[k].itemWarehouseState += itemWarehouse.state;

                        warehouseFound = true;
                        break;
                    }
                }

                if (!warehouseFound) {
                    const warehouse = {
                        itemWarehouseId: itemWarehouse.id,
                        itemWarehouseName: itemWarehouse.name,
                        itemWarehouseState: 0,
                    }

                    if (itemWarehouse.state >= 0)
                        warehouse.itemWarehouseState = itemWarehouse.state;

                    itemsWarehouseStates.push(warehouse);
                }
            }
        }

        return itemsWarehouseStates;
    }

    getMeasurement(value) {
        const measurement = measurements.find(o => o.value === value.toString());
        return measurement.label;
    }

    getCurrency(currencies, id) {
        return currencies.find(c => c.id === id);
    }

    getDefaultCurrency(currencies) {
        const activeCurrencies = currencies.filter(c => c.is_active);

        const nationalCurrency = activeCurrencies.find(c => c.is_national);
        if (nationalCurrency)
            return nationalCurrency;

        const [defaultRandomCurrency] = activeCurrencies;
        if (defaultRandomCurrency)
            return defaultRandomCurrency

        return currencies[0];
    }

    getCurrencyAmountInNationalCurrency(currencies, amount, currencyId) {
        let resultAmount = 0;
        for (let i = 0; i < currencies.length; i++) {
            if (currencies[i].id === currencyId) {
                resultAmount = currencies[i].rate * amount;
                break;
            }
        }
        return resultAmount;
    }

    findCategoryFromTree(categoriesTree, categoryId) {
        for (let i = 0; i < categoriesTree.length; i++) {
            let c = categoriesTree[i]
            if (c.id === categoryId)
                return c;

            c = this.findCategoryFromTree(c.childs, categoryId)
            if (c !== null)
                return c
        }

        return null
    }

    filterCategory(categoriesTree, filteringCategoryId, itemCategoryId) {
        if (!itemCategoryId)
            return false

        const filteringCategory = this.findCategoryFromTree(categoriesTree, filteringCategoryId)
        if (!filteringCategory)
            return false

        if (!this.findCategoryFromTree([filteringCategory], itemCategoryId))
            return false

        return true
    }

    returnStatusState(state) {
        if (state > 0) return 'primary'
        else if (state < 0) return 'danger'
        else return 'secondary'
    }

    generateGtinBarcode(externalBarcode) {
        let barcode = externalBarcode.substring(0, externalBarcode.length - 1);
        //let lastNum = parseInt(externalBarcode.substring(externalBarcode.length - 1), 10);
        let calcSum = 0;
        //let calcChecksum = 0;

        barcode.split('').map(function (number, index) {
            number = parseInt(number, 10);

            //agar barcodeni uzunligi juft son bo'lsa index ni birga oshirish kerak
            if (externalBarcode.length % 2 === 0) {
                index += 1;
            }
            //toq elementlarni 3 ga ko'paytramiz obshiy summaga qoshamiz, juft elementni o'zini qo'shamiz
            if (index % 2 === 0) {
                calcSum += number;
            } else {
                calcSum += number * 3;
            }
        });

        calcSum %= 10;

        let c_num = (calcSum === 0) ? 0 : (10 - calcSum);
        return barcode + c_num
    }
}


const itemWrapper = new ItemWrapper();
export default itemWrapper;
