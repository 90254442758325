import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import EventBus from "../../../../app/eventbus/EventBus";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import {getRefundItemAsync} from "../../../../app/store/reducers/warehouse-operation/refundReducer";
import {
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/refundEvents";
import EditRefundForm from "../../../../components/hippo/warehouse-operation/refund/forms/EditRefundForm";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSRefundUpdate} from "../../../../enum/Permission/WarehouseOperationPermission";
import Error403 from "../../../../components/errors/Error403";
import {PermissionCRMContractorPayoutUpdate} from "../../../../enum/Permission/CrmPermission";


const EditRefund = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [refund, setRefund] = useState(null)
    const history = useHistory()
    const items = useSelector(selectItems)
    const {id} = useParams()

    // permissions
    const isRefundEdit = useCheckPermission(PermissionWMSRefundUpdate)
    const isPayoutEdit = useCheckPermission(PermissionCRMContractorPayoutUpdate);

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, (id) => {
            loadRefund()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`../view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onDeletePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        });

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, (id) => {
            loadRefund()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, (id) => {
            loadRefund()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS, (response) => {
            setRefund(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, (id) => {
            loadRefund()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS, onDeletePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, onChangedNumberHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
        }
    }, [])

    useEffect(() => {
        loadRefund()
    },[activeOrganization, id])



    const loadRefund = async () => {
        if (isRefundEdit || isPayoutEdit) {
            await getRefundItemAsync({refundId: id})
                .then((response) => {
                    setRefund(response.data, response.data.items.reverse())
                })
                .catch((error) => {
                    setRefund(null)
                })
        }
    }


    if (!isRefundEdit || !isPayoutEdit) return <Error403/>

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!refund)
        return (
            <SpinnerComponent />
        )


    return(
        <>
            <EditRefundForm refund={refund}/>
        </>
    )
}

export default EditRefund;
