import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../roaming/act/View/TypeView";
import roamingService from "../../../../services/roaming/roamingService";
import {debounce} from "underscore";
import ActDataTable from "../../roaming/act/ViewDataTable/ActDataTable";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import dayjs from "dayjs";

const ExcelRegistryRoamingActDataTable = ({registryId}) => {
    const viewer = VIEW_DRAFT;
    const activeOrganization = useSelector(selectActiveOrganization);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const [filters, setFilters] = useState({
        registryId: registryId,
        status: null,
        number: '',
        dateStart: null,
        dateEnd: null,
        createdAtStart: null,
        createdAtEnd: null,
        contractNumber: '',
        contractDate: null,
        contractor: null
    });
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
    });
    const [loading, setLoading] = useState(false);
    const [acts, setActs] = useState([]);
    const [count, setCount] = useState(0);
    const [columnVisibility, setColumnVisibility] = useState({
        "selectColumn": true,
        "#": true,
        "number": true,
        "date": true,
        "total": true,
        "status": true,
        "created_at": true,
        "customer": "true",
        "contract_number": true,
        "contract_date": true
    });
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);

    const onFilterChange = (filter) => {
        setFilters(filter);
        setPagination({...pagination, pageIndex: 0})
        load(viewer, filter, {...pagination, pageIndex: 0});
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        setFilters(filter);
        setPagination({...pagination, pageIndex: 0});
        load(viewer, filter, {...pagination, pageIndex: 0});
    }, 300), [pagination.pageSize])
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        setPagination(updater);
        load(viewer, filters, nextState)
    };
    const onColumnVisibilityChange = (columnVisibility) => {
        setColumnVisibility(columnVisibility);
    };
    const onRowSelectionChange = (rowSelection) => {
        setRowSelection(rowSelection);
    };
    const load = async (viewer, filter, pagination) => {
        try {
            setLoading(true);
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                if (filter.registryId)
                    filterParams["registry_id"] = filter.registryId;

                if (viewer !== VIEW_DRAFT && filter.status !== null)
                    filterParams["status"] = filter.status;

                if (filter.number)
                    filterParams["number"] = filter.number;

                if (filter.dateStart)
                    filterParams["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD");

                if (filter.dateEnd)
                    filterParams["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD");

                if (filter.createdAtStart)
                    filterParams["created_at_start"] = dayjs(filter.createdAtStart).format("YYYY-MM-DD");

                if (filter.createdAtEnd)
                    filterParams["created_at_end"] = dayjs(filter.createdAtEnd).format("YYYY-MM-DD");

                if (filter.contractNumber)
                    filterParams["contract_number"] = filter.contractNumber;

                if (filter.contractDate)
                    filterParams["contract_date"] = dayjs(filter.contractDate).format("YYYY-MM-DD");

                if (filter.contractor) {
                    if (viewer === VIEW_DRAFT || viewer === VIEW_OUTBOX)
                        filterParams["customer_inn"] = filter.contractor.person_num || filter.contractor.inn;
                    else if (viewer === VIEW_INBOX)
                        filterParams["executor_inn"] = filter.contractor.person_num || filter.contractor.inn;
                }
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize
            }

            // load documents and count
            switch (viewer) {
                case VIEW_INBOX:
                    const getCustomerActsPromise = roamingService.getCustomerAct({...filterParams, ...paginationParams});
                    const getCustomerActsCountPromise = roamingService.getCustomerActCount(filterParams)
                    const {data: customerActs} = await getCustomerActsPromise;
                    const {data: customerActsCount} = await getCustomerActsCountPromise;
                    setActs(customerActs);
                    setCount(customerActsCount);
                    break;
                case VIEW_OUTBOX:
                    const getExecutorActsPromise = roamingService.getExecutorAct({...filterParams, ...paginationParams});
                    const getExecutorActsCountPromise = roamingService.getExecutorActCount(filterParams)
                    const {data: executorActs} = await getExecutorActsPromise;
                    const {data: executorActsCount} = await getExecutorActsCountPromise;
                    setActs(executorActs);
                    setCount(executorActsCount);
                    break;
                case VIEW_DRAFT:
                    const getExecutorDraftActsPromise = roamingService.getExecutorActDraft({...filterParams, ...paginationParams});
                    const getExecutorDraftActsCountPromise = roamingService.getExecutorActDraftCount(filterParams);
                    const {data: executorDraftActs} = await getExecutorDraftActsPromise;
                    const {data: executorDraftActsCount} = await getExecutorDraftActsCountPromise;
                    setActs(executorDraftActs);
                    setCount(executorDraftActsCount);
                    break;
                default:
                    break
            }
            setLoading(false);
        } catch (error) {
            setActs([]);
            setCount(0);
            setLoading(false);
        }
    };

    useEffect(() => {
        load(viewer, filters, pagination)
    }, [activeOrganization, viewer]);

    return (
        <ActDataTable activeOrganization={activeOrganization}
                      activeCertificate={activeCertificate}
                      viewer={VIEW_DRAFT}
                      filter={filters}
                      pagination={pagination}
                      columnVisibility={columnVisibility}
                      onFilterChange={onFilterChange}
                      onDebouncedFilterChange={onDebouncedFilterChange}
                      onPaginationChange={onPaginationChange}
                      onColumnVisibilityChange={onColumnVisibilityChange}
                      onReloadData={() => load(viewer, filters, pagination)}
                      loading={loading}
                      rowSelection={rowSelection}
                      onRowSelectionChange={onRowSelectionChange}
                      lastRowSelected={lastRowSelected}
                      acts={acts}
                      count={count}
        />
    );
};

export default ExcelRegistryRoamingActDataTable;