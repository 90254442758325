import  {Fragment} from "react";
import {Tab, Tabs, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Permissions from "./Permissions";
import {EdiDefaultPermissions} from "../../../../enum/Permission/EdiPermission";
import {RoamingDefaultPermissions} from "../../../../enum/Permission/RoamingPermission";
import {AnotherDefaultPermission} from "../../../../enum/Permission/RolePermission";
import {changeDefaultRole} from "../../../../app/store/reducers/role/roleReducer";
import {WarehouseOperationDefaultPermission} from "../../../../enum/Permission/WarehouseOperationPermission";
import {CrmDefaultPermission} from "../../../../enum/Permission/CrmPermission";
import {WarehouseItemsDefaultPermissions} from "../../../../enum/Permission/WarehouseItemPermission";
import {CashBoxDefaultPermission} from "../../../../enum/Permission/CashBoxPermission";

const EDI = "1"
const Roaming = "2"
const Another  = "3"
const WarehouseItems  = "4"
const WarehouseOperations  = "5"
const CRM  = "6"
const CashBox  = "7"

const PermissionsDatatable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h5 className={"ps-1 text-center my-2"}>{t(lang, "edi.permission.role_permissions_title")}</h5>
            <Tabs fill defaultActiveKey={EDI}>
                <Tab eventKey={EDI} title={'EDI'}>
                    <Permissions permission={EdiDefaultPermissions} name={'edi'} />
                </Tab>
                <Tab title={t(lang, 'edi.navbar.routes.edo')} eventKey={Roaming}>
                    <Permissions permission={RoamingDefaultPermissions} name={'roaming'} />
                </Tab>
                <Tab title={t(lang, 'system.permission.warehouse.item.label')} eventKey={WarehouseItems}>
                    <Permissions permission={WarehouseItemsDefaultPermissions} name={'warehouse_items'} />
                </Tab>
                <Tab title={t(lang, 'system.permission.warehouse.operation.label')} eventKey={WarehouseOperations}>
                    <Permissions permission={WarehouseOperationDefaultPermission} name={'warehouse_operations'} />
                </Tab>
                <Tab title={t(lang, 'CRM')} eventKey={CRM}>
                    <Permissions permission={CrmDefaultPermission} name={'crm'} />
                </Tab>
                <Tab title={t(lang, 'cashbox.bind.account.title')} eventKey={CashBox}>
                    <Permissions permission={CashBoxDefaultPermission} name={'cash_box'} />
                </Tab>
                <Tab title={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.miscellaneous')}  eventKey={Another}>
                    <Permissions permission={AnotherDefaultPermission} name={'another'} />
                </Tab>
            </Tabs>
        </Fragment>
    )
}
export default PermissionsDatatable
