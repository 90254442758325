import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_NOTE_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_DATE_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DATE_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, WAREHOUSE_OPERATION_TRANSFER_NUMBER_FAILED,
    WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS, WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_FAILED
} from "../../../eventbus/warehouse-operation/transferEvents";
import warehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import dayjs from "dayjs";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);


const transferReducer = createSlice({
    name: "transfer",
    initialState: {
        transfers: [],
        transferCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            contractor_id: null,
            employee_id: null,
            operation_account_id: null,
            is_approved: null,
            warehouse_id: null
        },
        transferDefaultsSettings: null,
        loading: false
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateTransfers: (state, action) => {
            state.transfers = action.payload
        },
        updateTransferCount: (state, action) => {
            state.transferCount = action.payload
        },
        setTransferDefaultsSettings: (state, action) => {
            state.transferDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("TransferSettings", action.payload)
        },
        loadTransferDefaultsSettings: (state, action) => {
            const transferDefaults = JSON.parse(localStorage.getItem("TransferSettings"))
            state.transferDefaultsSettings = transferDefaults
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        updateTransferItemNote: (state, action) => {
            state.transfers = state.transfers.map((transfer) => {
                if (transfer.id === action.payload.id) {
                    transfer.note = action.payload.note
                }
                return transfer
            })
        }
    },
})







export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(transferReducer.actions.updateFilterOptions({...params}))
}

export const getTransfersAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(transferReducer.actions.setLoading(true))
    return new Promise((resolve, reject) => {
        warehouseOperationService.getTransfers(params)
            .then((response) => {
                dispatch(transferReducer.actions.updateTransfers(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => dispatch(transferReducer.actions.setLoading(false)))
    })
}

export const getTransfersCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getTransfersCount(filters)
            .then((response) => {
                dispatch(transferReducer.actions.updateTransferCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const transferRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_REGISTER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, response.data.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_REGISTER_FAILED)
                reject(error)
            })
    })
}

export const transferAddItemAsync = ({transferId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addTransferItem({transferId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getTransferItemAsync = ({transferId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getTransfer({transferId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const transferDeleteItemAsync = ({transferId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteTransferItem({transferId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const transferUpdateItemAsync = ({transferId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updateTransferItem({transferId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const transferApproveAsync = ({transferId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approveTransfer({transferId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const transferDeleteAsync = (id) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferDelete(id)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const transferUpdateNoteAsync = ({transferId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferUpdateNote({transferId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_NOTE_FAILED)
                reject(error)
            })
    })
}


export const transferUpdateDateAsync = ({transferId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferUpdateDate({transferId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_DATE_FAILED)
                reject(error)
            })
    })
}

export const transferUpdateNumberAsync = ({transferId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferUpdateNumber({transferId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, transferId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(<WAREHOUSE_OPERATION_TRANSFER_NUMBER_FAILED></WAREHOUSE_OPERATION_TRANSFER_NUMBER_FAILED>)
                reject(error)
            })
    })
}

export const transferChangeEmployeeAsync = ({transferId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferChangeEmployee({transferId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const transferDownloadPdfAsync = ({transferId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.transferDownloadPdf({transferId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const onTransferNoteChanged = ({note, id}) => (dispatch) => {
    dispatch(transferReducer.actions.updateTransferItemNote({note, id}))
}


export const selectTransferFilterOptions = state => state.transfer.filterOptions
export const selectTransferCount = state => state.transfer.transferCount
export const selectTransfers = state => state.transfer.transfers


export const selectTransferDefaultsSettings = state => state.transfer.transferDefaultsSettings
export const transferLoading = state => state.transfer.loading
export const {setTransferDefaultsSettings, loadTransferDefaultsSettings} = transferReducer.actions

export default transferReducer.reducer;
