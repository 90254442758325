import React, {Fragment, useEffect, useMemo, useState} from "react"
import {Badge, Button, Card, Overlay, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import ContractorDataTableHeader from "./ContractorDataTableHeader";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    selectContractorFilterOptions,
    selectAllContractor, loadContractorAsync, updateContractorDebtPromiseDate
} from "../../../../app/store/reducers/contractor/contractorReducer";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";

import {Link} from "react-router-dom";
import EditContractor from "./EditContractor";
import DeleteContractorModal from "./DeleteContractorModal";
import SoftBadge from "../../../common/SoftBadge";
import {
    ContractorDebtStateFilterIAmOwedDebt,
    ContractorDebtStateFilterIOweDebt,
    ContractorTypeBuyer,
    ContractorTypeProvider, debtPromiseDateDefault, debtPromiseDateShow, sortByDesc1, sortByDesc2,
} from "../../../../enum/ContractorWrapper";

import numeral from "numeral";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import IconButton from "../../../common/IconButton";
import Flex from "../../../common/Flex";
import {UZS} from "../../../../enum/CurrencyCode";
import {selectPaginationOptions, updatePagination} from "../../../../app/store/reducers/contractor/contractorReducer";
import AdvanceTableCustomPagination2 from "../../item/datatable/AdvanceTableCustomPagination2";
import {transliterate} from "transliteration";
import naturalCompare from "natural-compare";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import KanbanModal from "../../../kanban/modal/kanban/KanbanModal";
import {columnType, ContractorDebtBoardType} from "../../../../enum/KanbanWrapper";
import DebtRepayment from "./DebtRepayment";
import {
    getTask,
    selectBoards,
    selectWithTasks,
    updateModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {
    PermissionCRMContractorDelete,
    PermissionCRMContractorUpdate,
    PermissionCRMContractorView
} from "../../../../enum/Permission/CrmPermission";



const ContractorDataTable = () => {
    const contractors = useSelector(selectAllContractor);
    const filters = useSelector(selectContractorFilterOptions);
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)
    const dateFormat = useSelector(selectDateDayJSFormat);
    const boards = useSelector(selectBoards);
    const withTasks = useSelector(selectWithTasks);

    const [loading, setLoading] = useState(null)
    const [loadingTask, setLoadingTask] = useState(null)
    const [sort, setSort] = useState(null)
    const [editTask, setEditTask] = useState(false);
    const [sortByDesc, setSortByDesc] = useState(sortByDesc2);
    const [debtPromiseDate, setDebtPromiseDate] = useState(debtPromiseDateDefault);
    const {page, limit} = useSelector(selectPaginationOptions,shallowEqual);

    const lang = useSelector(selectLang);
    const t = Translate;

    const dispatch = useDispatch();

    // permissions
    const isContractorEdit = useCheckPermission(PermissionCRMContractorUpdate)
    const isContractorDelete = useCheckPermission(PermissionCRMContractorDelete)

    const [editingContractor, setEditingContractor] = useState(null);
    const [deletingContractorId, setDeletingContractorId] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [showDebtPromiseDate, setShowDebtPromiseDate] = useState(false);
    const [contractorId, setContractorId] = useState(null);

    useEffect(() => {
        setSelectedCurrency(currency.find(currency => currency.code === UZS))
    }, [currency])

    useEffect(() => {
        dispatch(updatePagination({limit: limit, page: 1}));
    }, [filters])

    const separationCurrenciesAndTotalSum = (states) => {
        let totalSum = 0
        states.forEach(state => {
            totalSum += state?.amount * currency.find(f => f.id === state.currency.id)?.rate
        })
        return totalSum
    }

    const data = useMemo(() => {
        let filtered_contractors = [];

        // filter by name
        for (let i = 0; i < contractors.length; i++) {
            const contractor = contractors[i];

            if (filters.name) {
                let found = false;
                const searchName = filters.name.trim().toLowerCase();
                const contractorNameLower = contractor.name.trim().toLowerCase();

                const transliteratedQuery = transliterate(searchName);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');

                const transliteratedItemName = transliterate(contractorNameLower);

                if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    found = true;

                // search by contact
                if (contractor.contacts.some((contact) => contact.value.toLowerCase().includes(searchName))) {
                    found = true;
                }

                if (!found)
                    continue;
            }
            filtered_contractors.push(contractor);
        }

        //filter by type
        if (filters.type) {
            filtered_contractors = filtered_contractors.filter(contractor => {
                const isExistType = contractor?.type?.some(t => t === filters.type)
                if (isExistType)
                    return contractor
            })
        }

        //filter by label
        if (filters.label) {
            filtered_contractors = filtered_contractors.filter(contractor => {
                const isExist = contractor.labels.some(label => label.id === filters.label)
                if (isExist)
                    return contractor
            })
        }

        // filter by debt
        if (filters.debt) {
            filtered_contractors = filtered_contractors.filter(item => {
                if (item?.debts?.length) {
                    let totalSum = 0;
                    item.debts.forEach(debt => {
                        totalSum += debt?.amount * currency.find(f => f.id === debt.currency.id)?.rate
                    })
                    if (filters.debt === ContractorDebtStateFilterIOweDebt && totalSum > 0) {
                        return {...item}
                    }
                    if (filters.debt === ContractorDebtStateFilterIAmOwedDebt && totalSum < 0) {
                        return {...item}
                    }
                }
            })
        }

        // filter by debt promise date
        if (debtPromiseDate === debtPromiseDateShow) {
            filtered_contractors = filtered_contractors.filter(item => item?.upcoming_debt_promise_task?.date)
        }

        // filter by debt promise type
        if (filters.debtPromiseType) {
            filtered_contractors = filtered_contractors.filter(item => item?.upcoming_debt_promise_task?.board_column_type === filters.debtPromiseType)
        }

        switch (sort) {
            case 'debt':
                if (+sortByDesc === sortByDesc1)
                    return filtered_contractors.sort((a, b) => naturalCompare(separationCurrenciesAndTotalSum(b.debts), separationCurrenciesAndTotalSum(a.debts)));
                return filtered_contractors.sort((a, b) => naturalCompare(separationCurrenciesAndTotalSum(a.debts), separationCurrenciesAndTotalSum(b.debts)));
            case 'debtDate':
                if (+sortByDesc === sortByDesc1) {
                    return filtered_contractors.sort((a, b) => {
                        if (a?.upcoming_debt_promise_task?.date === null && b?.upcoming_debt_promise_task?.date !== null) {
                            return 1;
                        }
                        if (b?.upcoming_debt_promise_task?.date === null && a?.upcoming_debt_promise_task?.date !== null) {
                            return -1;
                        }
                        if (a?.upcoming_debt_promise_task?.date !== null && b?.upcoming_debt_promise_task?.date !== null) {
                            return naturalCompare(new Date(a?.upcoming_debt_promise_task?.date), new Date(b?.upcoming_debt_promise_task?.date)) ;
                        }
                        return 0;
                    });
                }
                return filtered_contractors.sort((a, b) => {
                    if (a?.upcoming_debt_promise_task?.date === null && b?.upcoming_debt_promise_task?.date !== null) {
                        return 1;
                    }
                    if (b?.upcoming_debt_promise_task?.date === null && a?.upcoming_debt_promise_task?.date !== null) {
                        return -1;
                    }
                    if (a?.upcoming_debt_promise_task?.date !== null && b?.upcoming_debt_promise_task?.date !== null) {
                        return naturalCompare(new Date(b?.upcoming_debt_promise_task?.date), new Date(a?.upcoming_debt_promise_task?.date)) ;
                    }
                    return 0;
                });

            default:
                if (+sortByDesc === sortByDesc1)
                    return filtered_contractors.sort((a, b) => naturalCompare(b.name, a.name));
                return filtered_contractors.sort((a, b) => naturalCompare(a.name, b.name));
        }
    }, [contractors, filters, page, limit, sort, sortByDesc, debtPromiseDate])

    const separationCurrenciesAndTotalSums = useMemo(() => {
        let totalSum = 0
        data.filter((item,index) => index >= (page - 1) * limit && index < page * limit).forEach(contractor => {
            contractor.debts.forEach(debt => {
                totalSum += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
            })
        })
        return isNaN(totalSum) ? 0 : totalSum
    }, [contractors, data, filters, page, limit, sort, sortByDesc, debtPromiseDate])

    const totalGroupCurrency = useMemo(() => {
        const groupedSums = {}
        data.filter((item,index) => index >= (page - 1) * limit && index < page * limit).forEach(contractor => {
            contractor.debts.forEach(debt => {
                if (!groupedSums[debt?.currency?.id]) {
                    groupedSums[debt?.currency?.id] = {
                        total: 0,
                        currency_id: debt?.currency?.id,
                        name: debt.currency?.name
                    }
                }
                groupedSums[debt?.currency?.id].total += debt?.amount
            })
        })
        return Object.values(groupedSums)
    }, [contractors, data, filters, page, limit, selectedCurrency, sort, sortByDesc, debtPromiseDate])

    const onClickEdit = (contractor) => {
        let con = JSON.parse(JSON.stringify(contractor));
        con.contacts = con.contacts.map(contact => {
            if (contact.type === 1) {
                let phone = contact.value;
                if (phone.length === 12 && phone.slice(0, 3) === '998') {
                    phone = phone.slice(3, 12)
                }
                return {
                    type: contact.type,
                    value: phone,
                    description: contact.description
                }
            } else {
                return contact
            }
        })
        setEditingContractor(con)
    }

    const getTaskAsync = async (contractor) => {
        setContractorId(contractor.id)
        const {board_type, board_column_type, id} = contractor?.upcoming_debt_promise_task
        const board_id = boards.find(board => board.type === board_type)?.id
        const board_column_id = withTasks?.columns.find(column => column.type === board_column_type)?.id

        const data = {
            board_column_id: board_column_id,
            board_column_task_id: id
        }

        await dispatch(getTask(board_id, data))
            .then(res => {
                dispatch(updateModalContent(res))
            })
            .finally(() => setLoadingTask(false))
    }

    const handlerEditDebtDate = async (contractor) => {
        setLoadingTask(true)
        setShowDebtPromiseDate(true)
        setEditTask(true)
        getTaskAsync(contractor)
    }

    const columns = useMemo(() => {
        const dataTableColumn =  [
            {
                Header: "#",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: ({row}) => {
                    return `${row.index + 1}`;
                }
            },
            {
                accessor: 'info.name',
                Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
                headerProps: {className: 'pe-7'},
                Cell: ({row: {original}}) => {
                    return (
                        <Link to={`contractor-card/${original.id}`}>
                            <strong>{original.name}</strong>
                        </Link>
                    );
                }
            },
            {
                accessor: 'type',
                Header: 'Тип',
                headerProps: {className: 'pe-7'},
                totalInfo: `${t(lang, "Итого:")}`,
                totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
                Cell: ({row: {original}}) => {
                    if (original.type?.length > 0) {
                        return original.type?.map((item, index) => {
                            if (item === ContractorTypeBuyer) {
                                return (
                                    <SoftBadge className={'me-1'} bg="success"
                                               key={index}>{t(lang, "edi.common.buyer")}</SoftBadge>
                                )
                            }
                            if (item === ContractorTypeProvider) {
                                return (
                                    <SoftBadge bg="warning" key={index}>{t(lang, "edi.common.executor")}</SoftBadge>
                                )
                            }
                        })
                    } else {
                        return (<SoftBadge bg={'info'}>Не указано</SoftBadge>)
                    }
                }
            },
            {
                Header: t(lang, "edi.contractor.datatable.header.row.state"),
                accessor: 'debts',
                headerProps: {className: 'pe-7'},
                totalInfoClassName: "fw-bold fs--1 text-end text-nowrap",
                totalInfo: totalGroupCurrency.length > 0 && totalGroupCurrency.map((debt, index) => {
                    return (
                        <div key={index} className='me-3'>
                            <span>{numeral['formats'][numberFormat].format(debt.total)}</span>
                            <small className={'ms-2 fw-bold text-info'}>{debt.name}</small>
                        </div>
                    )
                }) || <span className={'me-3'}>0</span>,
                cellProps: {className: 'text-end'},
                Cell: ({row: {original}}) => {
                    if (original?.debts?.length !== 0) {
                        return original.debts.map((payment, index) => {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        {
                                            payment.amount ?
                                                <SoftBadge bg='primary' className='me-2'>
                                                    <span>{numeral.formats[numberFormat].format(payment.amount)}</span>
                                                    <small className={'ms-2 fw-bold text-info'}>{payment.currency.name}</small>
                                                </SoftBadge>
                                                : null}
                                    </div>
                                )
                            }
                        )
                    } else {
                        return <SoftBadge bg='primary' className='me-2'>
                            0
                        </SoftBadge>
                    }
                }
            },
            {
                Header: t(lang, 'crm.common.datatable.total.sum'),
                accessor: 'total_debts',
                headerProps: {className: 'pe-2 text-end'},
                totalInfoClassName: "fw-bold fs-0 text-end text-nowrap",
                totalInfo: <div className={'mt-2'}>
                    <span>{numeral['formats'][numberFormat].format(separationCurrenciesAndTotalSums / selectedCurrency?.rate)}</span>
                    <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
                </div>
                ,
                Cell: ({row: {original}}) => {
                    if (original?.debts?.length) {
                        return (
                            <Flex className={'fw-bold justify-content-end fs-0 align-items-center'}>
                                <span>{separationCurrenciesAndTotalSum(original.debts) ? numeral.formats[numberFormat].format(separationCurrenciesAndTotalSum(original.debts) / selectedCurrency?.rate) : 0}</span>
                                <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
                            </Flex>
                        )
                    } else {
                        return <Flex className={'fw-bold justify-content-end fs-0 align-items-center'}>
                            <span>0</span>
                            <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
                        </Flex>
                    }
                }
            },
            {
                Header: t(lang, 'crm.contractor.datatable.label'),
                accessor: 'labels',
                headerProps: {className: 'pe-2 text-center'},
                cellProps: {className: 'text-center'},
                Cell: ({row: {original}}) => {
                    if (original.labels?.length > 0) {
                        return original.labels?.map((label, index) => {
                            return (
                                <SoftBadge className={'me-1'} key={index}>{label.name}</SoftBadge>
                            )
                        })
                    } else {
                        return (<span/>)
                    }
                }
            },

            {
                accessor: 'organization_code',
                Header: t(lang, "partners.common.organization_code"),
                headerProps: {className: 'pe-7 text-center'},
                cellProps: {className: 'text-center'},
                Cell: ({row: {original}}) => {
                    return (
                        <strong className={'fs-0'}>{original.organization_code}</strong>
                    );
                }
            },
            {
                Header: t(lang, 'crm.contractor.datatable.debt_promise_date'),
                accessor: 'debt_promise_date',
                headerProps: {className: 'pe-2 text-center'},
                cellProps: {className: 'text-center'},
                Cell: ({row: {original}}) => {
                    const selectColumnType = columnType(original?.upcoming_debt_promise_task?.board_type, original?.upcoming_debt_promise_task?.board_column_type)
                    return (
                        <Fragment>
                            {original?.upcoming_debt_promise_task?.date ? <Fragment>
                                <div className={'d-flex justify-content-center align-items-center gap-2'}>
                                    <strong className={'fw-bold'}>
                                        {dayjs(new Date(original?.upcoming_debt_promise_task?.date), 'DD-MM-YYYY').format(dateFormat)}
                                    </strong>
                                    <Badge bg={selectColumnType?.variant}>
                                        {t(lang, selectColumnType?.name)}
                                    </Badge>
                                </div>
                                <div className={'fs-0 d-flex justify-content-center align-items-center gap-1 mt-1'}>
                                    <DebtRepayment getTaskAsync={() => getTaskAsync(original)}
                                                   contractorId={original?.id}>
                                        <SoftBadge title={t(lang, "crm.contractor.datatable.debt_promise_date.date.repayment.due.date")} bg={'success'} className={'cursor-pointer'}>
                                            {t(lang, 'task.board.common.close')}
                                        </SoftBadge>
                                    </DebtRepayment>
                                    <SoftBadge bg={'warning'} className={'cursor-pointer'}
                                               onClick={() => handlerEditDebtDate(original)}>
                                        {t(lang, 'crm.orders.item.row.button.edit')}
                                    </SoftBadge>
                                </div>
                            </Fragment> :
                                <Button onClick={() => {
                                    setContractorId(original.id);
                                    setShowDebtPromiseDate(true)
                                }} className={'my-1'} size={'sm'} variant={'primary'}>
                                    {t(lang, 'crm.contractor.datatable.set.debt')}
                                </Button>
                            }
                        </Fragment>
                )
                }
            },
            {
                accessor: 'none',
                Header: '',
                disableSortBy: true,
                cellProps: {
                    width: '80px',
                    className: 'text-end'
                },
                Cell: ({row}) => {
                    return (
                        <div className={'d-flex align-items-center justify-content-end gap-2'}>
                            {isContractorEdit && <IconButton
                                className="py-0"
                                onClick={() => {
                                    onClickEdit(row.original)
                                }}
                                variant="falcon-warning"
                                size="sm"
                                icon="edit"
                                transform="shrink-3"
                            />}
                            {isContractorDelete && <IconButton
                                className={'py-0'}
                                onClick={() => {
                                    setDeletingContractorId(row.original.id);
                                }}
                                variant="falcon-danger"
                                size="sm"
                                icon="trash"
                                transform="shrink-3"
                            />}

                        </div>
                    );
                }
            }
        ].filter(i => i);

        return dataTableColumn
    }, [contractors, filters, page, limit, selectedCurrency, sort, sortByDesc, debtPromiseDate, withTasks])

    const handlerClose = async () => {
        setLoadingTask(true)
        setShowDebtPromiseDate(false)
        setEditTask(false)

        const contractor = await loadContractorAsync(contractorId)
        contractor && dispatch(updateContractorDebtPromiseDate(contractor))
        setLoadingTask(false)
    }

    const onLimitChange = (limit) => dispatch(updatePagination({limit: limit, page: page}));
    const onPageChange = (page) => dispatch(updatePagination({limit: limit, page: page}));

    if (!currency.length) return <Spinner className={'position-absolute top-50'} style={{left: '50%'}}
                                          animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                selection
                width={'50px'}
                pagination
                perPage={limit}
                currentPage={page - 1}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractorDataTableHeader setSelectedCurrency={setSelectedCurrency}
                                                   setDebtPromiseDate={setDebtPromiseDate}
                                                   selectedCurrency={selectedCurrency}
                                                   debtPromiseDate={debtPromiseDate}
                                                   setSortByDesc={setSortByDesc}
                                                   sortByDesc={sortByDesc}
                                                   currency={currency}
                                                   setSort={setSort}
                                                   data={data}
                                                   table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                       onPageChange={onPageChange}
                                                       onLimitChange={onLimitChange}
                                                       table
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {editingContractor &&
                <EditContractor contractor={editingContractor} onClose={() => {
                    setEditingContractor(null)
                }}/>
            }

            {deletingContractorId &&
                <DeleteContractorModal id={deletingContractorId} show={!!deletingContractorId} onClose={() => {
                    setDeletingContractorId(null)
                }}/>
            }

            <KanbanModal
                loading={loadingTask}
                editDate={true}
                edit={editTask}
                show={showDebtPromiseDate}
                setShow={setShowDebtPromiseDate}
                contractorId={contractorId}
                fastAddTask={true}
                handlerClose={handlerClose}
                boardType={ContractorDebtBoardType}
            />

        </Fragment>
    )
}
export default ContractorDataTable
