import React, {useState, Fragment} from 'react';
import {Card} from 'react-bootstrap';
import ContractTableHeader from './ContractDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {selectContracts} from "../../../app/store/reducers/contract/contractReducer";
import {useSelector} from 'react-redux';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';
import ActionButton from '../../common/ActionButton';
import EditContract from "../../../pages/main/edi/contract/EditEdiContract";
import {selectActiveOrganization} from '../../../app/store/reducers/auth/authReducer';
import {selectLang, Translate, selectDateDayJSFormat} from "../../../app/store/reducers/main/mainReducer";
import dayjs from 'dayjs';
import {Link, useHistory} from "react-router-dom";
import DeleteContractModal from "./DeleteContractModal";

const ContractDataTable = ({activeColumns}) => {
    const contracts = useSelector(selectContracts)
    const [showEditForm, setShowEditForm] = useState(false)

    const [idDeleteContract, setIdDeleteContract] = useState(null)

    const [idContract, setIdContract] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const t = Translate;
    const routeHistory = useHistory();

    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'info.number',
            id: "number",
            Header: t(lang, "edi.contract.datatable.header.row.number"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`../crm/contractor-contract/${original.id}`}>
                        <strong>{original.info.name} {original.info.number} от {dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</strong>
                    </Link>
                )
            }
        },
        {
            accessor: 'info.date',
            id: "date",
            Header: t(lang, "edi.contract.datatable.header.row.date"),
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.contract.datatable.header.row.contractor"),
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if (isCustomer) {
                    return <b>
                        {original.executor.name}
                    </b>
                }

                return <b>
                    {original.customer.name}
                </b>

            }
        },
        {
            accessor: 'none',
            id: "button_action",
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <ActionButton
                            icon="edit"
                            title="Изменить"
                            variant="action"
                            className="p-0 me-2"
                            onClick={() => routeHistory.push(`../crm/contractor-contract/edit/${row.original.id}`)}
                        />
                        <ActionButton
                            icon="trash-alt"
                            title="Удалить"
                            variant="action"
                            className="p-0"
                            onClick={() => setIdDeleteContract(row.original.id)}
                        />
                    </>
                );
            }
        }
    ]);

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={contracts}
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            table
                            rowsPerPageSelection
                            rowsPerPageOptions={[10, 25, 50]}
                        />
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
                <EditContract id={idContract} show={showEditForm} onClose={() => {
                    setIdContract(null);
                    setShowEditForm(false)
                }}/>
            </AdvanceTableWrapper>
            {idDeleteContract && <DeleteContractModal id={idDeleteContract} onClose={() => {
                setIdDeleteContract(null)
            }}/>}
        </Fragment>
    );
}

export default ContractDataTable;
