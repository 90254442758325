import {
    PermissionPeriodReportView,
    PermissionWMSOperationItemsReportView,
    PermissionWMSPurchaseView,
    PermissionWMSRefundView,
    PermissionWMSReturnView,
    PermissionWMSRevisionView,
    PermissionWMSSaleView,
    PermissionWMSTransferView,
    PermissionWMSWriteOffView,
    WarehouseOperationPermissions
} from "./WarehouseOperationPermission";
import {
    CrmPermissions,
    PermissionCRMContractorLabelView,
    PermissionCRMContractorOperationView,
    PermissionCRMContractorPaymentView,
    PermissionCRMContractorPayoutView,
    PermissionCRMContractorView,
    PermissionCRMTelegramOrderView
} from "./CrmPermission";
import {PermissionItemView, WarehouseItemPermissions} from "./WarehouseItemPermission";
import {
    EDIPermissions,
    PermissionContractFormationView,
    PermissionEdiAttorneyView,
    PermissionEdiInvoiceView,
    PermissionEdiOrderView,
    PermissionEdiReturnInvoiceView,
    PermissionEdiReturnOrderView,
    PermissionEdiReturnShipmentView,
    PermissionEdiShipmentView
} from "./EdiPermission";
import {
    PermissionRoamingActView,
    PermissionRoamingContractView,
    PermissionRoamingEmpowermentView,
    PermissionRoamingInvoiceView,
    PermissionRoamingShipmentView,
    PermissionRoamingVerificationActView,
    RoamingPermissions
} from "./RoamingPermission";
import {AnotherPermissions, PermissionBranchView} from "./RolePermission";
import {
    CashBoxPermissions,
    PermissionCashBoxCashInView,
    PermissionCashBoxCashOutView,
    PermissionCashBoxExpenseView,
    PermissionCashBoxOperationView,
    PermissionCashBoxTransferView,
    PermissionCashBoxView
} from "./CashBoxPermission";

const checkPermission = (permission) => (_, getState) => {

    const accountPermissions = getState().role?.permissions;

    for (let i = 0; i <= accountPermissions?.length; i++) {
        if (accountPermissions[i] === permission) {
            return true
        }
    }
    return false
}
export default checkPermission

export const checkViewPermissions = (permission, hasPermission) => {
    switch (permission) {
        case PermissionEdiOrderView:
            return hasPermission(PermissionEdiOrderView)
        case PermissionEdiShipmentView:
            return hasPermission(PermissionEdiShipmentView)
        case PermissionEdiInvoiceView:
            return hasPermission(PermissionEdiInvoiceView)
        case PermissionEdiReturnOrderView:
            return hasPermission(PermissionEdiReturnOrderView)
        case PermissionEdiReturnShipmentView:
            return hasPermission(PermissionEdiReturnShipmentView)
        case PermissionEdiReturnInvoiceView:
            return hasPermission(PermissionEdiReturnInvoiceView)
        case PermissionEdiAttorneyView:
            return hasPermission(PermissionEdiAttorneyView)
        case PermissionContractFormationView:
            return hasPermission(PermissionContractFormationView)
        case PermissionRoamingInvoiceView:
            return hasPermission(PermissionRoamingInvoiceView)
        case PermissionRoamingContractView:
            return hasPermission(PermissionRoamingContractView)
        case PermissionRoamingActView:
            return hasPermission(PermissionRoamingActView)
        case PermissionRoamingVerificationActView:
            return hasPermission(PermissionRoamingVerificationActView)
        case PermissionRoamingEmpowermentView:
            return hasPermission(PermissionRoamingEmpowermentView)
        case PermissionRoamingShipmentView:
            return hasPermission(PermissionRoamingShipmentView)
        case PermissionWMSSaleView:
            return hasPermission(PermissionWMSSaleView)
        case PermissionWMSPurchaseView:
            return hasPermission(PermissionWMSPurchaseView)
        case PermissionWMSRefundView:
            return hasPermission(PermissionWMSRefundView)
        case PermissionWMSReturnView:
            return hasPermission(PermissionWMSReturnView)
        case PermissionWMSRevisionView:
            return hasPermission(PermissionWMSRevisionView)
        case PermissionWMSWriteOffView:
            return hasPermission(PermissionWMSWriteOffView)
        case PermissionWMSTransferView:
            return hasPermission(PermissionWMSTransferView)
        case PermissionCRMContractorView:
            return hasPermission(PermissionCRMContractorView)
        case PermissionCRMContractorPaymentView:
            return hasPermission(PermissionCRMContractorPaymentView)
        case PermissionCRMContractorPayoutView:
            return hasPermission(PermissionCRMContractorPayoutView)
        case PermissionCRMContractorOperationView:
            return hasPermission(PermissionCRMContractorOperationView)
        /*case PermissionContractorContractView:
            return hasPermission(PermissionContractorContractView)*/
        case PermissionCRMTelegramOrderView:
            return hasPermission(PermissionCRMTelegramOrderView)
        case PermissionCRMContractorLabelView:
            return hasPermission(PermissionCRMContractorLabelView)
        case PermissionItemView:
            return hasPermission(PermissionItemView)
        case PermissionBranchView:
            return hasPermission(PermissionBranchView)
        case PermissionCashBoxView:
            return hasPermission(PermissionCashBoxView)
        case PermissionCashBoxCashInView:
            return hasPermission(PermissionCashBoxCashInView)
        case PermissionCashBoxCashOutView:
            return hasPermission(PermissionCashBoxCashOutView)
        case PermissionCashBoxExpenseView:
            return hasPermission(PermissionCashBoxExpenseView)
        case PermissionCashBoxTransferView:
            return hasPermission(PermissionCashBoxTransferView)
        case PermissionCashBoxOperationView:
            return hasPermission(PermissionCashBoxOperationView)
        case PermissionPeriodReportView:
            return hasPermission(PermissionPeriodReportView)
        case PermissionWMSOperationItemsReportView:
            return hasPermission(PermissionWMSOperationItemsReportView)
        default:
            return false
    }
}

export const defaultPermissions = {
    edi: EDIPermissions,
    roaming: RoamingPermissions,
    warehouse_items: WarehouseItemPermissions,
    warehouse_operations: WarehouseOperationPermissions,
    crm: CrmPermissions,
    cash_box: CashBoxPermissions,
    another: AnotherPermissions
};
