import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, FormProvider, useForm} from "react-hook-form";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActReject} from "../../../../../enum/Permission/RoamingPermission";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_ACT_BULK_DECLINE_REQUESTED,
    ROAMING_ACT_BULK_DECLINE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import {ActCustomerDeclineHashCodeAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import ResultCounter from "../../../item/forms/bulk-item/ResultCounter";
import IconButton from "../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../View/TypeView";
import roamingService from "../../../../../services/roaming/roamingService";

const DeclineAllActs = ({viewer, count, filters, activeCertificate}) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            comment: ''
        }
    });
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const permissionOK = dispatch(checkPermission(PermissionRoamingActReject));
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const declineAllSendActs = async (formData) => {
        let acts = [];

        try {
            if (!window.confirm(t(lang, 'roaming.invoice.send_button_all_confirmation_text')))
                return;

            setLoading(true);
            ref.current.updateLoadedCount(count);
            EventBus.dispatch(ROAMING_ACT_BULK_DECLINE_REQUESTED, acts);

            const taskSize = 10;
            const tasks = []

            // load all acts partly
            {
                let page = 0;
                let limit = 1000;
                let filterParams = {};
                let paginationParams = {};

                // build filters
                {
                    if (viewer !== VIEW_DRAFT && filters.status !== null)
                        filterParams["status"] = filters.status;
                }

                // load acts
                {
                    while (true) {
                        if (acts.length === count)
                            break;

                        // build pagination
                        {
                            paginationParams["limit"] = limit;
                            paginationParams["skip"] = page * limit;
                        }

                        switch (viewer) {
                            case VIEW_INBOX:
                                const getCustomerActsPromise = roamingService.getCustomerAct({...filterParams, ...paginationParams});
                                const {data: customerActs} = await getCustomerActsPromise;
                                acts = [...acts, ...customerActs];
                                break;
                            case VIEW_OUTBOX:
                                const getExecutorActsPromise = roamingService.getExecutorAct({...filterParams, ...paginationParams});
                                const {data: executorActs} = await getExecutorActsPromise;
                                acts = [...acts, ...executorActs];
                                break;
                            case VIEW_DRAFT:
                                const getExecutorDraftActsPromise = roamingService.getExecutorActDraft({...filterParams, ...paginationParams});
                                const {data: executorDraftActs} = await getExecutorDraftActsPromise;
                                acts = [...acts, ...executorDraftActs];
                                break;
                            default:
                                break
                        }
                        page++
                    }
                }
            }

            // sign
            {
                const signer = async (act) => {
                    try {
                        await ActCustomerDeclineHashCodeAsync(activeCertificate, lang, act.id, formData.comment);
                        ref.current.incrementSucceedCount();
                    } catch (error) {
                        ref.current.incrementFailedCount();
                    }
                };

                for (let i = 0; i < acts.length; i++) {
                    tasks.push(signer(acts[i]))

                    if (tasks.length >= taskSize) {
                        await Promise.all(tasks)
                        tasks.splice(0, tasks.length)
                    }
                }

                if (tasks.length > 0) {
                    await Promise.all(tasks)
                    tasks.splice(0, tasks.length)
                }
            }

            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_BULK_DECLINE_SUCCESS, acts);
        } catch (error) {
            setLoading(false);
            EventBus.dispatch(ROAMING_ACT_BULK_DECLINE_SUCCESS, acts);
        }
    };

    return (
        <React.Fragment>
            <ResultCounter ref={ref}/>
            <IconButton icon={faSignature}
                        variant="falcon-danger"
                        size="sm"
                        disabled={!permissionOK || loading}
                        onClick={handleShow}
            >
                {loading && <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/>}
                {t(lang, "edi.common.decline_all")}
            </IconButton>
            {show &&
                <Modal size="md"
                       show={true}
                       onHide={handleClose}
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>{t(lang, "edi.common.decline_all")}</Modal.Title>
                                <FalconCloseButton onClick={handleClose}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name="comment"
                                            rules={{
                                                required: t(lang, "edi.common.forms.validations.is_required")
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Group>
                                                    <Form.Label>
                                                        {t(lang, "edi.common.modal.reason")}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control name="comment"
                                                                  as="textarea"
                                                                  rows={3}
                                                                  isInvalid={!!error}
                                                                  placeholder={t(lang, "edi.common.modal.reason.placeholder")}
                                                                  value={field.value}
                                                                  onChange={field.onChange}
                                                    />
                                                    {!!error && <Form.Control.Feedback
                                                        type="invalid">{error?.message}</Form.Control.Feedback>}
                                                </Form.Group>
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                        size="sm"
                                        onClick={handleClose}
                                >
                                    {t(lang, "edi.common.button.close")}
                                </Button>
                                <SelectCertificateWrapperComponent
                                    permission={PermissionRoamingActReject}
                                    variant="falcon-danger"
                                    openDialogButtonText={t(lang, "common.button.cancel_all")}
                                    submitButton={
                                        <IconButton icon={faSignature}
                                                    variant="falcon-danger"
                                                    id="Decline"
                                                    size="sm"
                                                    disabled={loading}
                                                    onClick={form.handleSubmit(declineAllSendActs)}
                                        >
                                            {loading &&
                                                <Spinner size="sm" className='align-middle me-2' animation='border'
                                                         role='switch'/>}
                                            {t(lang, "common.button.cancel_all")}
                                        </IconButton>
                                    }
                                />
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </React.Fragment>
    );
};

export default DeclineAllActs;