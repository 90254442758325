import dayjs from "dayjs";
import classNames from "classnames";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import React, {Fragment, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../../helpers/excel-uploader";
import {uploadSalePreparatoryDataAsync} from "../../../../../app/store/reducers/excel/excelReducer";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const DownloadSalePreparatoryDataToExcel = () => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const currentDate = new Date()
    const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        defaultValues: {
            dateStart: dateTimeStartFormat,
            dateEnd: dateTimeEndFormat,
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = (formData) => {
        const dateStart = dayjs(formData.dateStart).format("YYYY-MM-DD HH:mm:ss");
        const dateEnd = dayjs(formData.dateEnd).format("YYYY-MM-DD HH:mm:ss");

        setLoading(true);
        uploadSalePreparatoryDataAsync({dateTimeStart: dateStart, dateTimeEnd: dateEnd})
            .then(fileContent => {
                excelFileUploader({
                    content: fileContent,
                    fileName: t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.file.title', {date_start: dateStart, date_end: dateEnd}),
                });
                handleClose();
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };

    return (
        <Fragment>
            <IconButton icon={faFileExcel} onClick={handleShow} size={'sm'} variant={'falcon-success'} className={'me-2'}>
                {t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.button.title')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.field.date_start')}</Form.Label>
                            <Controller
                                control={control}
                                name={'dateStart'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field, fieldState}) => (
                                    <DatePicker
                                        timeFormat={'p'}
                                        isClearable={true}
                                        timeIntervals={60}
                                        onChange={field.onChange}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        selected={field.value ? new Date(field.value) : null}
                                        wrapperClassName={classNames({'is-invalid': !!fieldState.error})}
                                        className={classNames('form-control', {'is-invalid': !!fieldState.error})}
                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.dateStart?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.field.date_end')}</Form.Label>
                            <Controller
                                control={control}
                                name={'dateEnd'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field, fieldState}) => (
                                    <DatePicker
                                        timeFormat={'p'}
                                        isClearable={true}
                                        timeIntervals={60}
                                        onChange={field.onChange}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        selected={field.value ? new Date(field.value) : null}
                                        wrapperClassName={classNames({'is-invalid': !!fieldState.error})}
                                        className={classNames('form-control', {'is-invalid': !!fieldState.error})}
                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.dateEnd?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.button.back')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={loading}>
                            {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                            {t(lang, 'warehouse_operation.download_sale_preparatory_data_modal.button.download')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default DownloadSalePreparatoryDataToExcel;