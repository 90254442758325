import React, {useCallback, useMemo, useRef, useState} from 'react';
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {
    selectCategoriesTree,
    selectColumnVisibility,
    selectFilterOptions,
    selectItems,
    selectLoading,
    selectPaginationOptions,
    selectSorting,
    updateColumnVisibility,
    updateFilters,
    updatePagination,
    updateSorting,
} from "../../../../app/store/reducers/item/itemReducer";
import {Badge, Card, Dropdown, Form, OverlayTrigger, Popover} from "react-bootstrap";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import {Link} from "react-router-dom";
import classNames from "classnames";
import CatalogStatusBadge from "../components/CatalogStatusBadge";
import SoftBadge from "../../../common/SoftBadge";
import numeral from "numeral";
import CardDropdown from "../../../common/CardDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddRevisionModal from "../revision/AddRevisionModal";
import PrintItemDropdownItem from "../components/PrintItemDropdownItem";
import ValidateCatalogDropdownItem from "../components/ValidateCatalogDropdownItem";
import DeleteItemDropdownItem from "../components/DeleteItemDropdownItem";
import dayjs from "dayjs";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import ItemDataTableHeader from "./ItemDataTableHeader";
import {debounce} from "underscore";
import {getNumberFixedValue, getNumberValue} from "../../../../helpers/utils";
import {transliterate} from "transliteration";
import itemWrapper, {ALERT_ON_STATE_1, ALERT_ON_STATE_2, ALERT_ON_STATE_3, ALERT_ON_STATE_4} from "../enum/itemWrapper";
import naturalCompare from "natural-compare";
import DataTableColumnSettings from "../../../common/table/DataTableColumnSettings";
import {selectBranches} from "../../../../app/store/reducers/branch/branchReducer";
import Flex from "../../../common/Flex";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionWMSRevisionCreate} from "../../../../enum/Permission/WarehouseOperationPermission";
import {
    PermissionItemCreate,
    PermissionItemDelete,
    PermissionItemUpdate,
    PermissionItemView
} from "../../../../enum/Permission/WarehouseItemPermission";
import Error403 from "../../../errors/Error403";

const roundingValue = 2;
const ItemDataTable = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const items = useSelector(selectItems);
    const warehouses = useSelector(selectWarehouses);
    const currencies = useSelector(selectCurrency);
    const branches = useSelector(selectBranches);
    const categoriesTree = useSelector(selectCategoriesTree);
    const pagination = useSelector(selectPaginationOptions, shallowEqual);
    const filter = useSelector(selectFilterOptions);
    const sorting = useSelector(selectSorting);
    const columnVisibility = useSelector(selectColumnVisibility);
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const loading = useSelector(selectLoading);
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);

    // permissions
    const isRevisionCreate = useCheckPermission(PermissionWMSRevisionCreate)
    const isItemView = useCheckPermission(PermissionItemView)
    const isItemCreate = useCheckPermission(PermissionItemCreate)
    const isItemEdit = useCheckPermission(PermissionItemUpdate)
    const isItemDelete = useCheckPermission(PermissionItemDelete)

    const onFilterChange = (filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
    }, 500), [pagination.pageSize]);
    const onSortingChange = (sorting) => {
        dispatch(updateSorting(sorting));
    };
    const onColumnVisibilityChange = (updater) => {
        const nextState = updater(columnVisibility);
        dispatch(updateColumnVisibility(nextState));
    };
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        dispatch(updatePagination(nextState))
    };

    const columns = useMemo(() => {
        const dataTableColumns = [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i].id] = true;
                        }

                        setRowSelection(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td></td>
                    )
                },
            },
            {
                id: 'name',
                accessorKey: t(lang, "edi.common.product.name"),
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1">
                            {t(lang, "items.common.name")}
                        </th>
                    )
                },
                cell: ({row: {original: {item}}}) => {
                    return (
                        <td className="p-1 align-middle">
                            <Link to={`items/${item.id}`}
                                  className={classNames('fs--1 fw-semi-bold font-sans-serif text-dark')}>{item.name}</Link>
                            {(item.tax?.catalog?.code || item.category || item.code || item.sku || item.barcodes?.length > 0) &&
                                <br/>}
                            {item.tax?.catalog?.code &&
                                <CatalogStatusBadge catalogCode={item.tax.catalog.code} tasnif_info={item.tasnif_info}
                                                    badgeProps={{
                                                        className: 'me-1'
                                                    }}/>}
                            {item.category &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'info'}>{t(lang, 'items.common.category')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='info' className='me-1'>{item.category.name}</SoftBadge>
                                </OverlayTrigger>
                            }
                            {item.barcodes?.length > 0 && item.barcodes.map((barcode, index) =>
                                <OverlayTrigger key={index}
                                                trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'secondary'}>{t(lang, 'items.common.barcode')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='secondary' className='me-1'>{barcode}</SoftBadge>
                                </OverlayTrigger>
                            )}

                            {item.code &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'warning'}>{t(lang, 'items.common.code')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='warning' className='me-1'>{item.code}</SoftBadge>
                                </OverlayTrigger>
                            }

                            {item.sku &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'dark'}>{t(lang, 'items.common.sku')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge bg='dark' className='me-1'>{item.sku}</SoftBadge>
                                </OverlayTrigger>
                            }
                            {item.package_measurements?.length > 0 && item.package_measurements.map((packageMeasurement, index) => (
                                <OverlayTrigger
                                    key={index}
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={
                                        <Popover id="popover-basic" className='ms-0'>
                                            <Popover.Body className={'py-2 px-3'}>
                                                <h6 className={'mb-0'}>
                                                    <span
                                                        className='me-2'>{t(lang, 'items.common.package_measurement.quantity')}:</span>
                                                    <SoftBadge bg={'primary'}>{packageMeasurement.quantity}</SoftBadge>
                                                </h6>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <SoftBadge bg={'primary'} className={'me-1'}>{packageMeasurement.name}</SoftBadge>
                                </OverlayTrigger>
                            ))}
                        </td>
                    )
                },
                footer: ({table}) => {
                    return (
                        <td></td>
                    )
                }
            },
            {
                id: 'purchasePrice',
                accessorKey: t(lang, "items.common.purchase_price"),
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'items.common.purchase_price')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                    let warehouseItems = warehouse_items;
                    if (filter.warehouseId)
                        warehouseItems = warehouseItems.filter(w => w.id === filter.warehouseId);
                    const hasPurchasePrice = warehouseItems.some(({purchase_price}) => purchase_price !== null && purchase_price.price !== null && purchase_price.price.amount > 0);

                    if (!hasPurchasePrice) {
                        return (
                            <td className="p-1 align-middle text-center text-dark"></td>
                        )
                    }

                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <div>
                                {warehouseItems.length && warehouseItems.map((warehouseItem, index) => {
                                    const color = colors[index % colors.length]
                                    return <div className={"fs-0"} key={warehouseItem?.id}>
                                        {warehouseItem?.purchase_price && <OverlayTrigger trigger={['hover', 'focus']}
                                                                                          placement={'bottom'}
                                                                                          overlay={
                                                                                              <Popover
                                                                                                  id="popover-basic"
                                                                                                  className='mt-0'>
                                                                                                  <Popover.Header>
                                                                                                      <SoftBadge
                                                                                                          bg={color}>
                                                                                                          {warehouseItem?.name}
                                                                                                      </SoftBadge>
                                                                                                  </Popover.Header>
                                                                                              </Popover>
                                                                                          }
                                        >
                                            <SoftBadge className={'d-block text-end my-1'} bg={color}>
                                                {warehouseItem?.purchase_price?.price?.amount} {warehouseItem?.purchase_price?.price?.currency?.name}
                                            </SoftBadge>
                                        </OverlayTrigger>}
                                    </div>
                                })}
                            </div>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const purchasePrice = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
                        let warehouseItems = warehouse_items;
                        if (filter.warehouseId)
                            warehouseItems = warehouseItems.filter(w => w.id === filter.warehouseId);
                        warehouseItems.forEach(item => {
                            if (item.state > 0) {
                                if (item.purchase_price) {
                                    const {amount, currency: {name}} = item.purchase_price.price;
                                    if (!acc[item.id]) acc[item.id] = {};
                                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                                    acc[item.id][name] += amount * item.state;
                                }
                            } else {
                                if (item.purchase_price) {
                                    const {currency: {name}} = item.purchase_price.price;
                                    if (!acc[item.id]) acc[item.id] = {};
                                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                                    acc[item.id][name] += 0;
                                }
                            }
                        });
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center">
                            {Object.keys(purchasePrice).map((warehouseId, index) => {
                                const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));
                                const color = colors[index % colors.length];

                                if (!warehouse) return null;

                                return (
                                    <OverlayTrigger key={warehouseId}
                                                    placement={'bottom'}
                                                    trigger={['hover', 'focus']}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <SoftBadge className={'d-block text-center fs--1'}
                                                                           bg={color}>
                                                                    {warehouse.name}
                                                                </SoftBadge>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <SoftBadge className={'d-block text-end my-1'} bg={color}>
                                            {Object.entries(purchasePrice[warehouseId]).map(([currency, amount]) => (
                                                <div key={currency}>
                                                    {`${numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(amount, roundingValue)))} ${currency}`}
                                                </div>
                                            ))}
                                        </SoftBadge>
                                    </OverlayTrigger>
                                )
                            })}
                        </td>
                    )
                }
            },
            {
                id: 'salePrice',
                accessorKey: t(lang, "items.common.price"),
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, "items.common.price")}
                        </th>
                    )
                },
                cell: ({row: {original: {price}}}) => {
                    return (
                        <td className="p-1 align-middle text-center">
                            <div className={'fs-0'}>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'primary'}>{t(lang, 'items.common.price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block text-end'} bg={'primary'}>
                                        {`${numeral.formats[numberFormat].format(price.common_price.amount)} ${price.common_price.currency.name}`}
                                    </SoftBadge>
                                </OverlayTrigger>
                                {price.bulk_price.amount > 0 &&
                                    <OverlayTrigger trigger={['hover', 'focus']}
                                                    placement={'bottom'}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <SoftBadge
                                                                    bg={'secondary'}>{t(lang, 'items.common.bulk_price')}</SoftBadge>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <SoftBadge className={'d-block d-block text-end mt-1'} bg={'secondary'}>
                                            {`${numeral.formats[numberFormat].format(price.bulk_price.amount)} ${price.bulk_price.currency.name}`}
                                        </SoftBadge>
                                    </OverlayTrigger>
                                }
                            </div>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const commonPrice = rows.reduce((acc, {original}) => {
                        let warehouseItems = original.warehouse_states.warehouse_items;
                        if (filter.warehouseId)
                            warehouseItems = warehouseItems.filter(w => w.id === filter.warehouseId);
                        const itemTotalWarehouseState = warehouseItems.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
                        const priceAmount = itemTotalWarehouseState > 0 ? original.price.common_price.amount * itemTotalWarehouseState : 0;
                        acc[original.price.common_price.currency.name] = (acc[original.price.common_price.currency.name] || 0) + priceAmount;
                        return acc;
                    }, {});
                    const bulkPrice = rows.reduce((acc, {original}) => {
                        let warehouseItems = original.warehouse_states.warehouse_items;
                        if (filter.warehouseId)
                            warehouseItems = warehouseItems.filter(w => w.id === filter.warehouseId);
                        const itemTotalWarehouseState = warehouseItems.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
                        const priceAmount = itemTotalWarehouseState > 0 ? original.price.bulk_price.amount * itemTotalWarehouseState : 0;
                        acc[original.price.bulk_price.currency.name] = (acc[original.price.bulk_price.currency.name] || 0) + priceAmount;
                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-center">
                            <div className={'fs-0'}>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'primary'}>{t(lang, 'items.common.price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block text-end'} bg={'primary'}>
                                        {Object.keys(commonPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(commonPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'secondary'}>{t(lang, 'items.common.bulk_price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block d-block text-end mt-1'} bg={'secondary'}>
                                        {Object.keys(bulkPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(bulkPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                            </div>
                        </td>
                    )
                }
            },
            {
                id: 'totalWarehouseState',
                accessorKey: t(lang, "items.common.total_item_warehouse_state_amount"),
                header: ({column}) => {
                    return (
                        <th className="p-1 text-center align-middle text-dark fs--1">
                            {t(lang, 'items.common.total_item_warehouse_state_amount')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    if (filter.warehouseId) {
                        const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                        const warehouse = warehouses.find(w => w.id === filter.warehouseId);
                        const warehouseItem = warehouse_items.find(warehouseItem => warehouseItem.id === filter.warehouseId);
                        const color = colors[0 % colors.length];

                        if (!warehouse || !warehouseItem) return null;

                        return (
                            <td className="p-1 text-center align-middle text-dark fw-bolder">
                                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Header className="py-1">
                                            <div className="d-flex flex-column">
                                                <SoftBadge key={warehouse.id}
                                                           bg={color}
                                                           className='mb-1 flex-fill'
                                                >
                                                    <span className="me-1">{warehouse.name}:</span>
                                                    <span className={classNames({
                                                        "text-danger": warehouseItem.state < 0,
                                                        "text-secondary": warehouseItem.state === 0,
                                                        "text-primary": warehouseItem.state > 0
                                                    })}>
                                                                {warehouseItem.state}
                                                            </span>
                                                </SoftBadge>
                                            </div>
                                        </Popover.Header>
                                    </Popover>
                                }>
                                    <div className="fw-bolder text-dark">
                                        {warehouseItem.state}
                                    </div>
                                </OverlayTrigger>
                            </td>
                        )
                    }

                    return (
                        <td className="p-1 text-center align-middle text-dark fw-bolder">
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header className="py-1">
                                        <div className="d-flex flex-column">
                                            {warehouse_items.map((warehouseItem, index) => {
                                                const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                const color = colors[index % colors.length];
                                                const warehouse = warehouses.find(w => w.id === warehouseItem.id);

                                                if (!warehouse) return null;

                                                return (
                                                    <SoftBadge key={warehouseItem.id}
                                                               bg={color}
                                                               className='mb-1 flex-fill'
                                                    >
                                                        <span className="me-1">{warehouse.name}:</span>
                                                        <span className={classNames({
                                                            "text-danger": warehouseItem.state < 0,
                                                            "text-secondary": warehouseItem.state === 0,
                                                            "text-primary": warehouseItem.state > 0
                                                        })}>
                                                                {warehouseItem.state}
                                                            </span>
                                                    </SoftBadge>
                                                )
                                            })}
                                        </div>
                                    </Popover.Header>
                                </Popover>
                            }>
                                <div className="fw-bolder text-dark">
                                    {warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0)}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const warehouseStates = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
                        warehouse_items.forEach((warehouseItem) => {
                            if (filter.warehouseId) {
                                if (warehouseItem.id === filter.warehouseId) {
                                    if (acc[warehouseItem.id])
                                        acc[warehouseItem.id] = acc[warehouseItem.id] + (warehouseItem.state > 0 ? warehouseItem.state : 0)
                                    else
                                        acc[warehouseItem.id] = warehouseItem.state > 0 ? warehouseItem.state : 0
                                }
                            } else {
                                if (acc[warehouseItem.id])
                                    acc[warehouseItem.id] = acc[warehouseItem.id] + (warehouseItem.state > 0 ? warehouseItem.state : 0)
                                else
                                    acc[warehouseItem.id] = warehouseItem.state > 0 ? warehouseItem.state : 0
                            }
                        })
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <OverlayTrigger
                                placement={'bottom'}
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Popover id="popover-basic" className='ms-0'>
                                        <Popover.Header className="py-1">
                                            <div className="d-flex flex-column">
                                                {Object.keys(warehouseStates).map((warehouseId, index) => {
                                                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                    const color = colors[index % colors.length];
                                                    const warehouse = warehouses.find(w => w.id === parseInt(warehouseId));

                                                    if (!warehouse) return null;

                                                    return (
                                                        <SoftBadge key={warehouse.id}
                                                                   bg={color}
                                                                   className='mb-1 flex-fill'
                                                        >
                                                            <span className="me-1">{warehouse.name}:</span>
                                                            <span className={classNames({
                                                                "text-danger": warehouseStates[warehouseId] < 0,
                                                                "text-secondary": warehouseStates[warehouseId] === 0,
                                                                "text-primary": warehouseStates[warehouseId] > 0
                                                            })}>
                                                                {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(warehouseStates[warehouseId], roundingValue)))}
                                                            </span>
                                                        </SoftBadge>
                                                    )
                                                })}
                                            </div>
                                        </Popover.Header>
                                    </Popover>
                                }
                            >
                                <div className="fw-bolder text-dark">
                                    {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(Object.values(warehouseStates).reduce((acc, value) => acc + value, 0), roundingValue)))}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                }
            },
            {
                id: 'totalBranchState',
                accessorKey: t(lang, "items.common.total_item_branch_state_amount"),
                header: ({column}) => {
                    return (
                        <th className="p-1 text-center align-middle text-dark fs--1">
                            {t(lang, 'items.common.total_item_branch_state_amount')}
                        </th>
                    )
                },
                cell: ({row: {original: {branch_states}}}) => {
                    if (filter.branchId) {
                        const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                        const color = colors[0 % colors.length];
                        const branch = branches.find(b => b.id === filter.branchId);
                        const branchItem = branch_states.find(b => b.id === filter.branchId);

                        if (!branch || !branchItem) return null;

                        return (
                            <td className="p-1 text-center align-middle text-dark fw-bolder">
                                <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Header className="py-1">
                                            <div className="d-flex flex-column">
                                                <SoftBadge key={branchItem.id}
                                                           bg={color}
                                                           className='mb-1 flex-fill'
                                                >
                                                    <span className="me-1">{branchItem.name}:</span>
                                                    <span className={classNames({
                                                        "text-danger": branchItem.state < 0,
                                                        "text-secondary": branchItem.state === 0,
                                                        "text-primary": branchItem.state > 0
                                                    })}>
                                                                {branchItem.state}
                                                            </span>
                                                </SoftBadge>
                                            </div>
                                        </Popover.Header>
                                    </Popover>
                                }>
                                    <div className="fw-bolder text-dark">
                                        {branchItem.state}
                                    </div>
                                </OverlayTrigger>
                            </td>
                        )
                    }

                    return (
                        <td className="p-1 text-center align-middle text-dark fw-bolder">
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header className="py-1">
                                        <div className="d-flex flex-column">
                                            {branch_states.map((branchItem, index) => {
                                                const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                const color = colors[index % colors.length];
                                                return (
                                                    <SoftBadge key={branchItem.id}
                                                               bg={color}
                                                               className='mb-1 flex-fill'
                                                    >
                                                        <span className="me-1">{branchItem.name}:</span>
                                                        <span className={classNames({
                                                            "text-danger": branchItem.state < 0,
                                                            "text-secondary": branchItem.state === 0,
                                                            "text-primary": branchItem.state > 0
                                                        })}>
                                                                {branchItem.state}
                                                            </span>
                                                    </SoftBadge>
                                                )
                                            })}
                                        </div>
                                    </Popover.Header>
                                </Popover>
                            }>
                                <div className="fw-bolder text-dark">
                                    {branch_states.reduce((sum, branchItem) => branchItem.state + sum, 0)}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const branchStates = rows.reduce((acc, {original: {branch_states}}) => {
                        branch_states.forEach((branchItem) => {
                            if (filter.branchId) {
                                if (branchItem.id === filter.branchId) {
                                    if (acc[branchItem.id])
                                        acc[branchItem.id] = acc[branchItem.id] + (branchItem.state > 0 ? branchItem.state : 0);
                                    else
                                        acc[branchItem.id] = branchItem.state > 0 ? branchItem.state : 0;
                                }
                            } else {
                                if (acc[branchItem.id])
                                    acc[branchItem.id] = acc[branchItem.id] + (branchItem.state > 0 ? branchItem.state : 0);
                                else
                                    acc[branchItem.id] = branchItem.state > 0 ? branchItem.state : 0;
                            }
                        });
                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <OverlayTrigger
                                placement={'bottom'}
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Popover id="popover-basic" className='ms-0'>
                                        <Popover.Header className="py-1">
                                            <div className="d-flex flex-column">
                                                {Object.keys(branchStates).map((branchId, index) => {
                                                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                                    const color = colors[index % colors.length];
                                                    const branch = branches.find(b => b.id === branchId);

                                                    if (!branch) return null;

                                                    return (
                                                        <SoftBadge key={branch.id}
                                                                   bg={color}
                                                                   className='mb-1 flex-fill'
                                                        >
                                                            <span className="me-1">{branch.name}:</span>
                                                            <span className={classNames({
                                                                "text-danger": branchStates[branchId] < 0,
                                                                "text-secondary": branchStates[branchId] === 0,
                                                                "text-primary": branchStates[branchId] > 0
                                                            })}>
                                                                {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(branchStates[branchId], roundingValue)))}
                                                            </span>
                                                        </SoftBadge>
                                                    )
                                                })}
                                            </div>
                                        </Popover.Header>
                                    </Popover>
                                }
                            >
                                <div className="fw-bolder text-dark">
                                    {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(Object.values(branchStates).reduce((acc, value) => acc + value, 0), roundingValue)))}
                                </div>
                            </OverlayTrigger>
                        </td>
                    )
                }
            },
            {
                id: 'createdAt',
                accessorKey: t(lang, "items.common.category.created_at"),
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'items.common.title.created_at')}
                        </th>
                    )
                },
                cell: ({row: {original: {item}}}) => {
                    return (
                        <td className="p-1 align-middle text-center fs--1">
                            {dayjs(item.created_at).format(`${dateFormat} HH:mm:ss`)}
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'action',
                accessorKey: t(lang, "notification.table.actions"),
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            <DataTableColumnSettings table={table}/>
                        </th>
                    )
                },
                cell: ({row: {original: {item, price}}}) => {
                    return (
                        <td className="p-1 align-middle text-center">
                            <CardDropdown btnRevealClass="btn-reveal-sm">
                                <div className="py-2">
                                    <PrintItemDropdownItem class={'border-bottom'} item={item} price={price}/>
                                    {isItemEdit && <Dropdown.Item as={Link} to={`/warehouse/items/edit/${item.id}`}
                                                    className="border-bottom align-center text-warning">
                                        <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                        <span>{t(lang, 'items.common.edit')}</span>
                                    </Dropdown.Item>}
                                    {isItemCreate && <Dropdown.Item as={Link} to={`/warehouse/items/add?item_id=${item.id}`}
                                                    className="text-primary border-bottom align-center">
                                        <FontAwesomeIcon className="me-1" size="sm" icon={faClone}/>
                                        <span>{t(lang, 'roaming.invoice.copy')}</span>
                                    </Dropdown.Item>}
                                    {isRevisionCreate && <Dropdown.Item className="border-bottom align-center">
                                        <AddRevisionModal itemDisabled={true} item={item}/>
                                    </Dropdown.Item>}
                                    <ValidateCatalogDropdownItem id={item.id}/>
                                    {isItemDelete && <DeleteItemDropdownItem item={item}/>}
                                </div>
                            </CardDropdown>
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
        ];

        if (!filter.warehouseId && items.every(i => i.warehouse_states.warehouse_items.length === 2)) {
            dataTableColumns.splice(6, 0, {
                id: 'warehouseStates',
                accessorKey: t(lang, 'items.common.warehouses_state'),
                header: () => {
                    return (
                        <th className="p-1 text-center align-middle text-dark fs--1">
                            {t(lang, 'items.common.warehouses_state')}
                        </th>
                    )
                },
                cell: ({row: {original: {warehouse_states: {warehouse_items}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <Flex className={'fs-0'}>
                                {warehouse_items.map((warehouseItem, index) => {
                                    const colors = ['primary', 'info', 'success', 'dark'];
                                    const color = colors[index % colors.length];
                                    const warehouse = warehouses.find(w => w.id === warehouseItem.id);

                                    if (!warehouse) return null;

                                    if (warehouseItem.alert_on > warehouseItem.state) {
                                        return (
                                            <Badge key={warehouseItem.id}
                                                   bg="dark"
                                                   className='mb-1 flex-fill me-1'
                                            >
                                                {warehouseItem.state}
                                                <br/>
                                                {warehouse.name}
                                            </Badge>
                                        )
                                    }

                                    return (
                                        <SoftBadge key={warehouseItem.id}
                                                   bg={color}
                                                   className='mb-1 flex-fill me-1'
                                        >
                                            {warehouseItem.state}
                                            <br/>
                                            {warehouse.name}
                                        </SoftBadge>
                                    )
                                })}
                            </Flex>
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">

                        </td>
                    )
                }
            },)
        }

        return dataTableColumns;
    }, [lang, lastRowSelected, filter, pagination, items, warehouses, branches]);
    const data = useMemo(() => {
        const filteredItems = items.filter((item) => {

            // search by filter.hasCategory
            {
                if (filter.hasCategory === true && !item.item.category)
                    return false;

                if (filter.hasCategory === false && item.item.category)
                    return false;
            }

            // search by filter.hasSku
            {
                if (filter.hasSku === true && !item.item.sku)
                    return false;

                if (filter.hasSku === false && item.item.sku)
                    return false;
            }

            // search by filter.hasBarcode
            {
                if (filter.hasBarcode === true && !item.item.barcodes.length)
                    return false;

                if (filter.hasBarcode === false && !!item.item.barcodes.length)
                    return false;
            }

            // search by filter.hasCatalog
            {
                if (filter.hasCatalog === true && !item.item.tax?.catalog?.code)
                    return false;

                if (filter.hasCatalog === false && item.item.tax?.catalog?.code)
                    return false;
            }

            // search by filter.hasCatalogPackage
            {
                if (filter.hasCatalogPackage === true && !item.item.tax?.catalog?.package?.code)
                    return false;

                if (filter.hasCatalogPackage === false && item.item.tax?.catalog?.package?.code)
                    return false;
            }

            // search by filter.warehouseId
            {
                if (filter.warehouseId && !item.warehouse_states.warehouse_items.some(w => w.id === filter.warehouseId))
                    return false;
            }

            // search by filter.branchId
            {
                if (filter.branchId && !item.branch_states.some(b => b.id === filter.branchId))
                    return false;
            }

            // search by filter.categoryId
            {
                if (filter.categoryId && !itemWrapper.filterCategory(categoriesTree, filter.categoryId, item.item.category?.id))
                    return false;
            }

            // search by filter.sku
            {
                if (filter.sku && !item.item.sku?.trim().toLowerCase().includes(filter.sku.trim().toLowerCase()))
                    return false;
            }

            // search by filter.measurement
            {
                if (filter.measurement !== null && parseInt(filter.measurement) !== item.item.measurement)
                    return false;
            }

            // search by filter.purchasePriceMoreThanSalePrice
            {
                if (filter.purchasePriceMoreThanSalePrice === true) {
                    return item.warehouse_states.warehouse_items.some(({purchase_price}) => {
                        if (!purchase_price || !purchase_price.price) return false;

                        const purchasePriceCurrencyRate = currencies.find(c => c.id === purchase_price.price.currency.id)?.rate || 1;
                        const bulkPriceCurrencyRate = currencies.find(c => c.id === item.price.bulk_price.currency.global_currency_id)?.rate || 1;
                        const commonPriceCurrencyRate = currencies.find(c => c.id === item.price.common_price.currency.global_currency_id)?.rate || 1;

                        const purchasePriceMoreThanCommonPrice = item.price.common_price.amount > 0 && purchase_price.price.amount * purchasePriceCurrencyRate > item.price.common_price.amount * commonPriceCurrencyRate;
                        const purchasePriceMoreThanBulkPrice = item.price.bulk_price.amount > 0 && purchase_price.price.amount * purchasePriceCurrencyRate > item.price.bulk_price.amount * bulkPriceCurrencyRate
                        return purchasePriceMoreThanCommonPrice || purchasePriceMoreThanBulkPrice;
                    })
                }

                if (filter.purchasePriceMoreThanSalePrice === false) {
                    return !item.warehouse_states.warehouse_items.some(({purchase_price}) => {
                        if (!purchase_price || !purchase_price.price) return false;

                        const purchasePriceCurrencyRate = currencies.find(c => c.id === purchase_price.price.currency.id)?.rate || 1;
                        const bulkPriceCurrencyRate = currencies.find(c => c.id === item.price.bulk_price.currency.global_currency_id)?.rate || 1;
                        const commonPriceCurrencyRate = currencies.find(c => c.id === item.price.common_price.currency.global_currency_id)?.rate || 1;

                        const purchasePriceMoreThanCommonPrice = item.price.common_price.amount > 0 && purchase_price.price.amount * purchasePriceCurrencyRate > item.price.common_price.amount * commonPriceCurrencyRate;
                        const purchasePriceMoreThanBulkPrice = item.price.bulk_price.amount > 0 && purchase_price.price.amount * purchasePriceCurrencyRate > item.price.bulk_price.amount * bulkPriceCurrencyRate
                        return purchasePriceMoreThanCommonPrice || purchasePriceMoreThanBulkPrice;
                    })
                }
            }

            // search by filter.searchTerm
            {
                if (filter.searchTerm) {
                    let found = false;
                    const searchTermLower = filter.searchTerm.trim().toLowerCase();

                    // search by barcode
                    {
                        if (item.item.barcodes.some((b) => b.toLowerCase().includes(searchTermLower)))
                            found = true;
                    }

                    // search by code
                    {
                        if (item.item.code && item.item.code.trim().toLowerCase().includes(searchTermLower))
                            found = true;
                    }

                    // search by name
                    {
                        const itemNameLower = item.item.name.trim().toLowerCase();
                        const transliteratedQuery = transliterate(searchTermLower);
                        const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                        const transliteratedItemName = transliterate(itemNameLower);
                        if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                            found = true;
                    }

                    if (!found)
                        return false;
                }
            }

            // search by filter.alertOn
            {
                if (filter.warehouseId) {
                    const warehouseItem = item.warehouse_states.warehouse_items.find(w => w.id === filter.warehouseId);
                    switch (filter.alertOn) {
                        case ALERT_ON_STATE_1:
                            return !(warehouseItem.state <= 0);
                        case ALERT_ON_STATE_2:
                            return (warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state) || warehouseItem.state < 0;
                        case ALERT_ON_STATE_3:
                            return !(warehouseItem.state !== 0);
                        case ALERT_ON_STATE_4:
                            return !(warehouseItem.state >= 0)
                        default:
                            break;
                    }
                } else {
                    const itemTotalWarehouseState = item.warehouse_states.warehouse_items.reduce((acc, accItem) => acc + accItem.state, 0);
                    switch (filter.alertOn) {
                        case ALERT_ON_STATE_1:
                            return !(itemTotalWarehouseState <= 0);
                        case ALERT_ON_STATE_2:
                            return itemTotalWarehouseState < 0 || item.warehouse_states.warehouse_items.some((warehouseItem) => warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state);
                        case ALERT_ON_STATE_3:
                            return !(itemTotalWarehouseState !== 0);
                        case ALERT_ON_STATE_4:
                            return !(itemTotalWarehouseState >= 0);
                        default:
                            break;
                    }
                }
            }

            return true;
        });
        if (!sorting.length) return filteredItems;

        return filteredItems.sort((a, b) => {
            for (const {id, desc} of sorting) {
                let comparison = 0;

                // sort by item.item.created_at
                if (id === "createdAt") {
                    comparison = desc ? naturalCompare(b.item.created_at, a.item.created_at) : naturalCompare(a.item.created_at, b.item.created_at);
                }

                // sort by item.warehouse_states.warehouse_items.state summary
                else if (id === "state") {
                    const aState = a.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0);
                    const bState = b.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0);
                    comparison = desc ? naturalCompare(bState, aState) : naturalCompare(aState, bState);
                }

                // sort by item.warehouse_states.warehouse_items.purchase_price.price.amount * currencyRate summary
                else if (id === "purchasePrice") {
                    const aState = a.warehouse_states.warehouse_items.reduce((acc, warehouseItem) => {
                        if (!warehouseItem.purchase_price || !warehouseItem.purchase_price.price) return acc;
                        const purchasePriceCurrencyRate = currencies.find(c => c.id === warehouseItem.purchase_price.price.currency.id)?.rate || 1;
                        return acc + warehouseItem.purchase_price.price.amount * purchasePriceCurrencyRate;
                    }, 0);
                    const bState = b.warehouse_states.warehouse_items.reduce((acc, warehouseItem) => {
                        if (!warehouseItem.purchase_price || !warehouseItem.purchase_price.price) return acc;
                        const purchasePriceCurrencyRate = currencies.find(c => c.id === warehouseItem.purchase_price.price.currency.id)?.rate || 1;
                        return acc + warehouseItem.purchase_price.price.amount * purchasePriceCurrencyRate;
                    }, 0);
                    comparison = desc ? naturalCompare(bState, aState) : naturalCompare(aState, bState);
                }

                // sort by item.price.common_price.amount * currencyRate
                else if (id === "commonPrice") {
                    const aCommonPriceCurrencyRate = currencies.find(c => c.id === a.price.common_price.currency.global_currency_id)?.rate || 1;
                    const bCommonPriceCurrencyRate = currencies.find(c => c.id === b.price.common_price.currency.global_currency_id)?.rate || 1;
                    const aState = a.price.common_price.amount * aCommonPriceCurrencyRate;
                    const bState = b.price.common_price.amount * bCommonPriceCurrencyRate;
                    comparison = desc ? naturalCompare(bState, aState) : naturalCompare(aState, bState);
                }

                // sort by item.price.bulk_price.amount * currencyRate
                else if (id === "bulkPrice") {
                    const aBulkPriceCurrencyRate = currencies.find(c => c.id === a.price.bulk_price.currency.global_currency_id)?.rate || 1;
                    const bBulkPriceCurrencyRate = currencies.find(c => c.id === b.price.bulk_price.currency.global_currency_id)?.rate || 1;
                    const aState = a.price.bulk_price.amount * aBulkPriceCurrencyRate;
                    const bState = b.price.bulk_price.amount * bBulkPriceCurrencyRate;
                    comparison = desc ? naturalCompare(bState, aState) : naturalCompare(aState, bState);
                }

                // sort by sku
                else if (id === "sku") {
                    comparison = desc ? naturalCompare(b.item.sku, a.item.sku) : naturalCompare(a.item.sku, b.item.sku);
                }

                // sort by item.item.name
                else if (id === "name") {
                    comparison = desc ? naturalCompare(b.item.name, a.item.name) : naturalCompare(a.item.name, b.item.name);
                }

                if (comparison !== 0)
                    return comparison;
            }

            return 0;
        });
    }, [items, currencies, categoriesTree, filter, sorting]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.item.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: onPaginationChange,
        onSortingChange: onSortingChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        meta: {
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                const rdaItem = row.original;
                const alertRed = 'rgb(227 81 97 / 23%)';
                const alertYellow = 'rgb(252 219 113 / 37%)';
                const rowStyle = {};

                if (filter.warehouseId) {
                    const warehouseItem = rdaItem.warehouse_states.warehouse_items.find((w) => w.id === filter.warehouseId);

                    if (warehouseItem.state < 0) {
                        rowStyle.backgroundColor = alertRed;
                        return rowStyle;
                    }

                    if (warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state) {
                        rowStyle.backgroundColor = alertYellow;
                        return rowStyle;
                    }
                } else {
                    const itemTotalWarehouseState = rdaItem.warehouse_states.warehouse_items.reduce((acc, item) => acc + item.state, 0);
                    if (itemTotalWarehouseState < 0) {
                        rowStyle.backgroundColor = alertRed;
                        return rowStyle;
                    }

                    if (rdaItem.warehouse_states.warehouse_items.some((warehouseItem) => warehouseItem.alert_on !== null && warehouseItem.alert_on > warehouseItem.state)) {
                        rowStyle.backgroundColor = alertYellow;
                        return rowStyle;
                    }
                }

                return rowStyle;
            },
        },
        state: {
            pagination: pagination,
            sorting: sorting,
            rowSelection: rowSelection,
            columnVisibility: columnVisibility
        }
    });

    if (!isItemView) return <Error403 />

    return (
        <Card>
            <Card.Header>
                <ItemDataTableHeader table={table}
                                     filter={filter}
                                     onFilterChange={onFilterChange}
                                     onDebouncedFilterChange={onDebouncedFilterChange}
                                     sorting={sorting}
                                     onSortingChange={onSortingChange}
                                     columnVisibility={columnVisibility}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={data.length}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default ItemDataTable;