import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import AddContractorPayout from "./AddContractorPayout";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync,
    selectFilterOptionsContractorPayout, selectLoading
} from "../../../../../app/store/reducers/contractor/contractorPayoutReducer";
import {Controller, useForm} from "react-hook-form";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {ContractorPayoutSources} from "../../../../../enum/ContractorPayoutSources";
import SelectContractor from "../SelectContractor";
import {PermissionCRMContractorPayoutCreate} from "../../../../../enum/Permission/CrmPermission";
import useCheckPermission from "../../../../../hooks/useCheckPermission";

const ContractorPayoutDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const payoutFilters = useSelector(selectFilterOptionsContractorPayout);
    const dispatch = useDispatch();
    const dateFormat = useSelector(selectDateFormat);
    const isLoading = useSelector(selectLoading);

    // permissions
    const isPayoutCreate = useCheckPermission(PermissionCRMContractorPayoutCreate)

    const methods = useForm({
        defaultValues: {
            contractor: payoutFilters.contractor_id || null,
            date_start: payoutFilters.date_start ? dayjs(payoutFilters.date_start).toDate() : null,
            date_end: payoutFilters.date_end ? dayjs(payoutFilters.date_end).toDate() : null,
            source: ContractorPayoutSources.find(source => source.value === payoutFilters?.payout_source) || null
        },
    });
    const onSubmitFilter = ({contractor, date_end, date_start, source, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD");
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD");
        const contractor_id = contractor?.id
        const payout_source = source?.value

        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start,
            date_end,
            contractor_id,
            payout_source,
            ...filters
        }))
    }

    return (
        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
            <Row className={'mb-1'}>
                <Col className="d-flex justify-content-between">
                    <h5 className="text-nowrap">{t(lang, "Платежи от контрагента")}</h5>
                    {isPayoutCreate && <div>
                        <AddContractorPayout/>
                    </div>}
                </Col>
            </Row>
            <Row className={'mb-1'}>
                <Form.Group as={Col} md={3} xs={12}>
                    <Form.Label>Контрагент</Form.Label>
                    <Controller
                        control={methods.control}
                        name="contractor"
                        render={({field}) => (
                            <SelectContractor
                                placeholder={t(lang, "edi.common.button.select")}
                                classNamePrefix="react-select"
                                onChange={field.onChange}
                                defaultValue={field?.value}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={3} xs={12}>
                    <Form.Label>Источник</Form.Label>
                    <Controller name={'source'}
                                className={''}
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <Select
                                            options={ContractorPayoutSources}
                                            value={field.value}
                                            onChange={field.onChange}
                                            getOptionValue={option => option.value}
                                            getOptionLabel={option => t(lang, option.label)}
                                            placeholder={t(lang, "edi.common.button.select")}
                                            classNamePrefix={'react-select'}
                                            isClearable
                                            hideSelectedOptions/>
                                    )
                                }}
                    />
                </Form.Group>
                <Form.Group as={Col} md={3} xs={12}>
                    <Form.Label>Дата оплата (начало)</Form.Label>
                    <Controller
                        control={methods.control}
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={"Дата оплата (начало)"}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={3} xs={12}>
                    <Form.Label>Дата оплата (конец)</Form.Label>
                    <Controller
                        control={methods.control}
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={"Дата оплата (конец)"}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={12} className={'text-end'}>
                    <Button variant="primary"
                            type='submit'
                            size={'xs'}
                            disabled={isLoading}
                            className={"ms-2 mt-4"}>
                        {t(lang, "edi.invoice.datatable.header.filter.show")}
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    );
};

ContractorPayoutDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractorPayoutDataTableHeader;
