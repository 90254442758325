import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useState} from "react";
import {InputGroup, Spinner} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {debounce} from "underscore";
import AddContractor from "./AddContractor";
import EditContractor from "./EditContractor";
import {selectAllContractor} from "../../../app/store/reducers/contractor/contractorReducer";
import IconButton from "../../common/IconButton";
import {saleChangeContractorAsync} from "../../../app/store/reducers/warehouse-operation/saleReducer";
import {toast} from "react-toastify";
import {transliterate} from "transliteration";
import useCheckPermission from "../../../hooks/useCheckPermission";
import {PermissionCRMContractorCreate, PermissionCRMContractorUpdate} from "../../../enum/Permission/CrmPermission";


const ContractorSelect = ({defaultValue, onChange, allowEdit, allowAdd, executor, buyer, onFocus, isEditing, changeContractor, loading, ...props}) => {
    const [value, setValue] = useState(null);
    const contractors = useSelector(selectAllContractor);
    const lang = useSelector(selectLang);
    const t = Translate;

    // permissions
    const isContractorCreate = useCheckPermission(PermissionCRMContractorCreate)
    const isContractorEdit = useCheckPermission(PermissionCRMContractorUpdate)

    const filterOptions = {page: 0, limit: 10}

    useEffect(() => {
        if (defaultValue !== null) {
            setValue(contractors?.find(x => x.id === defaultValue))
        } else {
            setValue(null)
        }
    }, [contractors, defaultValue])


    const loadOptions = (query, callback) => {
        if (query) {
            const searchLower = query.trim().toLowerCase();
            const transliteratedQuery = transliterate(searchLower);
            const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');

            const phoneType = 1;

            const filteredContractors = contractors?.filter(i => {
                const itemNameLower = i?.name?.trim().toLowerCase();
                const transliteratedItemName = transliterate(itemNameLower);

                const matchesName = filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length;
                const matchesInn = i?.inn?.includes(searchLower);
                const matchesContact = i?.contacts.find(contact => contact.type === phoneType && contact.value.includes(searchLower)) !== undefined;

                return matchesName || matchesInn || matchesContact;
            });

            callback(filteredContractors.slice(filterOptions?.page, filterOptions?.limit));
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 100);

    const onChangeContractor = option => {
        if (isEditing) {
            setValue(option)
        } else {
            setValue(option)
            onChange && onChange(option)
        }
    }

    const onContractorChanged = (e) => {
        changeContractor(e,value, setValue, defaultValue)
    }

    return(
        <>
            <InputGroup>
                <div className="react-select form-control p-0">
                    <AsyncSelect
                        loadOptions={debouncedLoadOptions}
                        onChange={(option)=> onChangeContractor(option)}
                        value={value}
                        autoFocus={onFocus}
                        hideSelectedOptions
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        placeholder={t(lang, 'crm.common.datatable.filter.contractor')}
                        defaultOptions={contractors?.slice(filterOptions?.page, filterOptions?.limit)}
                        noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                        styles={{
                            container: (provided) => ({
                            ...provided,
                            margin: '-1px'
                        })}}
                        {...props}
                    />
                </div>
                {isContractorCreate && allowAdd && !value &&
                    <AddContractor executor={executor} buyer={buyer}/>
                }
                {isContractorEdit && (value?.id !== defaultValue) || allowEdit && value &&
                    <EditContractor contractor={value}  />
                }
                {isEditing && value && value?.id !== defaultValue && <>
                    <IconButton
                        variant={"falcon-info"}
                        title={"Save"}
                        size="md"
                        style={{zIndex: 0}}
                        className="fs-1 position-relative"
                        onClick={() => onContractorChanged("Save")}
                        icon={!loading && "check"}
                    >
                        {loading && <Spinner size={'sm'} className='align-middle' animation='border' role='switch'/>}
                    </IconButton>
                    <IconButton
                        variant="danger"
                        title={"Cancel"}
                        size="md"
                        style={{zIndex: 0}}
                        className="fs-1 position-relative"
                        onClick={() => onContractorChanged("Cancel")}
                        icon="cancel"
                    />
                </>}
            </InputGroup>
        </>
    )
}

ContractorSelect.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    allowEdit: PropTypes.bool,
    allowAdd: PropTypes.bool,
    props: PropTypes.any,
    executor: PropTypes.bool,
    buyer: PropTypes.bool,
}

ContractorSelect.defaultProps = {
    onChange: () => {},
    defaultValue: "",
    allowEdit: false,
    allowAdd: false,
    executor: false,
    buyer: false,
}

export default ContractorSelect;
