import React, {Fragment, useMemo, useState} from 'react';
import classNames from "classnames";
import {Link} from "react-router-dom";
import CardDropdown from "../../../common/CardDropdown";
import {Card, Col, Dropdown, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {commonTotalAmount, totalGroupAmounts, totalGroupCashAmount} from "../../../../enum/cash_box/cashBoxWrapper";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectedCurrencyForCashBox, setCurrency} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import {PermissionCashBoxDelete, PermissionCashBoxUpdate} from "../../../../enum/Permission/CashBoxPermission";

const CashBoxDatatableBody = ({item, index, setDefaultValue, setShow, setShowConfirmationModal}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)
    const commonSelectedCurrency = useSelector(selectedCurrencyForCashBox)

    const [selectedCurrency, setSelectedCurrency] = useState(commonSelectedCurrency)

    // permissions
    const isCashBoxUpdate = useCheckPermission(PermissionCashBoxUpdate)
    const isCashBoxDelete = useCheckPermission(PermissionCashBoxDelete)

    const totalAmount = useMemo(() => {
        return commonTotalAmount(item, currency)
    }, [selectedCurrency, currency, item])
    return (
        <Col xxl={3} md={6} className={'position-relative'}>
            <strong className={'position-absolute z-index-2'} style={{left: 5}}>{index + 1})</strong>
            <Card className={classNames(`mb-2 px-2 h-100 border-md-0 border-sm-dashed-bottom`)}>
                <div className={'text-center position-relative'}>
                    <Link className={'fw-bold '} to={{
                        pathname: `/cash-box/operation`,
                        state: item
                    }}>{item.name}</Link>
                    {(isCashBoxUpdate || isCashBoxDelete) && <div className={'position-absolute'} style={{right: 0, top: 0}}>
                        <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
                            {isCashBoxUpdate && <Dropdown.Item
                                onClick={() => {
                                    setDefaultValue(item)
                                    setShow(true)
                                }}
                                className="text-warning">
                                <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                                <span>{t(lang, "roaming.common.edit")}</span>
                            </Dropdown.Item>}

                            {isCashBoxDelete && <Dropdown.Item
                                onClick={() => {
                                    setShowConfirmationModal(true)
                                    setDefaultValue(item)
                                }}
                                className="text-danger">
                                <FontAwesomeIcon className="me-1" icon={"trash"}/>
                                <span>{t(lang, "roaming.invoice.delete")}</span>
                            </Dropdown.Item>}
                        </CardDropdown>
                    </div>}
                </div>
                <Row className={'align-items-center justify-content-between'}>
                    <Col xs={12} md={6}>{item.branch.name}</Col>
                    <Col xs={12} md={6} className={'text-end'}>
                        <span className={'fs--1'}>{item.created_at}</span>
                    </Col>
                    <Col md={12} className={'text-end'}>
                        {
                            totalGroupCashAmount(item.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                                .filter(i => i.total !== 0)
                                .map((i, index) => (
                                    <strong key={index} className={classNames('text-success ms-2 py-2')}
                                            style={{fontSize: 12}}>
                                        <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                        <strong className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                                    </strong>
                                ))
                        }
                    </Col>
                    <Col md={12}
                         className={'text-end mt-2 d-flex flex-wrap gap-2 justify-content-end mb-md-3'}>
                        {
                            totalGroupAmounts(item.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type)))
                                .filter(i => i.total !== 0)
                                .map((i, index) => (

                                    <OverlayTrigger trigger={['hover', 'focus']}
                                                    placement={'bottom'}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <div
                                                                    className={'d-block text-center fs--1 px-2'}
                                                                    style={{backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>{t(lang, ContractorPaymentTypes.find(b => b.value === i?.money_type).label)}</div>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <div className={'d-block text-center fs--1 cursor-pointer'}>
                                            <strong key={index} className={`px-1 fw-bold`}
                                                    style={{
                                                        fontSize: 12,
                                                        backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg
                                                    }}>
                                                <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                                            </strong>
                                        </div>
                                    </OverlayTrigger>


                                ))
                        }
                    </Col>
                </Row>
                {totalAmount > 0 && <div className={'d-flex justify-content-end align-items-center gap-1'}>
                    <h6 className={'fw-bold mb-0'}>{numeral.formats[numberFormat].format(totalAmount / selectedCurrency?.rate)}</h6>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant={'link'}
                            className="p-0"
                        >
                            <strong className={'text-info'}>{selectedCurrency?.name}</strong>
                        </Dropdown.Toggle>
                        {currency.filter(i => i.is_active).length > 1 && <Dropdown.Menu
                            style={{minWidth: '2rem'}}
                            className="py-1">
                            {currency
                                .filter(i => i.is_active)
                                .filter(i => i.id !== selectedCurrency?.id)
                                .map(i => (
                                    <Dropdown.Item key={i.id}
                                                   onClick={() => setSelectedCurrency(i)}
                                                   as="div"
                                                   className="px-3 py-2 cursor-pointer">
                                        <strong>{i.name}</strong>
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>}
                    </Dropdown>
                </div>}
            </Card>
        </Col>
    );
};

export default React.memo(CashBoxDatatableBody);
