import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const EditableCellConfirmationModal = ({ show, onConfirm, onCancel }) => {

    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, 'warehouse.operation.common.confirmation.edit')}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="falcon-danger" onClick={onCancel}>
                    {t(lang, 'warehouse.operation.item.common.close')}
                </Button>
                <Button variant="falcon-primary" onClick={onConfirm}>
                    {t(lang, 'warehouse.operation.item.common.edit_button')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditableCellConfirmationModal;